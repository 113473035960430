import React, { useState, useEffect, useRef, useCallback  } from 'react';
import Logo from "../images/logo.svg";
import Select from 'react-select';
import Sidebar from '../component/Sidebar';
import { SelectPicker, Placeholder, Message ,Whisper, Tooltip, Popover, Button  } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { Link, useNavigate } from "react-router-dom";
import * as bootstrap from 'bootstrap';
import { ReactComponent as IconStarOutline } from "../images/star-outline.svg";
import { ReactComponent as IconStar } from "../images/IconStar.svg";
import debounce from 'lodash.debounce';
import IconInfo from "../images/IconErrorInfo.svg";
import IconError from "../images/IconError.svg";
import IconCheckError from "../images/IconCheckError.svg";
import Open_Link_Icon from "../images/Open_link_round.svg";
import IconJson from "../images/IconJSONAction.svg";
import 'aos/dist/aos.css';
import AOS from 'aos';


function History() {
  const [tabName, setTabName] = useState('Compliance History');
  useEffect(() => {
    document.title = tabName;
  }, [tabName]);


  useEffect(() => {
    AOS.init();
  }, []);

  const [isLoading, setLoading] = useState(true); // State to manage loading state
  const navigate = useNavigate();
  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const frontEndLink = process.env.REACT_APP_MUSAFFA_LINK;
  const roleName = localStorage.getItem("novaRolename");
  const merlinJuniorAnalyst = process.env.REACT_APP_MERLIN_JUNIOR_ANALYST;
  const [pageRange, setPageRange] = useState("20");

  const abortControllerRef = useRef(null);
  const [reportData, setReportData] = useState([]);
  const [reportCount, setReportCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [countryCode, setCountryCode] = useState("");
  const [complianceId, setComplianceId] = useState("");
  const [statusId, setStatusId] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const [countryData, setCountryData] = useState([]);
  const [complianceData, setComplianceData] = useState([]);
  const [statusData, setStatusData] = useState([]);

  const [selectedQuarter, setSelectedQuarter] = useState("");

  const userid = localStorage.getItem("novauserid");
  const progressStatus = process.env.REACT_APP_TASK_MANAGER_PROGRESS_Status;


  const [errorMessage, setErrorMessage] = useState('');
  const [messageType, setMessageType] = useState(null); // success, error, info

  const handleQuaterChange = (selectedOption) => {
    setSelectedQuarter(selectedOption); // Update selectedPeriod state with the selected option
  };

  //Country List
  useEffect(() => {
    const fetchCountryList = async () => {
      const url = ` ${apiEndPoint}/get_all_country_list`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response

        const formattedCountryData = data.data.map((country) => ({
          label: country.country_name,
          value: country.country_code,
        }));

        setCountryData(formattedCountryData);
  
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchCountryList();
  }, []);

  // Compliance List
  useEffect(() => {
    const fetchComplianceStatusList = async () => {
      const url = `${apiEndPoint}/get_compliance_status`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response

        const formattedComplianceStatusData = data.data.map((complaince) => ({
          label: complaince.name,
          value: complaince.id,
        }));

        setComplianceData(formattedComplianceStatusData);
  
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchComplianceStatusList();
  }, []);

  //Status List
  useEffect(() => {
    const fetchStatusList = async () => {
      const url = `${apiEndPoint}/get_status`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response

        const formattedStatusData = data.data.map((status) => ({
          label: status.name,
          value: status.id,
        }));

        setStatusData(formattedStatusData);
  
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchStatusList();
  }, []);

  const fetchGlobalReport = async () => {
    setLoading(true);
// Cancel the previous request if any
if (abortControllerRef.current) {
  abortControllerRef.current.abort();
}

 // Create a new AbortController for the current request
 const controller = new AbortController();
 abortControllerRef.current = controller;


        try {
      const apiUrl = `${apiEndPoint}/get_compliance_history_listing`;
      const requestData = {
        data: {
          user_id: userid,
          limit: pageRange,
          page: currentPage.toString(),
          q:searchInput,
          country_code: countryCode,
          AAOIFI_status:complianceId,
          start_date:"",
          end_date:"",
          sort_by:"",
          sort_order:"",
          symbol:"",

        }
      };
      console.log(JSON.stringify(requestData));
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData),
        signal: controller.signal
      });

      const jsonData = await response.json();
      const { status, message, count, data } = jsonData.data;
      if (status == "success") {
        setLoading(false);
        setReportCount(count);
        console.log('RES :'+ searchInput + ' : '+ JSON.stringify(data));
        setReportData(data);
      } else {
        setLoading(false);
        console.error("Error: ", message);
        setReportData("");
        setReportCount(0);
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request cancelled");
      }
      else{
        console.error("Error fetching data:", error.message);
      }
      return false;
    }
  };

  const debouncedFetchGlobalReport = useCallback(
    debounce(() => {
      fetchGlobalReport();
    }, 500),
    [searchInput] // Only debounce on search input changes
  );

  useEffect(() => {
    debouncedFetchGlobalReport();
    return () => {
      debouncedFetchGlobalReport.cancel();
    };
  }, [searchInput]);

  useEffect(() => {
    fetchGlobalReport();
  }, [currentPage, countryCode, complianceId, statusId,pageRange]); // Regular effect for other dependencies


   
  const data2 = [
    { label: "Q1", value: 1 },
    { label: "Q2", value: 2 },
    { label: "Q3", value: 3 },
    { label: "Q4", value: 4 },
  ];



const handleSearch = (event) => {
  console.log(event.target.value);

  setSearchInput(event.target.value);
  setCurrentPage(1); // Reset to the first page for new search
};


const handleCountryChange = (value) => {
  setCountryCode(value);
};

const handleComplianceChange = (value) => {
  setComplianceId(value);
};

const handleStatusChange = (value) => {
  setStatusId(value);
};



const handleAddNewReport = async(companyId) => {

 

  // Fetch the specific company details based on companyId if needed
  const selectedCompany = reportData.find(company => company.row_id === companyId);

  // Example: Accessing company data
  if (selectedCompany) {

    const tickerAndCompanyName = selectedCompany.date + ' : ' + selectedCompany.company_symbol;
    const reportSource = document.getElementById(`reportSource_${companyId}`).value;
    
    if(!reportSource)
    {
      setErrorMessage('Please Enter Report Year');
      setMessageType('error');
      return false;
    }
    if(!selectedQuarter)
    {
        setErrorMessage('Please Select Quater');
        setMessageType('error');
        return false;
    }
    


    const date = selectedCompany.date;
    const company_symbol = selectedCompany.company_symbol;
    const year = reportSource;
    const quarter = selectedQuarter;
    
    //await checkTask(selectedCompany,reportSource,selectedQuarter)
    //await updateReportStatus(userid,company_symbol,date,quarter,year,selectedCompany.companyId);
    const taskCheckSuccess = await checkTask(selectedCompany, reportSource, selectedQuarter);
    if (taskCheckSuccess) {
      const cancelButton = document.getElementById(`Cancel_${companyId}`);
      if (cancelButton) {
        cancelButton.click();
      }
      await updateReportStatus(userid, company_symbol, date, quarter, year, selectedCompany.companyId);
    }
  }
};

const checkTask = async (selectedCompany,reportSource,selectedQuarter) => {
      try {
          const res = await fetch(`${apiEndPoint}/check_task`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                  data: {
                      user_id: userid,
                      company_symbol: selectedCompany.company_symbol,
                      quarter: selectedQuarter,
                      year: reportSource,
                  },
              }),
          });

          const result = await res.json();

          if (result.data.status === 'Success') {
            // setErrorMessage(result.data.message);
            // setMessageType('success');
            // return false;
            return true;
          } else {
            setErrorMessage(result.data.message);
            setMessageType('error');
            return false;
          }
      } catch (err) {
        console.log('Check Report : Error : ' + err.message);
        setErrorMessage('An error occurred while checking the task');
        setMessageType('error');
        return false; // Indicate failure
      }
};


const updateReportStatus = async (userid,company_symbol,date,quarter,year,companyId) => {
  const url = `${apiEndPoint}/update_tasks`;
  
  const payload = {
    data: {
      user_id: userid, // Replace this with the actual user_id if dynamic
      company_symbol: company_symbol, // Assuming itemId represents company_symbol
      date: date, // Replace this with the actual date if dynamic
      quarter: quarter, // Replace this with the actual quarter if dynamic
      year: year, // Replace this with the actual year if dynamic
      status: progressStatus
    }
  };

  //console.log(JSON.stringify(payload));
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const responseData = await response.json();
    const data = responseData.data; // Flatten the response
    if (data.status === "success") {
      //window.open(`/report/ancasdasdas`, '_blank');
     
        navigate(`/report/${data.data.encryptJSON}`);
      //
      return true; // Indicate success
    } else {
      console.error("API Error:", data.data.message);
      return false; // Indicate failure
    }
  } catch (error) {
    console.error("API Error:", error);
    return false; // Indicate failure
  }
};

const [customPaginationValue, setCustomPaginationValue] = useState("");

  // Debounced function to update the page number
  const debouncedSetPage = useCallback(
    debounce((page) => {
      setCurrentPage(page);
    }, 500), // Delay of 500ms (half a second)
    []
  );

  const handlePaginationChange = (e) => {
    const value = (e.target.value == 0 ? 1 : e.target.value).toString() ;
    setCustomPaginationValue(value); // Update input value immediately for fast typing
    debouncedSetPage(Number(value)); // Trigger the debounced function
  };

  const handlePagelimit = (value) => {
    setCurrentPage(1);
    setCustomPaginationValue(1);
    setPageRange(value); 
  };

  
  const renderPagination = () => {
    // Calculate total number of pages
    const totalPages = Math.ceil(reportCount / pageRange);
  
    // Show a default of 3 pages if the total pages are more than 3
    const pageNumbers = [];
    const visiblePageCount = 4; // Number of pages to show by default
  
    let startPage = currentPage - 1;
    let endPage = currentPage + 2;
  
    // Adjust pagination if total pages are less than the default
    if (totalPages <= visiblePageCount) {
      startPage = 1;
      endPage = totalPages;
      
    } else {
      
      // Ensure the pagination does not go out of bounds
      startPage = Math.max(1, startPage);
      endPage = Math.min(totalPages, endPage);
    }
  
    // Create page numbers to display based on current page
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
  
    return (
      <nav aria-label="..." id='pagination_row' style={{alignItems:'center', justifyContent:'space-between', background:'#FFF', paddingTop:0, paddingBottom:0}}>
        <div className='totalRecords'>
          Total Records :  {Number(reportCount).toLocaleString()}
        </div>
        <ul className="pagination" style={{border: 0, background: 'none'}}>

        <li class="page-item" style={{marginLeft:10}}>
                      <SelectPicker onChange={handlePagelimit} cleanable={false}
                      value={pageRange} 
                      placement="top" placeholder='Pages' data={PagingData} searchable={false} style={{ width: 85, height:35, display:'flex', alignItems:'center', borderRadius: 5, border: '1px solid #A1B9ED'}} />
                    </li>

          {/* Previous Page (Double Arrow) */}
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`} style={{marginLeft: 10}}>
            <a href="#" className="page-link" onClick={() => setCurrentPage(1)}>
              <i className="fa fa-angle-double-left"></i>
            </a>
          </li>
  
          {/* Previous Page (Single Arrow) */}
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a href="#" className="page-link" onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}>
              <i className="fa fa-angle-left"></i>
            </a>
          </li>
  
          {/* Page numbers */}
          {pageNumbers.map(page => (
            <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
              <a 
                className="page-link" href="#" 
                onClick={() => setCurrentPage(page)} // Set the clicked page as the current page
              >
                {page}
              </a>
            </li>
          ))}
          
          {/* Next Page (Single Arrow) */}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a href="#" className="page-link" onClick={() => setCurrentPage(Math.min(totalPages, currentPage + 1))}>
              <i className="fa fa-angle-right"></i>
            </a>
          </li>
  
          {/* Next Page (Double Arrow) */}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`} style={{marginRight: 10}}>
            <a href="#" className="page-link" onClick={() => setCurrentPage(totalPages)}>
              <i className="fa fa-angle-double-right"></i>
            </a>
          </li>
          
          {/* Go to Page */}
          <li className="page-item" style={{marginLeft: 10, display: 'flex', alignItems: 'center', gap: 5}}>
            <input
              type="text"
              placeholder="Go#"
              className="form-control"
              value={customPaginationValue}
              onChange={handlePaginationChange}
              onInput={(e) => {
                // Only allow numbers
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
              }}
            
              style={{
                padding: '0px 10px', 
                color: '#878787', 
                fontSize: 16, 
                borderRadius: 5, 
                border: '1px solid #A1B9ED', 
                width: 60, 
                height: 35, 
                textAlign: 'center'
              }}
            />
          </li>
        </ul>
      </nav>
    );
  };
  
  
  


// PAGINATION
const PagingData = ['10', '20', '50', '100'].map(
  item => ({ label: item, value: item })
);



const handleClearFilters = () => {
  setCurrentPage(1);
  setCountryCode("");
  setSearchInput("");
  setComplianceId("");
};


const renderStars = (ranking) => {
  const fullStars = Math.floor(ranking);
  const stars = [];
  for (let i = 0; i < 5; i++) {
    if (i < fullStars) {
      stars.push(<li key={i}><IconStar /></li>);
    } else {
      stars.push(<li key={i}><IconStarOutline /></li>);
    }
  }
  return stars;
};

const hideMessage = () => {
  setMessageType(null);
};

const renderMessage = () => {
  if (messageType) {
    setTimeout(hideMessage, 4000);
  };

  switch (messageType) {
    case 'success':
      return (
        <Message type="success" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:"fixed", right:10, top:10, zIndex: 99999}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_success'>
              <div className='icon_error_msg_inner Icon_success_inner'>
                <img src={IconCheckError} alt="Success Icon" />
                <div className="progress-circle progress_circle_success"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_success_text'>
              <h5>Success</h5>
              <p>{errorMessage}</p>
            </div>
          </div>
        </Message>
      );
    case 'error':
      return (
        <Message type="error" closable style={{ display: 'flex', alignItems: 'center', width: 'auto',  position:"fixed", right:10, top:10, zIndex: 99999}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_error'>
              <div className='icon_error_msg_inner Icon_error_inner'>
                <img src={IconError} alt="Error Icon" />
                <div className="progress-circle progress_circle_error"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_error_text'>
              <h5>Error</h5>
              <p>{errorMessage}</p>
            </div>
          </div>
        </Message>
      );
      case 'info':
      return (
        <Message type="info" closable style={{ display: 'flex', alignItems: 'center', width: 'auto',  position:"fixed", right:10, top:10, zIndex: 99999}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_info'>
              <div className='icon_error_msg_inner Icon_info_inner'>
                <img src={IconInfo} alt="Info Icon" />
                <div className="progress-circle progress_circle_info"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_info_text'>
              <h5>Info</h5>
              <p>No reports found for the selected priority</p>
            </div>
          </div>
        </Message>
      );
      default:
        return null;
  }
};



const [openStatusPopover, setOpenStatusPopover] = useState(null);
const [publishSymbol, setPublishSymbol] = useState("");
  // Function to handle checkbox click
  const handleCheckboxClick = (item) => {
      // Toggle the popover visibility for the specific item
      setPublishSymbol(item.company_symbol)
      if (openStatusPopover === item.id) {
        setOpenStatusPopover(null); // Close the popover if it's already open
      } else {
        setOpenStatusPopover(item.id); // Open the popover
      }
  };

  // Function to handle deletion confirmation
  const handlePublishConfirm = async (company) => {
    try {
      // Perform the API call
      const response = await fetch(`${apiEndPoint}/update_company_status`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          data: {
            user_id: userid,
            status: company.report_status ? '0' : '1',
            symbol: company.company_symbol // Assuming 'company.symbol' holds the company symbol
          }
        })
      });
  
      // Parse the response as JSON
      const result = await response.json();
  
      // Handle the response based on the status
      if (response.ok && result.data.status === 'success') {
        // Show success message
        console.log(result.data.message); // e.g., "Company status updated successfully"
        // Optionally, you can display a UI notification or message here
        fetchGlobalReport();
        // Close the popover after confirmation
        setOpenStatusPopover(null);

      } else {
        // Handle failure case (if any)
        console.error('Failed to update company status:', result.data.message || 'Unknown error');
        // Optionally, display an error message in the UI
      }
    } catch (error) {
      console.error('Error:', error);
      // Handle any network or unexpected errors
    }
  
    
  };
  

  const handleCheckboxChange = (e, item) => {
    setPublishSymbol(item.company_symbol)
    //e.stopPropagation(); // Prevents the event from bubbling up
    // Handle the checkbox change here
    // You can check e.target.checked to see if it's checked or unchecked
};



  return (
    <>
      <Sidebar />
      <div id='header'>
        <div className='logo'>
          <div className='logo_icon'>
            <img src={Logo} alt="Logo" />
          </div>
          <div className='brand_name'>Compliance History</div>
        </div>

        <div className='header_table_filter'>
          <div className='searchfield'>
            <button className='btn_search'><i className='icon-search2'></i></button>
            <input type='text' className='filter_searchfield' placeholder='Search' autocomplete="off"  onChange={handleSearch}   value={searchInput}/>
          </div>


          <div className='dropdown_col_filter dropdown__select'>
          <SelectPicker
              data={countryData}
              searchable={false}
              style={{width:170}}
              placeholder="Filter by Country"
              onChange={handleCountryChange}
              value={countryCode}
            />
          </div>
          
          <div className='dropdown_col_filter dropdown__select'>
            <SelectPicker
              data={complianceData}
              searchable={false}
              style={{ width: 180 }}
              placeholder="All Compliance"
              onChange={handleComplianceChange}
              value={complianceId}
            />
          </div>

          <a href="javascript:void(0)" className="btn_clear_filter" style={{textDecoration:"none"}} onClick={handleClearFilters}>
            Clear Filters
          </a>
        </div>
      </div>
      <div className="container-fluid content pb-5">
        <div className='company_table_list'>
        {isLoading && ( <Placeholder.Grid rows={20} columns={9} active style={{paddingLeft:20, paddingRight:20, paddingTop:12}} /> )}

{/* Table */}
{!isLoading && (
<>
          <div className='table-responsive clsPagingWrap' >
            <table className='table'>
              <thead>
                <tr>
                  <th>Ticker and Company Name</th>
                  <th>ISIN</th>
                  <th>Compliance</th>
                  <th>Report Date</th>
                  <th>Year</th>
                  <th>Quarter</th>
                  <th>Created Time</th>
                </tr>
              </thead>
              <tbody>
              {reportData.length > 0 ? (
  reportData.map((company, index) => (
                  <tr key={index}>
                    <td>
                      <div className='company_name_td'>
                        <span className='company_lable'>{company.main_ticker}</span>
                        {company.company_name}
                      </div>
                    </td>
                    <td>
                      <div className="table_td_big_content_url" >
                        {company.isin}
                      </div>
                    </td>
                    <td>
                        <span className={`tag_td ${ company.compliance_status == "0" || company.compliance_status == null ? '' : company.compliance_status == "COMPLIANT" ? 'halal_tag' : company.compliance_status == "NON_COMPLIANT" ? 'not_halal_tag' : company.compliance_status == "QUESTIONABLE" ? 'doughtful_tag' : company.AAOIFI_status == "4" ? 'uncover_tag' : 'doubtful_tag' }`} style={{width:75}}>
                       
                       {company.compliance_status === 'NON_COMPLIANT' ? 'NCMPT' : company.compliance_status === 'COMPLIANT' ? 'CMPT' : company.compliance_status === 'QUESTIONABLE' ? 'DBTF' : company.compliance_status}
                        </span>
                    
                    </td>

                    <td>
                    {company.report_date}
                    </td>
<td>
{company.reported_year}
</td>
                 <td>
                  {company.reported_quarter}
                 </td>
                 <td>
                 {company.create_date_time}
                 </td>

                  </tr>



                ))
              ) : (
                <tr>
                  <td colSpan="9" style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              )}
              </tbody>
            </table>
          </div>

          {reportCount > 0 && renderPagination()}


        </>    
)}
        </div>
      </div>

    </>
  );
}

export default History;
