import React,  { useState, useEffect }  from 'react';
import Sidebar from "../component/Sidebar";
import { useParams, useNavigate, Navigate } from "react-router-dom";

function ComplianceMerlineView() {
    const { encryptJSON } = useParams();
    const [responseDecodeData, setResponseDecodeData] = useState({});
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const userid = localStorage.getItem("novauserid");
    
    const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;

    const navigate = useNavigate();
    
      //Decode API
      useEffect(() => {
        const fetchData = async () => {
          const response = await fetch(`${apiEndPoint}/get_compliance_decode`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              data: {
                user_id: userid,
                id: encryptJSON
              }
            }),
          });
    
          const data = await response.json();
         
          setData(data);
        
          
        };
    
        fetchData();
      }, []);

  const handleBack = () => {
    navigate('/compliance-reports');
  };
  
  return (
   <>
    <Sidebar />
    

      <>{JSON.stringify(data, null, 2)}</>
    

   </>
  );
}

export default ComplianceMerlineView;
