// NEW CODE DELETE INCLUDE
import React, { useState, useEffect, useRef, createContext, useContext, forwardRef, } from "react";
// import Select from 'react-select';
//import ReportPdf from "../images/report1.pdf";
import Select from "react-select";
import { ReactComponent as Happyicon } from "../images/IconGreen.svg";
import { ReactComponent as Sadicon } from "../images/IconRed.svg";
import { ReactComponent as Doubticon } from "../images/IconOrange.svg";
import Logo from "../images/logo.svg";
import Sidebar from "../component/Sidebar";
import IconSlide from "../images/btn-slide.svg";
import { useStopwatch } from "react-timer-hook";
import IconPlay from "../images/icon-play.svg";
import IconPause from "../images/icon-pause.svg";
import { DatePicker, Tabs, SelectPicker, Whisper, Tooltip, Modal, Button, useToaster, Message, Popover,Placeholder, Loader, Toggle } from "rsuite";
// import DatePicker from "rsuite/DatePicker";
import "rsuite/DatePicker/styles/index.css";
import "rsuite/Tabs/styles/index.css";
import "rsuite/SelectPicker/styles/index.css";
import CurrencySelect from "react-select-currency";
//import sampledata1 from "../sample/sample.json";
//import pdfjsLib from 'pdfjs-dist';
import YearPicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useParams, useNavigate } from "react-router-dom";
import { auto } from "@popperjs/core";
import CryptoJS from "crypto-js";
import { FaLessThanEqual } from "react-icons/fa";
// import { set } from "rsuite/esm/utils/dateUtils";
import complianceJson from "../sample/complianceJsonExport.json";
import { FaListCheck } from "react-icons/fa6";
import IconNoData from "../images/IconNoData.svg";
import IconInfo from "../images/IconErrorInfo.svg";
import IconError from "../images/IconError.svg";
import IconCheckError from "../images/IconCheckError.svg";
import zIndex from "@mui/material/styles/zIndex";
//import Scrollbar from 'smooth-scrollbar';

import LoaderFull from "../component/Loader";
import IconNoComment from "../images/noComment.svg";
import iconWhiteCaret from "../images/iconWhiteCaret.svg";
import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';


import LoaderChecking from "../images/IconLoader.svg";
import LoaderChecked from "../images/IconsCheckSaving.svg";
import LoaderCross from "../images/IconCrossSaving.svg";
import 'rsuite/dist/rsuite.min.css';
import moment from 'moment-timezone';

// Create the TimerContext
const TimerContext = createContext();

// TimerProvider component
const TimerProvider = ({ children }) => {
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [timerRunning, setTimerRunning] = useState(true);

  useEffect(() => {
    let timerInterval;
    if (timerRunning && startTime !== null) {
      timerInterval = setInterval(() => {
        const currentTime = Date.now();
        const elapsed = currentTime - startTime;
        setElapsedTime(elapsed);
      }, 1000);
    }
    return () => clearInterval(timerInterval);
  }, [timerRunning, startTime]);

  return (
    <TimerContext.Provider
      value={{
        startTime,
        setStartTime,
        elapsedTime,
        setElapsedTime,
        timerRunning,
        setTimerRunning,
      }}
    >
      {children}
    </TimerContext.Provider>
  );
};

// useTimer hook to access the TimerContext
const useTimer = () => useContext(TimerContext);

// Timer component
const Timer = forwardRef(
  ({ initialHours = 0, initialMinutes = 0, initialSeconds = 0 }, ref) => {
    const {
      startTime,
      setStartTime,
      elapsedTime,
      setElapsedTime,
      timerRunning,
      setTimerRunning,
    } = useTimer();
    const localRef = useRef(null);
    const timerRef = ref || localRef;

    useEffect(() => {
      const initialElapsed =
        (initialHours * 60 * 60 + initialMinutes * 60 + initialSeconds) * 1000;
      setElapsedTime(initialElapsed);
      setStartTime(Date.now() - initialElapsed);
    }, [initialHours, initialMinutes, initialSeconds, setElapsedTime, setStartTime]);

    const toggleTimer = () => {
      if (timerRunning) {
        setTimerRunning(false);
        setStartTime(null);
      } else {
        const currentTime = Date.now();
        if (!startTime) {
          setStartTime(currentTime - elapsedTime);
        }
        setTimerRunning(true);
      }
    };

    const formatTime = () => {
      const totalSeconds = Math.floor(elapsedTime / 1000);
      const seconds = totalSeconds % 60;
      const minutes = Math.floor((totalSeconds / 60) % 60);
      const hours = Math.floor(totalSeconds / 3600);
      return `${hours < 10 ? "0" + hours : hours}:${
        minutes < 10 ? "0" + minutes : minutes
      }:${seconds < 10 ? "0" + seconds : seconds}`;
    };

    return (
      <div className="timer">
        <span ref={timerRef}>{formatTime()}</span>
        <button onClick={toggleTimer} className="btn_timer_control">
          {timerRunning ? (
            <img src={IconPause} alt="Pause" />
          ) : (
            <img src={IconPlay} alt="Play" />
          )}
        </button>
      </div>
    );
  }
);

// const Report = () => {
function Report() {
  const timeZone = 'Asia/Kolkata';
  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const [isLoader, setIsLoader] = useState(false);

const [sequentialProcessReport, setSequentialProcessReport] = useState('Saving');
const [sequentialProcessComplaince, setSequentialProcessComplaince] = useState('Saving');
const [sequentialProcessPublish, setSequentialProcessPublish] = useState('Publishing');
const [sequentialProcessCompare, setSequentialProcessCompare] = useState('Comparing ');

const [sequenceLoaderTypeReport, setSequenceLoaderTypeReport] = useState(LoaderChecking);
const [sequenceLoaderTypeComplaince, setSequenceLoaderTypeComplaince] = useState(LoaderChecking);
const [sequenceLoaderTypePublished, setSequenceLoaderTypePublished] = useState(LoaderChecking);
const [sequenceLoaderTypeCompare, setSequenceLoaderTypeCompare] = useState(LoaderChecking);
  
const [isCheckingReport, setIsCheckingReport] = useState(true);
const [isCheckingComplaince, setIsCheckingComplaince] = useState(true);
const [isCheckingPublished, setIsCheckingPublished] = useState(true);
const [isCheckingCompare, setIsCheckingCompare] = useState(true);

const [isSequenceVisible, setIsSequenceVisible] = useState(false);



     // Define state variables to store hours, minutes, and seconds
     const [hours1, setHours] = useState("0");
     const [minutes1, setMinutes] = useState("0");
     const [seconds1, setSeconds] = useState("0");

  const [unpublishButtonLoader, setUnpublishButtonLoader] = useState(false);
  const [publishButtonLoader, setPublishButtonLoader] = useState(false);
  const [commentdata, setCommentData] = useState(null);
  const [commentCount, setCommentCount] = useState(0);
  const [commentLoading, setCommentLoading] = useState(true); // State to manage loading state
  const [commentFieldId, setCommentFieldId] = useState(null);
  const [commentFetchTextValue, setCommentFetchTextValue] = useState(null);
  const [deleteFetchTextValue, setDeleteFetchTextValue] = useState(null);

  const [lastcomments, setLastComments] = useState({});
  const lastCommentsRef = useRef({});


  // const [commentMessage, setCommentMessage] = useState('');
  // const [commentSource, setCommentSource] = useState('');

  const [tabName, setTabName] = useState('Screening Report');
  const [month36Val, setMonth36Val] = useState("");
  const [month24Val, setMonth24Val] = useState("");
  const [isEdit, setIsEdit] = useState(0);

 const [sampledata, setSampledata] = useState([]);
 const [chkCalculation, setChkCalculation] = useState(0);


 const [errorMessage, setErrorMessage] = useState('');
 const [messageType, setMessageType] = useState(null); // success, error, info


 const [errorMessage1, setErrorMessage1] = useState('');
 const [messageType1, setMessageType1] = useState(null); // success, error, info

 const [mcCurrency, setMCCurrency] = useState("");

 const [custom36MC, setCustom36MC] = useState(0);
 const [custom24MC, setCustom24MC] = useState(0);
 
 const [cbaStatus, setCBAStatus] = useState(true);
 const [cbaComment, setCbaComment] = useState('');
 const cbaCommentRef = useRef(null);

  // const options = [
  //   { value: 1, label: "Ones" },
  //   { value: 1000, label: "Thousands" },
  //   { value: 100000, label: "Lakhs" },
  //   { value: 1000000, label: "Millions" },
  //   { value: 10000000, label: "Crores" },
  //   { value: 1000000000, label: "Billions" },
  // ];

  const [selectedValue, setSelectedValue] = useState(null); //Dropdown value

  const options = [
    { label: "Ones", value: 1 },
    { label: "Thousands", value: 1000 },
    { label: "Lakhs", value: 100000 },
    { label: "Millions", value: 1000000 },
    { label: "Crores", value: 10000000 },
    { label: "Billions", value: 1000000000 },
  ];


  useEffect(() => {
    //  handleSelectChange(options.find((option) => option.value === selectedValue));
    handleSelectChange(selectedValue);
    }, [month36Val,month24Val,selectedValue]);

    
    const handleSelectChange = (selectedOption) => {

      setSelectedValue(selectedOption);
      
      //console.log(selectedOption.value); // Selected value
      //alert('val'+selectedOption.value + ' : val36'+ month36Val);
      const val36 = removeCommas(month36Val) / selectedOption;
      const val24 = removeCommas(month24Val)  / selectedOption;
      setCalculate36Val(val36);
      setCalculate24Val(val24);
      
  
      sumCalculation();
    };


  useEffect(() => {
    document.title = tabName;
  }, [tabName]);

  //Compliance Json varibale declare
  const [complianceAccountsReceivable, setComplianceAccountsReceivable] = useState("");
  const [complianceHalalSum, setComplianceHalalSum] = useState("");
  const [complianceNotHalalSum, setComplianceNotHalalSum] = useState("");
  const [complianceDoubtfulSum, setComplianceDoubtfulSum] = useState("");

  //const [complianceHalalPercentage, setComplianceHalalPercentage] = useState("");
  //const [complianceHalalRevenue, setComplianceHalalRevenue] = useState("");

  //const [complianceNotHalalRevenue, setComplianceNotHalalRevenue] = useState("");
  //const [complianceDoubtfulRevenue, setComplianceDoubtfulRevenue] = useState("");
  //const [complianceTotalRevenue, setComplianceTotalRevenue] = useState("");

  
  //const [compliance, setCompliance] = useState(null);

  //*******************END Compliance Json varibale********* */

  const timerRef = useRef(null);
  const roleName = localStorage.getItem("novaRolename");
  const userName = localStorage.getItem("novaFirstname");
  const [selectedDate, setSelectedDate] = useState(null);
  const [isChecked, setIsChecked] = useState(false);

  const progressStatus = process.env.REACT_APP_TASK_MANAGER_PROGRESS_Status;

  const navigate = useNavigate();
  const { encryptJSON } = useParams();
  // let Millions1 = formatValue("111111");
  // let Millions2 = formatValue("222222");
  //let encryptJSON = formatValue(param1);

  const userid = localStorage.getItem("novauserid");
  const Identifier = localStorage.getItem("Identifier");

  const [editData, setEditData] = useState([]);

  const [responseDecodeData, setResponseDecodeData] = useState({});
  const [taskId, setTaskId] = useState(0);
  const [juniorAnalystId, setJuniorAnalystId] = useState(0);
 

  const [selectedPeriod, setSelectedPeriod] = useState(0); // State to store the selected period
  const [selectedQuarter, setSelectedQuarter] = useState("");

  // CURRENCY SELECT
  const [selectedCurrency, setSelectedCurrency] = useState("");


  let defCoreAssets = {};
  for(let i =0; i<=100; i++) {
    defCoreAssets["bs_rfco_assets_"+i] = 0;
  }
  const [coreRevenueParentCount, setCoreRevenueParentCount] = useState(defCoreAssets);

  // Get Screening
// useEffect(() => {
//   // Fetch data from the API
//   fetch('https://beta.infomanav.in/keep/finnhub_api/ci/api/get_screening_json', {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({ data: { user_id: '55' } }),
//   })
//     .then((response) => response.json())
//     .then((data) => {
//       // Log the data you want to see in the console
//       console.log(JSON.stringify(data.data.data));
//       // Assuming setSampledata is a function to update state
//       setSampledata(data.data.data); // Update your state as needed
//     })
//     .catch((error) => console.error('Error fetching data:', error));
// }, []);


  //Decode API
  useEffect(() => {
    const fetchData = async () => {
      setIsLoader(true);
      const url = `${apiEndPoint}/decode`;
      const requestData = {
        data: {
          user_id:userid, // For track which user send this request
          encoded: encryptJSON
        }
      };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData)
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }


      const responseData = await response.json();
      const data = responseData.data; // Flatten the response

      if(data.status === 'success')
      {
          // Handle the response data here
          console.log(data.data);
          setResponseDecodeData(data.data);
          setTaskId(data.data.id); // set task id
          console.log('TASK ID :' + data.data.id);
          setJuniorAnalystId(data.data.user_id);
          //setReportLRUrl(data.data.iframe_url);
          
          setSelectedYear(data.data.year);
          setSelectedQuarter(parseInt(data.data.quarter, 10)); // In API quarter is string format so thats why its not set 

          setTempReportLR(data.data.iframe_url10k);
          setTempReportAR(data.data.iframe_url10q);
          setReportLRUrl(data.data.LR_report);
          setReportARUrl(data.data.AR_report);
          if (data.data.country == 'US' && (!data.data.LR_report && !data.data.AR_report))
            {
              setActiveKey("");
            }
            else{
              setActiveKey("1");
            }

          const time = data.data.time;
          
          if (time) {
          const [hours, minutes, seconds] = time.split(":").map(Number);
  
          // Update state variables with extracted values
          
            setHours(hours);
            setMinutes(minutes);
            setSeconds(seconds);
    
            console.log('DB Timer : '+ minutes + ' My Timer' + seconds);
          }
          else{
            console.log('Time is null, skipping state updates.');
          }

          
      }
      else{
        console.error('Decode API Error : ' + error.message);
        setErrorMessage('Decode :'+ data.message);
        setMessageType('error');
        return false;
      }

      } catch (error) {
        // Handle errors here
        // console.error("Decode API Error:", error);
        setErrorMessage('DECODE CATCH :'+ error.message);
        setMessageType('error');
        return false;
      }
      finally {
        setLoadingLR(false); // Set loading state to false when done loading LR report
        setLoadingAR(false); // Set loading state to false when done loading AR report
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once on component mount


  //Edit Screening Json
  useEffect(() => {
    const fetchData = async () => {
      
      console.log('Junior Id'+ juniorAnalystId + ' Task Id'+ taskId);
      try {
       const response = await fetch(`${apiEndPoint}/get_task_screening_final`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            data: {
              user_id: juniorAnalystId,
              task_id: taskId
            }
          })
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        console.log('FINAL:', JSON.stringify(data.data.data));

        if(data.data.status == "success")
        {
          //console.log(JSON.stringify(data.data.data));
          if(data.data.data) {


            data.data.data.map((catObj, catId) => {
              if(catObj.category_id && catObj.category_id=="bs_screening" && catObj.sections) {
                catObj.sections.map((secObj, secId) => {
                  if(secObj.section_id && secObj.section_id=="total_revenue" && secObj.data && secObj.data[0] && secObj.data[0].additionalSubcategory) {
                    let obj = {};
                    secObj.data[0].rows.map((rowItem, rowIn) => {
                      let coreRevenueId = secObj.data[0].header_id+"_assets_"+(rowIn+20);
                      obj[coreRevenueId] = rowItem.sub && rowItem.sub.rows && rowItem.sub.rows.length ? rowItem.sub.rows.length : 0 ;
                    });
                    setCoreRevenueParentCount({...coreRevenueParentCount, ...obj});
                  }
                });
              }
            });
          } 
          //setSampledata(sampledata1);
          setSampledata(data.data.data);
          setChkCalculation(1);
          setIsLoader(false);
        }

        // Handle your data here


      } catch (error) {
        console.error('get_task_screening_final API Error : ' + error.message);
        setErrorMessage('get_task_screening_final :' + error.message);
        setMessageType('error');
        return false;
      }
    };

    if(juniorAnalystId && taskId)
    {
      fetchData();
    }
  }, [taskId,juniorAnalystId]); // Empty array means this effect runs once after the initial render



  //Get Market Cap
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${apiEndPoint}/get_market_caps?exchange=${responseDecodeData.exchange_symbol}&symbol=${responseDecodeData.company_symbol}&task_id=${taskId}`, {
          method: 'GET',
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

      const responseData = await response.json();
      const data = responseData.data; // Flatten the response
        if(data.status === 'success')
        {
            //setMonth36Val("200000000");
            //setMonth24Val("190000000");

           if(data.data.marketcap_36months.MarketCap == "0.00")
           {
            setErrorMessage(data.data.marketcap_36months.alert_msg);
            setMessageType('error');
           }
          //  setMonth36Val(data.data.marketcap_36months.MarketCap);
          //  setMonth24Val(data.data.marketcap_24months.MarketCap);

           setMonth36Val(formatNumberWithCommas(data.data.marketcap_36months.MarketCap|| "0"));
           setMonth24Val(formatNumberWithCommas(data.data.marketcap_24months.MarketCap|| "0"));

           setConvert36Val(formatValue(data.data.marketcap_36months.MarketCap|| "0"));
           setConvert24Val(formatValue(data.data.marketcap_24months.MarketCap|| "0"));
           
           setTooltip36MC(data.data.marketcap_36months.message || "");
           setTooltip24MC(data.data.marketcap_24months.message || "");

           setMCCurrency(data.data.cpcurrency);
           console.log('market cap API : DONE');

        }

      } catch (error) {
        console.error('get_market_caps API Error : ' + error.message);
        setErrorMessage('get_market_caps API Error : '+ error.message);
        setMessageType('error');
        return false;
      } finally {
       // setLoading(false);
      }
    };



    if(responseDecodeData.exchange_symbol && responseDecodeData.company_symbol && taskId)
    {
      fetchData();
    }

  }, [responseDecodeData.exchange_symbol,responseDecodeData.company_symbol,taskId]);

  


  //Edit Data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${apiEndPoint}/get_task_screening`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              data: {
                user_id: juniorAnalystId,
                task_id: taskId
              }
            })
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok " + response.statusText);
        }

        const responseData = await response.json();
        if (responseData.data.status !== "success") {
          throw new Error("API response status not successful");
        }

        

        const data = responseData.data.data[0];
        setSelectedPeriod(data.period_id);
        data.report_date = data.report_date === null ? "" : data.report_date;
        console.log("GET REPORT DATE : "+ data.report_date);
        setSelectedDate(new Date(data.report_date));
        data.fiscal_year = data.fiscal_year === "1970" ? "" : data.fiscal_year;
        

        //setSelectedYear(data.fiscal_year);
        //setSelectedQuarter(data.quarter_id);   
        // if(data.units == 0)
        // {
        //   data.units = 1000;
        // }
        setSelectedValue(data.units);
        //handleSelectChange(options.find((option) => option.value == data.units));
        //handleSelectChange(data.units);
        const selectedOption = options.find((option) => option.value === data.units);
        if (selectedOption) {
          handleSelectChange(selectedOption.value);
        }
        //setMCCurrency(data.mccurrency); this value comes from get market cap
        setSelectedCurrency(data.currency);
        if(!data.currency)
        {
          setSelectedCurrency(responseDecodeData.currency);
        }
        //console.log('CBA_STATUS :'+ data.cba_status);
        //handleCbaToggleChange(data.cba_status);
        setCBAStatus(data.cba_status);
        cbaCommentRef.current.value = data.cba_comment;

        console.log('Success MID-3 : Edit : MID');

        //const screening_json = JSON.stringify(JSON.parse(data.screening_json))
    
        // const screeningData = await getScreeningForm(standardJson);

        console.log('Check_Std : '+ data.standard_json);
        let standardJson = JSON.parse(data.standard_json);

        if(standardJson != null)
        {
         
          let AAOIFI = standardJson.data.Standards["AAOIFI"];
          let DJIM = standardJson.data.Standards["DJIM"];
          let SP = standardJson.data.Standards["S&P"];
          let FTSE = standardJson.data.Standards["FTSE"];
          let MSCI = standardJson.data.Standards["MSCI"];
          let MSCI_M_series = standardJson.data.Standards["MSCI_M-series"];
          let SAC_SC = standardJson.data.Standards["SAC-SC"];
          let ISSI = standardJson.data.Standards["ISSI"];

  
          setValComplianceStatusAAOIFI(AAOIFI);
          setValComplianceStatusDJIM(DJIM);
          setValComplianceStatusSP(SP);
          setValComplianceStatusFTSE(FTSE);
          setValComplianceStatusMSCI(MSCI);
          setValComplianceStatusMSCIMseries(MSCI_M_series);
          setValComplianceStatusSACSC(SAC_SC);
          setValComplianceStatusISSI(ISSI);
  
          let AR_Cash_to_36MC = standardJson.data.Ratios_and_Metrics["AR_&_Cash_to_36MC"];
          let AR_Cash_to_TA = standardJson.data.Ratios_and_Metrics["AR_&_Cash_to_TA"];
          let IB_assets_to_24MC = standardJson.data.Ratios_and_Metrics["IB_assets_to_24MC"];
          let IB_assets_to_36MC = standardJson.data.Ratios_and_Metrics["IB_assets_to_36MC"];
          let IB_assets_to_TA = standardJson.data.Ratios_and_Metrics["IB_assets_to_TA"];
          let IB_debt_to_36MC = standardJson.data.Ratios_and_Metrics["IB_debt_to_36MC"];
          let IB_debt_to_TA = standardJson.data.Ratios_and_Metrics["IB_debt_to_TA"];
  
          setValIBAssetsTo36MC(IB_assets_to_36MC);
          setValIBDebtTo36MC(IB_debt_to_36MC);
          setValIBAssetsTo24MC(IB_assets_to_24MC);
          setValIBAssetsToTA(IB_assets_to_TA);
          setValARAndCashToTA(AR_Cash_to_TA);
          setValARAndCashTo36MC(AR_Cash_to_36MC);
          setValIBDebtToTA(IB_debt_to_TA);
  
          let Doubtful_revenue = standardJson.data.Revenue_Categories["Doubtful_revenue"];
          let Impermissible_revenue = standardJson.data.Revenue_Categories["Impermissible_revenue"];
          let Non_compliant_revenue = standardJson.data.Revenue_Categories["Non-compliant_revenue"];
  
          setValNoncompliantRevenue(Non_compliant_revenue);
          setValDoubtfulRevenue(Doubtful_revenue);
          setValImpermissibleRevenue(Impermissible_revenue);
        }

        sumCalculation();


        //setIsEdit(1)// Only for temp purpose : once dynamic data show it will be removed
        console.log('Success : Edit : END');

      } catch (error) {
        // setError(error);
        console.error('get_task_screening API (EDIT) Error : ' + error.message);
        setErrorMessage(error.message);
        setMessageType('error');
        return false;

      } finally {
        //setLoading(false);
      }
    };

    if (taskId && juniorAnalystId) {
      // Only run fetchData if taskId exists
      fetchData();
    }
  }, [taskId, juniorAnalystId]);


  const [activePopover, setActivePopover] = useState(null); // State to manage which popover is active

  const handlePopoverToggle = (popover) => {
    setActivePopover(activePopover === popover ? null : popover);
  };
    
   const handleCustomMarketCap = () => {
    const month36Value = document.querySelector('.popover_36_market_cap .form-control[name="Custom_month36"]').value;
    const month24Value = document.querySelector('.popover_24_market_cap .form-control[name="Custom_month24"]').value;
  

    if (!month36Value) {
      setErrorMessage('Please Enter 36 Market Cap');
      setMessageType('error');
      return false;
    }
    if (!month24Value) {
      setErrorMessage('Please Enter 24 Market Cap');
      setMessageType('error');
      return false;
    }
    // console.log('36 Month Market Cap Value:', month36Value);
    // console.log('24 Month Market Cap Value:', month24Value);
  
    setMonth36Val(formatNumberWithCommas(month36Value));
    setMonth24Val(formatNumberWithCommas(month24Value));
  
    setCustom36MC(month36Value);
    setCustom24MC(month24Value);
    
    setConvert36Val(formatValue(month36Value));
    setConvert24Val(formatValue(month24Value));
  
    setTooltip36MC(`The market cap was last updated by ${userName} On Date ${getCurrentDate()}`);
    setTooltip24MC(`The market cap was last updated by ${userName} On Date ${getCurrentDate()}`);
  
    setActivePopover(null); // Close all popovers
  };

  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = today.getFullYear();
    return `${day}-${month}-${year}`;
  };
  
  
  const handleKeyUp = (e) => {
    const inputElement = e.target;
    let value = inputElement.value;
  
    // Remove non-numeric characters except for the decimal point
    value = value.replace(/[^0-9.]/g, '');
  
    // Ensure the value is a valid number with at most one decimal point
    const parts = value.split('.');
    if (parts.length > 2) {
      // More than one decimal point is not allowed
      value = parts[0] + '.' + parts.slice(1).join('');
    }
  
    // Update the input field with the sanitized value
    inputElement.value = value;
  
  
  
  };

  
  function formatValue(value) {
    if (value >= 1000000000000) {
      return (value / 1000000000000).toFixed(2) + "  T";
    } else if (value >= 1000000000) {
      return (value / 1000000000).toFixed(2) + "B";
    } else if (value >= 1000000) {
      return (value / 1000000).toFixed(2) + " M";
    } else if (value >= 1000) {
      return (value / 1000).toFixed(2) + " K";
    } else {
      return value;
    }
  }

  function formatNumberWithCommas(value) {
    const parts = value.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.length > 1 ? parts.join('.') : parts[0];
}

function removeCommas(value) {
  return value.replace(/,/g, '');
}

  // const [bsSumHappy, setBsSumHappy] = useState(0);
  // const [bsSumSad, setBsSumSad] = useState(0);
  // const [bsSumDoubt, setBsSumDoubt] = useState(0);

  // const [revenueSumHappy, setRevenueSumHappy] = useState(0);
  // const [revenueSumSad, setRevenueSumSad] = useState(0);
  // const [revenueSumDoubt, setRevenueSumDoubt] = useState(0);

  const [isPanelVisible, setIsPanelVisible] = useState(true);
  const standardsThRef = useRef(null);
  const swapSwitchThRef = useRef(null);



  const [convert36Val, setConvert36Val] = useState(0);
  const [convert24Val, setConvert24Val] = useState(0);

  const [calculate36Val, setCalculate36Val] = useState(0);
  const [calculate24Val, setCalculate24Val] = useState(0);

  const [indexNo, setIndexNo] = useState(0);

  // DATEPICKER
  const [date, setDate] = useState(new Date());
  function CustomCaretIcon() {
    return <i className="icon-calendar"></i>;
  }
  // handle onChange event of the dropdown
  const handleChange = (selectedOption) => {
    //const isChecked = e.target.checked;

    //selectedOption
    //setSelectedOption(e);
    //setDate(value);
    setSelectedCurrency(selectedOption.target.value);
    //console.log(selectedOption.target.value);
  };

  // handle onChange event of the dropdown
  // const handleChangePercentage = (e, value, currency) => {
  //   //const isChecked = e.target.checked;
  //   setIsChecked(!isChecked);

  //   // Define variables to store the percentages

  //   let percentage;

  //   // If checked, set percentage to 5, else set it to 20
  //   if (isChecked) {
  //     percentage = 5;
  //   } else {
  //     percentage = 20;
  //   }
  //   setSacscPercentage(percentage);
  //   sumCalculation();
  // };

  const handleChangePercentage = (e) => {
    setIsChecked(e.target.checked);
    let percentage = isChecked ? 5 : 20;
    setSacscPercentage(percentage);
  };

  useEffect(() => {
    sumCalculation();
  }, [isChecked]);

  // LINK MODAL
  //const [isActive, setIsActive] = useState(false);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const handleKeyPress = (event) => {
    // Check if Ctrl key and S key are pressed
    if (event.ctrlKey && event.key === "q") {
      // Trigger a click event on the standardsThRef
      standardsThRef.current.click();
    }
  };

  const handleSwitchPress = (event) => {
    // Check if Ctrl key and S key are pressed
    if (event.ctrlKey && event.key === "m") {
      // Trigger a click event on the standardsThRef
      swapSwitchThRef.current.click();
    }
  };

  const handleKeyPressReport = (event) => {
    // Check if Ctrl key and S key are pressed
    if (event.ctrlKey && event.key === "y") {
      // Apply styles to the report element
      const reportElement = document.getElementById("Reportbox1");
      if (reportElement) {
        reportElement.style.position = "fixed";
        reportElement.style.top = 0;
        reportElement.style.left = 0;
        reportElement.style.width = "100%";
        reportElement.style.height = "100%";
        reportElement.style.zIndex = 999;
        reportElement.style.marginTop = 0;
      }
      const reportViewer = document.getElementById("reportViewer");
      if (reportViewer) {
        reportViewer.style.height = "100vh";
      }
    }
  };

  const handleKeyPressScreen = (event) => {
    // Check if Ctrl key and S key are pressed
    if (event.ctrlKey && event.key === "i") {
      // Apply styles to the report element
      const reportElement = document.getElementById("Reportbox2");
      if (reportElement) {
        reportElement.style.position = "fixed";
        reportElement.style.top = 0;
        reportElement.style.left = 0;
        reportElement.style.width = "100%";
        reportElement.style.height = "100%";
        reportElement.style.zIndex = 999;
        reportElement.style.marginTop = 0;
      }
      const ReportScreening = document.getElementById("ReportScreening");
      if (ReportScreening) {
        ReportScreening.style.maxHeight = "100vh";
      }
    }
  };

  const handleKeyPressEsc = (event) => {
    // Check if Ctrl key and Esc key are pressed
    if (event.ctrlKey && event.key === "z") {
      // Remove styles from the report element
      const reportElement = document.getElementById("Reportbox1");
      if (reportElement) {
        reportElement.style.position = "";
        reportElement.style.top = "";
        reportElement.style.left = "";
        reportElement.style.width = "";
        reportElement.style.height = "";
        reportElement.style.zIndex = "";
        reportElement.style.marginTop = "";
      }
      const reportViewer = document.getElementById("reportViewer");
      if (reportViewer) {
        reportViewer.style.height = "";
      }
    }
  };

  const handleKeyPressEscScreen = (event) => {
    // Check if Ctrl key and Esc key are pressed
    if (event.ctrlKey && event.key === "x") {
      // Remove styles from the report element
      const reportElement = document.getElementById("Reportbox2");
      if (reportElement) {
        reportElement.style.position = "";
        reportElement.style.top = "";
        reportElement.style.left = "";
        reportElement.style.width = "";
        reportElement.style.height = "";
        reportElement.style.zIndex = "";
        reportElement.style.marginTop = "";
      }

      const ReportScreening = document.getElementById("ReportScreening");
      if (ReportScreening) {
        ReportScreening.style.maxHeight = "";
      }
    }
  };



  




  const optionsPeriod = [
    { value: 1, label: "Three Months" },
    { value: 2, label: "Six Months" },
    { value: 3, label: "Nine Months" },
    { value: 4, label: "Twelve Months" },
  ];



  useEffect(() => {
    // Add event listener when component mounts
    window.addEventListener("keydown", handleKeyPress);
    window.addEventListener("keydown", handleSwitchPress);
    window.addEventListener("keydown", handleKeyPressReport);
    window.addEventListener("keydown", handleKeyPressEsc);

    window.addEventListener("keydown", handleKeyPressScreen);
    window.addEventListener("keydown", handleKeyPressEscScreen);

    // setMonth36Val("200000000");
    // setMonth24Val("190000000");

    //handleSelectChange(options.find((option) => option.value === selectedValue) ); // call default select dropdown change event

    // const defaultValue = options.find((option) => option.value === selectedValue);
    // if (defaultValue) {
    //   handleSelectChange(defaultValue);
    // }

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      window.removeEventListener("keydown", handleSwitchPress);
      window.removeEventListener("keydown", handleKeyPressReport);
      window.removeEventListener("keydown", handleKeyPressEsc);
      window.removeEventListener("keydown", handleKeyPressScreen);
      window.removeEventListener("keydown", handleKeyPressEscScreen);
    };
  }, [isPanelVisible]);
  // Dependency array includes isPanelVisible to ensure effect runs when its value changes

  useEffect(() => {
    sumCalculation();
  }, [calculate36Val, calculate24Val]);



  const [selectedOption, setSelectedOption] = useState(null);

  //Current liabilities : Interest bearing liabilities

  const [currentLiabilitiesTotalSum, setCurrentLiabilitiesTotalSum] =
    useState(0);

  //Non-current liabilities : Interest bearing liabilities
  const [nonCurrentLiabilitiesTotalSum, setNonCurrentLiabilitiesTotalSum] =
    useState(0);

  //Standards
  // const [ratios, setRatios] = useState({
  //   IBAssetsTo36MC: 0,
  //   IBDebtTo36MC: 0,
  //   IBAssetsTo24MC: 0,
  //   IBAssetsToTA: 0,
  //   ARAndCashToTA: 0,
  //   ARAndCashTo36MC: 0,
  //   IBDebtToTA: 0,
  // });

  const [valIBAssetsTo36MC, setValIBAssetsTo36MC] = useState(0);
  const [valIBDebtTo36MC, setValIBDebtTo36MC] = useState(0);
  const [valIBAssetsTo24MC, setValIBAssetsTo24MC] = useState(0);
  const [valIBAssetsToTA, setValIBAssetsToTA] = useState(0);
  const [valARAndCashToTA, setValARAndCashToTA] = useState(0);
  const [valARAndCashTo36MC, setValARAndCashTo36MC] = useState(0);
  const [valIBDebtToTA, setValIBDebtToTA] = useState(0);

  //Standards 1
  // const [ratios1, setRatios1] = useState({
  //   NoncompliantRevenue: 0,
  //   ImpermissibleRevenue: 0,
  //   DoubtfulRevenue: 0,
  // });

  const [valNoncompliantRevenue, setValNoncompliantRevenue] = useState(0);
  const [valImpermissibleRevenue, setValImpermissibleRevenue] = useState(0);
  const [valDoubtfulRevenue, setValDoubtfulRevenue] = useState(0);

  // const [statusCompliance, setStatusCompliance] = useState({
  //   ComplianceStatusAAOIFI: "-",
  //   ComplianceStatusDJIM: "-",
  //   ComplianceStatusSP: "-",
  //   ComplianceStatusFTSE: "-",
  //   ComplianceStatusMSCI: "-",
  //   ComplianceStatusMSCIMseries: "-",
  //   ComplianceStatusSACSC: "-",
  //   ComplianceStatusISSI: "-",
  // });

  const [valComplianceStatusAAOIFI, setValComplianceStatusAAOIFI] = useState(0);
  const [valComplianceStatusDJIM, setValComplianceStatusDJIM] = useState(0);
  const [valComplianceStatusSP, setValComplianceStatusSP] = useState(0);
  const [valComplianceStatusFTSE, setValComplianceStatusFTSE] = useState(0);
  const [valComplianceStatusMSCI, setValComplianceStatusMSCI] = useState(0);
  const [valComplianceStatusMSCIMseries, setValComplianceStatusMSCIMseries] =
    useState(0);
  const [valComplianceStatusSACSC, setValComplianceStatusSACSC] = useState(0);
  const [valComplianceStatusISSI, setValComplianceStatusISSI] = useState(0);

  //Dropdown

  const colourStyles = {
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      //      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isFocused ? "#F8F8F8" : "",
        color: "#333333",
        borderBottom: 1,
        borderStyle: "solid",
        borderColor: "#F2F2F2",
        fontSize: 14,
        fontWeight: 500,
      };
    },
  };

  //const defaultValue = options[0];

  const [isBox1Visible, setIsBox1Visible] = useState(true);

  const toggleBoxes = () => {
    setIsBox1Visible(!isBox1Visible);
  };

  const handleChangeFaceSelection = (selectedOption, clsId, inputId) => {
    //get hidden input id and update value to selected input
    // let hiddenInputId = clsId.id+'_hidden';

    let hiddenInputId = inputId + "_hidden";
    let hiddenElement = document.getElementById(hiddenInputId);
    hiddenElement.getAttributeNode("custom_value").value = selectedOption.value;
    sumCalculation();
  };

  //let headerItemObj = {};

  const sumCalculation = () => {

     if(chkCalculation == 1)
     {
      console.log('CAL : '+ calculate36Val);
     let bsHappySum = 0;
     let bsSadSum = 0;
     let bsDoubtSum = 0;

     let revenueHappySum = 0;
     let revenueSadSum = 0;
     let revenueDoubtSum = 0;

     const allClsCashItems = document.getElementsByClassName("clsCashItems");

     const allClsCalculation = document.getElementsByClassName( "clsCalculation_hidden" );
     let headerItemObj = {}; // Initialize headerItemObj
     const cal_ar_n_cash_value = document.getElementsByClassName("cal_ar_n_cash");
     let ar_n_cash = 0;

     for (let i = 0; i < cal_ar_n_cash_value.length; i++) {
       let cVal = parseFloat(cal_ar_n_cash_value[i].value.replace(/,/g, '')) || 0;
       ar_n_cash += cVal;
     }

     //document.getElementById("ar_n_cash").value = parseFloat(ar_n_cash);
     document.getElementById("ar_n_cash").value = parseFloat(ar_n_cash).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

     const total_assets = document.getElementById("total_assets").value.replace(/,/g, '');

     // const ar_n_cash = document.getElementById('ar_n_cash').value;
     const account_receivables = document.getElementById( "account_receivables" ).value.replace(/,/g, '');
     const non_current_account_receivables = document.getElementById( "non_current_account_receivables" ).value.replace(/,/g, '');

     // console.log("total assets and ar N cash ",total_assets, ar_n_cash, account_receivables, non_current_account_receivables);

     // console.log(" allClsCashItems ", allClsCashItems.length);

     for (let i = 0; i < allClsCashItems.length; i++) {
       let clsItem = allClsCashItems[i];
       //let currentItemValue = parseFloat(clsItem.value) || 0;
       let currentItemValue = parseFloat(clsItem.value.replace(/,/g, '')) || 0; // remove comma and calculate
      
       //update each section sum
       let itemallCls = clsItem.classList;
       for (let j = 0; j < itemallCls.length; j++) {
         if (j < 2) {
           continue;
         }
         let getClassName = itemallCls[j];
         if (!(getClassName in headerItemObj)) {
           headerItemObj[getClassName] = 0;
         }  
         headerItemObj[getClassName] += currentItemValue;
       }

       // console.log("last class item ", itemallCls.slice(2));
       // update sum of faces

      // debugger;

       //let currentFaceValue = (allClsCalculation[i] && parseFloat(allClsCalculation[i].getAttributeNode("custom_value").value )) || 0;
       // console.log(" selectItemVal ", currentItemValue, currentFaceValue);
       let currentFaceValue = 0;
        if (allClsCalculation[i]) {
          let customValue = parseFloat(allClsCalculation[i].getAttribute("custom_value"));
          currentFaceValue = isNaN(customValue) ? 0 : customValue;
        }


       if (currentFaceValue === 1) {
         bsHappySum += currentItemValue;
       }
       if (currentFaceValue === 2) {
         bsSadSum += currentItemValue;
       }
       if (currentFaceValue === 3) {
         bsDoubtSum += currentItemValue;
       }
     }

     const allClsCashRevenueItems = document.getElementsByClassName( "clsCashItemsRevenue" );
     const allClsRevenueCalculation = document.getElementsByClassName( "clsCalculationRevenue_hidden" );

    //  console.log(" revenue len ", allClsCashRevenueItems.length, allClsRevenueCalculation.length);

     let parentTrIds = [];

     for (let i = 0; i < allClsCashRevenueItems.length; i++) {
      let clsItem = allClsCashRevenueItems[i];
      if(clsItem.getAttribute("id").startsWith("bs_rfco_assets_")) {
        parentTrIds.push(clsItem.getAttribute("id"));
      }
     }

    //  console.log("parentTrIds ", parentTrIds);
     let parentTrIdsIndexes = [];
     let processedIds = [];


     for (let i = 0; i < allClsCashRevenueItems.length; i++) {
      let clsItem = allClsCashRevenueItems[i];
      let clsItemId = clsItem.getAttribute("id");
      let currentItemValue = parseFloat(clsItem.value.replace(/,/g, '')) || 0;
      

      let itemallCls = clsItem.classList;
      let filteredArray = parentTrIds.filter(value => itemallCls.value.split(" ").includes(value));
      for (let j = 0; j < itemallCls.length; j++) {
          if (j < 2) {
            continue;
          }
          let getClassName = itemallCls[j];
          let indOf = parentTrIds.indexOf(getClassName);
          
          let newInd = i-1;
          if(filteredArray.length > 0 && currentItemValue > 0 && getClassName.startsWith("bs_rfco_assets_") && parentTrIds.includes(getClassName) && indOf > -1 &&  !parentTrIdsIndexes.includes(newInd) && !processedIds.includes(getClassName)) {
            parentTrIdsIndexes.push(newInd);
            processedIds.push(getClassName);
            // console.log("parentTrIdsIndexes ", parentTrIdsIndexes, i, processedIds);
          }
        }
    }

     

     for (let i = 0; i < allClsCashRevenueItems.length; i++) {
       let clsItem = allClsCashRevenueItems[i];
       let clsItemId = clsItem.getAttribute("id");

       let currentItemValue = parseFloat(clsItem.value.replace(/,/g, '')) || 0;

       //update each section sum
       let itemallCls = clsItem.classList;
      //  console.log("itemallCls ", itemallCls, itemallCls.value);

       let filteredArray = parentTrIds.filter(value => itemallCls.value.split(" ").includes(value));
       for (let j = 0; j < itemallCls.length; j++) {
         if (j < 2) {
           continue;
         }
         let getClassName = itemallCls[j];
         if (!(getClassName in headerItemObj)) {
           headerItemObj[getClassName] = 0;
         }
        //  console.log("filteredArray ", filteredArray, itemallCls.value);
          if(filteredArray.length > 0 || itemallCls.value.split(" ").includes("bs_other_income")) {
            headerItemObj[getClassName] += currentItemValue;
          }
          
       }


       let currentFaceValue = 0;
        if(document.getElementById(clsItemId+"_hidden") && !processedIds.includes(clsItemId)) {
          let customValue = parseFloat(document.getElementById(clsItemId+"_hidden").getAttribute("custom_value"));
          currentFaceValue = isNaN(customValue) ? 0 : customValue;
          // console.log("-- clsItemId ", clsItemId, currentFaceValue, document.getElementById(clsItemId+"_hidden").getAttribute("custom_value"), currentItemValue);
        }
        // if (allClsRevenueCalculation[i]) {
        //   let customValue = parseFloat(allClsRevenueCalculation[i].getAttribute("custom_value"));
        //   currentFaceValue = isNaN(customValue) ? 0 : customValue;
        // }
        if (currentFaceValue === 1) {
          revenueHappySum += currentItemValue;
          //console.log('SMILE : '+ revenueHappySum);
        }
        if (currentFaceValue === 2) {
          revenueSadSum += currentItemValue;
        }
        if (currentFaceValue === 3) {
          revenueDoubtSum += currentItemValue;
        }
     }

     setComplianceHalalSum(revenueHappySum); // Save this value For Compliance Caluclation
     setComplianceNotHalalSum(revenueSadSum); // Save this value For Compliance Caluclation
     setComplianceDoubtfulSum(revenueDoubtSum); // Save this value For Compliance Caluclation

    //  console.log("headerItemObj ", headerItemObj);

    //console.log('Hala Revenue :'+ revenueHappySum);

     for (let i = 0; i < allClsCashRevenueItems.length; i++) {
        let clsItem = allClsCashRevenueItems[i];
        let clsItemId = clsItem.getAttribute("id");
        if(!clsItemId.startsWith("bs_rfco_assets_")) {
          continue;
        }

        let itemallCls = clsItem.classList;
        let filteredArray = parentTrIds.filter(value => itemallCls.value.split(" ").includes(value));
        for (let j = 0; j < itemallCls.length; j++) {
            if (j < 2 || headerItemObj[clsItemId] > 0) {
              continue;
            }
            let getClassName = itemallCls[j];
            if(clsItemId.startsWith("bs_rfco_assets_") && filteredArray.length == 0 && !itemallCls.value.split(" ").includes("bs_other_income")) {
                headerItemObj[getClassName] += parseFloat(clsItem.value.replace(/,/g, '')) || 0;
            }
          }
      }

     let current_liabilities_sum = 0;
     let non_current_liabilities_sum = 0;
    
     Object.entries(headerItemObj).forEach((entry) => {
       const [key, value] = entry;
       if (key === "current_liabilities") {
         // setCurrentLiabilitiesTotalSum(value);
         current_liabilities_sum = value;
       }
       if (key === "non_current_liabilities") {
         // setNonCurrentLiabilitiesTotalSum(value);
         non_current_liabilities_sum = value;
       }
       if (document.getElementById(key)) {

         if (key.includes("bs_rfco_assets_")) {
           if (document.getElementById(key) && value > 0) {
             document.getElementById(key).value = parseFloat(value).toFixed(2);
           }
         } else {

           document.getElementById(key).value = parseFloat(value).toFixed(2);
           document.getElementById(key).value = parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

           //document.getElementById(key).innerText = parseFloat(value).toFixed(2);
           document.getElementById(key).innerText = parseFloat(value).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });

         }
       }

     });


     // console.log("headerItemObj ", headerItemObj);
    //  console.log("BS ", bsHappySum, bsSadSum, bsDoubtSum, calculate36Val);
    //  console.log("Revenue ", revenueHappySum, revenueSadSum, revenueDoubtSum);

     //IB assets to 36MC
     let IBAssetspercentage = (bsSadSum / calculate36Val) * 100;
     IBAssetspercentage = Math.round(IBAssetspercentage * 100) / 100;
    //  console.log("IBAssetspercentage ", IBAssetspercentage);

     let IBAssetsTo36MC = isNaN(IBAssetspercentage) || !isFinite(IBAssetspercentage) ? 0 : IBAssetspercentage.toFixed(2);
     IBAssetsTo36MC = IBAssetsTo36MC === "0.00" ? 0 : IBAssetsTo36MC;

     //IB debt to 36MC
     let IBDebtTo36MCpercentag = ((current_liabilities_sum + non_current_liabilities_sum) / calculate36Val) * 100;
     let IBDebtTo36MC = isNaN(IBDebtTo36MCpercentag) || !isFinite(IBDebtTo36MCpercentag) ? 0 : IBDebtTo36MCpercentag.toFixed(2);
     IBDebtTo36MC = IBDebtTo36MC === "0.00" ? 0 : IBDebtTo36MC;

     //IB assets to 24MC
     let IBAssetsTo24MCpercentage = (bsSadSum / calculate24Val) * 100;
     IBAssetsTo24MCpercentage = Math.round(IBAssetsTo24MCpercentage * 100) / 100; let IBAssetsTo24MC = isNaN(IBAssetsTo24MCpercentage) || !isFinite(IBAssetsTo24MCpercentage) ? 0 : IBAssetsTo24MCpercentage.toFixed(2);
     IBAssetsTo24MC = IBAssetsTo24MC === "0.00" ? 0 : IBAssetsTo24MC;

     //IB assets to TA
     let IBAssetsToTApercentage = parseFloat( ((bsSadSum / total_assets) * 100).toFixed(2) );
     let IBAssetsToTA = isNaN(IBAssetsToTApercentage) || !isFinite(IBAssetsToTApercentage) ? 0 : IBAssetsToTApercentage.toFixed(2);
     IBAssetsToTA = IBAssetsToTA === "0.00" ? 0 : IBAssetsToTA;

     //AR & Cash to TA
     let ARAndCashToTApercentage = (ar_n_cash / total_assets) * 100;
     let ARAndCashToTA = isNaN(ARAndCashToTApercentage) || !isFinite(ARAndCashToTApercentage) ? 0 : ARAndCashToTApercentage.toFixed(2);
     ARAndCashToTA = ARAndCashToTA === "0.00" ? 0 : ARAndCashToTA;

     //AR & Cash to 36MC
     let ARAndCashTo36MCpercentage = (ar_n_cash / calculate36Val) * 100;
     let ARAndCashTo36MC = isNaN(ARAndCashTo36MCpercentage) || !isFinite(ARAndCashTo36MCpercentage) ? 0 : ARAndCashTo36MCpercentage.toFixed(2);
     ARAndCashTo36MC = ARAndCashTo36MC === "0.00" ? 0 : ARAndCashTo36MC;

     //IB debt to TA
     let IBDebtToTApercentage = ((current_liabilities_sum + non_current_liabilities_sum) / total_assets) * 100;
     let IBDebtToTA = isNaN(IBDebtToTApercentage) || !isFinite(IBDebtToTApercentage) ? 0 : IBDebtToTApercentage.toFixed(2);
     IBDebtToTA = IBDebtToTA === "0.00" ? 0 : IBDebtToTA;

     // console.log("IBAssetsTo36MC ", IBAssetsTo36MC, IBDebtTo36MC, IBAssetsTo24MC, IBAssetsToTA, ARAndCashToTA, ARAndCashTo36MC, IBDebtToTA);

     // setBsSumHappy(bsHappySum);
     // setBsSumSad(bsSadSum);
     // setBsSumDoubt(bsDoubtSum);

     // setRevenueSumHappy(revenueHappySum);
     // setRevenueSumSad(revenueSadSum);
     // setRevenueSumDoubt(revenueDoubtSum);

     //total_revenue
     let total_revenue = headerItemObj["total_revenue"];




     //console.log('Total Revenue'+ total_revenue);
     let NoncompliantRevenuePercentage = (revenueSadSum / total_revenue) * 100;
     //Doubtful revenue
     let DoubtfulRevenuePercentage = (revenueDoubtSum / total_revenue) * 100;


     //Impermissible revenue
     let ImpermissibleRevenuePercentage =
       NoncompliantRevenuePercentage + DoubtfulRevenuePercentage;

      //  console.log("total_revenue ", total_revenue, NoncompliantRevenuePercentage, DoubtfulRevenuePercentage, ImpermissibleRevenuePercentage);

     let NoncompliantRevenue =
       isNaN(NoncompliantRevenuePercentage) ||
       !isFinite(NoncompliantRevenuePercentage)
         ? 0
         : NoncompliantRevenuePercentage.toFixed(2);
     let DoubtfulRevenue =
       isNaN(DoubtfulRevenuePercentage) || !isFinite(DoubtfulRevenuePercentage)
         ? 0
         : DoubtfulRevenuePercentage.toFixed(2);
     let ImpermissibleRevenue =
       isNaN(ImpermissibleRevenuePercentage) ||
       !isFinite(ImpermissibleRevenuePercentage)
         ? 0
         : ImpermissibleRevenuePercentage.toFixed(2);

     // Compliance Status
     //let ComplianceStatusAAOIFI = (Math.max(A, B) >= 30) ? 'Non-compliant' : '-';
     //let ComplianceStatusAAOIFI = Math.max(IBAssetsTo36MC, IBDebtTo36MC) === 0 || NoncompliantRevenue === 0 || ImpermissibleRevenue === 0 ? "-" : Math.max(IBAssetsTo36MC, IBDebtTo36MC) >= 30 ? "Non-compliant" : NoncompliantRevenue >= 5 ? "Non-compliant" : ImpermissibleRevenue >= 5 ? "Doubtful" : "Compliant";
     let ComplianceStatusAAOIFI =
       Math.max(IBAssetsTo36MC, IBDebtTo36MC) === 0 &&
       NoncompliantRevenue === 0 &&
       ImpermissibleRevenue === 0
         ? "Compliant"
         : Math.max(IBAssetsTo36MC, IBDebtTo36MC) >= 30
         ? "Non-compliant"
         : NoncompliantRevenue >= 5
         ? "Non-compliant"
         : ImpermissibleRevenue >= 5
         ? "Doubtful"
         : "Compliant";

     let ComplianceStatusDJIM =
       Math.max(IBAssetsTo24MC) === 0 &&
       NoncompliantRevenue === 0 &&
       ImpermissibleRevenue === 0
         ? "-"
         : Math.max(IBAssetsTo24MC) >= 33
         ? "Non-compliant"
         : NoncompliantRevenue >= 5
         ? "Non-compliant"
         : ImpermissibleRevenue >= 5
         ? "Doubtful"
         : "Compliant";
     let ComplianceStatusSP =
       Math.max(IBAssetsTo36MC) === 0 && ImpermissibleRevenue === 0
         ? "-"
         : Math.max(IBAssetsTo36MC) >= 33
         ? "Non-compliant"
         : NoncompliantRevenue >= 5
         ? "Non-compliant"
         : ImpermissibleRevenue >= 5
         ? "Doubtful"
         : "Compliant";
     let ComplianceStatusFTSE =
       Math.max(IBAssetsToTA, ARAndCashToTA, IBDebtToTA) === 0 &&
       ImpermissibleRevenue === 0
         ? "-"
         : Math.max(IBAssetsToTA, ARAndCashToTA, IBDebtToTA) >= 33.333
         ? "Non-compliant"
         : NoncompliantRevenue >= 5
         ? "Non-compliant"
         : ImpermissibleRevenue >= 5
         ? "Doubtful"
         : "Compliant";
     let ComplianceStatusMSCI =
       Math.max(IBAssetsToTA, ARAndCashToTA, IBDebtToTA) === 0 &&
       ImpermissibleRevenue === 0
         ? "-"
         : Math.max(IBAssetsToTA, ARAndCashToTA, IBDebtToTA) >= 33.33
         ? "Non-compliant"
         : NoncompliantRevenue >= 5
         ? "Non-compliant"
         : ImpermissibleRevenue >= 5
         ? "Doubtful"
         : "Compliant";
     let ComplianceStatusMSCIMseries =
       Math.max(IBAssetsTo36MC, IBDebtTo36MC, ARAndCashTo36MC) === 0 &&
       ImpermissibleRevenue === 0
         ? "-"
         : Math.max(IBAssetsTo36MC, IBDebtTo36MC, ARAndCashTo36MC) >= 33.33
         ? "Non-compliant"
         : NoncompliantRevenue >= 5
         ? "Non-compliant"
         : ImpermissibleRevenue >= 5
         ? "Doubtful"
         : "Compliant";

     let ComplianceStatusSACSC =
       Math.max(IBAssetsToTA, IBDebtToTA) === 0 && ImpermissibleRevenue === 0
         ? "-"
         : Math.max(IBAssetsToTA, IBDebtToTA) >= 33
         ? "Non-compliant"
         : NoncompliantRevenue >= sacscPercentage
         ? "Non-compliant"
         : ImpermissibleRevenue >= sacscPercentage
         ? "Doubtful"
         : "Compliant";

     let ComplianceStatusISSI =
       Math.max(IBDebtToTA) === 0 && ImpermissibleRevenue === 0
         ? "-"
         : Math.max(IBDebtToTA) >= 45
         ? "Non-compliant"
         : NoncompliantRevenue >= 10
         ? "Non-compliant"
         : ImpermissibleRevenue >= 10
         ? "Doubtful"
         : "Compliant";

     //console.log(ImpermissibleRevenue + '>='+ sacscPercentage);
     //alert(IBAssetsToTA, IBDebtToTA);

     // setRatios({
     //     IBAssetsTo36MC,
     //     IBDebtTo36MC,
     //     IBAssetsTo24MC,
     //     IBAssetsToTA,
     //     ARAndCashToTA,
     //     ARAndCashTo36MC,
     //     IBDebtToTA
     // });

     setValIBAssetsTo36MC(IBAssetsTo36MC);
     setValIBDebtTo36MC(IBDebtTo36MC);
     setValIBAssetsTo24MC(IBAssetsTo24MC);
     setValIBAssetsToTA(IBAssetsToTA);
     setValARAndCashToTA(ARAndCashToTA);
     setValARAndCashTo36MC(ARAndCashTo36MC);
     setValIBDebtToTA(IBDebtToTA);

     // setRatios1({
     //     NoncompliantRevenue,
     //     DoubtfulRevenue,
     //     ImpermissibleRevenue
     // });

     setValNoncompliantRevenue(NoncompliantRevenue);
     setValImpermissibleRevenue(ImpermissibleRevenue);
     setValDoubtfulRevenue(DoubtfulRevenue);

     // setStatusCompliance({
     //     ComplianceStatusAAOIFI,
     //     ComplianceStatusDJIM,
     //     ComplianceStatusSP,
     //     ComplianceStatusFTSE,
     //     ComplianceStatusMSCI,
     //     ComplianceStatusMSCIMseries,
     //     ComplianceStatusSACSC,
     //     ComplianceStatusISSI
     // });
     setValComplianceStatusAAOIFI(ComplianceStatusAAOIFI);
     setValComplianceStatusDJIM(ComplianceStatusDJIM);
     setValComplianceStatusSP(ComplianceStatusSP);
     setValComplianceStatusFTSE(ComplianceStatusFTSE);
     setValComplianceStatusMSCI(ComplianceStatusMSCI);
     setValComplianceStatusMSCIMseries(ComplianceStatusMSCIMseries);
     setValComplianceStatusSACSC(ComplianceStatusSACSC);
     setValComplianceStatusISSI(ComplianceStatusISSI);
   }
    };






  const addSubSection = (headerId) => {
    let newIndex = indexNo + 1;
    if (newIndex <= 14) {
      setIndexNo(newIndex);
      let idVal = headerId + "_assets_" + newIndex;
      document.getElementById(idVal).style.display = "table-row";
    }
  };

  const addSubSectionRow = (rowId) => {
    const newNode = document.getElementById( "sub_default_row_" + rowId ).firstChild;
    if (newNode) {
      //newNode.classList.add("cls_subCategory");
      const originalDiv = document.getElementById(rowId);
      originalDiv.parentNode.insertBefore(
        newNode,
        document.getElementById("sub_default_row_" + rowId)
      );
      let obj = {};
      obj[rowId] = coreRevenueParentCount[rowId]?coreRevenueParentCount[rowId]+1:1;
      setCoreRevenueParentCount({...coreRevenueParentCount, ...obj});
    }
  };

  const removeSubSection = (rowId,inputCommentId) => {

    document.getElementById(rowId).remove();
    document.querySelectorAll("." + rowId).forEach((el) => el.remove());
    // coreRevenueParentCount[rowId] = 0;
    setCoreRevenueParentCount({...coreRevenueParentCount, rowId: 0});
    //console.log(" removeSubSection coreRevenueParentCount ", coreRevenueParentCount);
    sumCalculation();

    deleteWholeRowComment(inputCommentId);

  };

  const addMoreRow = (rowId) => {
    //rowId = default_row_current_assets__cash_items
    const newNode = document.getElementById(rowId).firstChild;
    if (newNode) {
      const originalDiv = document.getElementById(rowId);
      originalDiv.parentNode.insertBefore(newNode, originalDiv);
    }
  };

  const removeRowItem = (e,inputCommentId) => {
    console.log('AAA'+ e.target);

    // console.log("e node ",e.target.getAttribute("parent_id"), e.target);
    let revenueCoreParentId = e.target.getAttribute("parent_id");

    // if(coreRevenueParentCount[revenueCoreParentId]) {
    //   coreRevenueParentCount[revenueCoreParentId] = coreRevenueParentCount[revenueCoreParentId]-1;
    // } else {
    //   coreRevenueParentCount[revenueCoreParentId] = 0;
    // }
    let obj = {};
    obj[revenueCoreParentId] = (coreRevenueParentCount[revenueCoreParentId] && coreRevenueParentCount[revenueCoreParentId] > 0) ? coreRevenueParentCount[revenueCoreParentId]-1 : 0;
    // console.log("removeRowItem coreRevenueParentCount ", coreRevenueParentCount);
    // setCoreRevenueParentCount(coreRevenueParentCount);
    setCoreRevenueParentCount({...coreRevenueParentCount, ...obj});
    // console.log("after removeRowItem coreRevenueParentCount ", coreRevenueParentCount);
    const closest = e.target.closest("tr").remove();
    sumCalculation();

    //delete comment if any for this row
    deleteWholeRowComment(inputCommentId);
    // console.log("parent node ", e.target.parentNode, closest);
  };

  // TABLE TOGGLE

  const [reportLRUrl, setReportLRUrl] = useState("");
  const [reportARUrl, setReportARUrl] = useState("");
  const [activeKey, setActiveKey] = useState("1"); // State to track active tab
  const [isActive, setIsActive] = useState(false);
  const [loadingLR, setLoadingLR] = useState(true); // Loading state for LR report
  const [loadingAR, setLoadingAR] = useState(true); // Loading state for AR report
  const [tempReportLR, setTempReportLR] = useState("");
  const [tempReportAR, setTempReportAR] = useState("");

  const handleReportLRInputChange = (e) => {
    //LR Link Paste
    setReportLRUrl(e.target.value);
    setIsActive(false);
  };

  const handleReportARInputChange = (e) => {
    //AR Link Paste
    setReportARUrl(e.target.value);
    setIsActive(false);
  };

  const [sacscPercentage, setSacscPercentage] = useState(5);

  const data2 = [
    { label: "Q1", value: 1 },
    { label: "Q2", value: 2 },
    { label: "Q3", value: 3 },
    { label: "Q4", value: 4 },
  ];

  const handlePdfLoad = () => {
    // PDF is loaded, you can now access its content
    // highlightKeywords();
  };

  // const highlightKeywords = () => {


  //   // Get the iframe element
  //   const iframe = document.getElementById('reportViewer');
  //   const iframeWindow = iframe.contentWindow;

  //   // Access the PDF document loaded in the iframe
  //   const pdfDocument = iframeWindow.PDFViewerApplication.pdfDocument;

  //   // Define the keywords to highlight
  //   const keywords = ['UNITED STATES', 'TRANSITION', 'Exact'];

  //   // Loop through each page of the PDF
  //   for (let i = 1; i <= pdfDocument.numPages; i++) {
  //       // Get the text content of the page
  //       pdfDocument.getPage(i).then((page) => {
  //           page.getTextContent().then((textContent) => {
  //               // Loop through each item in the text content
  //               textContent.items.forEach((textItem) => {
  //                   // Check if the text item contains any of the keywords
  //                   if (keywords.some(keyword => textItem.str.includes(keyword))) {
  //                       // Highlight the text item
  //                       const textDivs = iframeWindow.document.querySelectorAll(`.textLayer > div[data-annotation-id="${textItem.str}"]`);
  //                       textDivs.forEach((textDiv) => {
  //                           textDiv.style.backgroundColor = 'yellow';
  //                       });
  //                   }
  //               });
  //           });
  //       });
  //   }
  // };

  //const [selectedYear, setSelectedYear] = useState("");
  const [selectedYear, setSelectedYear] = useState(null); // Initialize with current year

  const CustomInput = ({ value, onClick }) => (
    <button className="custom-datepicker-button" onClick={onClick}>
      {value || "Year"}
      <i className="icon-calendar" />
    </button>
  );

  const years = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= currentYear - 100; i--) {
    years.push(i);
  }

  // In your protected route component
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  // Check if isLoggedIn is set to true or not
  if (!isLoggedIn) {
    navigate("/");
  }

  const [tooltip36MC, setTooltip36MC] = useState(0);
  const [tooltip24MC, setTooltip24MC] = useState(0);

  const tooltip = <Tooltip>You Can Swap The Report Using This Button.</Tooltip>;
  const tooltip_36MarketCap = <Tooltip>{tooltip36MC}</Tooltip>;
  const tooltip_24MarketCap = <Tooltip>{tooltip24MC}</Tooltip>;

  //const [activeKey, setActiveKey] = useState("1"); // State to track active tab

  // Handler function to change active tab
  const handleTabChange = (key) => {
    setActiveKey(key);

    // if(key == 1)
    // {
    //   setReportLRUrl(tempReportLR);
    // }
    // else if(key == 2)
    // {
    //   setReportARUrl(tempReportAR);
    // }
    
  };

  // TABLE ACCORDION
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  //value cleanup 0.00

  useEffect(() => {
    //When an input field gains focus, this handler checks if the value is "0.00" or "0". If it is, it clears the input value.
    const handleFocus = (event) => {
      if (event.target.value === "0.00" || event.target.value === "0") {
        event.target.value = "";
      }
    };
    //When an input field loses focus, this handler checks if the input value is empty. If it is, it sets the value to "0.00".
    const handleBlur = (event) => {
      if (event.target.value === "") {
        event.target.value = "0.00";
      }
    };

    // const handleInput = (event) => {
    //   const value = event.target.value;
    //   // Allow only numbers and dots using regular expression
    //   //const sanitizedValue = value.replace(/[^0-9.]/g, '');
    //   const sanitizedValue = value.replace(/(?!^-)[^0-9.]/g, '');
    //   event.target.value = sanitizedValue;
    // };

    // When a key is pressed or released in an input field, this handle
    //Removes any non-numeric characters except for dots, commas, and minus signs.
    //Removes any existing commas to facilitate proper formatting.
    //Formats the number by adding commas as thousand separators.
    //Updates the input value with the formatted value.

    const handleInput = (event) => {
      let value = event.target.value;
    
      // Remove non-numeric characters except dot, comma, and minus sign (for negative values)
      //const sanitizedValue = value.replace(/[^0-9.,-]/g, '');
      const sanitizedValue = value.replace(/(?!^)-/g, '').replace(/[^0-9.,-]/g, '');
      // Remove existing commas for proper formatting
      const valueWithoutCommas = sanitizedValue.replace(/,/g, '');
    
      // Format the number with commas
      const parts = valueWithoutCommas.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    
      // Join the integer and decimal parts (if any)
      const formattedValue = parts.length > 1 ? parts.join('.') : parts[0];
    
      event.target.value = formattedValue;
    };
    
    const formatValue = (input) => {
      let value = input.value;
      const sanitizedValue = value.replace(/[^0-9.,-]/g, '');
      const valueWithoutCommas = sanitizedValue.replace(/,/g, '');
      const parts = valueWithoutCommas.split('.');
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      const formattedValue = parts.length > 1 ? parts.join('.') : parts[0];
      input.value = formattedValue;
    };
    
    const inputs = document.querySelectorAll(
      ".clsCashItems, .account_receivables, .clsCashItemsRevenue, .non_current_account_receivables, .total_assets, .clsasset"
    );
    inputs.forEach((input) => {
      formatValue(input); // Format the value on page load
      input.addEventListener("focus", handleFocus);
      input.addEventListener("blur", handleBlur);
      input.addEventListener("keydown", handleInput);
      input.addEventListener("keyup", handleInput);
    });


    // Cleanup event listeners on component unmount or when inputs change
    return () => {
      inputs.forEach((input) => {
        input.removeEventListener("focus", handleFocus);
        input.removeEventListener("blur", handleBlur);
        input.removeEventListener("keydown", handleInput);
        input.removeEventListener("keyup", handleInput);
      });
    };
  }, [sampledata]);

  //Caret color
  const CustomDropdownIndicator = ({ selectProps }) => {
    const { value } = selectProps;
    let caretColor;

    // Set caret color based on selected value
    // switch (value?.value) {
    //   case "1":
    //     return <i className="icon-arrow_caret caret_green"></i>;
    //     break;
    //   case "2":
    //     return <i className="icon-arrow_caret caret_red"></i>;
    //     break;
    //   case "3":
    //     return <i className="icon-arrow_caret caret_orange"></i>;
    //     break;
    //   default:
    //     return <i className="icon-arrow_caret caret_grey"></i>;
    // }
    switch (value?.value) {
      case "1":
        return;
        break;
      case "2":
        return ;
        break;
      case "3":
        return ;
        break;
      default:
        return ;
    }
  };

  // For smooth Scrollbar

  // const scrollbarRef = useRef(null);

  //   useEffect(() => {
  //     if (scrollbarRef.current) {
  //       Scrollbar.init(scrollbarRef.current, options);

  //       return () => {
  //         if (scrollbarRef.current) {
  //           Scrollbar.destroy(scrollbarRef.current);
  //         }
  //       };
  //     }
  //   }, [options]);

  const [cashItems, setCashItems] = useState([]);
  const [otherInterestBearingAssets, setOtherInterestBearingAssets] = useState(
    []
  );

  const [cashItems1, setCashItems1] = useState([]);
  const [otherInterestBearingAssets1, setOtherInterestBearingAssets1] =
    useState([]);

  const [interest_bearing_liabilities, setInterest_bearing_liabilities] =
    useState([]);
  const [interest_bearing_liabilities1, setInterest_bearing_liabilities1] =
    useState([]);

  const [otherIncome, setOtherIncome] = useState([]);

  const getDynamicJSONFormat = () => {
    return new Promise((resolve, reject) => {
      try {
        const cashItems = [];
        const otherInterestBearingAssets = [];

        const cashItems1 = [];
        const otherInterestBearingAssets1 = [];

        const interest_bearing_liabilities = [];
        const interest_bearing_liabilities1 = [];

        const otherIncome = [];

        //Current Assets : Cash Items
        const current_assets__cash_items = document.querySelectorAll( ".current_assets__cash_items" );
        const current_assets__cash_items_sum = parseFloat( document.getElementById("current_assets__cash_items").textContent.replace(/,/g, '') );

        //Current Assets : Other interest bearing assets
        const current_assets__other_interest_bearing_assets = document.querySelectorAll( ".current_assets__other_interest_bearing_assets" );
        const total_other_interest_bearing_assets_value_sum = parseFloat( document.getElementById( "current_assets__other_interest_bearing_assets" ).textContent.replace(/,/g, '') );
        const account_receivables = parseFloat( document.getElementById("account_receivables").value.replace(/,/g, '') );

        //Non-Current Assets :  Cash Items
        const non_current_assets__cash_items = document.querySelectorAll( ".non_current_assets__cash_items" );
        const non_current_assets__cash_items_sum = parseFloat( document.getElementById("non_current_assets__cash_items").textContent.replace(/,/g, '') );

        //Non-Current Assets : Other interest bearing assets
        const non_current_assets__other_interest_bearing_assets = document.querySelectorAll( ".non_current_assets__other_interest_bearing_assets" );
        const non_current_assets__other_interest_bearing_assets_sum = parseFloat( document.getElementById( "non_current_assets__other_interest_bearing_assets" ).textContent.replace(/,/g, '') );

        const non_current_account_receivables = parseFloat( document.getElementById("non_current_account_receivables").value.replace(/,/g, '') );
        const ar_n_cash = parseFloat( document.getElementById("ar_n_cash").value.replace(/,/g, '') );
        const total_assets = parseFloat( document.getElementById("total_assets").value.replace(/,/g, '') );

        //Current Liabilities
        const current_liabilities__ibl = document.querySelectorAll( ".current_liabilities__ibl" );
        const current_liabilities__ibl_sum = parseFloat( document.getElementById("current_liabilities__ibl").textContent.replace(/,/g, '') );

        //Non-Current Liabilities
        const non_current_liabilities__ibl = document.querySelectorAll( ".non_current_liabilities__ibl" );
        const non_current_liabilities__ibl_sum = parseFloat( document.getElementById("non_current_liabilities__ibl").textContent.replace(/,/g, '') );

        //BUSINESS ACTIVITY SCREENING
        const total_revenue_sum = parseFloat( document.getElementById("total_revenue").textContent.replace(/,/g, ''));
        const bs_rfco_sum = parseFloat( document.getElementById("bs_rfco").textContent.replace(/,/g, ''));

        // Other Income
        const bs_other_income = document.querySelectorAll(".bs_other_income");
        const bs_other_income_sum = parseFloat( document.getElementById("bs_other_income").textContent.replace(/,/g, '') );

        current_assets__cash_items.forEach((row) => {
          // Extract the values based on class names
          const nameInput = row.querySelector( '.tablel_field input[type="text"]' );
          const valueInput = row.querySelector( '.text_right input[type="text"]' );
          const faceValueInput = row.querySelector( '.select_box input[type="hidden"]' );
          const commentIcon = row.querySelector('.remove_Comment .icon-comment');

          // Get the values from the inputs
          const name = nameInput ? nameInput.value : "";
          const value = valueInput ? parseFloat(valueInput.value.replace(/,/g, '')) : 0;
          const faceValue = faceValueInput ? parseInt(faceValueInput.getAttribute("custom_value")) : 0;
          const comment = commentIcon ? commentIcon.getAttribute("data-comment") : "";


          if(!((name === "" && value === 0 && faceValue === 0) || (name === "" && value === 0 && faceValue === 2) ))
             {
            // Push the extracted data to the cashItems array
            cashItems.push({
              name: name,
              value: value,
              faceValue: faceValue,
              inputCommentId:comment,
            });
          }
        });

        current_assets__other_interest_bearing_assets.forEach((row) => {
          // Extract the values based on class names
          const nameInput = row.querySelector( '.tablel_field input[type="text"]' );
          const valueInput = row.querySelector( '.text_right input[type="text"]' );
          const faceValueInput = row.querySelector( '.select_box input[type="hidden"]' );
          const commentIcon = row.querySelector('.remove_Comment .icon-comment');
          // Get the values from the inputs
          const name = nameInput ? nameInput.value : "";
          const value = valueInput ? parseFloat(valueInput.value.replace(/,/g, '')) : 0;
          const faceValue = faceValueInput ? parseInt(faceValueInput.getAttribute("custom_value")) : 0;
          const comment = commentIcon ? commentIcon.getAttribute("data-comment") : "";

          if ( !( (name === "" && value === 0 && faceValue === 0) || (name === "" && value === 0 && faceValue === 2) ) ) {
            // Push the extracted data to the cashItems array
            otherInterestBearingAssets.push({
              name: name,
              value: value,
              faceValue: faceValue,
              inputCommentId:comment,
            });
          }
        });

        non_current_assets__cash_items.forEach((row) => {
          // Extract the values based on class names
          const nameInput = row.querySelector( '.tablel_field input[type="text"]' );
          const valueInput = row.querySelector( '.text_right input[type="text"]' );
          const faceValueInput = row.querySelector( '.select_box input[type="hidden"]' );
          const commentIcon = row.querySelector('.remove_Comment .icon-comment');

          // Get the values from the inputs
          const name = nameInput ? nameInput.value : "";
          const value = valueInput ? parseFloat(valueInput.value.replace(/,/g, '')) : 0;
          const faceValue = faceValueInput ? parseInt(faceValueInput.getAttribute("custom_value")) : 0;
          const comment = commentIcon ? commentIcon.getAttribute("data-comment") : "";

          if ( !( (name === "" && value === 0 && faceValue === 0) || (name === "" && value === 0 && faceValue === 2) ) ) {
            // Push the extracted data to the cashItems array
            cashItems1.push({
              name: name,
              value: value,
              faceValue: faceValue,
              inputCommentId:comment,
            });
          }
        });

        non_current_assets__other_interest_bearing_assets.forEach((row) => {
          // Extract the values based on class names
          const nameInput = row.querySelector( '.tablel_field input[type="text"]' );
          const valueInput = row.querySelector( '.text_right input[type="text"]' );
          const faceValueInput = row.querySelector( '.select_box input[type="hidden"]' );
          const commentIcon = row.querySelector('.remove_Comment .icon-comment');
          // Get the values from the inputs
          const name = nameInput ? nameInput.value : "";
          const value = valueInput ? parseFloat(valueInput.value.replace(/,/g, '')) : 0;
          const faceValue = faceValueInput ? parseInt(faceValueInput.getAttribute("custom_value")) : 0;
          const comment = commentIcon ? commentIcon.getAttribute("data-comment") : "";

          if ( !( (name === "" && value === 0 && faceValue === 0) || (name === "" && value === 0 && faceValue === 2) ) ) {
            // Push the extracted data to the cashItems array
            otherInterestBearingAssets1.push({
              name: name,
              value: value,
              faceValue: faceValue,
              inputCommentId:comment,
            });
          }
        });

        current_liabilities__ibl.forEach((row) => {
          // Extract the values based on class names
          const nameInput = row.querySelector( '.tablel_field input[type="text"]' );
          const valueInput = row.querySelector( '.text_right input[type="text"]' );
          const commentIcon = row.querySelector('.remove_Comment .icon-comment');

          // Get the values from the inputs
          const name = nameInput ? nameInput.value : "";
          const value = valueInput ? parseFloat(valueInput.value.replace(/,/g, '')) : 0;
          const comment = commentIcon ? commentIcon.getAttribute("data-comment") : "";
          if (name !== "" || value !== 0) {
            // Push the extracted data to the cashItems array
            interest_bearing_liabilities.push({
              name: name,
              value: value,
              inputCommentId:comment,
            });
          }
        });

        non_current_liabilities__ibl.forEach((row) => {
          // Extract the values based on class names
          const nameInput = row.querySelector( '.tablel_field input[type="text"]' );
          const valueInput = row.querySelector( '.text_right input[type="text"]' );
          const commentIcon = row.querySelector('.remove_Comment .icon-comment');

          // Get the values from the inputs
          const name = nameInput ? nameInput.value : "";
          const value = valueInput ? parseFloat(valueInput.value.replace(/,/g, '')) : 0;
          const comment = commentIcon ? commentIcon.getAttribute("data-comment") : "";

          if (name !== "" || value !== 0) {
            // Push the extracted data to the cashItems array
            interest_bearing_liabilities1.push({
              name: name,
              value: value,
              inputCommentId:comment,
            });
          }
        });

        bs_other_income.forEach((row) => {
          // Extract the values based on class names
          const nameInput = row.querySelector( '.tablel_field input[type="text"]' );
          const valueInput = row.querySelector( '.text_right input[type="text"]' );
          const faceValueInput = row.querySelector( '.select_box input[type="hidden"]' );
          const commentIcon = row.querySelector('.remove_Comment .icon-comment');

          // Get the values from the inputs
          const name = nameInput ? nameInput.value.trim() : "";
          const value = valueInput ? parseFloat(valueInput.value.replace(/,/g, '')) : 0;
          const faceValue = faceValueInput ? parseInt(faceValueInput.getAttribute("custom_value")) : 0;
          const comment = commentIcon ? commentIcon.getAttribute("data-comment") : "";

          //if ( !( name === "" && value === 0 && (faceValue === null || faceValue === "NaN" || parseFloat(faceValue) === 1) ) ) {
            if ( !( (name === "" && value === 0 && faceValue === 0) || (name === "" && value === 0 && faceValue === 1) ) ) {
            // Push the extracted data to the cashItems array
            otherIncome.push({
              name: name,
              value: value,
              faceValue: faceValue,
              inputCommentId:comment,
            });
          }
        });

        //debugger;
        const assets = [];
        /***** To fix sequence */
        let index = 19;
        while (index < 50) {
          // Construct the row ID using the base ID and the current index
          index++;
          const rowId = `bs_rfco_assets_${index}`;

          // Try to get the row by ID
          const row = document.getElementById(rowId);

          // If the row doesn't exist, break the loop
          if (!row) {
            // break;
            continue;
          }
          

          // Extract the values based on class names
          const nameInput = row.querySelector( '.tablel_field input[type="text"]' );
          const valueInput = row.querySelector( '.text_right input[type="text"]' );
          const faceValueInput = row.querySelector( '.select_box input[type="hidden"]' );
          const commentIcon = row.querySelector('.remove_Comment .icon-comment');

          // Get the values from the inputs
          //const name = nameInput ? nameInput.value : "";
          const name = nameInput ? nameInput.value.trim() : "";// Trim the last character because an extra \t (tab) was being added
          const value = valueInput ? parseFloat(valueInput.value.replace(/,/g, '')) : 0;
          const faceValue = faceValueInput ? parseInt(faceValueInput.getAttribute("custom_value")) : 0;
          const comment = commentIcon ? commentIcon.getAttribute("data-comment") : "";
          
          // Check if the name is a default value like "Asset 1", "Asset 2", etc.
          const isDefaultAssetName = /^Asset \d+$/.test(name);

          if (!isDefaultAssetName && (name !== "" && value !== 0)) {  
            // Create an array to store sub-assets
            const subAssets = [];

            // Now loop through sub-assets
            const subAssetRows = document.querySelectorAll( `.bs_rfco_assets_${index}` );
            subAssetRows.forEach((subRow) => {
              // Extract the values based on class names
              const subNameInput = subRow.querySelector( '.tablel_field input[type="text"]' );
              const subValueInput = subRow.querySelector( '.text_right input[type="text"]' );
              const subFaceValueInput = subRow.querySelector( '.select_box input[type="hidden"]' );
              const subcommentIcon = subRow.querySelector('.remove_Comment .icon-comment');

              // Get the values from the inputs
              const subName = subNameInput ? subNameInput.value : "";
              const subValue = subValueInput ? parseFloat(subValueInput.value.replace(/,/g, '')) : 0;
              const subFaceValue = subFaceValueInput ? parseInt(subFaceValueInput.getAttribute("custom_value")) : 0;
              const subcomment = subcommentIcon ? subcommentIcon.getAttribute("data-comment") : "";
              
              const isDefaultSubAssetName = /^Sub Asset \d+$/.test(subName);

              if ( !isDefaultSubAssetName && (subName !== "" && subValue !== 0)) {
                // Push the extracted sub-asset data to the subAssets array
                subAssets.push({
                  name: subName,
                  value: subValue,
                  faceValue: subFaceValue,
                  inputCommentId:subcomment,
                });
              }
            });

            // Push the extracted data for the main asset with its sub-assets to the cashItems array
            assets.push({
              name: name,
              total: value,
              sub_assets: subAssets,
              faceValue: faceValue,
              inputCommentId:comment,
            });
            // console.log("assets ", assets);
          }

          // Increment the index for the next iteration
        }
        /***** end of To fix sequence */

        index = -1;
        while (index < 19) {
          // Construct the row ID using the base ID and the current index
          index++;
          const rowId = `bs_rfco_assets_${index}`;

          // Try to get the row by ID
          const row = document.getElementById(rowId);

          // If the row doesn't exist, break the loop
          if (!row) {
            // break;
            continue;
          }
          

          // Extract the values based on class names
          const nameInput = row.querySelector( '.tablel_field input[type="text"]');
          const valueInput = row.querySelector( '.text_right input[type="text"]');
          const faceValueInput = row.querySelector( '.select_box input[type="hidden"]');
          const commentIcon = row.querySelector('.remove_Comment .icon-comment');

          // Get the values from the inputs
          const name = nameInput ? nameInput.value : "";
          const value = valueInput ? parseFloat(valueInput.value.replace(/,/g, '')) : 0;
          const faceValue = faceValueInput ? parseInt(faceValueInput.getAttribute("custom_value")) : 0;
          const comment = commentIcon ? commentIcon.getAttribute("data-comment") : "";
          // Check if the name is a default value like "Asset 1", "Asset 2", etc.
          const isDefaultAssetName = /^Asset \d+$/.test(name);

          if (!isDefaultAssetName && (name !== "" && value !== 0)) {  
            // Create an array to store sub-assets
            const subAssets = [];

            // Now loop through sub-assets
            const subAssetRows = document.querySelectorAll( `.bs_rfco_assets_${index}` );
            subAssetRows.forEach((subRow) => {
              // Extract the values based on class names
              const subNameInput = subRow.querySelector( '.tablel_field input[type="text"]' );
              const subValueInput = subRow.querySelector( '.text_right input[type="text"]' );
              const subFaceValueInput = subRow.querySelector( '.select_box input[type="hidden"]' );
              const subcommentIcon = subRow.querySelector('.remove_Comment .icon-comment');

              // Get the values from the inputs
              const subName = subNameInput ? subNameInput.value : "";
              const subValue = subValueInput ? parseFloat(subValueInput.value.replace(/,/g, '')) : 0;
              const subFaceValue = subFaceValueInput ? parseInt(subFaceValueInput.getAttribute("custom_value")) : 0;
              const subcomment = subcommentIcon ? subcommentIcon.getAttribute("data-comment") : "";

              const isDefaultSubAssetName = /^Sub Asset \d+$/.test(subName);

              if ( !isDefaultSubAssetName && (subName !== "" && subValue !== 0)) {
                // Push the extracted sub-asset data to the subAssets array
                subAssets.push({
                  name: subName,
                  value: subValue,
                  faceValue: subFaceValue,
                  inputCommentId:subcomment,
                });
              }
            });

            // Push the extracted data for the main asset with its sub-assets to the cashItems array
            assets.push({
              name: name,
              total: value,
              sub_assets: subAssets,
              faceValue: faceValue,
              inputCommentId:comment,
            });
            // console.log("assets ", assets);
          }

          // Increment the index for the next iteration
        }

        // Existing JSON structure
        const jsonStructure = {
          Financial_Screening: {
            current_assets: {
              cash_items: [],
              total_cash_items_value: 0,
              other_interest_bearing_assets: [],
              total_other_interest_bearing_assets_value: 0,
            },
            account_receivables_current_assets: 0,
            non_current_assets: {
              cash_items: [],
              total_cash_items_value: 0,
              other_interest_bearing_assets: [],
              total_other_interest_bearing_assets_value: 0,
            },
            account_receivables_non_current_assets: 0,
            ar_cash: 0,
            total_assets: 0,
            current_liabilities: {
              interest_bearing_liabilities: [],
              total_current_liabilities_value: 0,
            },
            non_current_liabilities: {
              interest_bearing_liabilities: [],
              total_interest_bearing_liabilities_value: 0,
            },
          },
          Business_Activity_Screening: {
            total_revenue: 0,
            revenue_from_core_operation: {
              total: 0,
              assets: [],
            },
            other_income: {
              total: 0,
              assets: [],
            },
          },
        };

        //Current Assets : Cash Items
        jsonStructure.Financial_Screening.current_assets.cash_items =
          cashItems.length > 0
            ? cashItems
            : jsonStructure.Financial_Screening.current_assets.cash_items;
        jsonStructure.Financial_Screening.current_assets.total_cash_items_value =
          current_assets__cash_items_sum;

        //Current Assets : Other interest bearing assets
        jsonStructure.Financial_Screening.current_assets.other_interest_bearing_assets = otherInterestBearingAssets.length > 0 ? otherInterestBearingAssets : jsonStructure.Financial_Screening.current_assets .other_interest_bearing_assets;
        jsonStructure.Financial_Screening.current_assets.total_other_interest_bearing_assets_value = total_other_interest_bearing_assets_value_sum;
        jsonStructure.Financial_Screening.account_receivables_current_assets = account_receivables;

        //Non-Current Assets :  Cash Items
        jsonStructure.Financial_Screening.non_current_assets.cash_items = cashItems1.length > 0 ? cashItems1 : jsonStructure.Financial_Screening.non_current_assets.cash_items;
        jsonStructure.Financial_Screening.non_current_assets.total_cash_items_value = non_current_assets__cash_items_sum;

        //Non-Current Assets : Other interest bearing assets
        jsonStructure.Financial_Screening.non_current_assets.other_interest_bearing_assets = otherInterestBearingAssets1.length > 0 ? otherInterestBearingAssets1 : jsonStructure.Financial_Screening.non_current_assets .other_interest_bearing_assets;
        jsonStructure.Financial_Screening.non_current_assets.total_other_interest_bearing_assets_value = non_current_assets__other_interest_bearing_assets_sum;

        jsonStructure.Financial_Screening.account_receivables_non_current_assets = non_current_account_receivables;
        //jsonStructure.Financial_Screening.account_receivables_non_current_assets = non_current_account_receivables;
        jsonStructure.Financial_Screening.ar_cash = ar_n_cash;
        jsonStructure.Financial_Screening.total_assets = total_assets;

        //Current Liabilities
        jsonStructure.Financial_Screening.current_liabilities.interest_bearing_liabilities =
          interest_bearing_liabilities.length > 0
            ? interest_bearing_liabilities
            : jsonStructure.Financial_Screening.current_liabilities
                .interest_bearing_liabilities;
        jsonStructure.Financial_Screening.current_liabilities.total_current_liabilities_value =
          current_liabilities__ibl_sum;

        //Non-Current Liabilities
        jsonStructure.Financial_Screening.non_current_liabilities.interest_bearing_liabilities =
          interest_bearing_liabilities1.length > 0
            ? interest_bearing_liabilities1
            : jsonStructure.Financial_Screening.non_current_liabilities
                .interest_bearing_liabilities;
        jsonStructure.Financial_Screening.non_current_liabilities.total_interest_bearing_liabilities_value =
          non_current_liabilities__ibl_sum;

        //********************/
        jsonStructure.Business_Activity_Screening.total_revenue = total_revenue_sum;
        jsonStructure.Business_Activity_Screening.revenue_from_core_operation.total = bs_rfco_sum;
        jsonStructure.Business_Activity_Screening.revenue_from_core_operation.assets =assets;
          

        jsonStructure.Business_Activity_Screening.other_income.total = bs_other_income_sum;
        jsonStructure.Business_Activity_Screening.other_income.assets =otherIncome;
         

        //console.log(JSON.stringify(otherIncome, null, 2));

        // Return the updated JSON structure
        //return jsonStructure;

        // Complaince Report Json
        // setComplianceAccountsReceivable(account_receivables + non_current_account_receivables);        
        // setComplianceDebtRevenue((current_liabilities__ibl_sum + non_current_liabilities__ibl_sum) * selectedValue);

        // setComplianceHalalPercentage((complianceHalalSum/total_revenue_sum)*100);
        // setComplianceHalalRevenue(complianceHalalSum * selectedValue);

        
        // setComplianceNotHalalRevenue(complianceNotHalalSum * selectedValue);
        // setComplianceDoubtfulRevenue(complianceDoubtfulSum  * selectedValue);
        // setComplianceTotalRevenue(total_revenue_sum * selectedValue);

        resolve(jsonStructure);
      } catch (error) {
        // If any error occurs during the process, reject the Promise with the error
        reject(error);
      }
    });
  };


  const getStandardJsonFormat = () => {
    return new Promise((resolve, reject) => {
      try {
        const standardTable = {
          Standards: {
            AAOIFI: valComplianceStatusAAOIFI,
            DJIM: valComplianceStatusDJIM,
            "S&P": valComplianceStatusSP,
            FTSE: valComplianceStatusFTSE,
            MSCI: valComplianceStatusMSCI,
            "MSCI_M-series": valComplianceStatusMSCIMseries,
            "SAC-SC": valComplianceStatusSACSC,
            ISSI: valComplianceStatusISSI,
          },
          Ratios_and_Metrics: {
            IB_assets_to_36MC: valIBAssetsTo36MC,
            IB_debt_to_36MC: valIBDebtTo36MC,
            IB_assets_to_24MC: valIBAssetsTo24MC,
            IB_assets_to_TA: valIBAssetsToTA,
            "AR_&_Cash_to_TA": valARAndCashToTA,
            "AR_&_Cash_to_36MC": valARAndCashTo36MC,
            IB_debt_to_TA: valIBDebtToTA,
          },
          Revenue_Categories: {
            "Non-compliant_revenue": valNoncompliantRevenue,
            Doubtful_revenue: valDoubtfulRevenue,
            Impermissible_revenue: valImpermissibleRevenue,
          },
        };

        //Scoring Calculation
        //AAOIFI_scoring
        let AAOIFI_IB_Assets_To_36MC = 0;
        let AAOIFI_IB_debt_To_36MC = 0;
        let AAOIFI_Impermissible_revenue = 0;
        //let AAOIFI_AGGREGATE SCORE = 0;

        //const jsonString = JSON.stringify(standardTable);
        resolve(standardTable); // Resolve the Promise with the JSON string
      } catch (error) {
        reject(error); // If any error occurs during the process, reject the Promise with the error
      }
    });
  };

  const getScoringRankingJsonFormat = () => {
    return new Promise((resolve, reject) => {
      try {
        const rowStandardTable = {
          Standards: {
            AAOIFI: {
              IB_assets_to_36MC: valIBAssetsTo36MC,
              IB_debt_to_36MC: valIBDebtTo36MC,
              IB_assets_to_24MC: 0,
              IB_assets_to_TA: 0,
              AR_and_Cash_to_TA: 0,
              AR_and_Cash_to_36MC: 0,
              IB_debt_to_TA: 0,
              Non_compliant_revenue: valNoncompliantRevenue,
              Doubtful_revenue: valDoubtfulRevenue,
              Impermissible_revenue: valImpermissibleRevenue,
              Compliance_status: valComplianceStatusAAOIFI,
              C_status: valComplianceStatusAAOIFI === "Compliant" ? 1 : valComplianceStatusAAOIFI === "Non-compliant" ? 2 : valComplianceStatusAAOIFI === "Doubtful" ? 3 : 0,
            },
            DJIM: {
              IB_assets_to_36MC: 0,
              IB_debt_to_36MC: 0,
              IB_assets_to_24MC: valIBAssetsTo24MC,
              IB_assets_to_TA: 0,
              AR_and_Cash_to_TA: 0,
              AR_and_Cash_to_36MC: 0,
              IB_debt_to_TA: 0,
              Non_compliant_revenue: valNoncompliantRevenue,
              Doubtful_revenue: valDoubtfulRevenue,
              Impermissible_revenue: valImpermissibleRevenue,
              Compliance_status: valComplianceStatusDJIM,
              C_status:
                valComplianceStatusDJIM === "Compliant"
                  ? 1
                  : valComplianceStatusDJIM === "Non-compliant"
                  ? 2
                  : valComplianceStatusDJIM === "Doubtful"
                  ? 3
                  : 0,
            },
            SP: {
              IB_assets_to_36MC: valIBAssetsTo36MC,
              IB_debt_to_36MC: 0,
              IB_assets_to_24MC: 0,
              IB_assets_to_TA: 0,
              AR_and_Cash_to_TA: 0,
              AR_and_Cash_to_36MC: 0,
              IB_debt_to_TA: 0,
              Non_compliant_revenue: valNoncompliantRevenue,
              Doubtful_revenue: valDoubtfulRevenue,
              Impermissible_revenue: valImpermissibleRevenue,
              Compliance_status: valComplianceStatusSP,
              C_status:
                valComplianceStatusSP === "Compliant"
                  ? 1
                  : valComplianceStatusSP === "Non-compliant"
                  ? 2
                  : valComplianceStatusSP === "Doubtful"
                  ? 3
                  : 0,
            },
            FTSE: {
              IB_assets_to_36MC: 0,
              IB_debt_to_36MC: 0,
              IB_assets_to_24MC: 0,
              IB_assets_to_TA: valIBAssetsToTA,
              AR_and_Cash_to_TA: valARAndCashToTA,
              AR_and_Cash_to_36MC: 0,
              IB_debt_to_TA: valIBDebtToTA,
              Non_compliant_revenue: valNoncompliantRevenue,
              Doubtful_revenue: valDoubtfulRevenue,
              Impermissible_revenue: valImpermissibleRevenue,
              Compliance_status: valComplianceStatusFTSE,
              C_status:
                valComplianceStatusFTSE === "Compliant"
                  ? 1
                  : valComplianceStatusFTSE === "Non-compliant"
                  ? 2
                  : valComplianceStatusFTSE === "Doubtful"
                  ? 3
                  : 0,
            },
            MSCI: {
              IB_assets_to_36MC: 0,
              IB_debt_to_36MC: 0,
              IB_assets_to_24MC: 0,
              IB_assets_to_TA: valIBAssetsToTA,
              AR_and_Cash_to_TA: valARAndCashToTA,
              AR_and_Cash_to_36MC: 0,
              IB_debt_to_TA: valIBDebtToTA,
              Non_compliant_revenue: valNoncompliantRevenue,
              Doubtful_revenue: valDoubtfulRevenue,
              Impermissible_revenue: valImpermissibleRevenue,
              Compliance_status: valComplianceStatusMSCI,
              C_status:
                valComplianceStatusMSCI === "Compliant"
                  ? 1
                  : valComplianceStatusMSCI === "Non-compliant"
                  ? 2
                  : valComplianceStatusMSCI === "Doubtful"
                  ? 3
                  : 0,
            },
            MSCI_M_series: {
              IB_assets_to_36MC: valIBAssetsTo36MC,
              IB_debt_to_36MC: valIBDebtTo36MC,
              IB_assets_to_24MC: 0,
              IB_assets_to_TA: 0,
              AR_and_Cash_to_TA: 0,
              AR_and_Cash_to_36MC: valARAndCashTo36MC,
              IB_debt_to_TA: 0,
              Non_compliant_revenue: valNoncompliantRevenue,
              Doubtful_revenue: valDoubtfulRevenue,
              Impermissible_revenue: valImpermissibleRevenue,
              Compliance_status: valComplianceStatusMSCIMseries,
              C_status:
                valComplianceStatusMSCIMseries === "Compliant"
                  ? 1
                  : valComplianceStatusMSCIMseries === "Non-compliant"
                  ? 2
                  : valComplianceStatusMSCIMseries === "Doubtful"
                  ? 3
                  : 0,
            },
            SAC_SC: {
              IB_assets_to_36MC: 0,
              IB_debt_to_36MC: 0,
              IB_assets_to_24MC: 0,
              IB_assets_to_TA: valIBAssetsToTA,
              AR_and_Cash_to_TA: 0,
              AR_and_Cash_to_36MC: 0,
              IB_debt_to_TA: valIBDebtToTA,
              Non_compliant_revenue: valNoncompliantRevenue,
              Doubtful_revenue: valDoubtfulRevenue,
              Impermissible_revenue: valImpermissibleRevenue,
              Compliance_status: valComplianceStatusSACSC,
              C_status:
                valComplianceStatusSACSC === "Compliant"
                  ? 1
                  : valComplianceStatusSACSC === "Non-compliant"
                  ? 2
                  : valComplianceStatusSACSC === "Doubtful"
                  ? 3
                  : 0,
            },
            ISSI: {
              IB_assets_to_36MC: 0,
              IB_debt_to_36MC: 0,
              IB_assets_to_24MC: 0,
              IB_assets_to_TA: 0,
              AR_and_Cash_to_TA: 0,
              AR_and_Cash_to_36MC: 0,
              IB_debt_to_TA: valIBDebtToTA,
              Non_compliant_revenue: valNoncompliantRevenue,
              Doubtful_revenue: valDoubtfulRevenue,
              Impermissible_revenue: valImpermissibleRevenue,
              Compliance_status: valComplianceStatusISSI,
              C_status:
                valComplianceStatusISSI === "Compliant"
                  ? 1
                  : valComplianceStatusISSI === "Non-compliant"
                  ? 2
                  : valComplianceStatusISSI === "Doubtful"
                  ? 3
                  : 0,
            },
          },
        };

        resolve(rowStandardTable); // Resolve the Promise with the JSON object
      } catch (error) {
        reject(error); // If any error occurs during the process, reject the Promise with the error
      }
    });
  };

  const getReviewSubtickerJsonFormat = (jsonStructure) => {
    return new Promise((resolve, reject) => {
      try {
        const financialScreening = jsonStructure.Financial_Screening;
        const businessActivityScreening = jsonStructure.Business_Activity_Screening;

         //console.log(JSON.stringify(businessActivityScreening));
         //return false;

        // Function to transform items into the desired format
        const transformItems = (items) => {
          return Object.entries(items).map(([key, value]) => ({
            label: key,
            style: value.style,
            value: [value.value]
          }));
        };

        // Create arrays to hold formatted items
        let currentAssetCashItems = [];
        let currentAssetOtherInterestBearingAssets = [];
        let nonCurrentAssetCashItems = [];
        let nonCurrentAssetOtherInterestBearingAssets = [];
        let currentLiabilitiesInterestBearingLiabilities = [];
        let nonCurrentLiabilitiesInterestBearingLiabilities = [];

        let revenueFromCoreOperation = [];
        let otherIncome = [];

        // Current Assets: Cash Items
        financialScreening.current_assets.cash_items.forEach(item => {
          currentAssetCashItems.push({
            label: item.name,
            style: "Child",
            value: [item.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })] // Assuming value needs to be a string
          });
        });

        // Current Assets: Other interest bearing assets
        financialScreening.current_assets.other_interest_bearing_assets.forEach(item => {
          currentAssetOtherInterestBearingAssets.push({
            label: item.name,
            style: "Child",
            value: [item.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })] // Assuming value needs to be a string
          });
        });

        // Non-Current Assets: Cash Items
        financialScreening.non_current_assets.cash_items.forEach(item => {
          nonCurrentAssetCashItems.push({
            label: item.name,
            style: "Child",
            value: [item.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })] // Assuming value needs to be a string
          });
        });

        // Non-Current Assets: Other interest bearing assets
        financialScreening.non_current_assets.other_interest_bearing_assets.forEach(item => {
          nonCurrentAssetOtherInterestBearingAssets.push({
            label: item.name,
            style: "Child",
            value: [item.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })] // Assuming value needs to be a string
          });
        });

        // Current Liabilities: Interest bearing liabilities
        financialScreening.current_liabilities.interest_bearing_liabilities.forEach(item => {
          currentLiabilitiesInterestBearingLiabilities.push({
            label: item.name,
            style: "Child",
            value: [item.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })] // Assuming value needs to be a string
          });
        });

        // Non-Current Liabilities: Interest bearing liabilities
        financialScreening.non_current_liabilities.interest_bearing_liabilities.forEach(item => {
          nonCurrentLiabilitiesInterestBearingLiabilities.push({
            label: item.name,
            style: "Child",
            value: [item.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })] // Assuming value needs to be a string
          });
        });

        //BUSINESS ACTIVITY SCREENING
        // businessActivityScreening.revenue_from_core_operation.assets.forEach(item => {
        //   revenueFromCoreOperation.push({
        //     label: item.name,
        //     style: "Child",
        //     value: [item.total.toString()] // Assuming value needs to be a string
        //   });
        // });

        // Process revenue_from_core_operation assets
      businessActivityScreening.revenue_from_core_operation.assets.forEach(asset => {
      revenueFromCoreOperation.push({ "label": asset.name, "style": "Child_header", "value": [asset.total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })] });
      asset.sub_assets.forEach(subAsset => {
        revenueFromCoreOperation.push({ "label": subAsset.name, "style": "Child", "value": [subAsset.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })] });
      });
    });

    // Process other_income assets
    businessActivityScreening.other_income.assets.forEach(asset => {
      otherIncome.push({ "label": asset.name, "style": "Child", "value": [asset.value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })] });
    });


        const subtickerFormat = [
          { label: "Description", style: "header", value: [responseDecodeData.company_symbol] },
          { label: "Currency", style: "plain", value: [selectedCurrency.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })] },
          { label: "Rate To Main Currency", style: "plain", value: [responseDecodeData.usd_rate] },
          { label: "Shares Outstanding", style: "Y_header", value: [responseDecodeData.share_outstanding] },
          { label: "36 Avg Market Cap", style: "plain", value: [month36Val] },
          { label: "Current Assets", style: "Parent_header", value: [financialScreening.current_assets.total_cash_items_value + financialScreening.current_assets.total_other_interest_bearing_assets_value] },
          { label: "Cash Items", style: "Child_header", value: [financialScreening.current_assets.total_cash_items_value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })] },
          ...currentAssetCashItems,
          { label: "Other interest bearing assets", style: "Child_header", value: [financialScreening.current_assets.total_other_interest_bearing_assets_value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })] },
          ...currentAssetOtherInterestBearingAssets,
          { label: "Account receivables", style: "Y_header", value: [financialScreening.account_receivables_current_assets.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })] },
          { label: "Non-Current Assets", style: "Parent_header", value: ["0"] },
          { label: "Cash Items", style: "Child_header", value: [financialScreening.non_current_assets.total_cash_items_value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })] },
          ...nonCurrentAssetCashItems,
          { label: "Other interest bearing assets", style: "Child_header", value: [financialScreening.non_current_assets.total_other_interest_bearing_assets_value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })] },
          ...nonCurrentAssetOtherInterestBearingAssets,
          { label: "Account receivables", style: "Y_header", value: [financialScreening.account_receivables_non_current_assets.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })] },
          { label: "AR & Cash", style: "Y_header", value: [financialScreening.ar_cash.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })] },
          { label: "Total assets", style: "G_header", value: [financialScreening.total_assets.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })] },
          { label: "Current Liabilities", style: "Parent_header", value: ["0"] },
          { label: "Interest bearing liabilities", style: "Child_header", value: [financialScreening.current_liabilities.total_current_liabilities_value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })] },
          ...currentLiabilitiesInterestBearingLiabilities,
          { label: "Non-Current Liabilities", style: "Parent_header", value: ["0"] },
          { label: "Interest bearing liabilities", style: "Child_header", value: [financialScreening.non_current_liabilities.total_interest_bearing_liabilities_value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })] },
          ...nonCurrentLiabilitiesInterestBearingLiabilities,

          { label: "TOTAL REVENUE", style: "Parent_header", value: [businessActivityScreening.total_revenue.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })] },
          { label: "Revenue from core operation", style: "Parent_header", value: [businessActivityScreening.revenue_from_core_operation.total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })]},
          ...revenueFromCoreOperation,
          { label: "Other income", style: "Parent_header", value: [businessActivityScreening.other_income.total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })]},
          ...otherIncome
        ];

        //console.log(JSON.stringify(subtickerFormat));
        //return false;

        resolve(subtickerFormat); // Resolve the Promise with the JSON object
      } catch (error) {
        reject(error); // If any error occurs during the process, reject the Promise with the error
      }
    });
  };

  const getComplianceReport = (jsonStructure) => {
    return new Promise((resolve, reject) => {
      try {

  
        

        const handleSelectedYear = (selectedYear) => typeof selectedYear === "number" ? selectedYear : new Date(selectedYear).getFullYear();

      // Make a copy of the imported JSON data
      //const modifiedData = { ...complianceJson };
      const modifiedData = { ...complianceJson };

      //const modifiedData = { };
      const financialScreening = jsonStructure.Financial_Screening;
      const businessActivityScreening = jsonStructure.Business_Activity_Screening;
      
      //securitiesAndAssetsJson.shortTermJson :  
      // Current Assets
      const currentAsset_CashItem = financialScreening.current_assets.cash_items;
      const currentAsset_otherInterestBA = financialScreening.current_assets.other_interest_bearing_assets;
      const currentAsset = [...currentAsset_CashItem, ...currentAsset_otherInterestBA];
      const sumCurrentAsset = financialScreening.current_assets.total_cash_items_value + financialScreening.current_assets.total_other_interest_bearing_assets_value;
      //securitiesAndAssetsJson.longTermJson
       // Non-Current Assets
      const nonCurrentAsset_CashItem = financialScreening.non_current_assets.cash_items;
      const nonCurrentAsset_otherInterestBA= financialScreening.non_current_assets.other_interest_bearing_assets;
      const non_CurrentAsset = [...nonCurrentAsset_CashItem, ...nonCurrentAsset_otherInterestBA];
      const sumNon_CurrentAsset = financialScreening.non_current_assets.total_cash_items_value + financialScreening.non_current_assets.total_other_interest_bearing_assets_value;;

      // Revenue from core operation
      const coreRevenue = businessActivityScreening.revenue_from_core_operation.assets;
      const CoreRevinueTotal = businessActivityScreening.revenue_from_core_operation.total;
      const CoreTotalRevenue = businessActivityScreening.total_revenue;
        
      //console.log(JSON.stringify(coreRevenue));
      //return false;



      const nonCurrentInterestBearingLiabilities = financialScreening.non_current_liabilities.interest_bearing_liabilities;
      const currentInterestBearingLiabilities = financialScreening.current_liabilities.interest_bearing_liabilities;
      
      const totalnonCurrentAssets = financialScreening.non_current_assets.total_cash_items_value + financialScreening.non_current_assets.total_other_interest_bearing_assets_value;
      
      const interestIncomeJson = businessActivityScreening.other_income.assets;

      const interestIncomeTotal = businessActivityScreening.other_income.total;
      


    //calculation 
    const account_receivables = parseFloat( document.getElementById("account_receivables").value.replace(/,/g, '') );
    const non_current_account_receivables = parseFloat( document.getElementById("non_current_account_receivables").value.replace(/,/g, '') );
    const current_liabilities__ibl_sum = parseFloat( document.getElementById("current_liabilities__ibl").textContent.replace(/,/g, '') );
    const non_current_liabilities__ibl_sum = parseFloat( document.getElementById("non_current_liabilities__ibl").textContent.replace(/,/g, '') );
    const total_revenue_sum = parseFloat( document.getElementById("total_revenue").textContent.replace(/,/g, '') );
    const complianceDebtRevenue = ((current_liabilities__ibl_sum + non_current_liabilities__ibl_sum) * selectedValue);
    


    //Non-Current Liabilities
    const itemslongTermJsonArray = nonCurrentInterestBearingLiabilities.map((item, index) => ({
          id: (index + 1).toString(), // Generate IDs as needed
          name: item.name,
          items: [],
          amount: item.value.toString(), // Convert value to string if needed
          comment: [
            {
            "body": getCommentMessage(item.inputCommentId),
            "files": []
            }
            ],
          selector: "",
          percentage: (item.value / calculate36Val) * 100, 
          amountInOnes: item.value * selectedValue,
          itemsNotHalal: 0,
          itemsQuestionable: 0
    }));

    //Current Liabilities
    const itemshortTermJsonArray = currentInterestBearingLiabilities.map((item, index) => ({
        id: (index + 1).toString(), // Generate IDs as needed
        name: item.name,
        items: [],
        amount: item.value.toString(), // Convert value to string if needed
        comment: [
          {
          "body": getCommentMessage(item.inputCommentId),
          "files": []
          }
          ],
        selector: "",
        percentage: (item.value / calculate36Val) * 100,
        amountInOnes: item.value * selectedValue, 
        itemsNotHalal: 0,
        itemsQuestionable:0
    }));

    //Other Income
    let otherHalalSum = 0;
    let otherNonHalalSum = 0;
    let otherDoubtfulSum = 0;

    const itemInterestIncomeJson = interestIncomeJson.map((item, index) => {
      // Update otherHalal if item.faceValue is 1
          if (item.faceValue === 1) {
            otherHalalSum += item.value;
          }
          else if(item.faceValue === 2)
          {
            otherNonHalalSum+= item.value;
          }
          else if(item.faceValue === 3)
          {
            otherDoubtfulSum+= item.value;
          }

      return {
            id: (index + 1).toString(), // Generate IDs as needed
            name: item.name,
            items: [],
            amount: item.value.toString(),
            comment: [
              {
              "body": getCommentMessage(item.inputCommentId),
              "files": []
              }
              ],
            selector: item.faceValue === 1 ? "COMPLIANT" : item.faceValue === 2 ? "NON_COMPLIANT" : item.faceValue === 0 ? "" : item.faceValue === 3 ? "QUESTIONABLE" : "",
            percentage: (item.value / total_revenue_sum) * 100,
            amountInOnes: item.value * selectedValue,
            itemsNotHalal: 0,
            itemsQuestionable: 0
          };
    });

    
    //Revenue from core operation
     let revenueHalalSum = 0;
     let revenueNonHalalSum = 0;
     let revenueDoubtfulSum = 0;

  //   const itemTotalRevenueFromCoreOperation = coreRevenue.map((item, index) => {

  //     // if (item.faceValue === 1) {
  //     //   revenueHalalSum += item.total;
  //     // }
  //     // else if(item.faceValue === 2)
  //     // {
  //     //   revenueNonHalalSum+= item.total;
  //     // }
  //     // else if(item.faceValue === 3)
  //     // {
  //     //   revenueDoubtfulSum+= item.total;
  //     // }

  //     if (item.faceValue === 1) {
  //       revenueHalalSum += item.total;
  //     } else if (item.faceValue === 2) {
  //       revenueNonHalalSum += item.total;
  //     } else if (item.faceValue === 3) {
  //       revenueDoubtfulSum += item.total;
  //     } else if (item.faceValue === 0) {
  //       item.sub_assets.forEach(subAsset => {
  //         if (subAsset.faceValue === 1) {
  //           revenueHalalSum += subAsset.value;
  //         } else if (subAsset.faceValue === 2) {
  //           revenueNonHalalSum += subAsset.value;
  //         } else if (subAsset.faceValue === 3) {
  //           revenueDoubtfulSum += subAsset.value;
  //         }
  //       });
  //     }
  // return {
  //     id: (index + 1).toString(),
  //     name: item.name,
  //     items: [
  //       {
  //         id: (index + 1).toString(),
  //         name: item.name,
  //         items: [],
  //         amount: item.total.toString(), // Convert value to string if needed
  //         comment: [
  //           {
  //             "body": getCommentMessage(item.inputCommentId),
  //             "files": []
  //           }
  //         ],
  //         selector: item.faceValue === 1 ? "COMPLIANT" : item.faceValue === 2 ? "NON_COMPLIANT" : item.faceValue === 0 ? "" : item.faceValue === 3 ? "QUESTIONABLE" : "",
  //         percentage: (item.total /CoreTotalRevenue) * 100,
  //         openComment:false,
  //         amountInOnes: (item.total * selectedValue).toString(), // Assuming this is the same as amount initially
  //         itemsNotHalal: 0,
  //         itemsQuestionable: 0
  //       }
  //     ],
  //     amount: item.total.toString(), // Convert value to string if needed
  //     comment: [
  //       {
  //         "body": getCommentMessage(item.inputCommentId),
  //         "files": []
  //         }
  //     ],
  //     selector: item.faceValue === 1 ? "COMPLIANT" : item.faceValue === 2 ? "NON_COMPLIANT" : item.faceValue === 0 ? "" : item.faceValue === 3 ? "QUESTIONABLE" : "",
  //     percentage: (item.total /CoreTotalRevenue) * 100,
  //     openComment:false,
  //     amountInOnes: (item.total * selectedValue).toString(), // Assuming this is the same as amount initially
  //     itemsNotHalal: 0,
  //     itemsQuestionable: 0
  //   };
  //   });
  //console.log(JSON.stringify(coreRevenue));

  const itemTotalRevenueFromCoreOperation = coreRevenue.map((item, index) => {
    // Define the structure for sub-items
    let subItems = [];
  
    // Handle sub-assets if faceValue is 0
    if (item.faceValue == 0) {
      subItems = item.sub_assets.map((subAsset, subIndex) => {
        // Determine the selector based on faceValue of the subAsset
        const subAssetSelector = subAsset.faceValue === 1 ? "COMPLIANT" : subAsset.faceValue === 2 ? "NON_COMPLIANT" : subAsset.faceValue === 3 ? "QUESTIONABLE" : "";
  
        // Add to respective revenue sums
        if (subAsset.faceValue == 1) {
          revenueHalalSum += subAsset.value;
        } else if (subAsset.faceValue == 2) {
          revenueNonHalalSum += subAsset.value;
        } else if (subAsset.faceValue == 3) {
          revenueDoubtfulSum += subAsset.value;
        }
  
        return {
          id: (subIndex + 1).toString(),
          name: subAsset.name,
          items: [], // No further nesting for subAssets
          amount: subAsset.value.toString(), // Convert value to string if needed
          comment: [
            {
              "body": getCommentMessage(subAsset.inputCommentId),
              "files": []
            }
          ],
          selector: subAssetSelector,
          percentage: (subAsset.value / CoreTotalRevenue) * 100,
          openComment: false,
          amountInOnes: subAsset.value * selectedValue, // Assuming this is the same as amount initially
          itemsNotHalal: 0,
          itemsQuestionable: 0
        };
      });
    }
    else {
      // Add to respective revenue sums based on item.faceValue
      if (item.faceValue == 1) {
        revenueHalalSum += item.total;
      } else if (item.faceValue == 2) {
        revenueNonHalalSum += item.total;
      } else if (item.faceValue == 3) {
        revenueDoubtfulSum += item.total;
      }
    }
  
    // Determine the selector based on faceValue of the item
    const itemSelector = item.faceValue === 1 ? "COMPLIANT" : item.faceValue === 2 ? "NON_COMPLIANT" : item.faceValue === 3 ? "QUESTIONABLE" : "";
  
    // Return the final object with items
    return {
      id: (index + 1).toString(),
      name: item.name,
      items: [
        {
          id: (index + 1).toString(),
          name: item.name,
          items: subItems, // Include sub-items here
          amount: item.total.toString(), // Convert value to string if needed
          comment: [
            {
              "body": getCommentMessage(item.inputCommentId),
              "files": []
            }
          ],
          selector: itemSelector,
          percentage: (item.total / CoreTotalRevenue) * 100,
          openComment: false,
          amountInOnes: item.total * selectedValue, // Assuming this is the same as amount initially
          itemsNotHalal: 0,
          itemsQuestionable: 0
        }
      ],
      amount: item.total.toString(), // Convert value to string if needed
      comment: [
        {
          "body": getCommentMessage(item.inputCommentId),
          "files": []
        }
      ],
      selector: itemSelector,
      percentage: (item.total / CoreTotalRevenue) * 100,
      openComment: false,
      amountInOnes: item.total * selectedValue, // Assuming this is the same as amount initially
      itemsNotHalal: 0,
      itemsQuestionable: 0
    };
  });
  

    console.log('revenueHalalSum : '+ (revenueHalalSum) + ' : otherHalalSum' + otherHalalSum + ' : total_revenue_sum : '+ total_revenue_sum);
    const complianceHalalPercentage = ((revenueHalalSum + otherHalalSum)/total_revenue_sum)*100;
    console.log('complianceHalalPercentage : '+ complianceHalalPercentage);

    const complianceHalalRevenue = (revenueHalalSum + otherHalalSum) * selectedValue;
    console.log('complianceNotHalalSum : '+ revenueHalalSum + ' selectedValue : '+ selectedValue);
    const complianceNotHalalRevenue = (revenueNonHalalSum + otherNonHalalSum ) * selectedValue;
    const complianceDoubtfulRevenue = (revenueDoubtfulSum + otherDoubtfulSum)  * selectedValue;
    const complianceTotalRevenue = total_revenue_sum * selectedValue;

//securities_and_assets_json
let CA_HalalSum = 0;
let CA_NonHalalSum = 0;

let NCA_HalalSum = 0;
let NCA_NonHalalSum = 0;

  //Current assets
  const itemCurrentItem_ShortTerm = currentAsset.map((item, index) => {
      if (item.faceValue === 1) {
        CA_HalalSum += item.value;
      }
      else if(item.faceValue === 2)
      {
        CA_NonHalalSum+= item.value;
      }
  return {
      id: (index + 1).toString(),
      name: item.name,
      items: [],
      amount: item.value.toString(), // Convert value to string if needed
      comment: [
        {
        "body": getCommentMessage(item.inputCommentId),
        "files": []
        }
        ],
      selector: item.faceValue === 1 ? "ISLAMIC" : (item.faceValue === 2 ? "NON_ISLAMIC" : "Doubtful"),
      percentage: (item.value / calculate36Val) * 100,
      amountInOnes: item.value * selectedValue, 
      itemsNotHalal: 0,
      itemsQuestionable: 0
    };
  });
  //Non Current Assets
  const itemCurrentItem_LongTerm = non_CurrentAsset.map((item, index) => {
      if (item.faceValue === 1) {
        NCA_HalalSum += item.value;
      }
      else if(item.faceValue === 2)
      {
        NCA_NonHalalSum+= item.value;
      }
  return {
    id: (index + 1).toString(),
    name: item.name,
    items: [],
    amount: item.value.toString(), 
    comment: [
      {
      "body": getCommentMessage(item.inputCommentId),
      "files": []
      }
      ],
    selector: item.faceValue === 1 ? "ISLAMIC" : (item.faceValue === 2 ? "NON_ISLAMIC" : "Doubtful"),
    percentage: (item.value / calculate36Val) * 100,
    amountInOnes:  item.value * selectedValue, 
    itemsNotHalal: 0,
    itemsQuestionable: 0
  };
  });
    

   

    //setComplianceAccountsReceivable(account_receivables + non_current_account_receivables);        


      modifiedData.identifier = Identifier; // Role id identifier : Login API
      //modifiedData.accounts_receivable = complianceAccountsReceivable;//Current Asset AR + Non Current Asset AR SUM
      modifiedData.accounts_receivable = account_receivables + non_current_account_receivables;//Current Asset AR + Non Current Asset AR SUM
      modifiedData.cba_comment = cbaCommentRef.current.value; // This value is coming from the comments section in the Merline system, but it is currently not available in Nova.
      modifiedData.cba_status = cbaStatus ? "Pass" : "Fail"; // Not Available on Nova yet
      modifiedData.company_name = responseDecodeData.name;
      modifiedData.country = responseDecodeData.country;
      modifiedData.currency = selectedCurrency;
      modifiedData.debt = valIBDebtTo36MC; // IB debt to 36MC : total interest-bearing debt/market cap * 100 : current_liabilities_sum + non_current_liabilities_sum) / calculate36Val) * 100
      modifiedData.debt_revenue = complianceDebtRevenue; // total debt (Current Liabilities + Non-Current Liabilities)
      //modifiedData.debt_status = valIBDebtTo36MC === 0 ? 0 : (valIBDebtTo36MC >= 30 ? "Fail" : "Pass"); // Fail if total interest-bearing debt/market cap * 100 equal or greater than the threshold (30% for AAIOFI)
      modifiedData.debt_status = valIBDebtTo36MC >= 30 ? "Fail" : "Pass";
      modifiedData.exchange = responseDecodeData.exchange_symbol;
      modifiedData.financial_statement_date = null;
      modifiedData.halal = complianceHalalPercentage; // Percentage of halal revenue (sum of halal revenue/total revenue * 100)
      modifiedData.halal_revenue = complianceHalalRevenue; // amount of halal revenue in the given units and the currency
      modifiedData.impermissible_interest_income_amount_in_once = 0;

      //Current Liabilities(Short Term) + Non Current Liabilities(Long Term)
      modifiedData.interest_bearing_debt_json = {
          totalRetio: (financialScreening.non_current_liabilities.total_interest_bearing_liabilities_value + financialScreening.current_liabilities.total_current_liabilities_value)/calculate36Val * 100,
          totalAmount: financialScreening.non_current_liabilities.total_interest_bearing_liabilities_value + financialScreening.current_liabilities.total_current_liabilities_value,
          longTermJson: {
              name: "Long-term",
              items: itemslongTermJsonArray, // ARRAY
              retio: (financialScreening.non_current_liabilities.total_interest_bearing_liabilities_value/calculate36Val) * 100,
              amount: financialScreening.non_current_liabilities.total_interest_bearing_liabilities_value,
              amountInOnes: financialScreening.non_current_liabilities.total_interest_bearing_liabilities_value * selectedValue,
              islamicAmount: 0,
              nonIslamicAmount: financialScreening.non_current_liabilities.total_interest_bearing_liabilities_value,
              islamicPercentage: 0,
              islamicAmountInOnes: 0,
              nonIslamicPercentage: 0,
              nonIslamicAmountInOnes: financialScreening.non_current_liabilities.total_interest_bearing_liabilities_value * selectedValue
          },
          shortTermJson: {
            name: "Short-term",
            items: itemshortTermJsonArray,
            retio: (financialScreening.current_liabilities.total_current_liabilities_value/calculate36Val) * 100,
            amount: financialScreening.current_liabilities.total_current_liabilities_value,
            amountInOnes: financialScreening.current_liabilities.total_current_liabilities_value * selectedValue,
            islamicAmount: 0,
            nonIslamicAmount: financialScreening.current_liabilities.total_current_liabilities_value,
            islamicPercentage: 0,
            islamicAmountInOnes: 0,
            nonIslamicPercentage: 0,
            nonIslamicAmountInOnes: financialScreening.current_liabilities.total_current_liabilities_value * selectedValue
        },
          totalNonIslamicAmount: financialScreening.non_current_liabilities.total_interest_bearing_liabilities_value + financialScreening.current_liabilities.total_current_liabilities_value,
      };

      //Other Income
      modifiedData.interest_income_json = {
        name: "OTHER INCOME",
        items: [
            {
                id: 1,
                name: "Interest Income",
                items: itemInterestIncomeJson,
                amount: interestIncomeTotal,
                comment: "",
                showCalc: true,
                percentage: (interestIncomeTotal / total_revenue_sum) * 100,
                amountInOnes: interestIncomeTotal * selectedValue,
                itemsNotHalal: otherNonHalalSum,
                itemsQuestionable: otherDoubtfulSum
            }
        ],
        amount: otherNonHalalSum, //sum of non-halal(with red indicator) items in other income
        percentage: interestIncomeTotal/total_revenue_sum * 100,
        halalAmount: otherHalalSum,
        amountInOnes: otherNonHalalSum * selectedValue,
        notHalalAmount: otherNonHalalSum,
        halalPercentage: (otherHalalSum/total_revenue_sum) * 100,
        halalAmountInOnes: otherHalalSum *  selectedValue,
        notHalalPercentage: (otherNonHalalSum / total_revenue_sum) * 100,
        questionableAmount: otherDoubtfulSum,
        notHalalAmountInOnes: otherNonHalalSum *  selectedValue,
        questionablePercentage:(otherDoubtfulSum/total_revenue_sum) * 100,
        questionableAmountInOnes: otherDoubtfulSum *  selectedValue
      };

      modifiedData.isin = responseDecodeData.isin;
      modifiedData.local_rank = responseDecodeData.star_rating;
      modifiedData.main_currency = selectedCurrency;
      modifiedData.main_ticker = responseDecodeData.company_symbol;
      modifiedData.not_halal = valNoncompliantRevenue;//...........................Sometimes state not change
      modifiedData.not_halal_revenue = complianceNotHalalRevenue; 
      modifiedData.publish_un_publish = "PUBLISH"; // Publish And unpublish kab add krna hai dont know
      modifiedData.questionable = valDoubtfulRevenue;
      modifiedData.questionable_revenue = complianceDoubtfulRevenue;
      modifiedData.ranking = responseDecodeData.star_rating;
      //modifiedData.report_date = selectedDate;
      modifiedData.report_date = moment(selectedDate).tz(timeZone).format('YYYY-MM-DD');
      //modifiedData.report_period = 364;
      modifiedData.report_period = selectedPeriod === 1 ? 91 : selectedPeriod === 2 ? 182 : selectedPeriod === 3 ? 273 : selectedPeriod === 4 ? 364 : 0;

      modifiedData.report_source = reportLRUrl;

      let quarterName = { "1": "FIRST_QUARTER", "2": "SECOND_QUARTER", "3": "THIRD_QUARTER", "4": "ANNUAL" }[selectedQuarter];


      modifiedData.report_type_section = quarterName;
      modifiedData.report_type_year = selectedYear;

      //Revenue from core operation
      modifiedData.revenue_breakdown_json= {
        name: "SALES BREAKDOWN",
        items: itemTotalRevenueFromCoreOperation,
        amount: CoreRevinueTotal,
        percentage: (CoreRevinueTotal/total_revenue_sum)*100,
        halalAmount: revenueHalalSum,
        amountInOnes: CoreRevinueTotal * selectedValue,
        notHalalAmount: revenueNonHalalSum,
        halalPercentage: (revenueHalalSum/total_revenue_sum)*100,
        halalAmountInOnes: revenueHalalSum * selectedValue,
        notHalalPercentage: (revenueNonHalalSum/total_revenue_sum)*100,
        questionableAmount: revenueDoubtfulSum,
        notHalalAmountInOnes: revenueNonHalalSum * selectedValue,
        questionablePercentage: (revenueDoubtfulSum/total_revenue_sum)*100,
        questionableAmountInOnes: revenueDoubtfulSum * selectedValue
      };

      let revenueBreakdownStatus = { "Compliant": "Pass", "Non-compliant": "Fail", "Doubtful": "Fail"}[valComplianceStatusAAOIFI];
      //modifiedData.revenue_breakdown_status = revenueBreakdownStatus;
      modifiedData.revenue_breakdown_status = valImpermissibleRevenue >= 5 ? "Fail" : "Pass";
      // console.log('STATUS : '+ valImpermissibleRevenue);
      modifiedData.screening_form_type = "NEW";
      modifiedData.securities_and_assets = valIBAssetsTo36MC; //total interest-bearing assets(current assets + non-current assets) / market cap * 100

      // OLD const totalRatio = (sumCurrentAsset + sumNon_CurrentAsset)/calculate36Val  * 100;
      const totalRatio =  (CA_NonHalalSum + NCA_NonHalalSum)/calculate36Val  * 100;
     
      // Non Current Asset (Long Term) + Current Asset (Short Term)
      modifiedData.securities_and_assets_json = {
        totalRetio: totalRatio,
        totalAmount: CA_NonHalalSum,
        longTermJson: {
          name: "Long-term",
          items: itemCurrentItem_LongTerm,
          retio: (NCA_NonHalalSum/calculate36Val) * 100,
          amount: NCA_NonHalalSum,
          amountInOnes: NCA_NonHalalSum * selectedValue,
          islamicAmount: 0,
          nonIslamicAmount: NCA_NonHalalSum,
          islamicPercentage: 0,
          islamicAmountInOnes: 0,
          nonIslamicPercentage: 0,
          nonIslamicAmountInOnes: NCA_NonHalalSum * selectedValue
      },
      shortTermJson: {
        name: "Short-term",
        items: itemCurrentItem_ShortTerm,
        retio: (CA_NonHalalSum/calculate36Val) * 100,
        amount: CA_NonHalalSum,
        amountInOnes: CA_NonHalalSum * selectedValue,
        islamicAmount: 0,
        nonIslamicAmount: CA_NonHalalSum,
        islamicPercentage: 0,
        islamicAmountInOnes: 0,
        nonIslamicPercentage: 0,
        nonIslamicAmountInOnes: CA_NonHalalSum * selectedValue
    },
        totalNonIslamicAmount: CA_NonHalalSum
  };
    console.log('TOTAL RATIO :' + totalRatio);
      //modifiedData.securities_and_assets_revenue = parseFloat(document.getElementById("current_assets__cash_items").textContent) + parseFloat(document.getElementById("current_assets__other_interest_bearing_assets").textContent);//Current Assets Cash Item +  Other interest bearing assets
      //modifiedData.securities_and_assets_revenue = (parseFloat(document.getElementById("current_assets__cash_items")?.textContent.replace(/,/g, '')) || 0) + (parseFloat(document.getElementById("current_assets__other_interest_bearing_assets")?.textContent.replace(/,/g, '')) || 0);
      modifiedData.securities_and_assets_revenue =  (CA_NonHalalSum || 0) + (NCA_NonHalalSum || 0);
      //modifiedData.securities_and_assets_status = totalRatio === 0 ? "" : (totalRatio >= 30 ? "Fail" : "Pass");
      modifiedData.securities_and_assets_status = totalRatio > 30 ? "Fail" : "Pass";
      modifiedData.share_outstanding = responseDecodeData.share_outstanding; // MUST
      modifiedData.source = reportLRUrl; // MUST
      let status = { "Compliant": "COMPLIANT", "Non-compliant": "NON_COMPLIANT", "Doubtful": "QUESTIONABLE"}[valComplianceStatusAAOIFI];

      modifiedData.status = status;
      modifiedData.stock_id = 0;
      modifiedData.stock_name = responseDecodeData.company_symbol; // subticker MUST
      modifiedData.total_assets = parseFloat(document.getElementById("total_assets")?.value.replace(/,/g, '')) || 0;
      modifiedData.total_revenue = complianceTotalRevenue;

      modifiedData.total_score = 0;// this value update from server side MUST
      modifiedData.trailing36mon_avr_cap = removeCommas(month36Val);
      modifiedData.units = selectedValue;
      modifiedData.usd_rate = responseDecodeData.usd_rate; // MUST
      modifiedData.user_id = juniorAnalystId;
      modifiedData.username = responseDecodeData.email;

       console.log(JSON.stringify(modifiedData)); 
      // Set the modified JSON data to state
      //setComplianceJsonData(modifiedData);

        resolve(modifiedData); // Resolve the Promise with the JSON object
      } catch (error) {
        reject(error); // If any error occurs during the process, reject the Promise with the error
      }
    });
  };

  // const getScreeningForm = (jsonStructure) => {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       const sampledata = { ...sampledata };
  //       const financialScreening = sampledata[0].sections[0].data;


  //       resolve(sampledata); // Resolve the Promise with the JSON object
  //     } catch (error) {
  //       reject(error); // If any error occurs during the process, reject the Promise with the error
  //     }
  //   });
  // };


  // Helper function to generate entries for cash_items
  function generateCashItemsEntries(cashItems, category) {
    //console.log(cashItems);
    const entries = {};

    if (!cashItems || cashItems.length === 0) {
      return entries;
    }

    cashItems.forEach((item, index) => {
      const keyName = `${category}.cash_items[${index}].name`;
      const keyValue = `${category}.cash_items[${index}].value`;

      entries[keyName] = {
        style: "child",
        value: [item.name],
      };

      entries[keyValue] = {
        style: "child",
        value: [item.value],
      };
    });

    return entries;
  }

  // Example usage:
  // getStandardJsonFormat().then(standardTable => {
  //   console.log(JSON.stringify(standardTable, null, 2));
  // }).catch(error => {
  //   console.error(error);
  // });

  //Complaince Json
  const [complianceJsonData, setComplianceJsonData] = useState(null);

  

  // Blank event handler for 'Send to review' button
  const handleSendToReview = async () => {
    try {
      
      if (!reportLRUrl && !reportARUrl) {
        setErrorMessage('Please provide at least one URL for an LR or AR report.');
        setMessageType('error');
        return false; 
      }
      if (!validateInputs()) {
        // If validation fails, stop further processing
        return;
      }
      if (!validateTextboxes()) {
        return false; // Prevent saving if validation fails
      }
      //Get Dynamic Json structure
      const jsonStructure = await getDynamicJSONFormat();
      // console.log(JSON.stringify(jsonStructure));
      const standardJsonFormat = await getStandardJsonFormat();
      const scoringRankingJsonFormat = await getScoringRankingJsonFormat();
      const reviewSubtickerJsonFormat = await getReviewSubtickerJsonFormat(jsonStructure);
      

      //const complianceReport = await getComplianceReport(jsonStructure);

      const timerElement = timerRef.current;
      const timer = timerElement.innerText;

      // Call APIs sequentially
      //await sendComplianceData(complianceReport);
      await submitReportData(jsonStructure, timer, 2, standardJsonFormat, scoringRankingJsonFormat,reviewSubtickerJsonFormat,true);

    } catch (error) {
      console.error("An error occurred while handling save as draft:", error);
    }
  };

  //Complaince Insert
  async function sendComplianceData(complianceJsonData) {
    try {
        const postData = {
            data: complianceJsonData
        };

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(postData)
        };

        console.log(JSON.stringify(postData));
        
        const response = await fetch(`${apiEndPoint}/add_compliance`, requestOptions);
        const data = await response.json();

      
        if (data && data.data && data.data.message === 'success') {
            console.log('Compliance data submitted successfully:', data);
            setSequentialProcessComplaince('Saved');
            setSequenceLoaderTypeComplaince(LoaderChecked);
            setIsCheckingComplaince(false);
            return true; // Indicate success
            // Handle success response here
            // e.g., update UI, show success message, etc.
        } else {

          setSequentialProcessComplaince('Failed');
          setSequenceLoaderTypeComplaince(LoaderCross);
          setIsCheckingComplaince(false);

          const cancelButton = document.getElementById(`CancelPublish`);
          if (cancelButton) {
            cancelButton.click();
          }
          setPublishButtonLoader(false);
          setErrorMessage('Compliance :'+ data.data.message);
          setMessageType('error');
          return false;// Indicate failure
        }

    } catch (error) {
          setSequentialProcessComplaince('Failed');
          setSequenceLoaderTypeComplaince(LoaderCross);
          setIsCheckingComplaince(false);

       const cancelButton = document.getElementById(`CancelPublish`);
        if (cancelButton) {
          cancelButton.click();
        }
      setPublishButtonLoader(false);
      console.error('add_compliance API Error : ' + error.message);
        setErrorMessage('Compliance :'+error.message);
        setMessageType('error');
        return false;// Indicate failure
    }
}

//compareTypesense
async function compareTypesense(complianceJsonData) {
  alert('ss');
  try {
      const postData = {
          data: complianceJsonData
      };

      const requestOptions = {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(postData)
      };

      //console.log(JSON.stringify(postData));
      
      const response = await fetch(`${apiEndPoint}/verify_report_data`, requestOptions);
      const data = await response.json();
 
      if (data && data.message === 'success') {
        setSequentialProcessCompare('Saved');
        setSequenceLoaderTypeCompare(LoaderChecked);
        setIsCheckingCompare(false);
        setPublishButtonLoader(false);
        return true; // Indicate success
      } else {

        setSequentialProcessCompare('Failed');
        setSequenceLoaderTypeCompare(LoaderCross);
        setIsCheckingCompare(false);
        setPublishButtonLoader(false);
        return false;// Indicate failure
      }

  } catch (error) {
        setSequentialProcessCompare('Failed');
        setSequenceLoaderTypeCompare(LoaderCross);
        setIsCheckingCompare(false);

     const cancelButton = document.getElementById(`CancelPublish`);
      if (cancelButton) {
        cancelButton.click();
      }
    setPublishButtonLoader(false);
    console.error('add_compliance API Error : ' + error.message);
      setErrorMessage('Compliance :'+error.message);
      setMessageType('error');
      return false;// Indicate failure
  }
}

async function getLastComments() {
    try {
      const response = await fetch(`${apiEndPoint}/get_last_comment_list`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            user_id: userid,
            task_id: taskId,
          },
        }),
      });

      if (response.ok) {
          const result = await response.json();
        if (result.data && result.data.comments) {
              console.log(result.data.comments);
              //setLastComments(result.data.comments);
              lastCommentsRef.current = result.data.comments;
              return true; 
        } else {
          setError('No comments found');
          return true; 
        }
      } else {
        setError('Error fetching comments: ' + response.statusText);
        return true; 
      }
    } catch (error) {
      setError('Error fetching comments: ' + error.message);
      return true; 
    }
}


const getCommentMessage = (commentKey) => {
  return lastCommentsRef.current[commentKey]?.messages || '';
};


const validateTextboxes = () => {

  const textboxes = document.querySelectorAll('.isvalidate');
  let isValid = true;
  textboxes.forEach((textbox) => {

    if (textbox.closest('#default_row_current_assets__cash_items') ||
        textbox.closest('#default_row_current_assets__other_interest_bearing_assets') ||
        textbox.closest('#default_row_non_current_assets__cash_items') ||
        textbox.closest('#default_row_non_current_assets__other_interest_bearing_assets') ||
        textbox.closest('#default_row_current_liabilities__ibl') ||
        textbox.closest('#default_row_non_current_liabilities__ibl') ||
        textbox.closest('#default_row_bs_other_income')) {
      return;
    }

     // Skip validation for textboxes inside <tr> elements with class 'bs_rfco' and style 'display: none;'
     const closestTR = textbox.closest('tr.bs_rfco');
     if (closestTR && window.getComputedStyle(closestTR).display === 'none') {
       return;
     }

    // Skip validation for textboxes inside <div> elements with class 'validateSubrow'
    if (textbox.closest('div.validateSubrow')) {
      return;
    }




    if (textbox.closest('#default_row_bs_other_income')) {
      // Skip validation for textboxes inside the default_row_bs_other_income container
      return;
    }


    const value = textbox.value || ''; // Ensure value is defined
    if (value.trim() === '') {
      textbox.style.border = '1px solid red';
      isValid = false;
    } else {
      textbox.style.border = '';
    }
  });
  return isValid;
};

const validateInputs = () => {

    if (!month36Val || /^[0]+$/.test(month36Val.replace(/,/g, ''))) {
    setErrorMessage('36-month market cap cannot be 0');
    setMessageType('error');
    return false;
  }

  if (!month24Val || /^[0]+$/.test(month24Val.replace(/,/g, ''))) {
    setErrorMessage('24-month market cap cannot be 0');
    setMessageType('error');
    return false;
  }

  if (!selectedPeriod) {
    setErrorMessage('Please select report period');
    setMessageType('error');
    return false;
  }
 
  if (!selectedDate || isNaN(new Date(selectedDate).getTime())) {
    setErrorMessage('Please select a valid report date');
    setMessageType('error');
    return false;
}
  if (!selectedYear) {
    setErrorMessage('Please select year');
    setMessageType('error');
    return false;
  }
  if (!selectedQuarter) {
    setErrorMessage('Please select quarter');
    setMessageType('error');
    return false;
  }
  if (!selectedValue) {
    setErrorMessage('Please select Unit');
    setMessageType('error');
    return false;
  }

  if (!selectedCurrency) {
    setErrorMessage('Please select a currency');
    setMessageType('error');
    return false;
  }

  const totalAssetsElement = document.getElementById('total_assets');
  const totalAssetsValue = parseFloat(totalAssetsElement.value);
  if (isNaN(totalAssetsValue) || totalAssetsValue <= 0) {
    setErrorMessage('Total Assets must not be null or empty!');
    setMessageType('error');
    return false;
  } else {
   // setError('');
    // Proceed with form submission or other logic
  }

  return true;
};

  // Blank event handler for 'Save as draft' button
  const handleSaveAsDraft = async () => {

    try {
      //setIsLoader(true);
      //return false;

      if (!validateInputs()) {
        // If validation fails, stop further processing
        return;
      }
      if (!validateTextboxes()) {
        return false; // Prevent saving if validation fails
      }

      //Get Dynamic Json structure
      const jsonStructure = await getDynamicJSONFormat();
      // const complianceReport = await getComplianceReport(jsonStructure);
      //console.log(JSON.stringify(jsonStructure));
      //return false;

      const standardJsonFormat = await getStandardJsonFormat();      
      const scoringRankingJsonFormat = await getScoringRankingJsonFormat();
      const reviewSubtickerJsonFormat = {};
      const timerElement = timerRef.current;
      const timer = timerElement.innerText;

      //const timer = '00:00:00';

      // Once the structure created then save Report as a Draft (stored data in database)
      await submitReportData( jsonStructure, timer, 1, standardJsonFormat, scoringRankingJsonFormat,reviewSubtickerJsonFormat,false); // false means it should not be redirect to task manager
    } catch (error) {
      console.error("An error occurred while handling save as draft:", error);
      // Handle errors here if needed
    }
  };

  const handleResetSequence = async () => {
    setSequenceLoaderTypeReport(LoaderChecking);
    setSequenceLoaderTypeReport(LoaderChecking);
    setSequenceLoaderTypeReport(LoaderChecking);
    setSequenceLoaderTypeReport(LoaderChecking);

  };
  


  const handlePublish = async () => {
    
    setIsSequenceVisible(true);
    // setSequenceLoaderTypeReport(LoaderChecking);
    // setSequenceLoaderTypeComplaince(LoaderChecking);
    // setSequenceLoaderTypePublished(LoaderChecking);
    // setSequenceLoaderTypeCompare(LoaderChecking);

    try {
      if (!reportLRUrl && !reportARUrl) {
        setErrorMessage('Please provide at least one URL for an LR or AR report.');
        setMessageType('error');
        setIsSequenceVisible(false);
        return false; 
      }
      if (!validateInputs()) {
        // If validation fails, stop further processing
        setIsSequenceVisible(false);
        return;
      }
    if (!validateTextboxes()) {
      setIsSequenceVisible(false);
      return false; // Prevent saving if validation fails
    }

    setPublishButtonLoader(true);
    
    //Call API For : Fetch Last comment list
    const commentSuccess = await getLastComments();
    // If the report submission failed, stop further processing
    if (!commentSuccess) {
     return;
    }

   
    const jsonStructure = await getDynamicJSONFormat();

    //console.log(JSON.stringify(jsonStructure));
    //return false;

    const standardJsonFormat = await getStandardJsonFormat();
    const scoringRankingJsonFormat = await getScoringRankingJsonFormat();
    const reviewSubtickerJsonFormat = await getReviewSubtickerJsonFormat(jsonStructure);    
    const complianceReport = await getComplianceReport(jsonStructure);


    const timerElement = timerRef.current;
    const timer = timerElement.innerText;

    //console.log('starttt + 1');
   //update_task API
    const reportSuccess = await submitReportData1(jsonStructure, timer, 2, standardJsonFormat, scoringRankingJsonFormat,reviewSubtickerJsonFormat);
     // If the report submission failed, stop further processing
     console.log('API 1 : '+ reportSuccess);
     if (!reportSuccess) {
      return;
    }
    
    // Add_compliance
    const complianceSuccess = await sendComplianceData(complianceReport);
    // If the compliance data submission failed, stop further processing
    console.log('API 2 : '+ complianceSuccess);
    if (!complianceSuccess) {
      return;
    }

    const publisedReport = await sendToPublish();
    // If the compliance data submission failed, stop further processing
    console.log('API 3 : '+ publisedReport);
    if (!publisedReport) {
      return;
    }


    // const comparedTypesense = await compareTypesense();
    // // If the compared Typesense data submission failed, stop further processing
    // console.log('API 4 : '+ compareTypesense);
    // if (!comparedTypesense) {
    //   return;
    // }

  } catch (error) {
    console.error("Error in handlePublish:", error.message);
  }
  };


  const handleUnPublish = async () => {

    setUnpublishButtonLoader(true);
    
    const url = `${apiEndPoint}/unpublish_compliance`;
    const data = {
      data: {
        main_ticker: responseDecodeData.company_symbol,
        user_id: userid
      }
    };


    try {
      const result = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      const responseData = await result.json();
      const data1 = responseData.data; // Flatten the response

      if (data1.status == "success") {
        
        const cancelButton = document.getElementById(`CancelUnPublish`);
        if (cancelButton) {
          cancelButton.click();
        }
        setErrorMessage('Report has been successfully Unpublished.');
        
        setMessageType('success');
        setTimeout(() => navigate("/TaskManager"), 4000);
        setUnpublishButtonLoader(false);
      }
      else{
        const cancelButton = document.getElementById(`CancelUnPublish`);
        if (cancelButton) {
          cancelButton.click();
        }
        setUnpublishButtonLoader(false);
        setErrorMessage(data1.message);
        setMessageType('error');
        setTimeout(() => navigate("/TaskManager"), 4000);
      }

    } catch (error) {
      const cancelButton = document.getElementById(`CancelUnPublish`);
        if (cancelButton) {
          cancelButton.click();
        }
        setUnpublishButtonLoader(false);
        console.error('Error:', error.message);
    }
  };

  const handleSave = async () => {
    try { 
      if (!validateInputs()) {
        // If validation fails, stop further processing
        return;
      }
      if (!validateTextboxes()) {
        return false; // Prevent saving if validation fails
      }

      const jsonStructure = await getDynamicJSONFormat();

      console.log(JSON.stringify(jsonStructure));
      
      const standardJsonFormat = await getStandardJsonFormat();      
      const scoringRankingJsonFormat = await getScoringRankingJsonFormat();
      const reviewSubtickerJsonFormat = await getReviewSubtickerJsonFormat(jsonStructure);
      const timerElement = timerRef.current;
      const timer = timerElement.innerText;

      await submitReportData( jsonStructure, timer, 2, standardJsonFormat, scoringRankingJsonFormat,reviewSubtickerJsonFormat,false);
    } catch (error) {
      console.error("An error occurred while handling save:", error);
      // Handle errors here if needed
    }
  };
  async function sendToPublish(){

    console.log(JSON.stringify({
      data: {
        user_id: juniorAnalystId,
        task_id: taskId,
        status: "3",
        main_ticker:responseDecodeData.company_symbol,
        published_by: localStorage.getItem("novauserid") || ""
      },
    }));


    fetch(`${apiEndPoint}/publish_report`, {

      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        data: {
          user_id: juniorAnalystId,
          task_id: taskId,
          status: "3",
          main_ticker:responseDecodeData.company_symbol,
          published_by: localStorage.getItem("novauserid") || ""
        },
      }),
    })
      .then((response) => {
        console.log('Response status:', response.status);
        if (!response.ok) {
          setPublishButtonLoader(false);

          setSequentialProcessPublish('Failed');
          setSequenceLoaderTypePublished(LoaderCross);
          setIsCheckingPublished(false); 

          console.log("sendToPublish : Network response was not ok");
          return false; 
        }
        return response.json(); // Assuming the response is JSON
      })
      .then((data) => {
        console.log('Response data:', data);
      //  alert(data.data.status);
        // Check if the expected keys and values are present in the response
        if (data.data.status == "success") {
          
          setSequentialProcessPublish('Published');
          setSequenceLoaderTypePublished(LoaderChecked);
          setIsCheckingPublished(false); 

          console.log('Publishing report successful');

          const cancelButton = document.getElementById(`CancelPublish`);
          if (cancelButton) {
            cancelButton.click();
          }
          setErrorMessage('Report has been successfully published.');
          setMessageType('success');
          setTimeout(() => navigate("/TaskManager"), 4000);

         // PublishReport();
          return true; // Indicate success
         
        } else {
          setPublishButtonLoader(false);

          setSequentialProcessPublish('Failed');
          setSequenceLoaderTypePublished(LoaderCross);
          setIsCheckingPublished(false); 

          setErrorMessage('sendToPublish :' + data.message);
          setMessageType('error');
          return false;
        }
      })
      .catch((error) => {
        setPublishButtonLoader(false);

          setSequentialProcessPublish('Failed');
          setSequenceLoaderTypePublished(LoaderCross);
          setIsCheckingPublished(false); 

        console.log('publish_report API Error : ' + error.message);
        setErrorMessage('sendToPublish :' + error.message);
        setMessageType('error');
        return false;
      });
  };


  const PublishReport = async () => {
    // Added async keyword
    //const url = "https://beta.infomanav.in/keep/finnhub_api/task_manager/nv_user_task.php";
    const url = `${apiEndPoint}/update_tasks`;
    

    // Assuming juniorAnalystId and taskId are defined somewhere in your code
    // const payload = {
    //   user_id: juniorAnalystId, // Replace this with the actual user_id if dynamic
    //   task_id: taskId,
    //   status: 3,
    //   published_by: localStorage.getItem("novauserid") || "", // Added fallback in case novauserid is not found
    // };

    const payload = {
      data: {
        user_id: juniorAnalystId, // Replace this with the actual user_id if dynamic
        task_id: taskId,
        status: 3,
        company_symbol: responseDecodeData.company_symbol, // Assuming itemId represents company_symbol
        date: responseDecodeData.date, // Replace this with the actual date if dynamic
        quarter: responseDecodeData.quarter, // Replace this with the actual quarter if dynamic
        year: responseDecodeData.year, // Replace this with the actual year if dynamic
        published_by: localStorage.getItem("novauserid") || ""
      }
    };
    //console.log(JSON.stringify(payload));

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        setPublishButtonLoader(false);
        throw new Error("PublishReport : Network response was not ok");
      }

      const responseData = await response.json();
      const data = responseData.data; // Flatten the response
      console.log(data);

      if (data.status === "success") {
        const cancelButton = document.getElementById(`CancelPublish`);
        if (cancelButton) {
          cancelButton.click();
        }
        setErrorMessage('Report has been successfully published.');
        setMessageType('success');
        
        setTimeout(() => navigate("/TaskManager"), 4000);
        setPublishButtonLoader(false);
        //; // Assuming navigate is defined somewhere
      } else {
        // console.error("API Error:", data.message);
        // return false; // Indicate failure
        setPublishButtonLoader(false);
        setErrorMessage(data.message);
        setMessageType('error');
        return false;
      }
    } catch (error) {
      setPublishButtonLoader(false);
      console.error('update_tasks status: 3 API Error : ' + error.message);
      setErrorMessage(error.message);
	    setMessageType('error');
	    return false;
    }
  };

  const handleReject = async () => {
    handleLinkClick();
  };
  

  
  const handleLinkClick = async () => {
    const url = `${apiEndPoint}/update_tasks`;
    const payload = {
      data: {
        user_id: juniorAnalystId, // ReplaresponseDecodeDatace this with the actual user_id if dynamic
        company_symbol: responseDecodeData.company_symbol, // Assuming itemId represents company_symbol
        date: responseDecodeData.date, // Replace this with the actual date if dynamic
        quarter: responseDecodeData.quarter, // Replace this with the actual quarter if dynamic
        year: responseDecodeData.year, // Replace this with the actual year if dynamic
        status: 1,
        date: responseDecodeData.date, // Replace this with the actual date if dynamic
        quarter: responseDecodeData.quarter, // Replace this with the actual quarter if dynamic
        cba_status: cbaStatus,
        cba_comment: cbaCommentRef.current.value
      }
    };
  
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
  
      const responseData = await response.json();
      const data = responseData.data; // Flatten the response
      if (data.status === "success") {
        navigate("/TaskManager");
        return true; // Indicate success
      } else {
        setErrorMessage(data.message);
        setMessageType('error');
        return false;
      }
    } catch (error) {
      console.error('update_tasks status: 1 API Error : ' + error.message);
      setErrorMessage(error.message);
      setMessageType('error');
      return false;
    }
  };

  async function submitReportData(jsonStructure, timer, status, standardJsonFormat, scoringRankingJsonFormat, reviewSubtickerJsonFormat,shouldRedirect) {

    
    const userid = localStorage.getItem("novauserid");
    const handleSelectedYear = (selectedYear) => typeof selectedYear === "number" ? selectedYear : new Date(selectedYear).getFullYear();

    const reportDate = moment(selectedDate).tz(timeZone).format();

  //   console.log(JSON.stringify({
  //     data: {
  //       user_id: userid,
  //       task_id: taskId,
  //       screening_json: {
  //           data: jsonStructure,
  //       },
  //       standard_json: {
  //           data: standardJsonFormat,
  //       },
  //       raw_standard_json: {
  //           data: scoringRankingJsonFormat,
  //       },
  //       review_subticker_json: {
  //           data: reviewSubtickerJsonFormat,
  //       },
  //       time: timer,
  //       period_id: selectedPeriod,
  //       report_date: selectedDate,
  //       fiscal_year: handleSelectedYear(selectedYear),
  //       quarter_id: selectedQuarter,
  //       units: selectedValue,
  //       currency: selectedCurrency,
  //       status: status, // Status 1 indicates the data should be in progress section
  //       LR_report:reportLRUrl,
  //       AR_report:reportARUrl,
  //       c24market_cap:custom24MC,
  //       c36market_cap:custom36MC,
  //       market_cap_updated_by:userid,
  //       cba_status: cbaStatus,
  //       cba_comment: cbaCommentRef.current.value
  //   }
  // }));

    try {
        const response = await fetch(`${apiEndPoint}/update_tasks`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                data: {
                    user_id: userid,
                    task_id: taskId,
                    screening_json: {
                        data: jsonStructure,
                    },
                    standard_json: {
                        data: standardJsonFormat,
                    },
                    raw_standard_json: {
                        data: scoringRankingJsonFormat,
                    },
                    review_subticker_json: {
                        data: reviewSubtickerJsonFormat,
                    },
                    time: timer,
                    period_id: selectedPeriod,
                    report_date: reportDate,
                    fiscal_year: handleSelectedYear(selectedYear),
                    quarter_id: selectedQuarter,
                    units: selectedValue,
                    currency: selectedCurrency,
                    status: status, // Status 1 indicates the data should be in progress section
                    LR_report:reportLRUrl,
                    AR_report:reportARUrl,
                    c24market_cap:custom24MC,
                    c36market_cap:custom36MC,
                    market_cap_updated_by:userid,
                    cba_status: cbaStatus,
                    cba_comment: cbaCommentRef.current.value,
                    mccurrency:mcCurrency
                }
            }),
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const data = await response.json();

        if (data.data.status === "success") {
            // Handle successful response data here
            //console.log(data.data.message);
            if (shouldRedirect) {
              navigate("/TaskManager");
            }
            else{
              setErrorMessage('Your report has been saved as a draft');
              setMessageType('success');
              return false;
            }
        } else {
            // Handle the case where the status is not success
            //console.error("Screening update failed:", data.data.message);
            setErrorMessage(data.data.message);
            setMessageType('error');
            return false;
        }
    } catch (error) {
        // Handle errors here
        console.error("update_tasks : Screening update failed:", error.message);
        setErrorMessage(error.message);
        setMessageType('error');
        return false;
    }
}

async function submitReportData1(jsonStructure, timer, status, standardJsonFormat, scoringRankingJsonFormat, reviewSubtickerJsonFormat) {
  const userid = localStorage.getItem("novauserid");
  const handleSelectedYear = (selectedYear) => typeof selectedYear === "number" ? selectedYear : new Date(selectedYear).getFullYear();
  const reportDate = moment(selectedDate).tz(timeZone).format();
//   console.log(JSON.stringify({
//     data: {
//         user_id: userid,
//         task_id: taskId,
//         screening_json: {
//             data: jsonStructure,
//         },
//         standard_json: {
//             data: standardJsonFormat,
//         },
//         raw_standard_json: {
//             data: scoringRankingJsonFormat,
//         },
//         review_subticker_json: {
//             data: reviewSubtickerJsonFormat,
//         },
//         time: timer,
//         period_id: selectedPeriod,
//         report_date: selectedDate,
//         fiscal_year: handleSelectedYear(selectedYear),
//         quarter_id: selectedQuarter,
//         units: selectedValue,
//         currency: selectedCurrency,
//         status: status, // Status 1 indicates the data should be in progress section
//         LR_report:reportLRUrl,
//         AR_report:reportARUrl,
//         c24market_cap:custom24MC,
//         c36market_cap:custom36MC,
//         market_cap_updated_by:userid
//     }
// }));



  try {
      const response = await fetch(`${apiEndPoint}/update_tasks`, {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify({
              data: {
                  user_id: userid,
                  task_id: taskId,
                  screening_json: {
                      data: jsonStructure,
                  },
                  standard_json: {
                      data: standardJsonFormat,
                  },
                  raw_standard_json: {
                      data: scoringRankingJsonFormat,
                  },
                  review_subticker_json: {
                      data: reviewSubtickerJsonFormat,
                  },
                  time: timer,
                  period_id: selectedPeriod,
                  report_date: reportDate,
                  fiscal_year: handleSelectedYear(selectedYear),
                  quarter_id: selectedQuarter,
                  units: selectedValue,
                  currency: selectedCurrency,
                  status: status, // Status 1 indicates the data should be in progress section
                  LR_report:reportLRUrl,
                  AR_report:reportARUrl,
                  c24market_cap:custom24MC,
                  c36market_cap:custom36MC,
                  market_cap_updated_by:userid,
                  cba_status: cbaStatus,
                  cba_comment: cbaCommentRef.current.value,
                  mccurrency:mcCurrency
              }
          }),
      });

      if (!response.ok) {
          setPublishButtonLoader(false);
          
          setSequentialProcessReport('Failed');
          setSequenceLoaderTypeReport(LoaderCross);
          setIsCheckingReport(false); 

          console.error("submitReportData1 : Network response was not ok");
          return false;
          
      }
      const data = await response.json();

      if (data.data.status === "success") {
        
        setSequentialProcessReport('Saved');
        setSequenceLoaderTypeReport(LoaderChecked);
        setIsCheckingReport(false); 

        return true; //Indicate success
          // Handle successful response data here
          //console.log(data.data.message);
         // navigate("/TaskManager");
      } else {
        setSequentialProcessReport('Failed');
        setSequenceLoaderTypeReport(LoaderCross);
        setIsCheckingReport(false); 
          // Handle the case where the status is not success
          //console.error("Screening update failed:", data.data.message);
          const cancelButton = document.getElementById(`CancelPublish`);
          if (cancelButton) {
            cancelButton.click();
          }

          setPublishButtonLoader(false);
          setErrorMessage('submitReportData1 :' + data.data.message);
          setMessageType('error');
          return false;// Indicate failure
      }
  } catch (error) {
    setSequentialProcessReport('Failed');
    setSequenceLoaderTypeReport(LoaderCross);
    setIsCheckingReport(false); 

      // Handle errors here
      const cancelButton = document.getElementById(`CancelPublish`);
          if (cancelButton) {
            cancelButton.click();
          }

      setPublishButtonLoader(false);
      console.error("update_tasks : Screening update failed:", error.message);
      setErrorMessage(error.message);
      setMessageType('error');
      return false;// Indicate failure
  }
}


  const handlePeriodChange = (selectedOption) => {
    setSelectedPeriod(selectedOption); // Update selectedPeriod state with the selected option
  };

  const handleQuaterChange = (selectedOption) => {
    setSelectedQuarter(selectedOption); // Update selectedPeriod state with the selected option
  };

  //Sub Ticker
  const [open1, setOpen1] = useState(false);
  const handleOpen = () => setOpen1(true);
  const handleClose = () => setOpen1(false);

  //Generate Standard Table data
  const [jsonData, setJsonData] = useState(null);


  //Review SubTicker
  const [data, setData] = useState({});
  //const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    if (taskId && juniorAnalystId  && (responseDecodeData.reportStatus == 2 || responseDecodeData.reportStatus == 3)) { // Ensure both values are set before making the fetch call
      console.log("Making second API call with taskId:", taskId, "and juniorAnalystId:", juniorAnalystId);
      const fetchData = async () => {
        try {
          const response = await fetch(
            `${apiEndPoint}/review_subticker`,
            {
              method: "POST",
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                data: {
                  user_id: juniorAnalystId,
                  task_id: taskId
                }
              })
            }
          );
          const result = await response.json();
          if (result.data.status === "success") {
            // Assuming result.data.data is the array you need to set in the state
           
            setData(result.data.data);
            //console.log("review_subticker:", JSON.stringify(result.data.data));
          } else {
            //setErrorMessage(result.data.message);
            //setMessageType('error');
            //return false;
          }

        } catch (error) {
          console.log("CATCH : Making second API call with taskId:", taskId, "and juniorAnalystId:", juniorAnalystId);
          console.error("review_subticker API Error :", error.message);
          setErrorMessage('review_subticker'+ error.message);
          setMessageType('error');
          return false;
        } finally {
          //setLoading(false);
        }
      };

      fetchData();
    } else {
      console.log("Waiting for taskId and juniorAnalystId to be set...");
    }
  }, [taskId, juniorAnalystId,responseDecodeData.reportStatus]); // Depend on taskId and juniorAnalystId



  // Render the table once data is fetched and validated


  // Helper function to get row class name based on row style
const getRowClassName = (style) => {
  switch (style) {
      case "Parent_header":
          return "review_subticker_row";
      case "Y_header":
          return "dought_color_row";
      case "G_header":
          return "total_assets_row";
      default:
          return "fixed-side";
  }
};

// Helper function to get row style based on row style
const getRowStyle = (style) => {
  let baseStyle = {
      color: "#2D2D2D",
      fontSize: 16,
  };

  switch (style) {
      case "Parent_header":
          return {
              ...baseStyle,
              fontWeight: "bold",
              textTransform: "uppercase",
          };
      case "Child_header":
          return {
              ...baseStyle,
              fontWeight: "bold",
              textTransform: "uppercase",
              paddingLeft: 35,
          };
      case "Child":
          return {
              ...baseStyle,
              paddingLeft: 45,
          };
      case "Y_header":
      case "G_header":
          return {
              ...baseStyle,
              fontWeight: "bold",
              textTransform: "uppercase",
          };
      default:
          return baseStyle;
  }
};


const [currency, setCurrency] = useState([]);
//const [currencyVal, setCurrencyVal] = useState("");

useEffect(() => {
  const fetchCurrencyList = async () => {
    const url = `${apiEndPoint}/get_forex_list`;
    const payload = {
      data: {
        user_id: userid // Replace with the actual user_id if dynamic
      }
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      const data = responseData.data; // Flatten the response

      const formatterForexData = data.data.map((forex) => ({
        label: forex.quote_currency,
        value: forex.quote_currency,
      }));

      setCurrency(formatterForexData);

    } catch (error) {
      console.error("get_forex_list API :", error.message);
      setErrorMessage(error.message);
      setMessageType('error');
      return false;
    }
  };

  fetchCurrencyList();
}, []);

const handleCurrencyChange = (value) => {
  setSelectedCurrency(value);
};
const handleMCCurrencyChange = (value) => {
  setMCCurrency(value);
};
const hideMessage = () => {
  setMessageType(null);
};

const renderMessage = () => {
  if (messageType) {
    setTimeout(hideMessage, 4000);
  };

  switch (messageType) {
    case 'success':
      return (
        <Message type="success" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:"fixed", right:10, top:10, zIndex: 999}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_success'>
              <div className='icon_error_msg_inner Icon_success_inner'>
                <img src={IconCheckError} alt="Success Icon" />
                <div className="progress-circle progress_circle_success"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_success_text'>
              <h5>Success</h5>
              <p>{errorMessage}</p>
            </div>
          </div>
        </Message>
      );
    case 'error':
      return (
        <Message type="error" closable style={{ display: 'flex', alignItems: 'center', width: 'auto',  position:"fixed", right:10, top:10, zIndex: 999}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_error'>
              <div className='icon_error_msg_inner Icon_error_inner'>
                <img src={IconError} alt="Error Icon" />
                <div className="progress-circle progress_circle_error"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_error_text'>
              <h5>Error</h5>
              <p>{errorMessage}</p>
            </div>
          </div>
        </Message>
      );
      case 'info':
      return (
        <Message type="info" closable style={{ display: 'flex', alignItems: 'center', width: 'auto',  position:"fixed", right:10, top:10, zIndex: 999}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_info'>
              <div className='icon_error_msg_inner Icon_info_inner'>
                <img src={IconInfo} alt="Info Icon" />
                <div className="progress-circle progress_circle_info"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_info_text'>
              <h5>Info</h5>
              <p>No reports found for the selected priority</p>
            </div>
          </div>
        </Message>
      );
      default:
        return null;
  }
};


const hideMessage1 = () => {
  setMessageType1(null);
};

const renderMessage1 = () => {
  if (messageType1) {
    setTimeout(hideMessage1, 4000);
  };

  switch (messageType1) {
    case 'success':
      return (
        <Message type="success" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:"fixed", right:10, top:10, zIndex: 999}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_success'>
              <div className='icon_error_msg_inner Icon_success_inner'>
                <img src={IconCheckError} alt="Success Icon" />
                <div className="progress-circle progress_circle_success"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_success_text'>
              <h5>Success</h5>
              <p>{errorMessage1}</p>
            </div>
          </div>
        </Message>
      );
    case 'error':
      return (
        <Message type="error" closable style={{ display: 'flex', alignItems: 'center', width: 'auto',  position:"fixed", right:10, top:10, zIndex: 999}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_error'>
              <div className='icon_error_msg_inner Icon_error_inner'>
                <img src={IconError} alt="Error Icon" />
                <div className="progress-circle progress_circle_error"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_error_text'>
              <h5>Error</h5>
              <p>{errorMessage1}</p>
            </div>
          </div>
        </Message>
      );
      case 'info':
      return (
        <Message type="info" closable style={{ display: 'flex', alignItems: 'center', width: 'auto',  position:"fixed", right:10, top:10, zIndex: 999}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_info'>
              <div className='icon_error_msg_inner Icon_info_inner'>
                <img src={IconInfo} alt="Info Icon" />
                <div className="progress-circle progress_circle_info"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_info_text'>
              <h5>Info</h5>
              <p>No reports found for the selected priority</p>
            </div>
          </div>
        </Message>
      );
      default:
        return null;
  }
};



const handle10kReport = () => {
  // Your logic here
  console.log('10k report clicked');
  setReportLRUrl(tempReportLR);
  handleTabChange("1");
};

const handle10QReport = () => {
  // Your logic here
  console.log('10Q report clicked');
  setReportLRUrl(tempReportAR);
  handleTabChange("1");
};



const popupRef = useRef(null);
useEffect(() => {
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  document.addEventListener("mousedown", handleClickOutside);

  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, []);


//COMMENT MODAL
const [commentBoxOpen, setCommentBoxOpen] = React.useState(false);

const handleCommentBoxOpen = async (commentId) => {

  setCommentLoading(true);

  console.log('Get The userId :'+ userid);
  console.log('Get The Task Id :'+ taskId);
  console.log('Get The Commentid :'+ commentId);

  const inputElement = document.querySelector(`input[data-commentval="${commentId}"]`);
  
  // Check if the element exists and retrieve the defaultValue, otherwise return an empty string
  if (inputElement) {
    //console.log(inputElement.value || '');
    setCommentFetchTextValue(inputElement.value || '');
  }
  else{
    setCommentFetchTextValue('');
  }

  
 


  setCommentBoxOpen(true);

  setCommentFieldId(commentId); // this value is use in add comment section.

  const requestData = {
    data: {
      user_id: userid,  // 54
      task_id: taskId,  // 97
      field_id: commentId // cas_and_assets
    }
  };


  //console.log(JSON.stringify(requestData));

  try {
    const response = await fetch(`${apiEndPoint}/get_comments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    });

    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText);
      setCommentLoading(false);
    }

    const data = await response.json();
    
    if(data.data.status == 'success')
    {
      setCommentLoading(false);
      console.log('Count:', data.data.count);
      console.log('comments:', data.data.comments);
      setCommentCount(data.data.count);
      setCommentData(data.data.comments);
    }
    else{
      setCommentLoading(false);
      console.log('Response data:', data);
    }
  } catch (error) {
    setCommentLoading(false);
    console.error('There was a problem with the fetch operation:', error);
  }

  
};


const addComment = async () => {

  const message = document.getElementById('txtComment').value;
  const source = document.getElementById('txtSource').value;
  setAddCommentLoader(true);
  //setCommentLoading(true);
  const url = `${apiEndPoint}/add_comment`;
  const request = {
    data: {
      user_id: userid,
      task_id: taskId,
      field_id: commentFieldId,
      source: source,
      messages: message
    }
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(request),
    });

    const data = await response.json();
    if(data.data.status == 'success')
      {
        setAddCommentLoader(false);
        document.getElementById('txtComment').value = '';
        document.getElementById('txtSource').value = '';
       // setCommentLoading(false);
        console.log('Count:', data.data.count);
        console.log('comments:', data.data.comments);
        setCommentCount(data.data.count);
        setCommentData(data.data.comments);

        if (data.data.count > 0) {
          // Find the element with matching data-comment and update the class
          // document.querySelectorAll('i.icon-comment').forEach((icon) => {
          //   if (icon.getAttribute('data-comment') === commentFieldId) {
          //     icon.parentElement.classList.add('icon_comment_active');
          //   }
          // });

            // Find the element with the specific commentFieldId and update the class
            const targetIcon = document.querySelector(`i.icon-comment[data-comment='${commentFieldId}']`);
            if (targetIcon) {
              targetIcon.parentElement.classList.add('icon_comment_active');
            }
        }

      }
      else{
        setAddCommentLoader(false);
        document.getElementById('txtComment').value = '';
        document.getElementById('txtSource').value = '';
        //setCommentLoading(false);
        setErrorMessage1(data.data.message);
        setMessageType1('error');
        return false;
        console.log('Response data:', data);
      }
    //setResponse(result);
  } catch (error) {
    setAddCommentLoader(false);
    document.getElementById('txtComment').value = '';
    document.getElementById('txtSource').value = '';
    //setCommentLoading(false);
    console.error('Error:', error);
  }
};



const deleteComment = async (id) => {
  

  setCommentBoxOpen(true);

  const requestData = {
    data: {
      user_id: userid,  // 54
      id: id, // 97
      task_id:taskId,
      field_id: commentFieldId
    }
  };


  console.log(JSON.stringify(requestData));

  try {
    const response = await fetch(`${apiEndPoint}/delete_comment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    });

    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText);
    }

    const data = await response.json();
    console.log(JSON.stringify(data));
    if(data.data.status == 'success')
    {
      console.log('Count:', data.data.count);
      console.log('comments:', data.data.comments);
      setCommentCount(data.data.count);
      setCommentData(data.data.comments);

      if (data.data.count == 0) {
         // If count is 0, remove the class
         const targetIcon = document.querySelector(`i.icon-comment[data-comment='${commentFieldId}']`);
         if (targetIcon) {
           targetIcon.parentElement.classList.remove('icon_comment_active');
         }
      }
    }
    else{
      setErrorMessage1(data.data.message);
      setMessageType1('error');
      console.log('Response data:', data);
    }
  } catch (error) {
    
    console.error('There was a problem with the fetch operation:', error.message);
  }

  
}; 

const deleteWholeRowComment = async (inputCommentId) => {
  

  const requestData = {
    data: {
      user_id: userid,  // 54
      id: 0, // 97
      task_id:taskId,
      field_id: inputCommentId
    }
  };


  console.log(JSON.stringify(requestData));

  try {
    const response = await fetch(`${apiEndPoint}/delete_comment`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    });

    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText);
    }

    const data = await response.json();
    console.log(JSON.stringify(data));
    if(data.data.status == 'success')
    {
      // console.log('Count:', data.data.count);
      // console.log('comments:', data.data.comments);
      // setCommentCount(data.data.count);
      // setCommentData(data.data.comments);

      // if (data.data.count == 0) {
      //    // If count is 0, remove the class
      //    const targetIcon = document.querySelector(`i.icon-comment[data-comment='${commentFieldId}']`);
      //    if (targetIcon) {
      //      targetIcon.parentElement.classList.remove('icon_comment_active');
      //    }
      // }
    }
    else{
      setErrorMessage(data.data.message);
      setMessageType('error');
      console.log('Response data:', data);
    }
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error.message);
  }

  
}; 

const handleCommentBoxClose = () => setCommentBoxOpen(false);

// FILE UPLOAD
const [fileName, setFileName] = useState("");
const fileInputRef = useRef(null);

const handleFileChange = (event) => {
  setFileName(event.target.files[0].name);
};

const handleClick = () => {
  fileInputRef.current.click();
};
// END FILE UPLOAD

const [addCommentLoader, setAddCommentLoader] = useState(false);



  const updateTaskDate = async () => {
    try {
      const response = await fetch( `${apiEndPoint}/update_task_date`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            user_id: userid,
            task_id: taskId,
          },
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
     // setResponse(data);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  useEffect(() => {
    if (userid && taskId) {
      console.log('Call updateTaskDate API : ' +userid + ' : '+  taskId);
      // Call the function immediately
      updateTaskDate();

      // Set up the interval to call the function every 30 seconds (30000 milliseconds)
      const intervalId = setInterval(updateTaskDate, 30000);

      // Clean up the interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [userid, taskId]);


  const handleBack = () => {
    navigate("/TaskManager");
};

// COLLAPSIBLE
const [isCollapsed, setIsCollapsed] = useState(false);

const toggleCollapse = () => {
  setIsCollapsed(!isCollapsed);
};



const handleCbaToggleChange = (checked) => {
  if (checked) {
    setCBAStatus(true);
  } else {
    setCBAStatus(false);

  }
};



const [openReportPopover, setOpenReportPopover] = useState(false);
const [openPopoverId, setOpenPopoverId] = useState(null);

const handleDeleteButtonClick = (inputCommentId) => {
  const inputElement = document.querySelector(`input[data-commentval="${inputCommentId}"]`);
  
  // Check if the element exists and retrieve the defaultValue, otherwise return an empty string
  if (inputElement) {
    //console.log(inputElement.value || '');
    setDeleteFetchTextValue(inputElement.value || '');
  }
  else{
    setDeleteFetchTextValue('');
  }
  setOpenPopoverId((prevId) => (prevId === inputCommentId ? null : inputCommentId));
};

const handleCancelClick = () => {
  setOpenPopoverId(null);
};

const handleDeleteClick = () => {
  setOpenReportPopover(false); 
};



// const [dots, setDots] = useState('');
  
// useEffect(() => {
//   // Set an interval to update the dots every 500ms (half a second)
//   const interval = setInterval(() => {
//     setDots((prevDots) => {
//       // Update the dots: if there are 3 dots, reset to empty; otherwise, add one more dot
//       return prevDots.length < 3 ? prevDots + '.' : '';
//     });
//   }, 500);
//   // Clear the interval when the component unmounts to prevent memory leaks
//   return () => clearInterval(interval);
// }, []);


 let subcommentIndex = 0;



  return (
    <>
      <LoaderFull isLoader={isLoader}/>

      {renderMessage()}
      <div className="Screening_sidebar">
        <Sidebar />
      </div>
      <div id="header_screening">
        <div className="logo">
          <div className="logo_icon">
            <img src={Logo} />
          </div>
        </div>

        <div className="header_right_section">
          <div className="header_section_top">
            <div className="brand_name">
              {responseDecodeData.name}{" "}
              <span>{responseDecodeData.company_symbol}</span>
            </div>
            <div className="header_monthly_value">
           
            <div className="currency_col_filter" style={{padding:0, height:30}}>
        <SelectPicker
                      data={currency}
                      //searchable={true}
                      style={{width:90}}
                      menuClassName="fxCurrency"
                      placeholder=""
                      onChange={handleMCCurrencyChange}
                      value={mcCurrency}
                      cleanable={false}
                    />

        </div>

            <div className="month_price_row three_year_price">
                <div className="month_col">
                  36<span>-mo</span>
                </div>
                <Whisper placement="auto" trigger="click" open={activePopover === '36'} // Control popover visibility
                  onClick={() => handlePopoverToggle('36')} // Toggle popover
        speaker={
          <Popover className="value_tooltip_form">
            <div className="tooltip_form_inner">
              <div>
              <label>36 Month Market Cap</label>
              <div className="input-group popover_36_market_cap">
                <span className="input-group-text">{mcCurrency}</span>
                <input type="text" name="Custom_month36" className="form-control form_control_common clsCustom_MC" placeholder="Please enter your value"  onKeyUp={handleKeyUp}/> 
              
                {/* <button className='btn__save_value'>
                  Save
                </button> */}
              </div>
              </div>
              <div className="mt-3">
              <label>24 Month Market Cap</label>
              <div className="input-group popover_24_market_cap">
                <span className="input-group-text">{mcCurrency}</span>
                <input type="text" name="Custom_month24" className="form-control form_control_common clsCustom_MC" placeholder="Please enter your value" onKeyUp={handleKeyUp} />
                {/* <button className='btn__save_value'>
                  Save
                </button> */}
              </div>
              </div>
              <div className="btn__group mt-3">
                <button className="btn__save" onClick={handleCustomMarketCap}>Save</button>
              </div>
            </div>
          </Popover>
        }
      >
        
      <div>
                 <Whisper placement="bottomStart" controlId="control-id-hover" trigger="hover" speaker={tooltip_36MarketCap}> 

                <div className="price_white_col" style={{cursor:"pointer"}}>
                  {month36Val} <span>{mcCurrency}</span>
                  <span className="divider">|</span>
                  <span className="value_sortname">
                    {convert36Val}
                  </span>
                </div>

          </Whisper>
          </div>
                </Whisper>

              </div>
              
              <div className="month_price_row three_year_price">
                <div className="month_col">
                  24<span>-mo</span>
                </div>
                <Whisper
        placement="auto"
        trigger="click"
        open={activePopover === '24'} // Control popover visibility
        onClick={() => handlePopoverToggle('24')} // Toggle popover

        speaker={
          <Popover className="value_tooltip_form">
            <div className="tooltip_form_inner">
              <div>
              <label>36 Month Market Cap</label>
              <div className="input-group popover_36_market_cap">
                <span className="input-group-text">{mcCurrency}</span>
                <input type="text" name="Custom_month36" className="form-control form_control_common clsCustom_MC" placeholder="Please enter your value" onKeyUp={handleKeyUp} />
                {/* <button className='btn__save_value'>
                  Save
                </button> */}
              </div>
              </div>
              <div className="mt-3">
              <label>24 Month Market Cap</label>
              <div className="input-group popover_24_market_cap">
                <span className="input-group-text">{mcCurrency}</span>
                <input type="text" name="Custom_month24" className="form-control form_control_common clsCustom_MC" placeholder="Please enter your value" onKeyUp={handleKeyUp} />
                {/* <button className='btn__save_value'>
                  Save
                </button> */}
              </div>
              </div>
              <div className="btn__group mt-3">
                <button className="btn__save" onClick={handleCustomMarketCap}>Save</button>
              </div>
            </div>
          </Popover>
        }
      >
                <div>
                <Whisper placement="bottomStart" controlId="control-id-hover" trigger="hover" speaker={tooltip_24MarketCap} >
                <div className="price_white_col" style={{cursor:"pointer"}}>
                  {month24Val} <span>{mcCurrency} </span>
                  <span className="divider">|</span>
                  <span className="value_sortname">
                    {convert24Val}
                  </span>
                </div>
                </Whisper>
                </div>
                </Whisper>
              </div> 

            </div>
          </div>

          <div className="header_section_bottom">
            <div className="header_control_links">
              <TimerProvider>
                <Timer
                  ref={timerRef}
                  initialHours={hours1}
                  initialMinutes={minutes1}
                  initialSeconds={seconds1}
                />
              </TimerProvider>

              <div className="lisk_dropdown_row">
                <a href="#" className="link_trigger" onClick={toggleMenu}>
                  <i className="icon-link"></i>
                </a>

                <div ref={popupRef} className={`paste_link_box ${isActive ? "active" : ""}`}>
                  <div className="paste_link_header">Paste Links</div>
                  <div className="paste_link_body">
                    <div className="link_field_row">
                      <label>
                        <i className="icon-link"></i>Link
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="https://"
                        onChange={handleReportLRInputChange}
                        value={reportLRUrl}
                      />
                    </div>

                    <div className="link_field_row">
                      <label>
                        <i className="icon-link"></i>AR
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="https://"
                        onChange={handleReportARInputChange}
                        value={reportARUrl}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="toggle_switch">
                {/* <input type="checkbox" id="switch" /><label for="switch">Toggle</label> */}

                <label className="switch">
                  <input type="checkbox" />
                  <div></div>
                </label>
              </div>
              <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={tooltip} >
                <button
                  className="btn_slide_trigger"
                  onClick={toggleBoxes}
                  id="toggleBtn"
                  ref={swapSwitchThRef}
                >
                  <img src={IconSlide} />
                </button>
              </Whisper>

              <Tabs
                activeKey={activeKey}
                onSelect={handleTabChange}
                appearance="subtle"
                className="clsTab"
              >
                <Tabs.Tab eventKey="1" title="LR"></Tabs.Tab>
                <Tabs.Tab eventKey="2" title="AR"></Tabs.Tab>
              </Tabs>
            </div>

            <div className="header_filter_col">
              <div className="filter_col">
                <div className="currency_dropdown_col_filter calendar_col">
                  <SelectPicker
                    data={optionsPeriod}
                    searchable={false}
                    styles={colourStyles}
                    placeholder="Select Period"
                    style={{ width: 140 }}
                    onChange={handlePeriodChange} // Attach onChange event handler
                    value={selectedPeriod} // Set the value prop to control the selected value
                  />
                </div>

                <div className="date_col_filter">
                  <span>Date</span>
                  <div className="date_col_text calendar_col">
                    <DatePicker
                      oneTap
                      style={{ width: 140 }}
                      placeholder="Select Date"
                      caretAs={CustomCaretIcon}
                      value={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                    />
                  </div>
                </div>

                <div className="date_col_filter">
                  <span>FY</span>
                  <div className="date_col_text calendar_col" style={{}}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: 10,
                      }}
                    >
                      <YearPicker
                         selected={selectedYear ? new Date(selectedYear, 0, 1) : null} // Use null if no year is selected
                         onChange={(date) => setSelectedYear(date ? date.getFullYear() : null)} // Update state only if date is selected
                        customInput={<CustomInput />}
                        dateFormat="yyyy"
                        showYearPicker
                        scrollableYearDropdown
                        yearDropdownItemNumber={15}
                        minDate={new Date(currentYear - 100, 0, 1)}
                        maxDate={new Date(currentYear + 1, 11, 31)}
                        yearItemNumber={9}
                        disabled={true}
                      />
                    </div>

                    <SelectPicker
                      disabled 
                      data={data2}
                      searchable={false}
                      style={{ width: 90 }}
                      placeholder=""
                      onChange={handleQuaterChange} // Attach onChange event handler
                      value={selectedQuarter} // Set the value prop to control the selected value
                    />
                  </div>
                </div>

                <div className="currency_dropdown_col_filter">

                  
                  {/* <SelectPicker
                            data={options}
                            searchable={false}
                            style={{width:100 }}
                            placeholder=""
                            onChange={handleSelectChange}
                            value={options.find(
                              (option) => option.value === selectedValue
                            )}
                            /> */}

                  {/* <Select
                    options={options}
                    styles={colourStyles}
                    onChange={handleSelectChange}
                    //value={selectedValue}
                    value={options.find((option) => option.value === selectedValue)}
                  /> */}

                  <SelectPicker
                      data={options}
                      searchable={false}
                       style={{width:110}}
                      // menuClassName="fxCurrency"
                      placeholder="Unit"
                      onChange={handleSelectChange}
                      value={selectedValue}
                    />

{/* 
                  <SelectPicker
                      data={options}
                      searchable={false}
                      style={{width:100}}
                      placeholder="Unit"
                      onChange={handleSelectChange}
                      value={options.find((option) => option.value === selectedValue)}
                    /> */}



                  {/* <SelectPicker
                      data={options}
                      searchable={false}
                      style={{width:120 }}
                      //placeholder="Select"

                      value={options.find(
                        (option) => option.value === selectedValue
                      )}
                      /> */}
                </div>

                {/* <div className="currency_col_filter">
                  <CurrencySelect
                    value={selectedCurrency}
                    onChange={handleChange}
                    //onCurrencySelected={selectedCurrency}
                    name=""
                  />
                </div> */}

        <div className="currency_col_filter" style={{padding:0}}>
        <SelectPicker
                      data={currency}
                      //searchable={true}
                      style={{width:110}}
                      menuClassName="fxCurrency"
                      placeholder="Currency"
                      onChange={handleCurrencyChange}
                      value={selectedCurrency}
                    />

        </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid content pb-5">
        <div className="report_table">
          <div className="table-responsive">
            <table className="table table_main_report">
              <thead>
                <tr  ref={standardsThRef}
                    className={`accordion ${open ? "active" : ""}`}
                    onClick={handleToggle}>
                  <th className="standard_th"> Standards </th>
                  <th>
                    <span
                      className={
                        valComplianceStatusAAOIFI === "Non-compliant"
                          ? "table_icon_cross"
                          : valComplianceStatusAAOIFI === "Doubtful"
                          ? "table_icon_exlamation"
                          : valComplianceStatusAAOIFI === "Compliant"
                          ? "table_icon_check"
                          : ""
                      }
                    >
                      <i
                        className={
                          valComplianceStatusAAOIFI === "Non-compliant"
                            ? "icon-cross"
                            : valComplianceStatusAAOIFI === "Doubtful"
                            ? "icon-exclamation"
                            : valComplianceStatusAAOIFI === "Compliant"
                            ? "icon-check"
                            : ""
                        }
                      ></i>
                      AAOIFI
                    </span>
                  </th>
                  <th>
                    <span
                      className={
                        valComplianceStatusDJIM === "Non-compliant"
                          ? "table_icon_cross"
                          : valComplianceStatusDJIM === "Doubtful"
                          ? "table_icon_exlamation"
                          : valComplianceStatusDJIM === "Compliant"
                          ? "table_icon_check"
                          : ""
                      }
                    >
                      <i
                        className={
                          valComplianceStatusDJIM === "Non-compliant"
                            ? "icon-cross"
                            : valComplianceStatusDJIM === "Doubtful"
                            ? "icon-exclamation"
                            : valComplianceStatusDJIM === "Compliant"
                            ? "icon-check"
                            : ""
                        }
                      ></i>
                      DJIM
                    </span>
                  </th>
                  <th>
                    <span
                      className={
                        valComplianceStatusSP === "Non-compliant"
                          ? "table_icon_cross"
                          : valComplianceStatusSP === "Doubtful"
                          ? "table_icon_exlamation"
                          : valComplianceStatusSP === "Compliant"
                          ? "table_icon_check"
                          : ""
                      }
                    >
                      <i
                        className={
                          valComplianceStatusSP === "Non-compliant"
                            ? "icon-cross"
                            : valComplianceStatusSP === "Doubtful"
                            ? "icon-exclamation"
                            : valComplianceStatusSP === "Compliant"
                            ? "icon-check"
                            : ""
                        }
                      ></i>
                      S&P
                    </span>
                  </th>
                  <th>
                    <span
                      className={
                        valComplianceStatusFTSE === "Non-compliant"
                          ? "table_icon_cross"
                          : valComplianceStatusFTSE === "Doubtful"
                          ? "table_icon_exlamation"
                          : valComplianceStatusFTSE === "Compliant"
                          ? "table_icon_check"
                          : ""
                      }
                    >
                      <i
                        className={
                          valComplianceStatusFTSE === "Non-compliant"
                            ? "icon-cross"
                            : valComplianceStatusFTSE === "Doubtful"
                            ? "icon-exclamation"
                            : valComplianceStatusFTSE === "Compliant"
                            ? "icon-check"
                            : ""
                        }
                      ></i>
                      FTSE
                    </span>
                  </th>
                  <th>
                    <span
                      className={
                        valComplianceStatusMSCI === "Non-compliant"
                          ? "table_icon_cross"
                          : valComplianceStatusMSCI === "Doubtful"
                          ? "table_icon_exlamation"
                          : valComplianceStatusMSCI === "Compliant"
                          ? "table_icon_check"
                          : ""
                      }
                    >
                      <i
                        className={
                          valComplianceStatusMSCI === "Non-compliant"
                            ? "icon-cross"
                            : valComplianceStatusMSCI === "Doubtful"
                            ? "icon-exclamation"
                            : valComplianceStatusMSCI === "Compliant"
                            ? "icon-check"
                            : ""
                        }
                      ></i>
                      MSCI
                    </span>
                  </th>
                  <th>
                    <span
                      className={
                        valComplianceStatusMSCIMseries === "Non-compliant"
                          ? "table_icon_cross"
                          : valComplianceStatusMSCIMseries === "Doubtful"
                          ? "table_icon_exlamation"
                          : valComplianceStatusMSCIMseries === "Compliant"
                          ? "table_icon_check"
                          : ""
                      }
                    >
                      <i
                        className={
                          valComplianceStatusMSCIMseries === "Non-compliant"
                            ? "icon-cross"
                            : valComplianceStatusMSCIMseries === "Doubtful"
                            ? "icon-exclamation"
                            : valComplianceStatusMSCIMseries === "Compliant"
                            ? "icon-check"
                            : ""
                        }
                      ></i>
                      MSCI M-series
                    </span>
                  </th>
                  <th>
                    <span
                      className={
                        valComplianceStatusSACSC === "Non-compliant"
                          ? "table_icon_cross"
                          : valComplianceStatusSACSC === "Doubtful"
                          ? "table_icon_exlamation"
                          : valComplianceStatusSACSC === "Compliant"
                          ? "table_icon_check"
                          : ""
                      }
                    >
                      <i
                        className={
                          valComplianceStatusSACSC === "Non-compliant"
                            ? "icon-cross"
                            : valComplianceStatusSACSC === "Doubtful"
                            ? "icon-exclamation"
                            : valComplianceStatusSACSC === "Compliant"
                            ? "icon-check"
                            : ""
                        }
                      ></i>
                      SAC-SC
                    </span>
                  </th>
                  <th>
                    <span
                      className={
                        valComplianceStatusISSI === "Non-compliant"
                          ? "table_icon_cross"
                          : valComplianceStatusISSI === "Doubtful"
                          ? "table_icon_exlamation"
                          : valComplianceStatusISSI === "Compliant"
                          ? "table_icon_check"
                          : ""
                      }
                    >
                      <i
                        className={
                          valComplianceStatusISSI === "Non-compliant"
                            ? "icon-cross"
                            : valComplianceStatusISSI === "Doubtful"
                            ? "icon-exclamation"
                            : valComplianceStatusISSI === "Compliant"
                            ? "icon-check"
                            : ""
                        }
                      ></i>
                      ISSI
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody
                className={`panel ${open ? "open" : ""}`}
                id="StandardsAcco"
              >
                <tr className="threshold_row">
                  <td className="left_align_td">Threshold</td>
                  <td>30%</td>
                  <td>33%</td>
                  <td>33%</td>
                  <td>33.333%</td>
                  <td>33.33%</td>
                  <td>33.33%</td>
                  <td>33%</td>
                  <td>45%</td>
                </tr>
                <tr>
                  <td className="left_align_td">IB assets to 36MC</td>
                  <td
                    className={
                      valIBAssetsTo36MC === 0
                        ? ""
                        : valIBAssetsTo36MC >= 30
                        ? "tble_red_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valIBAssetsTo36MC}%{" "}
                  </td>
                  <td>-</td>
                  <td
                    className={
                      valIBAssetsTo36MC === 0
                        ? ""
                        : valIBAssetsTo36MC >= 33
                        ? "tble_red_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valIBAssetsTo36MC}%{" "}
                  </td>
                  <td>-</td>
                  <td>-</td>
                  <td
                    className={
                      valIBAssetsTo36MC === 0
                        ? ""
                        : valIBAssetsTo36MC >= 33.33
                        ? "tble_red_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valIBAssetsTo36MC}%{" "}
                  </td>
                  <td>-</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td className="left_align_td">IB debt to 36MC</td>
                  <td
                    className={
                      valIBDebtTo36MC === 0
                        ? ""
                        : valIBDebtTo36MC >= 30
                        ? "tble_red_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valIBDebtTo36MC}%{" "}
                  </td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td
                    className={
                      valIBDebtTo36MC === 0
                        ? ""
                        : valIBDebtTo36MC >= 33.33
                        ? "tble_red_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valIBDebtTo36MC}%{" "}
                  </td>
                  <td>-</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td className="left_align_td">IB assets to 24MC</td>
                  <td>-</td>
                  <td
                    className={
                      valIBAssetsTo24MC === 0
                        ? ""
                        : valIBAssetsTo24MC >= 33
                        ? "tble_red_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valIBAssetsTo24MC}%{" "}
                  </td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td className="left_align_td">IB assets to TA</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td
                    className={
                      valIBAssetsToTA === 0
                        ? ""
                        : valIBAssetsToTA >= 33.333
                        ? "tble_red_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valIBAssetsToTA}%{" "}
                  </td>
                  <td
                    className={
                      valIBAssetsToTA === 0
                        ? ""
                        : valIBAssetsToTA >= 33.33
                        ? "tble_red_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valIBAssetsToTA}%{" "}
                  </td>
                  <td>-</td>
                  <td
                    className={
                      valIBAssetsToTA === 0
                        ? ""
                        : valIBAssetsToTA >= 33
                        ? "tble_red_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valIBAssetsToTA}%{" "}
                  </td>
                  <td>-</td>
                </tr>
                <tr>
                  <td className="left_align_td">AR & Cash to TA</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td
                    className={
                      valARAndCashToTA === 0
                        ? ""
                        : valARAndCashToTA >= 33.333
                        ? "tble_red_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valARAndCashToTA}%{" "}
                  </td>
                  <td
                    className={
                      valARAndCashToTA === 0
                        ? ""
                        : valARAndCashToTA >= 33.33
                        ? "tble_red_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valARAndCashToTA}%{" "}
                  </td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td className="left_align_td">AR & Cash to 36MC</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td
                    className={
                      valARAndCashTo36MC === 0
                        ? ""
                        : valARAndCashTo36MC >= 33.33
                        ? "tble_red_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valARAndCashTo36MC}%{" "}
                  </td>
                  <td>-</td>
                  <td>-</td>
                </tr>
                <tr>
                  <td className="left_align_td">IB debt to TA</td>
                  <td>-</td>
                  <td>-</td>
                  <td>-</td>
                  <td
                    className={
                      valIBDebtToTA === 0
                        ? ""
                        : valIBDebtToTA >= 33.333
                        ? "tble_red_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valIBDebtToTA}%{" "}
                  </td>
                  <td
                    className={
                      valIBDebtToTA === 0
                        ? ""
                        : valIBDebtToTA >= 33.33
                        ? "tble_red_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valIBDebtToTA}%{" "}
                  </td>
                  <td>-</td>
                  <td
                    className={
                      valIBDebtToTA === 0
                        ? ""
                        : valIBDebtToTA >= 33
                        ? "tble_red_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valIBDebtToTA}%{" "}
                  </td>
                  <td
                    className={
                      valIBDebtToTA === 0
                        ? ""
                        : valIBDebtToTA >= 45
                        ? "tble_red_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valIBDebtToTA}%{" "}
                  </td>
                </tr>
                <tr className="threshold_row">
                  <td className="left_align_td">Threshold</td>
                  <td>5%</td>
                  <td>5%</td>
                  <td>5%</td>
                  <td>5%</td>
                  <td>5%</td>
                  <td>5%</td>
                  <td>
                    <div className="toggle__value">
                      <span
                        className={isChecked ? "light_color" : "dark_color"}
                      >
                        {" "}
                        5%{" "}
                      </span>
                      <label class="switchSmall">
                        {" "}
                        <input
                          type="checkbox"
                          onChange={handleChangePercentage}
                          checked={isChecked}
                        />{" "}
                        <small></small>{" "}
                      </label>
                      <span
                        className={isChecked ? "dark_color" : "light_color"}
                      >
                        {" "}
                        20%{" "}
                      </span>
                    </div>
                  </td>
                  <td>10%</td>
                </tr>

                <tr>
                  <td className="left_align_td">Non-compliant revenue</td>
                  <td
                    className={
                      valNoncompliantRevenue === 0
                        ? ""
                        : valNoncompliantRevenue > 5
                        ? "tble_red_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valNoncompliantRevenue}%{" "}
                  </td>
                  <td
                    className={
                      valNoncompliantRevenue === 0
                        ? ""
                        : valNoncompliantRevenue > 5
                        ? "tble_red_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valNoncompliantRevenue}%{" "}
                  </td>
                  <td
                    className={
                      valNoncompliantRevenue === 0
                        ? ""
                        : valNoncompliantRevenue > 5
                        ? "tble_red_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valNoncompliantRevenue}%{" "}
                  </td>
                  <td
                    className={
                      valNoncompliantRevenue === 0
                        ? ""
                        : valNoncompliantRevenue > 5
                        ? "tble_red_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valNoncompliantRevenue}%{" "}
                  </td>
                  <td
                    className={
                      valNoncompliantRevenue === 0
                        ? ""
                        : valNoncompliantRevenue > 5
                        ? "tble_red_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valNoncompliantRevenue}%{" "}
                  </td>
                  <td
                    className={
                      valNoncompliantRevenue === 0
                        ? ""
                        : valNoncompliantRevenue > 5
                        ? "tble_red_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valNoncompliantRevenue}%{" "}
                  </td>
                  <td
                    className={
                      valNoncompliantRevenue === 0
                        ? ""
                        : valNoncompliantRevenue > sacscPercentage
                        ? "tble_red_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valNoncompliantRevenue}%{" "}
                  </td>
                  <td
                    className={
                      valNoncompliantRevenue === 0
                        ? ""
                        : valNoncompliantRevenue > 10
                        ? "tble_red_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valNoncompliantRevenue}%{" "}
                  </td>
                </tr>
                <tr>
                  <td className="left_align_td">Doubtful revenue</td>
                  <td
                    className={
                      valDoubtfulRevenue === 0
                        ? ""
                        : valDoubtfulRevenue > 5
                        ? "tble_lyellow_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valDoubtfulRevenue}%{" "}
                  </td>
                  <td
                    className={
                      valDoubtfulRevenue === 0
                        ? ""
                        : valDoubtfulRevenue > 5
                        ? "tble_lyellow_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valDoubtfulRevenue}%{" "}
                  </td>
                  <td
                    className={
                      valDoubtfulRevenue === 0
                        ? ""
                        : valDoubtfulRevenue > 5
                        ? "tble_lyellow_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valDoubtfulRevenue}%{" "}
                  </td>
                  <td
                    className={
                      valDoubtfulRevenue === 0
                        ? ""
                        : valDoubtfulRevenue > 5
                        ? "tble_lyellow_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valDoubtfulRevenue}%{" "}
                  </td>
                  <td
                    className={
                      valDoubtfulRevenue === 0
                        ? ""
                        : valDoubtfulRevenue > 5
                        ? "tble_lyellow_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valDoubtfulRevenue}%{" "}
                  </td>
                  <td
                    className={
                      valDoubtfulRevenue === 0
                        ? ""
                        : valDoubtfulRevenue > 5
                        ? "tble_lyellow_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valDoubtfulRevenue}%{" "}
                  </td>
                  <td
                    className={
                      valDoubtfulRevenue === 0
                        ? ""
                        : valDoubtfulRevenue > sacscPercentage
                        ? "tble_lyellow_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valDoubtfulRevenue}%{" "}
                  </td>
                  <td
                    className={
                      valDoubtfulRevenue === 0
                        ? ""
                        : valDoubtfulRevenue > 10
                        ? "tble_lyellow_color"
                        : "tble_green_color"
                    }
                  >
                    {" "}
                    {valDoubtfulRevenue}%{" "}
                  </td>
                </tr>

                <tr>
                  <td className="left_align_td">Impermissible revenue</td>
                  <td
                    className={
                      valImpermissibleRevenue === 0
                        ? ""
                        : valNoncompliantRevenue >= 5
                        ? "tble_red_color"
                        : valNoncompliantRevenue < 5 &&
                          valImpermissibleRevenue < 5
                        ? "tble_green_color"
                        : valNoncompliantRevenue < 5 &&
                          valImpermissibleRevenue >= 5
                        ? "tble_lyellow_color"
                        : ""
                    }
                  >
                    {" "}
                    {valImpermissibleRevenue}%{" "}
                  </td>
                  <td
                    className={
                      valImpermissibleRevenue === 0
                        ? ""
                        : valNoncompliantRevenue >= 5
                        ? "tble_red_color"
                        : valNoncompliantRevenue < 5 &&
                          valImpermissibleRevenue < 5
                        ? "tble_green_color"
                        : valNoncompliantRevenue < 5 &&
                          valImpermissibleRevenue >= 5
                        ? "tble_lyellow_color"
                        : ""
                    }
                  >
                    {" "}
                    {valImpermissibleRevenue}%{" "}
                  </td>
                  <td
                    className={
                      valImpermissibleRevenue === 0
                        ? ""
                        : valNoncompliantRevenue >= 5
                        ? "tble_red_color"
                        : valNoncompliantRevenue < 5 &&
                          valImpermissibleRevenue < 5
                        ? "tble_green_color"
                        : valNoncompliantRevenue < 5 &&
                          valImpermissibleRevenue >= 5
                        ? "tble_lyellow_color"
                        : ""
                    }
                  >
                    {" "}
                    {valImpermissibleRevenue}%{" "}
                  </td>
                  <td
                    className={
                      valImpermissibleRevenue === 0
                        ? ""
                        : valNoncompliantRevenue >= 5
                        ? "tble_red_color"
                        : valNoncompliantRevenue < 5 &&
                          valImpermissibleRevenue < 5
                        ? "tble_green_color"
                        : valNoncompliantRevenue < 5 &&
                          valImpermissibleRevenue >= 5
                        ? "tble_lyellow_color"
                        : ""
                    }
                  >
                    {" "}
                    {valImpermissibleRevenue}%{" "}
                  </td>
                  <td
                    className={
                      valImpermissibleRevenue === 0
                        ? ""
                        : valNoncompliantRevenue >= 5
                        ? "tble_red_color"
                        : valNoncompliantRevenue < 5 &&
                          valImpermissibleRevenue < 5
                        ? "tble_green_color"
                        : valNoncompliantRevenue < 5 &&
                          valImpermissibleRevenue >= 5
                        ? "tble_lyellow_color"
                        : ""
                    }
                  >
                    {" "}
                    {valImpermissibleRevenue}%{" "}
                  </td>
                  <td
                    className={
                      valImpermissibleRevenue === 0
                        ? ""
                        : valNoncompliantRevenue >= 5
                        ? "tble_red_color"
                        : valNoncompliantRevenue < 5 &&
                          valImpermissibleRevenue < 5
                        ? "tble_green_color"
                        : valNoncompliantRevenue < 5 &&
                          valImpermissibleRevenue >= 5
                        ? "tble_lyellow_color"
                        : ""
                    }
                  >
                    {" "}
                    {valImpermissibleRevenue}%{" "}
                  </td>
                  <td
                    className={
                      valImpermissibleRevenue === 0
                        ? ""
                        : valNoncompliantRevenue >= sacscPercentage
                        ? "tble_red_color"
                        : valNoncompliantRevenue < sacscPercentage &&
                          valImpermissibleRevenue < sacscPercentage
                        ? "tble_green_color"
                        : valNoncompliantRevenue < sacscPercentage &&
                          valImpermissibleRevenue >= sacscPercentage
                        ? "tble_lyellow_color"
                        : ""
                    }
                  >
                    {" "}
                    {valImpermissibleRevenue}%{" "}
                  </td>
                  <td
                    className={
                      valImpermissibleRevenue === 0
                        ? ""
                        : valNoncompliantRevenue >= 10
                        ? "tble_red_color"
                        : valNoncompliantRevenue < 10 &&
                          valImpermissibleRevenue < 10
                        ? "tble_green_color"
                        : valNoncompliantRevenue < 10 &&
                          valImpermissibleRevenue >= 10
                        ? "tble_lyellow_color"
                        : ""
                    }
                  >
                    {" "}
                    {valImpermissibleRevenue}%{" "}
                  </td>
                </tr>

                <tr>
                  {/* tble_red_color
                        tble_yellow_color
                        tble_green_color */}

                  <td className="left_align_td">Compliance</td>
                  <td
                    className={
                      valComplianceStatusAAOIFI === "Non-compliant"
                        ? "tble_red_color"
                        : valComplianceStatusAAOIFI === "Doubtful"
                        ? "tble_yellow_color"
                        : valComplianceStatusAAOIFI === "Compliant"
                        ? "tble_green_color"
                        : ""
                    }
                  >
                    {" "}
                    {valComplianceStatusAAOIFI}{" "}
                  </td>
                  <td
                    className={
                      valComplianceStatusDJIM === "Non-compliant"
                        ? "tble_red_color"
                        : valComplianceStatusDJIM === "Doubtful"
                        ? "tble_yellow_color"
                        : valComplianceStatusDJIM === "Compliant"
                        ? "tble_green_color"
                        : ""
                    }
                  >
                    {" "}
                    {valComplianceStatusDJIM}{" "}
                  </td>
                  <td
                    className={
                      valComplianceStatusSP === "Non-compliant"
                        ? "tble_red_color"
                        : valComplianceStatusSP === "Doubtful"
                        ? "tble_yellow_color"
                        : valComplianceStatusSP === "Compliant"
                        ? "tble_green_color"
                        : ""
                    }
                  >
                    {" "}
                    {valComplianceStatusSP}{" "}
                  </td>
                  <td
                    className={
                      valComplianceStatusFTSE === "Non-compliant"
                        ? "tble_red_color"
                        : valComplianceStatusFTSE === "Doubtful"
                        ? "tble_yellow_color"
                        : valComplianceStatusFTSE === "Compliant"
                        ? "tble_green_color"
                        : ""
                    }
                  >
                    {" "}
                    {valComplianceStatusFTSE}{" "}
                  </td>
                  <td
                    className={
                      valComplianceStatusMSCI === "Non-compliant"
                        ? "tble_red_color"
                        : valComplianceStatusMSCI === "Doubtful"
                        ? "tble_yellow_color"
                        : valComplianceStatusMSCI === "Compliant"
                        ? "tble_green_color"
                        : ""
                    }
                  >
                    {" "}
                    {valComplianceStatusMSCI}{" "}
                  </td>
                  <td
                    className={
                      valComplianceStatusMSCIMseries === "Non-compliant"
                        ? "tble_red_color"
                        : valComplianceStatusMSCIMseries === "Doubtful"
                        ? "tble_yellow_color"
                        : valComplianceStatusMSCIMseries === "Compliant"
                        ? "tble_green_color"
                        : ""
                    }
                  >
                    {" "}
                    {valComplianceStatusMSCIMseries}{" "}
                  </td>
                  <td
                    className={
                      valComplianceStatusSACSC === "Non-compliant"
                        ? "tble_red_color"
                        : valComplianceStatusSACSC === "Doubtful"
                        ? "tble_yellow_color"
                        : valComplianceStatusSACSC === "Compliant"
                        ? "tble_green_color"
                        : ""
                    }
                  >
                    {" "}
                    {valComplianceStatusSACSC}{" "}
                  </td>
                  <td
                    className={
                      valComplianceStatusISSI === "Non-compliant"
                        ? "tble_red_color"
                        : valComplianceStatusISSI === "Doubtful"
                        ? "tble_yellow_color"
                        : valComplianceStatusISSI === "Compliant"
                        ? "tble_green_color"
                        : ""
                    }
                  >
                    {" "}
                    {valComplianceStatusISSI}{" "}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="report_table_data_row">
          <div id="Reportbox1" className={isBox1Visible ? "screening_report_pdf" : "slide-in"} >
          <div className="tab-content">
          {activeKey === "" ? (
              <div className="zero_data_content">
              <div className="zero_data_inner">
                <div className="report_load_desc">Choose the Type to load the latest Report <br/>
                of {responseDecodeData.company_symbol} from Edgar</div>
              <div style={{display:"flex", alignItems:"center", justifyContent:"center", gap:20, marginTop:40}}>
                   <button className="btn_report_load" onClick={handle10kReport}>10-K</button>
                   <button className="btn_report_load" onClick={handle10QReport}>10-Q</button>
              </div>
              
              </div>
            </div>
            ) : activeKey === "1" ? (
              loadingLR ? (
                <div>Loading LR Report...</div>
              ) : reportLRUrl ? (
                <iframe
                  title="LR Report PDF"
                  width="100%"
                  height="550"
                  src={reportLRUrl}
                  className="iframe_pdf"
                ></iframe>
              ) : (
                <div className="zero_data_content">
                  <div className="zero_data_inner">
                    <img src={IconNoData} alt="No Data" />
                    <p>No LR Report Available</p>
                  </div>
                </div>
              )
            ) : activeKey === "2" ? (
              loadingAR ? (
                <div>Loading AR Report...</div>
              ) : reportARUrl ? (
                <iframe
                  title="AR Report PDF"
                  width="100%"
                  height="550"
                  src={reportARUrl}
                  className="iframe_pdf"
                ></iframe>
              ) : (
                <div className="zero_data_content">
                  <div className="zero_data_inner">
                    <img src={IconNoData} alt="No Data" />
                    <p>No AR Report Available</p>
                  </div>
                </div>
              )
            ) : null}
      </div>

            

            {/* <Tabs defaultActiveKey="1" appearance="subtle">
              <Tabs.Tab eventKey="1" title="LR">
                <iframe
                  id="reportViewer"
                  title="Report PDF"
                  width="100%"
                  src={reportLRUrl}
                  //src={reportLRUrl}
                  className="iframe_pdf"
                  //onLoad={handlePdfLoad}

                ></iframe>
              </Tabs.Tab>
              <Tabs.Tab eventKey="2" title="AR">
                <iframe
                  id="reportViewer1"
                  title="Report PDF"
                  width="100%"
                  //src={ReportPdf}
                  src={reportARUrl}
                  className="iframe_pdf"
                ></iframe>
              </Tabs.Tab>
            </Tabs> */}
          </div>

          <div
            id="Reportbox2"
            className={!isBox1Visible ? "slide-out" : "financial_table_col"}
          >
            <div className="financial_table">
              {/* ref={scrollbarRef} */}
              <div className="table-responsive" id="ReportScreening">
              
              {sampledata.length > 0 && ( sampledata.map((categoryItem, catInd) => (
                  <table className="table accordion table_accordion" id={"bs_table" + catInd} key={"bs_table" + catInd} >
                    <thead className="clsTHead">
                      {" "}
                      <tr> {" "} <th colSpan={3}>{categoryItem.category_title}</th>{" "} </tr>{" "}
                    </thead>
                    {categoryItem.sections.map((sectionItem, sectionInd) => (
                      <>
                        {/* Start Sub Header Like : Current Assets, Non Current Assets, Current Liabilities, Non-Current Liabilities  */}
                        <thead className="clsSubTHead">
                          <tr>
                            {sectionItem.section_with_value && sectionItem.section_with_value === true ? (
                              <>
                                <td>
                                  {" "}
                                  <div style={{ display: "flex", alignItems: "center", }} >
                                    {" "}
                                    <div>{sectionItem.section_title}</div>{" "}
                                  </div>{" "}
                                </td>
                                <td id={sectionItem.section_id} style={{ textAlign: "right" }} >
                                  {" "}
                                  {sectionItem.value}{" "}
                                </td>
                                <td>
                                  {" "}
                                  <span id={sectionItem.section_id} style={{ display: "none" }} ></span>{" "}
                                </td>
                              </>
                            ) : (
                              <td colSpan={3}>
                                {" "}
                                {sectionItem.section_title}{" "}
                                <span id={sectionItem.section_id} style={{ display: "none" }} ></span>{" "}
                              </td>
                            )}
                          </tr>
                        </thead>
                        {/* END Sub Header Like : Current Assets, Non Current Assets, Current Liabilities, Non-Current Liabilities  */}

                        <tbody>
                          {sectionItem.data.map((sectionItemData, dataInd) => (
                            <>
                              {/* Start Like Sub Header Plus button :Cash Items,Other interest bearing assets,Interest bearing liabilities, Interest bearing liabilities, Revenue from core operation, other income   */}
                              <tr key={sectionItemData.header_id + "_" + dataInd} className={ sectionItemData.class ? sectionItemData.class : "" } >
                                <td>
                                  <div style={{ display: "flex", alignItems: "center", }} >
                                   
                                      {sectionItemData.additionalSubcategory ? (
                                         <button className="add_more_btn" data-length={ sectionItemData.defaultRows && sectionItemData.defaultRows.length } onClick={(e) => addSubSection( sectionItemData.header_id ) }>
                                          <i className="icon-plus" ></i>
                                        </button>
                                      ) : (
                                        <button className="add_more_btn" data-length={ sectionItemData.rows && sectionItemData.rows.length } onClick={(e) => addMoreRow( "default_row_" + sectionItemData.header_id ) }>
                                        <i className="icon-plus"></i>
                                        </button>
                                      )}
                                   
                                    <div>{sectionItemData.header_title}</div>
                                  </div>
                                </td>
                                <td className="text_right" id={sectionItemData.header_id} >
                                  {" "}
                                  {sectionItemData.value}{" "}
                                </td>
                                <td>&nbsp;</td>
                              </tr>
                              {/* END Like Sub Header Plus button :Cash Items,Other interest bearing assets,Interest bearing liabilities, Interest bearing liabilities, Revenue from core operation, other income   */}

                              {/* Start All Textboxs Row */}
                              {!sectionItemData.additionalSubcategory &&
                                sectionItemData.rows && sectionItemData.rows.map(
                                  (sectionRow, rowInd) => (
                                    // We are retrieving values based on the class name of the tr element to create a JSON object. className={sectionItemData.header_id}
                                    <tr className={sectionItemData.header_id}>
                                      {sectionRow.map((colData, colInd) => (
                                        <>
                                          {colData.form_input && colData.form_input === "textbox" ? (
                                            <td className={ colData.class ? colData.class : null } >
                                              {/* default row */}
                                              {(colData.showEditDelete && colData.showEditDelete === true) ||
                                              (colData.showAdd && colData.showAdd === true) ? (
                                                <div style={{ display: "flex", alignItems: "center", gap: 6, }} >
                                                  <ul className="remove_Comment list_HoverControl">
                                                    {colData.showEditDelete && colData.showEditDelete === true ? (
                                                      <>
                                                        <li> {" "} <a style={{ cursor: "pointer"}} className={colData.comment_active_class ? colData.comment_active_class : null} > {" "} <i className="icon-comment" data-comment={`${colData.inputCommentId}`} onClick={() => handleCommentBoxOpen(`${colData.inputCommentId}`)}></i>{" "} </a>{" "} </li>
                                                        <li style={{ display: 'none' }} data-id={colData.inputCommentId}> {" "} <a style={{ cursor: "pointer", }} > {" "} <i className="icon-delete" onClick={(e) => removeRowItem(e,colData.inputCommentId) } ></i>{" "} </a>{" "} </li>
                                                        <Whisper
                                                            placement="left"
                                                            trigger="click"
                                                            open={openPopoverId === colData.inputCommentId}
                                                            onClose={() => setOpenReportPopover(false)}
                                                            speaker={
                                                              <Popover className="value_tooltip_form">
                                                                <div className="tooltip_form_inner text-center">
                                                                  <div style={{ fontSize: 20, color: "#141B3C", fontWeight: 600, lineHeight: 1.3 }}>
                                                                    Are you sure you want to <br />
                                                                     delete {deleteFetchTextValue || 'this record'} record?
                                                                  </div>
                                                                  <div className='btn__group mt-5'>
                                                                    <a href="javascript:void(0);" className='btn__cancel' onClick={() => handleCancelClick(colData.inputCommentId)}>Cancel</a>
                                                                    
                                                                    <a
            style={{ cursor: "pointer" }}
            className="btn__assign"
            onClick={() => {
              const liElement = document.querySelector(`li[data-id="${colData.inputCommentId}"]`);
              if (liElement) {
                // Trigger the click event manually on the outer li
                liElement.querySelector('i.icon-delete').click();
                setOpenPopoverId(null);
              }
            }}
          >
            Delete
          </a>
                                                                  </div>
                                                                </div>
                                                              </Popover>
                                                            }
                                                          >
        <Button  onClick={() => handleDeleteButtonClick(colData.inputCommentId)} style={{ padding:0, backgroundColor:"transparent", border:'0px solid #BCCDF2'}}>
        <li> {" "} <a style={{ cursor: "pointer", }} >{" "} <i className="icon-delete"  ></i>{" "} </a>{" "} </li> 
      </Button>
      
      </Whisper>

                                                        

                                                      </>
                                                    ) : null}
                                                    {colData.showAdd && colData.showAdd === true ? ( <li> {" "} <a> {" "} <i className="icon-plus"></i>{" "} </a>{" "} </li> ) : null}
                                                  </ul>
                                                  <div className="tablel_field" >
                                                    <input type="text" id={colData.id} data-id="" defaultValue={ colData.value } className={ colData.inputClass ? colData.inputClass : null } data-commentval={`${colData.inputCommentId}`} />
                                                  </div>
                                                </div>
                                              ) : (
                                                <input type="text" id={colData.id}
                                                  //onChange={(e) => sumCalculation(e.target.value)}
                                                  onChange={sumCalculation} defaultValue={colData.value} className={ colData.inputClass ? colData.inputClass : null } data-commentval={`${colData.inputCommentId}`} />
                                              )}
                                            </td>
                                          ) : null}
                                          
                                          {colData.form_input && colData.form_input === "select" ? (
                                            <td className="">
                                              <div className={ colData.class ? colData.class : null } >
                                                {/* colData.isDefault */}
                                                <input type="hidden" id={colData.id + "_hidden"} className={ colData.inputClass + "_hidden" } defaultValue={ colData.defaultValue } custom_value={colData.isDefault+ 1} />
                                                <Select
                                                  placeholder=""
                                                  className={ colData.inputClass ? colData.inputClass : null }
                                                   classNamePrefix="clsDropdown"
                                                  onChange={(e) => handleChangeFaceSelection( e, colData, colData.id ) }
                                                  // onChange={handleChangeFaceSelection}
                                                  options={colData.value}
                                                  styles={colourStyles}
                                                  menuPortalTarget={document.body}
                                                  //menuIsOpen={true}
                                                  isSearchable={false}
                                                  menuPlacement={auto}
                                                  defaultValue={colData.value[colData.isDefault]}
                                                  //defaultValue={ colData.isDefault !== 0 ? colData.value[1] : undefined }
                                                  components={{ DropdownIndicator: CustomDropdownIndicator, }}
                                                  getOptionLabel={(e) => (
                                                    <div
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                      }}
                                                    >
                                                      {e.label ===
                                                      "Happyicon" ? (
                                                        <Happyicon />
                                                      ) : e.label ===
                                                        "Sadicon" ? (
                                                        <Sadicon />
                                                      ) : (
                                                        <Doubticon />
                                                      )}
                                                    </div>
                                                  )}
                                                />
                                              </div>
                                            </td>
                                          ) : null}
                                        </>
                                      ))}
                                      {/* <td>&nbsp;</td> */}
                                    </tr>
                                  )
                              )}

                              {/* END All Textboxs Row */}

                              {/*handling of Edit for revenue core operations*/}


                              {sectionItemData.additionalSubcategory && sectionItemData.rows &&
                                sectionItemData.rows.map((sectionRow, rowInd) => {
                                subcommentIndex = 0;
                                return(
                                      <>
                                      
                                        {/*Revenue From Core Operation ROW*/}
                                        <tr className={sectionItemData.header_id} id={ sectionItemData.header_id + "_assets_" + (parseInt(rowInd)+20) }
                                          style={{ display:"table-row", }} >

                                          {sectionRow.parent && sectionRow.parent.map((colData, colInd) => (
                                            <>
                                              {colData.form_input && colData.form_input === "textbox" ? (
                                                <td className={ colData.class ? colData.class : null } >
                                                  {(colData.showEditDelete && colData.showEditDelete === true) ||
                                                  (colData.showAdd && colData.showAdd === true) ? (
                                                    <div style={{ display: "flex", alignItems: "center", gap: 6, }} >
                                                      <ul className="remove_Comment list_HoverControl">
                                                        {colData.showEditDelete &&
                                                        colData.showEditDelete ===
                                                          true ? (
                                                          <>
                                                            {/* <li> {" "} <a style={{ cursor: "pointer", }}  className={colData.comment_active_class ? colData.comment_active_class : null} > {" "} <i className="icon-comment" data-comment={'comment_'+sectionItemData.header_id + "_assets_" + (parseInt(rowInd))} onClick={() => handleCommentBoxOpen('comment_'+sectionItemData.header_id + "_assets_" + (parseInt(rowInd)))} data-testComment={colData.inputCommentId}></i>{" "} </a>{" "} </li> */}
                                                            <li> {" "} <a style={{ cursor: "pointer", }}  className={colData.comment_active_class ? colData.comment_active_class : null} > {" "} <i className="icon-comment" data-comment={colData.inputCommentId} onClick={() => handleCommentBoxOpen(colData.inputCommentId)} data-testComment={colData.inputCommentId}></i>{" "} </a>{" "} </li>
                                                            <li style={{ display: 'none' }} data-id={colData.inputCommentId}> {" "} <a style={{ cursor: "pointer", }} > {" "} <i className="icon-delete" onClick={( e ) => removeSubSection(sectionItemData.header_id + "_assets_" + (parseInt(rowInd)+20), + colData.inputCommentId) } ></i>{" "} </a>{" "} </li>

                                                            <Whisper placement="left" trigger="click" open={openPopoverId === colData.inputCommentId} onClose={() => setOpenReportPopover(false)}
                                                            speaker={
                                                              <Popover className="value_tooltip_form">
                                                                <div className="tooltip_form_inner text-center">
                                                                  <div style={{ fontSize: 20, color: "#141B3C", fontWeight: 600, lineHeight: 1.3 }}>
                                                                    Are you sure you want to <br />
                                                                     delete {deleteFetchTextValue || 'this record'} record?
                                                                  </div>
                                                                  <div className='btn__group mt-5'>
                                                                    <a href="javascript:void(0);" className='btn__cancel' onClick={() => handleCancelClick(colData.inputCommentId)}>Cancel</a>
                                                                    
                                                                    <a
            style={{ cursor: "pointer" }}
            className="btn__assign"
            onClick={() => {
              const liElement = document.querySelector(`li[data-id="${colData.inputCommentId}"]`);
              if (liElement) {
                // Trigger the click event manually on the outer li
                liElement.querySelector('i.icon-delete').click();
                setOpenPopoverId(null);
              }
            }}
          >
            Delete
          </a>
                                                                  </div>
                                                                </div>
                                                              </Popover>
                                                            }
                                                          >
        <Button  onClick={() => handleDeleteButtonClick(colData.inputCommentId)} style={{ padding:0, backgroundColor:"transparent", border:'0px solid #BCCDF2'}}>
        <li> {" "} <a style={{ cursor: "pointer", }} >{" "} <i className="icon-delete"  ></i>{" "} </a>{" "} </li> 
      </Button>
      
      </Whisper>
                                                            {/* <li> {" "} <a style={{ cursor: "pointer", }} > {" "} <i className="icon-delete" onClick={( e ) => removeSubSection(sectionItemData.header_id + "_assets_" + (parseInt(rowInd)+20),'comment_'+sectionItemData.header_id + "_assets_" + (parseInt(rowInd))) } ></i>{" "} </a>{" "} </li> */}
                                                          </>
                                                        ) : null}
                                                        {colData.showAdd && colData.showAdd === true ? (
                                                          <li> <a style={{ cursor: "pointer", }} > <i className="icon-plus" onClick={(e) => addSubSectionRow( sectionItemData.header_id + "_assets_" + (parseInt(rowInd)+20) ) } ></i> </a> </li>
                                                        ) : null}
                                                      </ul>
                                                      <div className="tablel_field"> <input type="text" id={colData.id} defaultValue={colData.value} className={ colData.inputClass ? colData.inputClass : null } data-commentval={colData.inputCommentId} /> </div>
                                                    </div>
                                                  ) : (
                                                    <input type="text" className={ colData.inputClass ? `${colData.inputClass} borderhide clsasset` : "borderhide clsasset" } id={ sectionItemData.header_id + "_assets_" + (parseInt(rowInd)+20) + "_val" } defaultValue={ colData.value } data-commentval={colData.inputCommentId} onChange={ sumCalculation }></input>
                                                  )}
                                                </td>
                                              ) : null}
                                              {/* Asset 1 Caret */}
                                              
                                              { (!coreRevenueParentCount[sectionItemData.header_id + "_assets_" + (parseInt(rowInd)+20)] || coreRevenueParentCount[sectionItemData.header_id + "_assets_" + (parseInt(rowInd)+20)] <= 0) && colData.form_input &&
                                                    colData.form_input ===
                                                      "select" ? (
                                                      <td className="">
                                                        <div className={ colData.class ? colData.class : null } >
                                                          <input type="hidden" id={ sectionItemData.header_id + "_assets_" + (parseInt(rowInd)+20) + "_val_hidden" } className={ colData.inputClass + "_hidden" } defaultValue="" custom_value={colData.isDefault + 1 } />
                                                          <Select
                                                            placeholder=""
                                                            className={ colData.inputClass ? colData.inputClass : null }
                                                             classNamePrefix="clsDropdown"
                                                            onChange={(e) => handleChangeFaceSelection( e, colData, sectionItemData.header_id + "_assets_" + (parseInt(rowInd)+20) + "_val" ) }
                                                            // onChange={handleChangeFaceSelection}
                                                            options={ colData.value }
                                                            styles={ colourStyles }
                                                            menuPortalTarget={document.body}

                                                            isSearchable={false}
                                                            components={{
                                                              DropdownIndicator:
                                                                CustomDropdownIndicator,
                                                            }}
                                                            defaultValue={ colData.value[colData.isDefault] }
                                                            getOptionLabel={(
                                                              e
                                                            ) => (
                                                              <div style={{ display: "flex", alignItems: "center", }} >
                                                                {e.label ===
                                                                "Happyicon" ? (
                                                                  <Happyicon />
                                                                ) : e.label ===
                                                                  "Sadicon" ? (
                                                                  <Sadicon />
                                                                ) : (
                                                                  <Doubticon />
                                                                )}
                                                              </div>
                                                            )}
                                                          />
                                                        </div>
                                                      </td>
                                                    ) : null}
                                            </>
                                          ))}
                                          <td className=""></td>
                                        </tr>
                                        {/* END Revenue From Core Operation*/}


                                        {/*Revenue From Core Operation : SUB ROW EDIT*/}

                                       
                                        {sectionRow.sub && sectionRow.sub.rows && sectionRow.sub.rows.map((se, si) => (
                                            <tr className={ sectionItemData.header_id + "_assets_" + (parseInt(rowInd)+20) } >
                                              {se.map(
                                                (colData, colInd) => {

                                                  subcommentIndex = sectionRow.sub.subcommentIndex;

                                                  return (
                                                  <>

                                                   
                                                    
                                                  
                                                    {colData.form_input && colData.form_input === "textbox" ? (
                                                      <td className={ colData.class ? colData.class : null } >
                                                        {(colData.showEditDelete && colData.showEditDelete === true) ||
                                                        (colData.showAdd && colData.showAdd === true) ? (
                                                          <div style={{ display: "flex", alignItems: "center", gap: 6, }} className="cls_subCategory" >
                                                            <ul className="remove_Comment list_HoverControl">

                                                                <>
                                                                  {/* <li> {" "} <a style={{ cursor: "pointer", }} className={colData.comment_active_class ? colData.comment_active_class : null}> {" "} <i className="icon-comment"  data-comment={'comment_'+sectionItemData.header_id + "_assets_" + (parseInt(rowInd)) + (parseInt(si))} onClick={() => handleCommentBoxOpen('comment_'+sectionItemData.header_id + "_assets_" + (parseInt(rowInd)) + (parseInt(si)))} data-testComment={colData.inputCommentId}></i>{" "} </a>{" "}  */}
                                                                  <li> {" "} <a style={{ cursor: "pointer", }} className={colData.comment_active_class ? colData.comment_active_class : null}> {" "} <i className="icon-comment"  data-comment={colData.inputCommentId} onClick={() => handleCommentBoxOpen(colData.inputCommentId)} data-testComment={colData.inputCommentId}></i>{" "} </a>{" "} 
                                                                  {/* <input type="hidden" className="clsLastCommentId" value={colData.inputCommentId} ></input> */}
                                                                  </li>
                                                                  <li style={{ display: 'none' }} data-id={colData.inputCommentId}> {" "} <a style={{ cursor: "pointer", }} > {" "} <i className="icon-delete" parent_id={sectionItemData.header_id + "_assets_" + (parseInt(rowInd)+20)} onClick={( e ) => removeRowItem(e) } ></i>{" "} </a>{" "} </li>
                                                                 
                                                                 <Whisper placement="left" trigger="click" 
                                                                  open={openPopoverId === colData.inputCommentId} onClose={() => setOpenReportPopover(false)}
                                                            speaker={
                                                              <Popover className="value_tooltip_form">
                                                                <div className="tooltip_form_inner text-center">
                                                                  <div style={{ fontSize: 20, color: "#141B3C", fontWeight: 600, lineHeight: 1.3 }}>
                                                                    Are you sure you want to <br />
                                                                     delete {deleteFetchTextValue || 'this record'} record?
                                                                  </div>
                                                                  <div className='btn__group mt-5'>
                                                                    <a href="javascript:void(0);" className='btn__cancel' onClick={() => handleCancelClick(colData.inputCommentId)}>Cancel</a>
                                                                    
                                                                    <a
            style={{ cursor: "pointer" }}
            className="btn__assign"
            onClick={() => {
              const liElement = document.querySelector(`li[data-id="${colData.inputCommentId}"]`);
              if (liElement) {
                // Trigger the click event manually on the outer li
                liElement.querySelector('i.icon-delete').click();
                setOpenPopoverId(null);
              }
            }}
          >
            Delete
          </a>
                                                                  </div>
                                                                </div>
                                                              </Popover>
                                                            }
                                                          >
        <Button  onClick={() => handleDeleteButtonClick(colData.inputCommentId)} style={{ padding:0, backgroundColor:"transparent", border:'0px solid #BCCDF2'}}>
        <li> {" "} <a style={{ cursor: "pointer", }} >{" "} <i className="icon-delete"  ></i>{" "} </a>{" "} </li> 
      </Button>
      
      </Whisper>
                                                                </>
                                                            </ul>
                                                            <div className="tablel_field">
                                                              <input type="text" id={colData.id} data-id="" onChange={ sumCalculation } defaultValue={ colData.value } className={ colData.inputClass ? colData.inputClass + " " + sectionItemData.header_id + "_assets_" + (parseInt(rowInd)+20) + "_val" : null } data-commentval={colData.inputCommentId} />
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <input type="text" id={ sectionItemData.header_id + "_assets_" + (parseInt(rowInd)+20) + (parseInt(si)+11) + "_val" } onChange={ sumCalculation } defaultValue={ colData.value } className={ colData.inputClass ? colData.inputClass + " " + sectionItemData.header_id + "_assets_" + (parseInt(rowInd)+20) + "_val" : null } data-commentval={colData.inputCommentId} />
                                                            //onChange={(e) => sumCalculation(e.target.value)}

                                                        )}
                                                      </td>
                                                    ) : null}
                                                    {/* <td className=""></td> */}
                                                    {colData.form_input && colData.form_input === "select" ? (
                                                      <td className="">
                                                        <div className={ colData.class ? colData.class : null } >
                                                          <input type="hidden" id={ sectionItemData.header_id + "_assets_" + (parseInt(rowInd)+20) + (parseInt(si)+11) + "_val_hidden" } className={ colData.inputClass + "_hidden" } defaultValue={ colData.defaultValue } custom_value={parseInt(colData.isDefault)+1} />
                                                          <Select
                                                            placeholder=""
                                                            className={ colData.inputClass ? colData.inputClass : null }
                                                             classNamePrefix="clsDropdown"
                                                            onChange={(e) => handleChangeFaceSelection( e, colData, sectionItemData.header_id + "_assets_" + (parseInt(rowInd)+20) + (parseInt(si)+11) + "_val" ) }
                                                            // onChange={handleChangeFaceSelection}
                                                            options={ colData.value }
                                                            styles={ colourStyles }
                                                            menuPortalTarget={document.body}

                                                            isSearchable={false}
                                                            components={{
                                                              DropdownIndicator:
                                                                CustomDropdownIndicator,
                                                            }}
                                                            defaultValue={ colData.value[colData.isDefault] }
                                                            getOptionLabel={(
                                                              e
                                                            ) => (
                                                              <div style={{ display: "flex", alignItems: "center", }} >
                                                                {e.label ===
                                                                "Happyicon" ? (
                                                                  <Happyicon />
                                                                ) : e.label ===
                                                                  "Sadicon" ? (
                                                                  <Sadicon />
                                                                ) : (
                                                                  <Doubticon />
                                                                )}
                                                              </div>
                                                            )}
                                                          />
                                                        </div>
                                                      </td>
                                                    ) : null}


                                                  </>
                                                    );
                                                  }
                                                )}
                                            </tr>
                                          ))}
                                        {/* END Revenue From Core Operation : SUB ROW EDIT*/}
                                        



                                        {/* Revenue From Core Operation : SUB ROW DEFAULT Edit*/}
                                        <div
                                          id={ "sub_default_row_" + sectionItemData.header_id + "_assets_" + (parseInt(rowInd)+20) } style={{ display: "none" }} className="validateSubrow">


                                          {Array.apply(null, { length: 10, }).map((se, si) => (
                                            <tr className={ sectionItemData.header_id + "_assets_" + (parseInt(rowInd)+20) }>
                                              {sectionItemData.defaultRows && sectionItemData.defaultRows[0] && sectionItemData.defaultRows[0].map(
                                                (colData, colInd) => (
                                                  <>
                                                  
                                                    {colData.form_input && colData.form_input === "textbox" ? (
                                                      <td className={ colData.class ? colData.class : null } >
                                                        {(colData.showEditDelete && colData.showEditDelete === true) ||
                                                        (colData.showAdd && colData.showAdd === true) ? (
                                                          <div style={{ display: "flex", alignItems: "center", gap: 6, }} className="cls_subCategory" >
                                                            <ul className="remove_Comment list_HoverControl">
                                                              {colData.showEditDelete &&
                                                              colData.showEditDelete ===
                                                                true ? (
                                                                <>
                                                                  {/* <li> {" "} <a style={{ cursor: "pointer", }}  > {" "} <i className="icon-comment" data-comment={'comment_'+sectionItemData.header_id + "_assets_" + (parseInt(rowInd)) + parseInt(si+(sectionRow.sub && sectionRow.sub.rows.length ? sectionRow.sub.rows.length : 0 ))} onClick={() => handleCommentBoxOpen('comment_'+sectionItemData.header_id + "_assets_" + (parseInt(rowInd)) + parseInt(si+(sectionRow.sub && sectionRow.sub.rows.length ? sectionRow.sub.rows.length : 0 )))} data-testComment={'comment_'+sectionItemData.header_id + "_assets_"+ (parseInt(rowInd))+ subcommentIndex++}></i>{" "} </a>{" "} </li> */}
                                                                  <li> {" "} <a style={{ cursor: "pointer", }}  > {" "} <i className="icon-comment" data-comment={'comment_'+sectionItemData.header_id + "_assets_"+ (parseInt(rowInd))+ subcommentIndex++} onClick={() => handleCommentBoxOpen('comment_'+sectionItemData.header_id + "_assets_" + (parseInt(rowInd)) + parseInt(si+(sectionRow.sub && sectionRow.sub.rows.length ? sectionRow.sub.rows.length : 0 )))} data-testComment={'SUBROW_NEWE_EDIT'}></i>{" "} </a>{" "} </li>
                                                                  <li> {" "} <a style={{ cursor: "pointer", }} > {" "} <i className="icon-delete" parent_id={sectionItemData.header_id + "_assets_" + (parseInt(rowInd)+20)} onClick={( e ) => removeRowItem(e,'comment_'+sectionItemData.header_id + "_assets_" + (parseInt(rowInd)) + parseInt(si+(sectionRow.sub && sectionRow.sub.rows.length ? sectionRow.sub.rows.length : 0 ))) } ></i>{" "} </a>{" "} </li>
                                                                </>
                                                              ) : null}
                                                            </ul>
                                                            <div className="tablel_field">
                                                              <input type="text" id={colData.id} data-id=""
                                                                //defaultValue={"Sub " + colData.value +(si + 1)}
                                                                className={ colData.inputClass ? colData.inputClass : null } data-commentval={'comment_'+sectionItemData.header_id + "_assets_" + (parseInt(rowInd)) + parseInt(si+(sectionRow.sub && sectionRow.sub.rows.length ? sectionRow.sub.rows.length : 0 ))} />
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <input type="text" id={colData.id}
                                                            //onChange={(e) => sumCalculation(e.target.value)}
                                                            onChange={ sumCalculation }
                                                            defaultValue=""
                                                            className={ colData.inputClass ? colData.inputClass + " " + sectionItemData.header_id + "_assets_" + (parseInt(rowInd)+20) + "_val" : null }
                                                            data-commentval={'comment_'+sectionItemData.header_id + "_assets_" + (parseInt(rowInd)) + parseInt(si+(sectionRow.sub && sectionRow.sub.rows.length ? sectionRow.sub.rows.length : 0 ))}
                                                          />
                                                        )}
                                                      </td>
                                                    ) : null}
                                                    {/* <td className=""></td> */}
                                                    {colData.form_input && colData.form_input ===
                                                      "select" ? (
                                                      <td className="">
                                                        <div className={ colData.class ? colData.class : null } >
                                                          <input type="hidden" id={ sectionItemData.header_id + "_assets_" + (parseInt(rowInd)+20) + si + "_val_hidden" } className={ colData.inputClass + "_hidden" } defaultValue="" custom_value={colData.isDefault + 1 } />
                                                          <Select
                                                            placeholder=""
                                                            className={ colData.inputClass ? colData.inputClass : null }
                                                            classNamePrefix="clsDropdown"
                                                            onChange={(e) => handleChangeFaceSelection( e, colData, sectionItemData.header_id + "_assets_" + (parseInt(rowInd)+20) + si + "_val" ) }
                                                            // onChange={handleChangeFaceSelection}
                                                            options={ colData.value }
                                                            styles={ colourStyles }
                                                            menuPortalTarget={document.body}

                                                            isSearchable={false}
                                                            components={{
                                                              DropdownIndicator:
                                                                CustomDropdownIndicator,
                                                            }}
                                                            defaultValue={ colData.value[0] }
                                                            getOptionLabel={(
                                                              e
                                                            ) => (
                                                              <div style={{ display: "flex", alignItems: "center", }} >
                                                                {e.label ===
                                                                "Happyicon" ? (
                                                                  <Happyicon />
                                                                ) : e.label ===
                                                                  "Sadicon" ? (
                                                                  <Sadicon />
                                                                ) : (
                                                                  <Doubticon />
                                                                )}
                                                              </div>
                                                            )}
                                                          />
                                                        </div>
                                                      </td>
                                                    ) : null}
                                                  </>
                                                )
                                              )}
                                            </tr>
                                          ))}
                                        </div>
                                        {/* END Revenue From Core Operation : SUB ROW DEFAULT Dedault*/}



                                      </>
                                      );
}
                                )}

                              {/* end of handling editing of revenue */}


                {/*Revenue From Core Operation ROW & Subrows : FistTime*/}

                    {sectionItemData.additionalSubcategory && sectionItemData.defaultRows.map((sectionRow, rowInd) =>
                                  Array.apply(null, { length: 20 }).map(
                                    (e, i) => (
                                      <>
                                        {/*Revenue From Core Operation Parent ROW : New Page */}
                                        <tr className={sectionItemData.header_id} id={ sectionItemData.header_id + "_assets_" + i } style={{ display: i > 1 ? "none" : (sectionItemData.form && sectionItemData.form == "edit" ? "none" : "table-row"), }} >
                                          {sectionRow.map((colData, colInd) => (
                                            <>
                                              {colData.form_input && colData.form_input === "textbox" ? (
                                                <td className={ colData.class ? colData.class : null } >
                                                  {(colData.showEditDelete && colData.showEditDelete === true) ||
                                                  (colData.showAdd && colData.showAdd === true) ? (
                                                    <div style={{ display: "flex", alignItems: "center", gap: 6, }} >
                                                      <ul className="remove_Comment list_HoverControl">
                                                        {colData.showEditDelete &&
                                                        colData.showEditDelete ===
                                                          true ? (
                                                          <>
                                                                             {/* <li> {" "} <a style={{ cursor: "pointer", }}  > {" "} <i className="icon-comment" data-comment={'comment_'+sectionItemData.header_id + "_assets_" + parseInt(i + (sectionItemData.rows && sectionItemData.rows.length ? sectionItemData.rows.length-1 : 0))} onClick={() => handleCommentBoxOpen('comment_'+sectionItemData.header_id + "_assets_" + parseInt(i + (sectionItemData.rows && sectionItemData.rows.length ? sectionItemData.rows.length-1 : 0)))} data-testComment={'comment_'+sectionItemData.header_id + "_assets_"+ (colData.commentIndex + i)} ></i>{" "} </a>{" "} </li> */}
                                                                             <li> {" "} <a style={{ cursor: "pointer", }}  > {" "} <i className="icon-comment" data-comment={'comment_'+sectionItemData.header_id + "_assets_"+ (colData.commentIndex + i)} onClick={() => handleCommentBoxOpen('comment_'+sectionItemData.header_id + "_assets_"+ (colData.commentIndex + i))} data-testComment={'comment_'+sectionItemData.header_id + "_assets_"+ (colData.commentIndex + i)} ></i>{" "} </a>{" "} </li>
                                                                             <li style={{ display: 'none' }} data-id={'comment_'+sectionItemData.header_id + "_assets_"+ (colData.commentIndex + i)}> {" "} <a style={{ cursor: "pointer", }} > {" "} <i className="icon-delete" onClick={( e ) => removeSubSection( sectionItemData.header_id + "_assets_" + i, 'comment_'+sectionItemData.header_id + "_assets_"+ (colData.commentIndex + i))} ></i>{" "} </a>{" "} </li>
                                                            {/* <li> {" "} <a style={{ cursor: "pointer", }} > {" "} <i className="icon-delete" onClick={( e ) => removeSubSection( sectionItemData.header_id + "_assets_" + i,'comment_'+sectionItemData.header_id + "_assets_" + parseInt(i + (sectionItemData.rows && sectionItemData.rows.length ? sectionItemData.rows.length-1 : 0))) } ></i>{" "} </a>{" "} </li> */}

                                                            <Whisper placement="left" trigger="click"
                                                            open={openPopoverId === 'comment_'+sectionItemData.header_id + "_assets_"+ (colData.commentIndex + i)}
                                                            onClose={() => setOpenReportPopover(false)}
                                                            speaker={
                                                              <Popover className="value_tooltip_form">
                                                                <div className="tooltip_form_inner text-center">
                                                                  <div style={{ fontSize: 20, color: "#141B3C", fontWeight: 600, lineHeight: 1.3 }}>
                                                                    Are you sure you want to <br />
                                                                     delete {deleteFetchTextValue || 'this record'} record?
                                                                  </div>
                                                                  <div className='btn__group mt-5'>
                                                                    <a href="javascript:void(0);" className='btn__cancel' onClick={() => handleCancelClick('comment_'+sectionItemData.header_id + "_assets_"+ (colData.commentIndex + i))}>Cancel</a>
                                                                    
                                                                    <a
            style={{ cursor: "pointer" }}
            className="btn__assign"
            onClick={() => {
              const liElement = document.querySelector(`li[data-id="${'comment_'+sectionItemData.header_id + "_assets_"+ (colData.commentIndex + i)}"]`);
              if (liElement) {
                // Trigger the click event manually on the outer li
                liElement.querySelector('i.icon-delete').click();
                setOpenPopoverId(null);
              }
            }}
          >
            Delete
          </a>
                                                                  </div>
                                                                </div>
                                                              </Popover>
                                                            }
                                                          >
        <Button  onClick={() => handleDeleteButtonClick('comment_'+sectionItemData.header_id + "_assets_"+ (colData.commentIndex + i))} style={{ padding:0, backgroundColor:"transparent", border:'0px solid #BCCDF2'}}>
        <li> {" "} <a style={{ cursor: "pointer", }} >{" "} <i className="icon-delete"  ></i>{" "} </a>{" "} </li> 
      </Button>
      
      </Whisper>

                                                          </>
                                                        ) : null}
                                                        {colData.showAdd && colData.showAdd === true ? (
                                                          <li> <a style={{ cursor: "pointer", }} > <i className="icon-plus" onClick={(e) => addSubSectionRow( sectionItemData.header_id + "_assets_" + i ) } ></i> </a> </li>
                                                        ) : null}
                                                      </ul>
                                                      <div className="tablel_field">
                                                        <input type="text" id={colData.id}
                                                          //defaultValue={colData.value +(i + 1)}
                                                          //className={ colData.inputClass ? colData.inputClass : null }
                                                          className={`${colData.inputClass ? colData.inputClass : null} revenueParentField`}
                                                          data-commentval={'comment_'+sectionItemData.header_id + "_assets_"+ (colData.commentIndex + i)}
                                                        />
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <input type="text" onChange={ sumCalculation } className={ colData.inputClass ? `${colData.inputClass} borderhide clsasset` : "borderhide clsasset" } id={ sectionItemData.header_id + "_assets_" + i + "_val" } defaultValue={ colData.value } 
                                                    data-commentval={'comment_'+sectionItemData.header_id + "_assets_"+ (colData.commentIndex + i)}
                                                    ></input>
                                                  )}
                                                </td>
                                              ) : null}
                                              {/* Asset 1 Caret */}
                                              { (!coreRevenueParentCount[sectionItemData.header_id + "_assets_" + i] || coreRevenueParentCount[sectionItemData.header_id + "_assets_" + i] <= 0) && colData.form_input &&
                                                    colData.form_input ===
                                                      "select" ? (
                                                      <td className="">
                                                        <div className={ colData.class ? colData.class : null } >
                                                          <input type="hidden" id={ sectionItemData.header_id + "_assets_" + i + "_val_hidden" } className={ colData.inputClass + "_hidden" } defaultValue={ colData.defaultValue } custom_value={colData.isDefault + 1 } />
                                                          <Select
                                                            placeholder=""
                                                            className={ colData.inputClass ? colData.inputClass : null }
                                                            classNamePrefix="clsDropdown"
                                                            onChange={(e) => handleChangeFaceSelection( e, colData, sectionItemData.header_id + "_assets_" + i + "_val" ) }
                                                            // onChange={handleChangeFaceSelection}
                                                            options={ colData.value }
                                                            styles={ colourStyles }
                                                            menuPortalTarget={document.body}

                                                            isSearchable={false}
                                                            components={{
                                                              DropdownIndicator:
                                                                CustomDropdownIndicator,
                                                            }}
                                                            defaultValue={ colData.value[0] }
                                                            getOptionLabel={(
                                                              e
                                                            ) => (
                                                              <div style={{ display: "flex", alignItems: "center", }} >
                                                                {e.label ===
                                                                "Happyicon" ? (
                                                                  <Happyicon />
                                                                ) : e.label ===
                                                                  "Sadicon" ? (
                                                                  <Sadicon />
                                                                ) : (
                                                                  <Doubticon />
                                                                )}
                                                              </div>
                                                            )}
                                                          />
                                                        </div>
                                                      </td>
                                                    ) : null}
                                            </>
                                          ))}
                                          <td className=""></td>
                                        </tr>
                                        {/* END Revenue From Core Operation  Parent ROW : New Page*/}

                                        {/*Revenue From Core Operation : SUB ROW : New Page*/}
                                        <div id={ "sub_default_row_" + sectionItemData.header_id + "_assets_" + i } style={{ display: "none" }} className="validateSubrow" >
                                          {Array.apply(null, { length: 15, }).map((se, si) => (
                                            <tr className={ sectionItemData.header_id + "_assets_" + i } >
                                              {sectionRow.map(
                                                (colData, colInd) => (
                                                  <>
                                                    {colData.form_input && colData.form_input === "textbox" ? (
                                                      <td className={ colData.class ? colData.class : null } >
                                                        {(colData.showEditDelete && colData.showEditDelete === true) ||
                                                        (colData.showAdd && colData.showAdd === true) ? (
                                                          <div style={{ display: "flex", alignItems: "center", gap: 6, }} className="cls_subCategory" >
                                                            <ul className="remove_Comment list_HoverControl">
                                                              {colData.showEditDelete && colData.showEditDelete === true ? (
                                                                <>
                                                                  <li> {" "} <a style={{ cursor: "pointer", }} > {" "} <i className="icon-comment" data-comment={'comment_'+sectionItemData.header_id + "_assets_" + parseInt(i + (sectionItemData.rows && sectionItemData.rows.length ? sectionItemData.rows.length-1 : 0)) + si} onClick={() => handleCommentBoxOpen('comment_'+sectionItemData.header_id + "_assets_" + parseInt(i + (sectionItemData.rows && sectionItemData.rows.length ? sectionItemData.rows.length-1 : 0)) + si)} data-testComment={'NEWcomment_'+sectionItemData.header_id + "_assets_"+ colData.commentIndex + si } ></i>{" "} </a>{" "} </li>
                                                                  <li style={{ display: 'none' }} data-id={'comment_'+sectionItemData.header_id + "_assets_" + parseInt(i + (sectionItemData.rows && sectionItemData.rows.length ? sectionItemData.rows.length-1 : 0)) + si}> {" "} <a style={{ cursor: "pointer", }} > {" "} <i className="icon-delete" parent_id={sectionItemData.header_id + "_assets_" + i} onClick={( e ) => removeRowItem(e,'comment_'+sectionItemData.header_id + "_assets_" + parseInt(i + (sectionItemData.rows && sectionItemData.rows.length ? sectionItemData.rows.length-1 : 0)) + si) } ></i>{" "} </a>{" "} </li>
                                                                  <Whisper placement="left" trigger="click" 
                                                                  open={openPopoverId === 'comment_'+sectionItemData.header_id + "_assets_" + parseInt(i + (sectionItemData.rows && sectionItemData.rows.length ? sectionItemData.rows.length-1 : 0)) + si} onClose={() => setOpenReportPopover(false)}
                                                            speaker={
                                                              <Popover className="value_tooltip_form">
                                                                <div className="tooltip_form_inner text-center">
                                                                  <div style={{ fontSize: 20, color: "#141B3C", fontWeight: 600, lineHeight: 1.3 }}>
                                                                    Are you sure you want to <br />
                                                                     delete {deleteFetchTextValue || 'this record'} record?
                                                                  </div>
                                                                  <div className='btn__group mt-5'>
                                                                    <a href="javascript:void(0);" className='btn__cancel' onClick={() => handleCancelClick('comment_'+sectionItemData.header_id + "_assets_" + parseInt(i + (sectionItemData.rows && sectionItemData.rows.length ? sectionItemData.rows.length-1 : 0)) + si)}>Cancel</a>
                                                                    
                                                                    <a
            style={{ cursor: "pointer" }}
            className="btn__assign"
            onClick={() => {
              const liElement = document.querySelector(`li[data-id="${'comment_'+sectionItemData.header_id + "_assets_" + parseInt(i + (sectionItemData.rows && sectionItemData.rows.length ? sectionItemData.rows.length-1 : 0)) + si}"]`);
              if (liElement) {
                // Trigger the click event manually on the outer li
                liElement.querySelector('i.icon-delete').click();
                setOpenPopoverId(null);
              }
            }}
          >
            Delete
          </a>
                                                                  </div>
                                                                </div>
                                                              </Popover>
                                                            }
                                                          >
        <Button  onClick={() => handleDeleteButtonClick('comment_'+sectionItemData.header_id + "_assets_" + parseInt(i + (sectionItemData.rows && sectionItemData.rows.length ? sectionItemData.rows.length-1 : 0)) + si)} style={{ padding:0, backgroundColor:"transparent", border:'0px solid #BCCDF2'}}>
        <li> {" "} <a style={{ cursor: "pointer", }} >{" "} <i className="icon-delete"  ></i>{" "} </a>{" "} </li> 
      </Button>
      
      </Whisper>
                                                                </>
                                                              ) : null}
                                                            </ul>
                                                            <div className="tablel_field">
                                                              <input type="text" id={colData.id} data-id=""
                                                                //defaultValue={"Sub " + colData.value +(si + 1)}
                                                                //className={ colData.inputClass ? colData.inputClass : null }
                                                                className={`${colData.inputClass ? colData.inputClass : null} revenueChildField`}
                                                                data-commentval={'comment_'+sectionItemData.header_id + "_assets_" + parseInt(i + (sectionItemData.rows && sectionItemData.rows.length ? sectionItemData.rows.length-1 : 0)) + si}
                                                              />
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <input type="text"
                                                            // id={colData.id}
                                                            id={sectionItemData.header_id + "_assets_" + i + si + "_val"}
                                                            //onChange={(e) => sumCalculation(e.target.value)}
                                                            onChange={ sumCalculation } defaultValue={ colData.value }
                                                            className={ colData.inputClass ? colData.inputClass + " " + sectionItemData.header_id + "_assets_" + i + "_val" : null }
                                                            data-commentval={'comment_'+sectionItemData.header_id + "_assets_" + parseInt(i + (sectionItemData.rows && sectionItemData.rows.length ? sectionItemData.rows.length-1 : 0)) + si}
                                                          />
                                                        )}
                                                      </td>
                                                    ) : null}
                                                    {/* <td className=""></td> */}
                                                    {colData.form_input && colData.form_input === "select" ? (
                                                      <td className="">
                                                        <div className={ colData.class ? colData.class : null } >
                                                          <input type="hidden" id={ sectionItemData.header_id + "_assets_" + i + si + "_val_hidden" } className={ colData.inputClass + "_hidden" } defaultValue={ colData.defaultValue } custom_value={colData.isDefault + 1 } />
                                                          <Select
                                                            placeholder=""
                                                            className={ colData.inputClass ? colData.inputClass : null }
                                                            classNamePrefix="clsDropdown"
                                                            onChange={(e) => handleChangeFaceSelection( e, colData, sectionItemData.header_id + "_assets_" + i + si + "_val" ) }
                                                            // onChange={handleChangeFaceSelection}
                                                            options={ colData.value }
                                                            styles={ colourStyles }
                                                            menuPortalTarget={document.body}

                                                            isSearchable={false}
                                                            components={{
                                                              DropdownIndicator:
                                                                CustomDropdownIndicator,
                                                            }}
                                                            defaultValue={ colData.value[0] }
                                                            getOptionLabel={(
                                                              e
                                                            ) => (
                                                              <div style={{ display: "flex", alignItems: "center", }} >
                                                                {e.label ===
                                                                "Happyicon" ? (
                                                                  <Happyicon />
                                                                ) : e.label ===
                                                                  "Sadicon" ? (
                                                                  <Sadicon />
                                                                ) : (
                                                                  <Doubticon />
                                                                )}
                                                              </div>
                                                            )}
                                                          />
                                                        </div>
                                                      </td>
                                                    ) : null}
                                                  </>
                                                )
                                              )}
                                            </tr>
                                          ))}
                                        </div>
                                        {/* END Revenue From Core Operation : SUB ROW : New Page*/}
                                      </>
                                    )
                                  )
                    )}
                
                {/* END Revenue From Core Operation ROW & Subrows : FistTime*/}



                {/*Default Row For All Textboxes except Revenue*/}
                
                    {sectionItemData.default && sectionItemData.default.map(
                                  (sectionRow, rowInd) => (
                                    <div id={ "default_row_" + sectionItemData.header_id } style={{ display: "none" }} >
                                      {Array.apply(null, { length: 20 }).map(
                                        (e, i) => (
                                          <tr className={ sectionItemData.header_id } key={i} >
                                            {sectionRow.map(
                                              (colData, colInd) => (
                                                <>
                                                  {colData.form_input && colData.form_input === "textbox" ? (
                                                    <td className={ colData.class ? colData.class : null } >
                                                      {(colData.showEditDelete && colData.showEditDelete === true) ||
                                                      (colData.showAdd && colData.showAdd === true) ? (
                                                        <div style={{ display: "flex", alignItems: "center", gap: 6, }} >
                                                          <ul className="remove_Comment list_HoverControl">
                                                            {colData.showEditDelete &&
                                                            colData.showEditDelete ===
                                                              true ? (
                                                              <>
                                                                <li> {" "} <a style={{ cursor: "pointer", }} > {" "} <i className="icon-comment" data-comment={`${colData.inputCommentId}${colData.commentIndex + i}`} onClick={() => handleCommentBoxOpen(`${colData.inputCommentId}${colData.commentIndex + i}`)}></i>{" "} </a>{" "} </li>
                                                                <li style={{ display: 'none' }} data-id={`${colData.inputCommentId}${colData.commentIndex + i}`}> {" "} <a style={{ cursor: "pointer", }} > {" "} <i className="icon-delete" onClick={( e ) => removeRowItem(e,`${colData.inputCommentId}${colData.commentIndex + i}`) } ></i>{" "} </a>{" "} </li>

                                                                <Whisper
                                                            placement="left"
                                                            trigger="click"
                                                            open={openPopoverId === `${colData.inputCommentId}${colData.commentIndex + i}`}
                                                            onClose={() => setOpenReportPopover(false)}
                                                            speaker={
                                                              <Popover className="value_tooltip_form">
                                                                <div className="tooltip_form_inner text-center">
                                                                  <div style={{ fontSize: 20, color: "#141B3C", fontWeight: 600, lineHeight: 1.3 }}>
                                                                    Are you sure you want to <br />
                                                                     delete {deleteFetchTextValue || 'this record'} record?
                                                                  </div>
                                                                  <div className='btn__group mt-5'>
                                                                    <a href="javascript:void(0);" className='btn__cancel' onClick={() => handleCancelClick(`${colData.inputCommentId}${colData.commentIndex + i}`)}>Cancel</a>
                                                                    
                                                                    <a
            style={{ cursor: "pointer" }}
            className="btn__assign"
            onClick={() => {
              const liElement = document.querySelector(`li[data-id="${`${colData.inputCommentId}${colData.commentIndex + i}`}"]`);
              if (liElement) {
                // Trigger the click event manually on the outer li
                liElement.querySelector('i.icon-delete').click();
                setOpenPopoverId(null);
              }
            }}
          >
            Delete
          </a>
                                                                  </div>
                                                                </div>
                                                              </Popover>
                                                            }
                                                          >
        <Button  onClick={() => handleDeleteButtonClick(`${colData.inputCommentId}${colData.commentIndex + i}`)} style={{ padding:0, backgroundColor:"transparent", border:'0px solid #BCCDF2'}}>
        <li> {" "} <a style={{ cursor: "pointer", }} >{" "} <i className="icon-delete"  ></i>{" "} </a>{" "} </li> 
      </Button>
      
      </Whisper>

                                                              </>
                                                            ) : null}
                                                          </ul>
                                                          <div className="tablel_field">
                                                            <input type="text" id={colData.id}
                                                              // defaultValue={colData.value}
                                                              className={ colData.inputClass ? colData.inputClass : null }
                                                              data-commentval={`${colData.inputCommentId}${colData.commentIndex + i}`}
                                                            />
                                                          </div>
                                                        </div>
                                                      ) : (
                                                        <input type="text" id={colData.id + i}
                                                          // onChange={(e) => sumCalculation(e.target.value)}
                                                          onChange={ sumCalculation } defaultValue={ colData.value } className={ colData.inputClass ? colData.inputClass : null }
                                                          data-commentval={`${colData.inputCommentId}${colData.commentIndex + i}`}
                                                        />
                                                      )}
                                                    </td>
                                                  ) : null}

                                                  {colData.form_input && colData.form_input === "select" ? (
                                                    <td className="">
                                                      <div className={ colData.class ? colData.class : null } >
                                                        <input type="hidden" id={ colData.id + i + "_hidden" } className={ colData.inputClass + "_hidden" } defaultValue={ colData.defaultValue } custom_value={ colData.isDefault + 1 } />
                                                        <Select
                                                          placeholder=""
                                                          className={ colData.inputClass ? colData.inputClass : null }
                                                          classNamePrefix="clsDropdown"
                                                          onChange={(e) => handleChangeFaceSelection( e, colData, colData.id + i ) }
                                                          // onChange={handleChangeFaceSelection}
                                                          components={{ DropdownIndicator: CustomDropdownIndicator, }}
                                                          options={ colData.value }
                                                          styles={colourStyles}
                                                          menuPortalTarget={document.body}
                                                          // menuIsOpen={true}
                                                          isSearchable={false}
                                                          defaultValue={colData.value[colData.isDefault]}
                                                          //defaultValue={ colData.isDefault !== 0 ? colData.value[1] : undefined }
                                                          getOptionLabel={(
                                                            e
                                                          ) => (
                                                            <div style={{ display: "flex", alignItems: "center", }} >
                                                              {e.label === "Happyicon" ? ( <Happyicon /> ) : e.label === "Sadicon" ? ( <Sadicon /> ) : ( <Doubticon /> )}
                                                            </div>
                                                          )}
                                                        />
                                                      </div>
                                                    </td>
                                                  ) : null}
                                                </>
                                              )
                                            )}
                                          </tr>
                                        )
                                      )}
                                    </div>
                                  )
                    )}
                
                {/*END Default Row For All Textboxes except Revenue*/}
 
   {/*START Total Asset*/}
                              {sectionItemData.sub_sections && sectionItemData.sub_sections.map( (subsection_row, subsectionInd) => (
                                    // <tr className="tbl_manual_row">
                                    <tr className={`tbl_manual_row ${ subsection_row.some( (col) => col.value === "Total assets" ) ? "tbl_total_assets_row" : "" }`} >
                                      {subsection_row.map( (sub_colData, sub_colInd) => (
                                          <>
                                            {" "}
                                            {sub_colData.form_input && sub_colData.form_input === "label" ? (
                                              <td>{sub_colData.value}</td>
                                            ) : null}{" "}
                                            {sub_colData.form_input && sub_colData.form_input === "textbox" ? (
                                              <td className={ sub_colData.class ? sub_colData.class : null } >
                                                <input
                                                  type="text"
                                                  id={sub_colData.id}
                                                  onChange={sumCalculation}
                                                  defaultValue={
                                                    sub_colData.value
                                                  }
                                                  className={
                                                    sub_colData.inputClass
                                                      ? sub_colData.inputClass
                                                      : null
                                                  }
                                                />
                                              </td>
                                            ) : null}
                                            {sub_colData.no_item && sub_colData.no_item === true ? (
                                              <td>&nbsp;</td>
                                            ) : null}
                                          </>
                                        )
                                      )}
                                    </tr>
                                  )
                                )}
  {/*END Total Asset*/}

                            </>
                          ))}
                        </tbody>
                      </>
                    ))}
                  </table>
                ))
              )}

<div className="fTable_comment_section">
                <div className="header_cb_activity">
                  <div className="header_cb_activity_toggle" onClick={toggleCollapse}
                    aria-expanded={isCollapsed}
                    aria-controls="CORE_BUSINESS_ACTIVITY">
                    <span>CORE BUSINESS ACTIVITY</span> <img src={iconWhiteCaret} />  
                  </div>

                  <div className="toggle_div">
                    <Toggle className="" checkedChildren={<CheckIcon />} unCheckedChildren={<CloseIcon />}  onChange={handleCbaToggleChange} checked={cbaStatus} />
                  </div>
                </div>
                <div className={`collapse ${isCollapsed ? 'show' : ''}`} id="CORE_BUSINESS_ACTIVITY">
                  <div className="subheader_cb_activity">
                    Comments
                  </div>
                  <div className="fTable_comment_box">
                    <textarea className='textarea__control textarea_resisable' placeholder='Enter your comment' rows="2" ref={cbaCommentRef}></textarea>
                  </div>
                </div>

              </div>


<div className="add_new_btn_group clsScreeningButton">
              {(roleName === "MERLIN_ADMIN" || roleName === "MERLIN_SENIOR_ANALYST") && responseDecodeData.reportStatus == 2 ? (
              <>
              {/*  */}

              <button className="btn_review_subticker" onClick={handleBack}> Back </button>
              
                <button className="btn_cancel_form" data-bs-toggle="modal" data-bs-target="#ModalPublishReport" onClick={handleResetSequence}>
                  Publish
                </button>
                <button className="btn_cancel_form" onClick={handleSave}>
                  Save
                </button>
                <button className="btn_save_form" onClick={handleReject}>
                  Reject
                </button>
                <button className="btn_review_subticker" onClick={handleOpen}>
                  Review Sub Ticker
                </button>

                <Modal
                  open={open1}
                  onClose={handleClose}
                  style={{ width: "100%", maxWidth: "100%" }}
                  className="full-width-modal custom_modal_full"
                >
                  <Modal.Body>
                    <div id="table-scroll" className="table-scroll">
                        <div className="table-wrap">
                        <table className="table main-table">
                        <thead>
                                    {data && data.length > 0 && data[0].label === "Description" && (
                                        <tr>
                                            <th className="fixed-side" scope="col">{data[0].label}</th>
                                            {data[0].value.map((val, index) => (
                                                <th key={index}>{val}</th>
                                            ))}
                                        </tr>
                                    )}
                       </thead>
                       <tbody>
                       {data && data.length > 0 ? (
                          data.map((row, index) => (
                              index !== 0 && ( // Skip the first item (Description)
                                  <tr key={index} className={getRowClassName(row.style)}>
                                      <td className="fixed-side" style={getRowStyle(row.style)}>{row.label}</td>
                                      {row.value.map((val, valIndex) => (
                                          <td key={valIndex} style={{color: "#000", fontWeight: "bold", letterSpacing: 0.64,}}>{val}</td>
                                      ))}
                                  </tr>
                              )
                          ))
                      ) : (
                          <div className="table_zero_data">
                              <img src={IconNoData} alt="No Data" />
                              <p>No SubTicker Available</p>
                          </div>
                      )}
</tbody>

              </table>
                        </div>
                    </div>
                </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={handleClose} className="btn_cancel_form">
                      Close
                    </Button>
                    {/* <Button onClick={handleClose} className="btn_save_form"> Save </Button> */}
                  </Modal.Footer>
                </Modal>
              </>
            ): (roleName === "MERLIN_ADMIN" || roleName === "MERLIN_SENIOR_ANALYST") && responseDecodeData.reportStatus == 3 ? (
              <button className="btn_cancel_form" data-bs-toggle="modal" data-bs-target="#ModalUnPublishReport">
                Unpublish
              </button>
            ) : (
              <>
              <button className="btn_review_subticker" onClick={handleBack}> Back </button>
                <button className="btn_cancel_form" onClick={handleSendToReview} > Send to review </button>
                <button className="btn_save_form" onClick={handleSaveAsDraft}> Save as draft </button>
              </>
            )}
          </div>

          
              </div>
            </div>
          </div>
          
        </div>
      </div>

      <div class="modal fade report_delete_modals" id="ModalPublishReport" tabindex="-1" aria-labelledby="AddNewAnalystModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <h5>Are you sure you want to publish this report?</h5>
                  <p>Once published, it cannot be edited</p>
                  <div class="btn__group mt-5" style={{}}>
                      <button type="button" class="btn_cancel" id="CancelPublish" data-bs-dismiss="modal">Cancel</button>
                          {/* <button type="button" class="btn_delete" onClick={handlePublish}>Publish</button>   */}
                      <Button className="btn_delete" onClick={handlePublish} loading={publishButtonLoader}>Publish</Button>                   
                  </div>

                  
                  {/* {isSequenceVisible && ( 
                  <div className="clsCheckingReport" >
                    <p>Report {sequentialProcessReport} <img className={isCheckingReport ? "clsCheckloader" : ""} src={sequenceLoaderTypeReport}/></p>
                    <p>Compliance {sequentialProcessComplaince} <img className={isCheckingComplaince ? "clsCheckloader" : ""} src={sequenceLoaderTypeComplaince}/></p>
                    <p>Report  {sequentialProcessPublish} <img className={isCheckingPublished ? "clsCheckloader" : ""} src={sequenceLoaderTypePublished}/></p>
                    <p>{sequentialProcessCompare} with typesense <img className={isCheckingCompare ? "clsCheckloader" : ""} src={sequenceLoaderTypeCompare}/></p>
                 </div>   
                  )}                                             */}
                </div>    
              </div>
          </div>
      </div>

      <div class="modal fade report_delete_modals" id="ModalUnPublishReport" tabindex="-1" aria-labelledby="AddNewAnalystModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-body">
                  <h5>Are you sure you want to Unpublish this report?</h5>
                  <p>Once unpublished, it goes to Review section</p>
                  <div class="btn__group mt-5" style={{}}>
                      <button type="button" class="btn_cancel" id="CancelUnPublish" data-bs-dismiss="modal">Cancel</button>
                      <Button   className="btn_delete" onClick={handleUnPublish} loading={unpublishButtonLoader}>Unpublish</Button>
                      
                  </div>      
                                                           
                </div>
              
              </div>
          </div>
      </div>

      {/* <Button onClick={handleOpen3}> Open</Button> */}

      <Modal open={commentBoxOpen} onClose={handleCommentBoxClose} className={isBox1Visible ? "comment_modal_body" : "comment_modal_body slide-in"}>
      {renderMessage1()}
      {commentLoading ? (

      <div style={{backgroundColor:"white", height:"100vh"}}>
      <Placeholder.Paragraph rows={8} />
      <Loader center content={commentLoading} />
    </div>
  ) : (
    <>
        <Modal.Header className="comment_modal_header">
          <Modal.Title className="comment_modal_title">{commentFetchTextValue}</Modal.Title>
        </Modal.Header>

        <Modal.Body>


          <div className="comment_modal_content">
              <h4 className="mb-3">Comments({commentCount}{})</h4>
              {commentdata && commentdata.length === 0  ? (
                    <div className="zero_comment">
                    <img src={IconNoComment} />
                    <p>No Comments yet</p>
                </div>
              ) : (
                commentdata &&
                commentdata.map((item, index) => (
                  <div className="comment_modal_box">
                  <div className="flex_sp_row comment_modal_box_top">
                    <h5>By {item.username}</h5>
                    <div className="comment_date">{item.time_description}</div>
                    
                  </div>
                  {/* <p>{item.messages}</p> */}
                 
                  <p dangerouslySetInnerHTML={{ __html: item.messages .replace(/\n/g, "<br />") }}></p>
                  {/* <p dangerouslySetInnerHTML={{ __html: item.messages }}></p> */}

                  <div className="flex_sp_row comment_modal_box_bottom">
                    <h6>Source : {item.source} </h6>
                    <a href="javascript:void(0);" className="cdelete_icon" onClick={() => deleteComment(item.id)}><i className="icon-delete"></i></a>
                  </div>
                </div>
                ))
              )}
          </div>

         
        </Modal.Body>
        <Modal.Footer className="modal_footer_comment_form">
          <div className="modal_footer_comment_inner">
              <div className='mt-3'>
                  <label className='label_form'>Comment</label>
                  {/* <input type='text' className='form-control' placeholder='Enter your comment' id="txtComment" /> */}
                  <textarea className='form__control textarea_resisable' placeholder='Enter your comment' id="txtComment"></textarea>
              </div>
              <div className='mt-3'>
                  <label className='label_form'>Source</label>
                  <input type='text' className='form-control' placeholder='Add source' id="txtSource" />
              </div>

                <div className="btn__group">
                  <button type="button" className="btn_cancel" onClick={handleCommentBoxClose}>Close</button>
                  <Button className="btn_comment" onClick={() => addComment()} loading={addCommentLoader}>Add Comment</Button>
                </div>
          </div>
        </Modal.Footer>
        </>
 )} 
      </Modal>
    </>
  );
}

export default Report;

