import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Popover, Whisper, Button } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';

const StyledCell = styled('span')(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const DataTable = () => {
  const [anchorEl, setAnchorEl] = useState(null); // Element to anchor the popover
  const [deleteRowId, setDeleteRowId] = useState(null); // Row ID to be deleted
  const [showThankYou, setShowThankYou] = useState(false); // State to control Thank You message visibility

  const handleEdit = (row) => {
    console.log('Edit clicked for row:', row);
  };

  const handleDelete = (row) => {
    console.log('Delete clicked for row:', row);
  };

  const handleDeleteClick = (event, row) => {
    setAnchorEl(event.currentTarget); // Set the anchor element for the popover
    setDeleteRowId(row.id); // Set the row ID to be deleted
    setShowThankYou(false); // Hide the Thank You message when opening popover
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setDeleteRowId(null);
    setShowThankYou(false); // Hide the Thank You message when closing popover
  };

  const handleConfirmDelete = () => {
    const row = rows.find((r) => r.id === deleteRowId);
    if (row) {
      handleDelete(row);
    }
    setShowThankYou(true); // Show the Thank You message after confirming deletion
    setTimeout(() => {
      handleClosePopover(); // Close the popover after a delay
    }, 4000); // Adjust the delay time as needed
  };

  const columns = [
    
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'Company',
      headerName: 'Company Name',
      width: 500,
      flex: 1,
      editable: false,
      autoWidth: true,
      renderCell: (params) => (
      <Tooltip title={params.value || ''} placement="top" arrow>
        <StyledCell>{params.value}</StyledCell>
      </Tooltip>
    ),
    },
    {
      field: 'ISIN',
      headerName: 'ISIN',
      width: 140,
      flex: 1,
      editable: true,
      autoWidth: true,
      renderCell: (params) => (
      <Tooltip title={params.value || ''} placement="top" arrow>
        <StyledCell>{params.value}</StyledCell>
      </Tooltip>
    ),
    },
    {
      field: 'Country',
      headerName: 'Country',
      flex: 1,
      width: 90,
      editable: true,
      autoWidth: true,
      renderCell: (params) => (
      <Tooltip title={params.value || ''} placement="top" arrow>
        <StyledCell>{params.value}</StyledCell>
      </Tooltip>
    ),
    },
    {
      field: 'Compliance',
      headerName: 'Compliance',
      width: 90,
      flex: 1,
      editable: true,
      autoWidth: true,
      renderCell: (params) => (
      <Tooltip title={params.value || ''} placement="top" arrow>
        <StyledCell>{params.value}</StyledCell>
      </Tooltip>
    ),
    },
    {
      field: 'Username',
      headerName: 'Username',
      width: 110,
      flex: 1,
      editable: true,
      autoWidth: true,
      renderCell: (params) => (
      <Tooltip title={params.value || ''} placement="top" arrow>
        <StyledCell>{params.value}</StyledCell>
      </Tooltip>
    ),
    },
    {
      field: 'Updated',
      headerName: 'Updated',
      flex: 1,
      width: 110,
      editable: true,
      autoWidth: true,
      renderCell: (params) => (
      <Tooltip title={params.value || ''} placement="top" arrow>
        <StyledCell>{params.value}</StyledCell>
      </Tooltip>
    ),
    },
    {
      field: 'Quarter',
      headerName: 'Quarter',
      flex: 1,
      width: 110,
      editable: true,
      autoWidth: true,
      renderCell: (params) => (
      <Tooltip title={params.value || ''} placement="top" arrow>
        <StyledCell>{params.value}</StyledCell>
      </Tooltip>
    ),
    },
    {
      field: 'Action',
      headerName: 'Action',
      flex: 1,
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      autoWidth: true,
      renderCell: (params) => (
        <>
          <Tooltip title={'Edit'} placement="top" arrow>
            <IconButton
              onClick={() => handleEdit(params.row)}
              aria-label="edit"
              className='Icon_action_edit'
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
           
             
            <Whisper
              placement="auto"
              trigger="click"
              // open={Boolean(anchorEl)}
              // anchorEl={anchorEl}
              // onClose={handleClosePopover}

              open={deleteRowId === params.row.id}
              anchorEl={anchorEl}
              onClose={handleClosePopover}

              // open={Boolean(anchorEl)}
              // onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              
                speaker={
                  <Popover className="deletePopoverWrap">
                    <div className="deletePopover text-center">
                      {showThankYou ? (
                        <div class="thankyou_content">         
                          <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                          </svg>
                          <h4>Thank You!</h4>
                          <p>The row has been deleted.</p>
                        </div>
                      ) : (
                        <>
                          <h5>Confirm Deletion</h5>
                          <p>Are you sure you want to delete this row?</p>

                          <div className='btn__group'>
                            <a href="#" className="btn__assign" onClick={handleConfirmDelete}>Confirm</a>
                            <a href="#" className="btn__cancel" onClick={handleClosePopover}>
                              Cancel
                            </a>
                          </div>
                        </>
                      )}
                    </div>
                  </Popover>
                }
              >
                <Tooltip title={'Delete'} placement="top" arrow>
                  <StyledCell>
                  <Button
                  onClick={(event) => handleDeleteClick(event, params.row)}
                  aria-label="delete"
                  className='Icon_action_delete'
                >
                  <DeleteIcon />
                </Button>
                  </StyledCell>
                </Tooltip>
                
              </Whisper>                                
        </>
      ),
    },
  ];

  const rows = [
    {Action: 'NEW', id: 1, Company: 'Zonetail Inc', ISIN: 'CA98979N1006', Country: 'CA - NEOE', Compliance: 'NCMPT', Username: 'b', Updated: '16 Aug 2024', Quarter: '2024 - Q3', Action: 'NEW'},
    {Action: 'NEW', id: 2, Company: 'Shin Nippon Biomedical Laboratories Ltd', ISIN: 'JP3379950003', Country: 'JP - XDUS', Compliance: 'CMPT', Username: 'c', Updated: '16 Aug 2024', Quarter: '2024 - Q3', Action: 'NEW'},
    {Action: 'NEW', id: 3, Company: 'Zonetail Inc', ISIN: 'CA98979N1006', Country: 'CA - NEOE', Compliance: 'NCMPT', Username: 'b', Updated: '16 Aug 2024', Quarter: '2024 - Q3', Action: 'NEW'},
  ];

  return (
    <>
      <div style={{ boxShadow: '0 0 8px #DDD', backgroundColor: '#FFF', width: '100%' }}>
        <Box sx={{}}>
          <DataGrid
            rows={rows}
            columns={columns.map(column => ({ ...column, editable: false }))}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            rowsPerPageOptions={[10, 20, 30]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
      </div>
      
    </>
  );
};

export default DataTable;
