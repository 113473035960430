import React, { useState, useEffect } from 'react';
import Logo from "../images/logo.svg";
import { ReactComponent as PasswordShow } from "../images/icon-eye.svg";
import { ReactComponent as PasswordHide } from "../images/icon-eye-hide.svg";
import { ReactComponent as IconValid } from "../images/IconValid.svg"; // Your custom check icon
import { ReactComponent as IconInvalid } from "../images/IconInvalid.svg"; // Your custom cross icon
import PasswordChecklist from "react-password-checklist";
import { Message, useToaster } from 'rsuite';
import IconInfo from "../images/IconErrorInfo.svg";
import IconError from "../images/IconError.svg";
import IconCheckError from "../images/IconCheckError.svg"
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {

  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const [tabName, setTabName] = useState('Change Password');
  const userid = localStorage.getItem("novauserid");
  useEffect(() => {
    document.title = tabName;
  }, [tabName]);

  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [isPasswordFilled, setIsPasswordFilled] = useState(false);
  const [validity, setValidity] = useState({
    minLength: false,
    capital: false,
    specialChar: false,
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const togglePasswordVisibility2 = () => {
    setShowPassword2(!showPassword2);
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    setIsPasswordFilled(newPassword.length > 0);

    // Example validation criteria (adjust as per your requirements)
    const isMinLengthValid = newPassword.length >= 8;
    const hasCapitalLetter = /[A-Z]/.test(newPassword);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);

    setValidity({
      minLength: isMinLengthValid,
      capital: hasCapitalLetter,
      specialChar: hasSpecialChar,
      // Update other validity checks here
    });
  };

  const handlePasswordAgainChange = (e) => {
    setPasswordAgain(e.target.value);
    setIsPasswordFilled(!!e.target.value); // Update isPasswordFilled based on input
  };

  const updateValidity = (value) => {
    const minLengthValid = value.length >= 8;
    const capitalValid = /[A-Z]/.test(value);
    const specialCharValid = /[!@#$%^&*(),.?":{}|<>]/.test(value);

   
  };

  const [errorMessage, setErrorMessage] = useState('');
  const [messageType, setMessageType] = useState(null); // success, error, info

  const handleChangePassword = async () => {
   
    
    if (!password) {
      setErrorMessage('New Password is required.');
      setMessageType('error');
      return false;
    }
    if (!passwordAgain) {
      setErrorMessage('Confirm Password is required.');
      setMessageType('error');
      return false;
    }

    if (password && passwordAgain && password !== passwordAgain) {
      setErrorMessage('New Password and Confirm Password do not match.');
      setMessageType('error');
      return false;
    }
   
    // Perform action on change password click
    if (isPasswordFilled && Object.values(validity).every(v => v)) {

    
      // Perform the API call to change the password
    const response = await fetch(`${apiEndPoint}/setPassword`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: {
          user_id: userid,
          password: password,
          confirm_password: passwordAgain
        }
      })
    });

    const result = await response.json();

    // Handle the API response
    if (result.data.status === "error") {
      setErrorMessage(result.data.message);
      setMessageType('error');
      return false;
    } else {
      setErrorMessage('Password changed successfully');
      setMessageType('success');

      setTimeout(() => {
        navigate('/');
      }, 3000);

      return true;
    }

    } else {
      // Password is not valid, handle accordingly (e.g., show error message)
      //console.log('Password is not valid');
      setErrorMessage('Password does not meet the required criteria');
      setMessageType('error');
      return false;
    }
  };

  const hideMessage = () => {
    setMessageType(null);
  };

  const renderMessage = () => {
    if (messageType) {
      setTimeout(hideMessage, 2000);
    };

    switch (messageType) {
      case 'success':
        return (
          <Message type="success" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10}}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <div className='icon_error_msg Icon_success'>
                <div className='icon_error_msg_inner Icon_success_inner'>
                  <img src={IconCheckError} alt="Success Icon" />
                  <div className="progress-circle progress_circle_success"></div>
                </div>
              </div>
              <div className='error_msg_text error_msg_success_text'>
                <h5>Success</h5>
                <p>Password changed successfully</p>
              </div>
            </div>
          </Message>
        );
      case 'error':
        return (
          <Message type="error" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10}}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <div className='icon_error_msg Icon_error'>
                <div className='icon_error_msg_inner Icon_error_inner'>
                  <img src={IconError} alt="Error Icon" />
                  <div className="progress-circle progress_circle_error"></div>
                </div>
              </div>
              <div className='error_msg_text error_msg_error_text'>
                <h5>Error</h5>
                <p>{errorMessage}</p>
              </div>
            </div>
          </Message>
        );
        case 'info':
        return (
          <Message type="info" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10}}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <div className='icon_error_msg Icon_info'>
                <div className='icon_error_msg_inner Icon_info_inner'>
                  <img src={IconInfo} alt="Info Icon" />
                  <div className="progress-circle progress_circle_info"></div>
                </div>
              </div>
              <div className='error_msg_text error_msg_info_text'>
                <h5>Info</h5>
                <p>No reports found for the selected priority</p>
              </div>
            </div>
          </Message>
        );
        default:
          return null;
    }
  };


  return (
    <>
      {renderMessage()}
      <div className="loader-container">
        <div className={`loader`}>
          <div className="loader__icon">
            <svg width="80" height="80" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1_591)">
                <rect y="0.282349" width="66.4354" height="66.4354" rx="15.0491" fill="#141B3C" />
                <rect x="17.0926" y="34.8087" width="0" height="7.12692" rx="3.56346" transform="rotate(-38.5482 17.0926 34.8087)" fill="#FDCC72">
                  <animate attributeName="width" dur="2s" from="0" to="71.9071" repeatCount="indefinite" />
                </rect>
                <rect x="13.556" y="50.7231" width="0" height="6.18755" rx="3.09378" transform="rotate(-38.5482 13.556 50.7231)" fill="#FDCC72">
                  <animate attributeName="width" dur="2s" from="0" to="71.9071" repeatCount="indefinite" />
                </rect>
                <path id="line1" d="M-2.48236 42.6675L20.5136 23.3716" stroke="white" strokeWidth="5.89403" strokeLinecap="round" strokeDasharray="150" strokeDashoffset="150">
                  <animate attributeName="stroke-dashoffset" dur="2s" repeatCount="indefinite" from="150" to="0" />
                </path>
                <path id="line2" d="M20.6283 23.5651L40.9626 46.5518" stroke="white" strokeWidth="5.89403" strokeLinecap="round" strokeDasharray="150" strokeDashoffset="150">
                  <animate attributeName="stroke-dashoffset" dur="2s" repeatCount="indefinite" from="150" to="0" />
                </path>
                <path id="line3" d="M41.2585 46.6971L77.2121 17.9658" stroke="white" strokeWidth="5.89403" strokeLinecap="round" strokeDasharray="150" strokeDashoffset="150">
                  <animate attributeName="stroke-dashoffset" dur="2s" repeatCount="indefinite" from="150" to="0" />
                </path>
              </g>
              <defs>
                <clipPath id="clip0_1_591">
                  <rect y="0.282349" width="66.4354" height="66.4354" rx="15.0491" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="loader__tile"></div>
        </div>
      </div>
      
      <div className="login_wrapper">
        <div className='login_content'>
          <div className='login_header'>
            <div className='login_logo'>
              <img src={Logo} alt="Logo" />
            </div>
            <div className='login_header_text' style={{marginTop:15,}}>
              <h3>Change Password</h3>
            </div>
          </div>
          <div className='login_form'>
            <form>
              <div className='field_row'>
                <label className='custom_label'>New Password</label>
                <div className='text_field'>
                  <input 
                    type={showPassword ? 'text' : 'password'}
                    className='form-control'
                    onChange={handlePasswordChange}
                  />
                  <div className='icon_field' onClick={togglePasswordVisibility}>
                    {showPassword ? <PasswordShow /> : <PasswordHide />}
                  </div>
                </div>
              </div>

              <div className='field_row'>
                <label className='custom_label'>Confirm Password</label>
                <div className='text_field'>
                  <input
                    type={showPassword2 ? 'text' : 'password'}
                    className='form-control'
                    onChange={handlePasswordAgainChange}
                  />
                  <div className='icon_field' onClick={togglePasswordVisibility2}>
                    {showPassword2 ? <PasswordShow /> : <PasswordHide />}
                  </div>
                </div>
              </div>

              <div className='password_error_msg'>
                  {!isPasswordFilled && (
                    <ul className='message_default'>
                      <li>Must be at least 8 characters long</li>
                      <li>Include both uppercase and lowercase letters</li>
                      <li>Use special characters and numbers</li>
                    </ul>
                  )}

                  {isPasswordFilled && (
                    <ul className='message_after_type'>
                      <li className={validity.minLength ? 'valid' : 'invalid'}>Must be at least 8 characters long</li>
                      <li className={validity.capital ? 'valid' : 'invalid'}>Include both uppercase and lowercase letters</li>
                      <li className={validity.specialChar ? 'valid' : 'invalid'}>Use special characters and numbers</li>
                    </ul>
                  )}

                  {/* Example icons based on validity */}
                  {isPasswordFilled && Object.values(validity).every(v => v) && (
                    <IconValid />
                  )}
                  {isPasswordFilled && !Object.values(validity).every(v => v) && (
                    <IconInvalid />
                  )}
                </div>

              <div className='mt-5'>
                <button 
                  type='button' 
                  className={`btn_login ${isPasswordFilled && Object.values(validity).every(v => v) ? 'valid' : ''}`} 
                  onClick={handleChangePassword}
                >
                  Change Password
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
