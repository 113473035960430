import React, { useState } from 'react';
import Logo from "../images/logo.svg";
import { ReactComponent as IconGreen } from '../images/IconGreen.svg';
import { ReactComponent as IconRed } from '../images/IconRed.svg';
import { ReactComponent as IconOrange } from '../images/IconOrange.svg';

import Sidebar from '../component/Sidebar';
import Select from 'react-select';
import { SelectPicker } from 'rsuite';
import 'rsuite/SelectPicker/styles/index.css';

function TableAccordion() {

const data = ['India', 'United States', 'Australia'].map(
  item => ({ label: item, value: item })
);

const data2 = ['Quarter', 'Second Quarter', 'Third Quarter'].map(
  item => ({ label: item, value: item })
);



const options = [
  { value: 'option1', label: <IconGreen />, icon: IconGreen },
  { value: 'option2', label: <IconRed />, icon: IconRed },
  { value: 'option3', label: <IconOrange />, icon: IconOrange },
];

const customStyles = {
  // Define custom styles for the select
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'all .2s ease',
    transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
  }),
};

const [selectedOption, setSelectedOption] = useState(options[0]);

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const getOptionLabel = (option) => (
    <div>
      {option.icon}
      <span>{option.label}</span>
    </div>
  );

  const getOptionValue = (option) => option.value;

  const getOption = (option) => ({
    ...option,
    value: option.value,
    label: option.label,
  });

  const DropdownIndicator = (props) => {
    return (
      <div {...props}>
        <CaretIcon />
      </div>
    );
  };

  const CaretIcon = () => {
    // Determine which caret icon to use based on selected option
    if (selectedOption.value === 'option1') {
      return <CaretIconGreen />;
    } else if (selectedOption.value === 'option2') {
      return <CaretIconRed />;
    } else if (selectedOption.value === 'option3') {
      return <CaretIconOrange />;
    } else {
      // Default case
      return <DefaultCaretIcon />;
    }
  };

  function CaretIconGreen() {
    return <i className='icon-arrow_caret caret_green'></i>;
  }
  
  function CaretIconRed() {
    return <i className='icon-arrow_caret caret_red'></i>;
  }
  
  function CaretIconOrange() {
    return <i className='icon-arrow_caret caret_orange'></i>;
  }
  
  function DefaultCaretIcon() {
    return <i className='icon-arrow_caret caret_green'></i>;
  }


// TABLE ACCORDION 
// const [open, setOpen] = useState(false);

//   const handleToggle = () => {
//     setOpen((prevOpen) => !prevOpen);
//   };
  
  return (
    <>
    <Sidebar/>
    <div id='header'>
        <div className='logo'>
            <div className='logo_icon'>
                <img src={Logo} />
            </div>
            <div className='brand_name'>Company Profile</div>
        </div>

        <div className='header_table_filter'>
            <div className='searchfield'>
                <button className='btn_search'><i className='icon-search'></i></button>
                <input type='text' className='filter_searchfield' placeholder='Search by ISIN' />
            </div>

            <div className='dropdown_col_filter dropdown__select'>
                 <SelectPicker
                    data={data}
                    searchable={false}
                    style={{ width: 180 }}
                    placeholder="Both Statuses"
                    />
            </div> 
            <div className='dropdown_col_filter dropdown__select'>
                 <SelectPicker
                    data={data}
                    searchable={false}
                    style={{ width: 180 }}
                    placeholder="All Countries"
                    />
            </div> 
            <div className='dropdown_col_filter dropdown__select'>
                 <SelectPicker
                    data={data}
                    searchable={false}
                    style={{ width: 180 }}
                    placeholder="All Compliance"
                    />
            </div>

            <a href='#' className="btn_clear_filter">Clear Filters</a>
        </div>

        <div className='header_monthly_value'>
            <a href='/AddNewCompanyProfile' className='btn_add_new'><i className='icon-plus'></i>Add New</a>
        </div>
    </div>
    <div className="container-fluid content pb-5">
      <div className='company_table_list'>
        <div className='table-responsive' style={{paddingBottom:0}}>
          <table className='table'>
              <thead>
                <tr>
                  <th>Ticker And Comapny Name</th>
                  <th>Country</th>
                  <th>Market Cap</th>
                  <th>Exch</th>
                  <th>ISIN</th>
                  <th>Compliance</th>
                  <th style={{textAlign:'center'}}>Status</th>
                  <th>Updated</th>
                  <th>Actions</th>
                </tr>
              </thead>
              
              <tbody>

                <tr>
                  <td>
                    <div className='company_name_td'>
                    <span className='company_lable'>IGNE</span>
                    Igene Biotechnology Inc
                    </div>
                    </td>
                  <td>United States</td>
                  <td>USD 160,000.00</td>
                  <td>
                  <Select
                    options={options.map(getOption)}
                    styles={customStyles}
                    value={selectedOption}
                    onChange={handleSelectChange}
                    components={{ DropdownIndicator }}
                    className='select_box'
                  />
                  </td>
                  <td>
                    <div>
                      US4516951005
                    </div>
                  </td>
                  <td><span className='uncover_tag tag_td'>Uncovered</span></td>                  
                  <td>Published</td>
                  <td>Mar 26, 2024</td>
                  <td>
                    <div className='action_row'>
                        <a href='#' className='btn_create_report'>
                        Create Report
                        </a>
                        <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                        <a href='/CompanyProfileReports' className='btn_icon_action'><i className='icon-view-file'></i></a>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <div className='company_name_td'>
                    <span className='company_lable'>DTI.AX</span>
                    DTI Group Ltd
                    </div>
                    </td>
                  <td>United States</td>
                  <td>USD 310,000.00</td>
                  <td>Other OTC</td>
                  <td>
                    <div>
                      US5746671016
                    </div>
                  </td>
                  <td><span className='uncover_tag tag_td'>Uncovered</span></td>                  
                  <td>Published</td>
                  <td>Mar 26, 2024</td>
                  <td>
                    <div className='action_row'>
                        <a href='#' className='btn_create_report'>
                        Create Report
                        </a>
                        <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                        <a href='/CompanyProfileReports' className='btn_icon_action'><i className='icon-view-file'></i></a>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td>
                    <div className='company_name_td'>
                    <span className='company_lable'>BCI.AX</span>
                    BCI Minerals Ltd
                    </div>
                    </td>
                  <td>India</td>
                  <td>USD 469,400,000.00</td>
                  <td>NASDAQ</td>
                  <td>
                    <div>
                      US67577C1053
                    </div>
                  </td>
                  <td><span className='not_halal_tag tag_td'>Not halal</span></td>                  
                  <td>Published</td>
                  <td>Mar 26, 2024</td>
                  <td>
                    <div className='action_row'>
                        <a href='#' className='btn_create_report'>
                        Create Report
                        </a>
                        <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                        <a href='/CompanyProfileReports' className='btn_icon_action'><i className='icon-view-file'></i></a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className='company_name_td'>
                    <span className='company_lable'>HZR.AX</span>
                     Hazer Group Ltd
                    </div>
                    </td>
                  <td>Australia</td>
                  <td>USD 310,000.00</td>
                  <td>Other OTC</td>
                  <td>
                    <div>
                      US5746671016
                    </div>                    
                  </td>
                  <td><span className='uncover_tag tag_td'>Uncovered</span></td>                  
                  <td>Published</td>
                  <td>Mar 26, 2024</td>
                  <td>
                    <div className='action_row'>
                        <a href='#' className='btn_create_report'>
                        Create Report
                        </a>
                        <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                        <a href='/CompanyProfileReports' className='btn_icon_action'><i className='icon-view-file'></i></a>
                    </div>
                  </td>
                </tr> 
                <tr>
                  <td>
                    <div className='company_name_td'>
                    <span className='company_lable'>EWC.AX</span>
                    Energy World Corporation
                    </div>
                    </td>
                  <td>United States</td>
                  <td>USD 1,617,640,000.00</td>
                  <td>NYSE</td>
                  <td>
                    <div>
                      US10806B1008
                    </div>  
                  </td>
                  <td><span className='not_halal_tag tag_td'>Not halal</span></td>                  
                  <td>Published</td>
                  <td>Mar 26, 2024</td>
                  <td>
                    <div className='action_row'>
                        <a href='#' className='btn_create_report'>
                        Create Report
                        </a>
                        <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                        <a href='/CompanyProfileReports' className='btn_icon_action'><i className='icon-view-file'></i></a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className='company_name_td'>
                    <span className='company_lable'>SVG.AX</span>
                    Savannah Goldfields Ltd
                    </div>
                    </td>
                  <td>India</td>
                  <td>USD 300,000.00</td>
                  <td>Other OTC</td>
                  <td>
                    <div>
                      US3072511088
                    </div>
                  </td>
                  <td><span className='uncover_tag tag_td'>Uncovered</span></td>                  
                  <td>Published</td>
                  <td>Mar 26, 2024</td>
                  <td>
                    <div className='action_row'>
                        <a href='#' className='btn_create_report'>
                        Create Report
                        </a>
                        <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                        <a href='/CompanyProfileReports' className='btn_icon_action'><i className='icon-view-file'></i></a>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className='company_name_td'>
                    <span className='company_lable'>CDO.AX</span>
                    Cadence Opportunities
                    </div>
                    </td>
                  <td>United States</td>
                  <td>USD 24,340,000.00</td>
                  <td>NASDAQ</td>
                  <td>
                    <div>
                      US77467X1019
                    </div>
                  </td>
                  <td><span className='halal_tag tag_td'>Halal</span></td>                  
                  <td>Published</td>
                  <td>Mar 26, 2024</td>
                  <td>
                    <div className='action_row'>
                        <a href='#' className='btn_create_report'>
                        Create Report
                        </a>
                        <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                        <a href='/CompanyProfileReports' className='btn_icon_action'><i className='icon-view-file'></i></a>
                    </div>
                  </td>
                </tr> 

                <tr>
                  <td>
                    <div className='company_name_td'>
                    <span className='company_lable'>SGM.AX</span>
                    Sims Ltd
                    </div>
                    </td>
                  <td>United States</td>
                  <td>USD 561,940,000.00</td>
                  <td>NASDAQ</td>
                  <td>
                    <div>
                      US30292L1070
                    </div>
                  </td>
                  <td><span className='not_halal_tag tag_td'>Halal</span></td>                  
                  <td>Published</td>
                  <td>Mar 26, 2024</td>
                  <td>
                    <div className='action_row'>
                        <a href='#' className='btn_create_report'>
                        Create Report
                        </a>
                        <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                        <a href='/CompanyProfileReports' className='btn_icon_action'><i className='icon-view-file'></i></a>
                    </div>
                  </td>
                </tr>   
           
              </tbody>
           
          </table>
        </div>
      </div>
    </div>

    </>
  );
}

export default TableAccordion;
