import React, { useState, useEffect } from 'react';
import Logo from "../images/logo.svg";
import Sidebar from '../component/Sidebar';
import { Tabs, SelectPicker, TagPicker } from "rsuite";
import "rsuite/SelectPicker/styles/index.css";

const TagData = ['Electronic Technology', 'Technology', 'Banking Services', 'Basic Materials', 'Basic Materials/Resources', 'Business/Consumer Services', 'Chemicals', 'Communication Services', 'Construction Materials'].map(
    item => ({
      label: item,
      value: item,
      role: Math.random() > 0.5 ? 'Owner' : 'Guest'
    })
  );
  

function IndustrySector() {

    const [tabName, setTabName] = useState('Industry Sector');

    useEffect(() => {
      document.title = tabName;
    }, [tabName]);


const [inputValues, setInputValues] = useState({
    field1: '',
  });

  const handleInputChange = (e, fieldName) => {
    setInputValues({
      ...inputValues,
      [fieldName]: e.target.value
    });
  };

//  TAB
const [activeKey, setActiveKey] = useState('Sector');

const data = ['Junior Analyst', 'Senior Analyst'].map(
    item => ({ label: item, value: item })
);

    return (
        <>
            <Sidebar />
            <div id='header'>
                <div className='logo'>
                <div className='logo_icon'>
                    <img src={Logo} />
                </div>
                <div className='brand_name'>Industry And Sector Settings</div> </div>
            </div>
            <div className="container-fluid content pb-5">
                <div className='tabs_flex_row'>
                    <Tabs activeKey={activeKey} onSelect={setActiveKey} className='TabMenu'>
                        <Tabs.Tab eventKey="Sector" title="Sector" />
                        <Tabs.Tab eventKey="Industry" title="Industry" />
                    </Tabs>

                </div>

                
                    {activeKey === 'Sector' && (
                        <>
                         <div className='sector_title_desc_row'>
                            <div>
                                <h5>Sector Settings</h5>
                                <p>You can merge similar sectors by selecting and adding them to an existing sector group or create new.</p>
                            </div>
                            <a href='#' className='btn_create_sector_group' data-bs-toggle="modal" data-bs-target="#CreateSectorGroupModal"><i className='icon-plus'></i>Create Sector Group </a>
                        </div>
                        
                        <div className='tab_content_main' style={{padding:20,borderRadius: 16,backgroundColor:'#FFF',boxShadow: '0px 2px 24px 1px rgba(225, 225, 225, 0.80), 0px 2px 24px 1px rgba(225, 225, 225, 0.80)'}}>
                            <div className='sector_industry_table'>
                                <div className='table-responsive' style={{ paddingBottom: 0, maxHeight: 'inherit' }}>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>Category</th>
                                                <th style={{paddingLeft:35}}>Name</th>
                                                <th style={{ textAlign: 'center' }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className='sector_category_td_col'>
                                                        Consumer Discretionary
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='sector_name_td_col'>
                                                    Basic Materials, Basic Materials/Resources, Materials, Chemicals
                                                    </div>
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <div className='sector_action'>
                                                        <a href="javascript:avoid(0);" data-bs-toggle="modal" data-bs-target="#EditSectorGroupModal"><i className='icon-edit'></i></a>
                                                        <a href="javascript:avoid(0);" className='delete_section_icon' data-bs-toggle="modal" data-bs-target="#ReportDeleteModal"><i className='icon-delete'></i></a>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div className='sector_category_td_col'>
                                                    Materials
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='sector_name_td_col'>
                                                    Consumer Non-Cyclicals, Consumer Services, Consumer Goods, Consumer Discretionary
                                                    </div>
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <div className='sector_action'>
                                                        <a href="javascript:avoid(0);" data-bs-toggle="modal" data-bs-target="#EditSectorGroupModal"><i className='icon-edit'></i></a>
                                                        <a href="javascript:avoid(0);" className='delete_section_icon' data-bs-toggle="modal" data-bs-target="#ReportDeleteModal"><i className='icon-delete'></i></a>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div className='sector_category_td_col'>
                                                    Industrials
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='sector_name_td_col'>
                                                    Industrials, Industrials
                                                    </div>
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <div className='sector_action'>
                                                        <a href="javascript:avoid(0);" data-bs-toggle="modal" data-bs-target="#EditSectorGroupModal"><i className='icon-edit'></i></a>
                                                        <a href="javascript:avoid(0);" className='delete_section_icon' data-bs-toggle="modal" data-bs-target="#ReportDeleteModal"><i className='icon-delete'></i></a>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div className='sector_category_td_col'>
                                                    Health Care
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='sector_name_td_col'>
                                                    Health Technology, Medical devices, Health Care, Healthcare
                                                    </div>
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <div className='sector_action'>
                                                        <a href="javascript:avoid(0);" data-bs-toggle="modal" data-bs-target="#EditSectorGroupModal"><i className='icon-edit'></i></a>
                                                        <a href="javascript:avoid(0);" className='delete_section_icon' data-bs-toggle="modal" data-bs-target="#ReportDeleteModal"><i className='icon-delete'></i></a>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div className='sector_category_td_col'>
                                                    Financials
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='sector_name_td_col'>
                                                    financials, Banking Services, Financial Services, LEASING & FINANCE, Finance, Banking, Financial, Financial services, Insurance, Financial Services, Financials, Finance, Investment
                                                    </div>
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <div className='sector_action'>
                                                        <a href="javascript:avoid(0);" data-bs-toggle="modal" data-bs-target="#EditSectorGroupModal"><i className='icon-edit'></i></a>
                                                        <a href="javascript:avoid(0);" className='delete_section_icon' data-bs-toggle="modal" data-bs-target="#ReportDeleteModal"><i className='icon-delete'></i></a>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div className='sector_category_td_col'>
                                                    Information Technology
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='sector_name_td_col'>
                                                    Electronic Technology, Technology, Information Technology
                                                    </div>
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <div className='sector_action'>
                                                        <a href="javascript:avoid(0);" data-bs-toggle="modal" data-bs-target="#EditSectorGroupModal"><i className='icon-edit'></i></a>
                                                        <a href="javascript:avoid(0);" className='delete_section_icon' data-bs-toggle="modal" data-bs-target="#ReportDeleteModal"><i className='icon-delete'></i></a>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div className='sector_category_td_col'>
                                                    Communication Services
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='sector_name_td_col'>
                                                    Communication Services, Telecommunications Services, Communications, Wireless Telecommunications Services
                                                    </div>
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <div className='sector_action'>
                                                        <a href="javascript:avoid(0);" data-bs-toggle="modal" data-bs-target="#EditSectorGroupModal"><i className='icon-edit'></i></a>
                                                        <a href="javascript:avoid(0);" className='delete_section_icon' data-bs-toggle="modal" data-bs-target="#ReportDeleteModal"><i className='icon-delete'></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className='sector_category_td_col'>
                                                    Utilities
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='sector_name_td_col'>
                                                    Utilities, Utility
                                                    </div>
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <div className='sector_action'>
                                                        <a href="javascript:avoid(0);" data-bs-toggle="modal" data-bs-target="#EditSectorGroupModal"><i className='icon-edit'></i></a>
                                                        <a href="javascript:avoid(0);" className='delete_section_icon' data-bs-toggle="modal" data-bs-target="#ReportDeleteModal"><i className='icon-delete'></i></a>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div className='sector_category_td_col'>
                                                    Real Estate
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='sector_name_td_col'>
                                                    Real Estate/Construction,Real Estate,Real estate investment trust
                                                    </div>
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <div className='sector_action'>
                                                        <a href="javascript:avoid(0);" data-bs-toggle="modal" data-bs-target="#EditSectorGroupModal"><i className='icon-edit'></i></a>
                                                        <a href="javascript:avoid(0);" className='delete_section_icon' data-bs-toggle="modal" data-bs-target="#ReportDeleteModal"><i className='icon-delete'></i></a>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div className='sector_category_td_col'>
                                                    Real Estate
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='sector_name_td_col'>
                                                    Real Estate/Construction,Real Estate,Real estate investment trust
                                                    </div>
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <div className='sector_action'>
                                                        <a href="javascript:avoid(0);" data-bs-toggle="modal" data-bs-target="#EditSectorGroupModal"><i className='icon-edit'></i></a>
                                                        <a href="javascript:avoid(0);" className='delete_section_icon' data-bs-toggle="modal" data-bs-target="#ReportDeleteModal"><i className='icon-delete'></i></a>
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <div className='sector_category_td_col'>
                                                    Real Estate
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className='sector_name_td_col'>
                                                    Real Estate/Construction,Real Estate,Real estate investment trust
                                                    </div>
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <div className='sector_action'>
                                                        <a href="javascript:avoid(0);" data-bs-toggle="modal" data-bs-target="#EditSectorGroupModal"><i className='icon-edit'></i></a>
                                                        <a href="javascript:avoid(0);" className='delete_section_icon' data-bs-toggle="modal" data-bs-target="#ReportDeleteModal"><i className='icon-delete'></i></a>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            
                            </div>
                        </div>

                        </>
                    )}
                    {activeKey === 'Industry' && (
                        <>
                        <div className='sector_title_desc_row'>
                        <div>
                            <h5>Industry</h5>
                            <p>You can merge similar industries by selecting and adding them to an existing industry group or create new.</p>
                        </div>
                        <a href='#' className='btn_create_sector_group' data-bs-toggle="modal" data-bs-target="#CreateIndustryGroupModal"><i className='icon-plus'></i>Create Industry Group </a>
                        </div>

                        <div className='tab_content_main' style={{padding:20,borderRadius: 16,backgroundColor:'#FFF',boxShadow: '0px 2px 24px 1px rgba(225, 225, 225, 0.80), 0px 2px 24px 1px rgba(225, 225, 225, 0.80)'}}>
                        <div className='sector_industry_table'>
                            <div className='table-responsive' style={{ paddingBottom: 0, maxHeight: 'inherit' }}>
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th>Category</th>
                                            <th style={{paddingLeft:35}}>Name</th>
                                            <th style={{ textAlign: 'center' }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div className='sector_category_td_col'>
                                                    Consumer Discretionary
                                                </div>
                                            </td>
                                            <td>
                                                <div className='sector_name_td_col'>
                                                Basic Materials, Basic Materials/Resources, Materials, Chemicals
                                                </div>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <div className='sector_action'>
                                                    <a href="javascript:avoid(0);" data-bs-toggle="modal" data-bs-target="#EditSectorGroupModal"><i className='icon-edit'></i></a>
                                                    <a href="javascript:avoid(0);" className='delete_section_icon' data-bs-toggle="modal" data-bs-target="#IndustryDeleteModal"><i className='icon-delete'></i></a>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div className='sector_category_td_col'>
                                                Materials
                                                </div>
                                            </td>
                                            <td>
                                                <div className='sector_name_td_col'>
                                                Consumer Non-Cyclicals, Consumer Services, Consumer Goods, Consumer Discretionary
                                                </div>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <div className='sector_action'>
                                                    <a href="javascript:avoid(0);" data-bs-toggle="modal" data-bs-target="#EditSectorGroupModal"><i className='icon-edit'></i></a>
                                                    <a href="javascript:avoid(0);" className='delete_section_icon' data-bs-toggle="modal" data-bs-target="#IndustryDeleteModal"><i className='icon-delete'></i></a>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div className='sector_category_td_col'>
                                                Industrials
                                                </div>
                                            </td>
                                            <td>
                                                <div className='sector_name_td_col'>
                                                Industrials, Industrials
                                                </div>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <div className='sector_action'>
                                                    <a href="javascript:avoid(0);" data-bs-toggle="modal" data-bs-target="#EditSectorGroupModal"><i className='icon-edit'></i></a>
                                                    <a href="javascript:avoid(0);" className='delete_section_icon' data-bs-toggle="modal" data-bs-target="#IndustryDeleteModal"><i className='icon-delete'></i></a>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div className='sector_category_td_col'>
                                                Health Care
                                                </div>
                                            </td>
                                            <td>
                                                <div className='sector_name_td_col'>
                                                Health Technology, Medical devices, Health Care, Healthcare
                                                </div>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <div className='sector_action'>
                                                    <a href="javascript:avoid(0);" data-bs-toggle="modal" data-bs-target="#EditSectorGroupModal"><i className='icon-edit'></i></a>
                                                    <a href="javascript:avoid(0);" className='delete_section_icon' data-bs-toggle="modal" data-bs-target="#IndustryDeleteModal"><i className='icon-delete'></i></a>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div className='sector_category_td_col'>
                                                Financials
                                                </div>
                                            </td>
                                            <td>
                                                <div className='sector_name_td_col'>
                                                financials, Banking Services, Financial Services, LEASING & FINANCE, Finance, Banking, Financial, Financial services, Insurance, Financial Services, Financials, Finance, Investment
                                                </div>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <div className='sector_action'>
                                                    <a href="javascript:avoid(0);" data-bs-toggle="modal" data-bs-target="#EditSectorGroupModal"><i className='icon-edit'></i></a>
                                                    <a href="javascript:avoid(0);" className='delete_section_icon' data-bs-toggle="modal" data-bs-target="#IndustryDeleteModal"><i className='icon-delete'></i></a>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div className='sector_category_td_col'>
                                                Information Technology
                                                </div>
                                            </td>
                                            <td>
                                                <div className='sector_name_td_col'>
                                                Electronic Technology, Technology, Information Technology
                                                </div>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <div className='sector_action'>
                                                    <a href="javascript:avoid(0);" data-bs-toggle="modal" data-bs-target="#EditSectorGroupModal"><i className='icon-edit'></i></a>
                                                    <a href="javascript:avoid(0);" className='delete_section_icon' data-bs-toggle="modal" data-bs-target="#IndustryDeleteModal"><i className='icon-delete'></i></a>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div className='sector_category_td_col'>
                                                Communication Services
                                                </div>
                                            </td>
                                            <td>
                                                <div className='sector_name_td_col'>
                                                Communication Services, Telecommunications Services, Communications, Wireless Telecommunications Services
                                                </div>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <div className='sector_action'>
                                                    <a href="javascript:avoid(0);" data-bs-toggle="modal" data-bs-target="#EditSectorGroupModal"><i className='icon-edit'></i></a>
                                                    <a href="javascript:avoid(0);" className='delete_section_icon' data-bs-toggle="modal" data-bs-target="#IndustryDeleteModal"><i className='icon-delete'></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className='sector_category_td_col'>
                                                Utilities
                                                </div>
                                            </td>
                                            <td>
                                                <div className='sector_name_td_col'>
                                                Utilities, Utility
                                                </div>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <div className='sector_action'>
                                                    <a href="javascript:avoid(0);" data-bs-toggle="modal" data-bs-target="#EditSectorGroupModal"><i className='icon-edit'></i></a>
                                                    <a href="javascript:avoid(0);" className='delete_section_icon' data-bs-toggle="modal" data-bs-target="#IndustryDeleteModal"><i className='icon-delete'></i></a>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div className='sector_category_td_col'>
                                                Real Estate
                                                </div>
                                            </td>
                                            <td>
                                                <div className='sector_name_td_col'>
                                                Real Estate/Construction,Real Estate,Real estate investment trust
                                                </div>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <div className='sector_action'>
                                                    <a href="javascript:avoid(0);" data-bs-toggle="modal" data-bs-target="#EditSectorGroupModal"><i className='icon-edit'></i></a>
                                                    <a href="javascript:avoid(0);" className='delete_section_icon' data-bs-toggle="modal" data-bs-target="#IndustryDeleteModal"><i className='icon-delete'></i></a>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div className='sector_category_td_col'>
                                                Real Estate
                                                </div>
                                            </td>
                                            <td>
                                                <div className='sector_name_td_col'>
                                                Real Estate/Construction,Real Estate,Real estate investment trust
                                                </div>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <div className='sector_action'>
                                                    <a href="javascript:avoid(0);" data-bs-toggle="modal" data-bs-target="#EditSectorGroupModal"><i className='icon-edit'></i></a>
                                                    <a href="javascript:avoid(0);" className='delete_section_icon' data-bs-toggle="modal" data-bs-target="#IndustryDeleteModal"><i className='icon-delete'></i></a>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <div className='sector_category_td_col'>
                                                Real Estate
                                                </div>
                                            </td>
                                            <td>
                                                <div className='sector_name_td_col'>
                                                Real Estate/Construction,Real Estate,Real estate investment trust
                                                </div>
                                            </td>
                                            <td style={{ textAlign: 'center' }}>
                                                <div className='sector_action'>
                                                    <a href="javascript:avoid(0);" data-bs-toggle="modal" data-bs-target="#EditSectorGroupModal"><i className='icon-edit'></i></a>
                                                    <a href="javascript:avoid(0);" className='delete_section_icon' data-bs-toggle="modal" data-bs-target="#IndustryDeleteModal"><i className='icon-delete'></i></a>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        
                        </div>
                        </div>

                        </>
                    )}
                    
                </div>
            
        
            <div class="modal fade report_delete_modals" id="ReportDeleteModal" tabindex="-1" aria-labelledby="ReportDeleteModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body">
                        <h5>Are you sure you want to delete <br />this sector group ?</h5>
                        <p>This action cannot be reversed</p>
                        <div class="btn__group mt-5" style={{}}>
                            <button type="button" class="btn_cancel" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" class="btn_delete">Delete</button>
                        </div>                                               
                        </div>
                    
                    </div>
                </div>
            </div>

            <div class="modal fade report_delete_modals" id="IndustryDeleteModal" tabindex="-1" aria-labelledby="IndustryDeleteModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-body">
                        <h5>Are you sure you want to delete <br />this industry group ?</h5>
                        <p>This action cannot be reversed</p>
                        <div class="btn__group mt-5" style={{}}>
                            <button type="button" class="btn_cancel" data-bs-dismiss="modal">Cancel</button>
                            <button type="button" class="btn_delete">Delete</button>
                        </div>                                               
                        </div>
                    
                    </div>
                </div>
            </div> 
            

            <div class="modal fade create_sector_modals" id="CreateIndustryGroupModal" tabindex="-1" aria-labelledby="CreateIndustryGroupModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title">Create a New Industry Group</h1>
                            <p>Add industries to merge them into a new industry group. You can add <br />more industries or delete industries later.</p>
                        </div>
                        
                        <div class="modal-body">
                            <div className=''>
                                <label>Industry Group Name</label>
                                <input type='text' className='form-control' placeholder='Enter Industry Name' />
                            </div>

                            <div className='mt-4'>
                                <label>Add Industries</label>
                                <TagPicker
                                    creatable
                                    data={TagData}
                                    className='custom_tagpicker'
                                    style={{ width: '100%', minHeight:48, borderRadius:4,border: '1px solid #FDC152',display:'flex',alignItems:'center'}}
                                    menuStyle={{ }}
                                    menuClassName='custom_dropdown_menu'
                                    placeholder='Add Industries to merge'
                                    onCreate={(value, item) => {
                                        console.log(value, item);
                                    }} />
                            </div>

                        <div class="btn__group mt-5" style={{}}>
                            <button type="button" class="btn_cancel" data-bs-dismiss="modal" style={{width:158}}>Cancel</button>
                            <button type="button" class="btn_delete" style={{gap:12,width:158}}><i className='icon-plus'></i>Create</button>
                        </div>                                               
                        </div>
                    
                    </div>
                </div>
            </div>

            <div class="modal fade create_sector_modals" id="CreateSectorGroupModal" tabindex="-1" aria-labelledby="CreateSectorGroupModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title">Create a New Sector Group</h1>
                            <p>Add sectors to merge them into a new sector group. You can add <br />more sectors or delete sectors later.</p>
                        </div>
                        
                        <div class="modal-body">
                            <div className=''>
                                <label>Sector Group Name</label>
                                <input type='text' className='form-control' placeholder='Enter Sector Name' />
                            </div>

                            <div className='mt-4'>
                                <label>Add Sectors</label>
                                {/* <input type='text' className='form-control' placeholder='Add Sectors to merge' /> */}
                                <TagPicker
                                    creatable
                                    data={TagData}
                                    className='custom_tagpicker'
                                    style={{ width: '100%', minHeight:48, borderRadius:4,border: '1px solid #FDC152',display:'flex',alignItems:'center'}}
                                    menuStyle={{ }}
                                    menuClassName='custom_dropdown_menu'
                                    placeholder='Add Sectors to merge'
                                    onCreate={(value, item) => {
                                        console.log(value, item);
                                    }} />
                            </div>

                        <div class="btn__group mt-5" style={{}}>
                            <button type="button" class="btn_cancel" data-bs-dismiss="modal" style={{width:158}}>Cancel</button>
                            <button type="button" class="btn_delete" style={{gap:12,width:158}}><i className='icon-plus'></i>Create</button>
                        </div>                                               
                        </div>
                    
                    </div>
                </div>
            </div> 

            <div class="modal fade create_sector_modals" id="EditSectorGroupModal" tabindex="-1" aria-labelledby="EditSectorGroupModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title">Edit Sector Group</h1>
                            <p>Add or delete sectors from an existing sector group.</p>
                        </div>
                        
                        <div class="modal-body">
                            <div className=''>
                                <label>Sector Group Name</label>
                                <input type='text' className='form-control' placeholder='Enter Sector Name' />
                            </div>

                            <div className='mt-4'>
                                <label>Add Sectors</label>
                                {/* <input type='text' className='form-control' placeholder='Add Sectors to merge' /> */}
                                <TagPicker
                                    creatable
                                    data={TagData}
                                    className='custom_tagpicker'
                                    style={{ width: '100%', minHeight:48, borderRadius:4,border: '1px solid #FDC152',display:'flex',alignItems:'center'}}
                                    menuStyle={{ }}
                                    menuClassName='custom_dropdown_menu'
                                    placeholder='Add Sectors to merge'
                                    onCreate={(value, item) => {
                                        console.log(value, item);
                                    }} />
                            </div>

                        <div class="btn__group mt-5" style={{}}>
                            <button type="button" class="btn_cancel" data-bs-dismiss="modal" style={{width:158}}>Cancel</button>
                            <button type="button" class="btn_delete" style={{width:158}}>Save Changes</button>
                        </div>                                               
                        </div>
                    
                    </div>
                </div>
            </div>            
        
        </>
    );
}

export default IndustrySector;
