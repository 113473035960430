import React, { useState, useEffect } from 'react';
import Logo from "../images/logo.svg";
import IconUpYellow from "../images/IconUpYellow.svg";
import IconUpGreen from "../images/IconUpGreen.svg";

import Sidebar from '../component/Sidebar';
import BunyodUser from "../images/Profile-Bunyod.jpg";
import { Chart } from 'primereact/chart';
import 'aos/dist/aos.css';
import AOS from 'aos';

function Dashboard() {
  const [tabName, setTabName] = useState('Dashboard');
  useEffect(() => {
    document.title = tabName;
  }, [tabName]);
  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [dashboardData, setDashboardData] = useState(null); // State to store API response
    const userid = localStorage.getItem("novauserid");

    const [userInfo, setUserInfo] = useState(null);
    const [reportInfo, setReportInfo] = useState(null);
    const [analysedReport, setAnalysedReport] = useState(null);
    const [systemStatistics, setSystemStatistics] = useState(null);
    const [companiesAnalyzing, setCompaniesAnalyzing] = useState([]);
    const [totalCompanies, setTotalCompanies] = useState("");
    const [graphData, setGraphData] = useState([]);

    useEffect(() => {
        const data = {
            labels: graphData.days,
            datasets: [
                {
                    label: 'Sales',
                    data: graphData.value, // Adjusted values
                    backgroundColor: [
                        'rgba(43, 55, 121, 1)',
                        'rgba(43, 55, 121, 1)',
                        'rgba(43, 55, 121, 1)',
                        'rgba(245, 185, 34, 1)',
                        'rgba(43, 55, 121, 1)',
                        'rgba(43, 55, 121, 1)',
                        'rgba(43, 55, 121, 1)'
                    ],
                    borderColor: [
                        'rgb(255, 159, 64)',
                        'rgb(75, 192, 192)',
                        'rgb(54, 162, 235)',
                        'rgb(153, 102, 255)'
                    ],
                    borderWidth: 0,
                    barThickness: 20,
                    borderRadius: { topLeft: 60, topRight: 60 } // Added top radius
                }
            ]
        };

        const options = {
            plugins: {
              tooltip: {
                  backgroundColor: 'rgba(43, 55, 121, 0.85)', // Change tooltip background color here
                  titleFont: {
                      family: 'Inter',
                      weight: 'bold',
                      size: 14
                  },
                  bodyFont: {
                      family: 'Inter',
                      weight: 'normal',
                      size: 12
                  },
                  displayColors: false, // Optionally hide the color box in the tooltip
                  padding: 10,
                  cornerRadius: 4
              },
                legend: {
                    display: false  
                },
                title: {
                  display: true,
                  text: 'Weekly Average', // Chart name
                  position: 'bottom', // Position at the bottom
                  color: '#878787',
                  font: {
                      family: 'Inter',
                      size: 12,
                      weight: 500
                  },
                  padding: {
                      top: 10,
                      bottom: 0
                  }
              }
            },
            scales: {
              x: {
                ticks: {
                  color: '#767676',
                  font: {
                    family: 'Inter',
                    weight:'bold',
                }
                },
                grid: {
                  drawBorder: false, // Remove x-axis stroke
                  display: false // Optionally, hide grid lines as well
              },
              
            },
                y: {
                    beginAtZero: true,
                    borderWidth:0,
                    ticks: {
                      color: '#767676',
                      
                      
                      font: {
                            family: 'Inter',
                            weight:'bold',
                            size: 12,
                        }
                  },
                  grid: {
                    drawBorder: false, // Remove y-axis stroke
                    color: '#F0F1F1', // Change y-axis grid color
                    borderColor: 'transparent', // Remove left border line
                  },
                  border: {
                      color: 'transparent' // Ensure the borderColor of y-axis is set to transparent
                  }
                }
            }
        };

        setChartData(data);
        setChartOptions(options);
    }, [graphData.days,graphData.value]);


    useEffect(() => {
      AOS.init();
    }, []);


    // Fetch API data when component loads
  useEffect(() => {

    fetch(`${apiEndPoint}/get_dashboard`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          user_id: userid,
        },
      }),
    })
    .then((response) => response.json())
    .then((data) => {
     // Check if the status is success
     if (data?.data?.status == 'success') {
      // Store each part of the response in separate state variables
      const user = data.data.user[0]; // First user object
      const report = data.data.report; // Report details
      const analysed = data.data.report_analysed; // Reports analysed in previous and current week
      const graph = data.data.graph; // Reports analysed in previous and current week
      const totalCompanies = data.data.company_currently_Analysing.total_company_analyzing;
      const companies = data.data.company_currently_Analysing?.companies_currently_analyzing || []; // Companies currently being analyzed
      const systemStatistics = data.data.system_statistics;


      //console.log(graph);
      // Update state variables
      setUserInfo(user);
      setReportInfo(report);
      setAnalysedReport(analysed);
      setTotalCompanies(totalCompanies);
      setCompaniesAnalyzing(companies);
      setGraphData(graph);
      setSystemStatistics(systemStatistics);
    }
    })
    .catch((error) => {
      console.error('Error fetching API:', error);
    });
  }, []);


  return (
    <>
    <Sidebar/>
    <div id='header'>
        <div className='logo'>
            <div className='logo_icon'>
                <img src={Logo} />
            </div>
            <div className='brand_name'>Dashboard</div>
        </div>
    </div>
    <div className="container-fluid content pb-5 pt-3" data-aos="fade-up" data-aos-duration="600">
      <h2 className='title_main_dashboard'>Welcome Back!</h2>
      <div className='dashboard_row mt-3'>
        <div className='dashboard_user_col'>
          <div className='dashboard_user_row'>
          {userInfo ? (
    <>
            <div className='img_duser'>
              <img src={userInfo.profile_img} />
            </div>

            <div className='details_duser'>
              <h5>{userInfo.firstname + ' ' + userInfo.lastname}</h5>
              <p>{userInfo.role}</p>
              {/* <h6>Last Active : 12 Oct 2023, <span>5:09 AM</span></h6> */}
            </div>
</>
          ) : (
    <p>Loading user info...</p>
  )}
          </div>
          <div className='dashboard_user_activity'>
          {reportInfo ? ( 
            <>
            <div className='activity_number_col'>
              <h5>{reportInfo.totalReports_today}</h5>
              <p>Day</p>
            </div>
            <div className='activity_number_col'>
              <h5>{reportInfo.totalReports_week}</h5>
              <p>Week</p>
            </div>
            <div className='activity_number_col'>
              <h5>{reportInfo.totalReports_month}</h5>
              <p>Month</p>
            </div>
            </>
        ) : (
          <></>
        )}
          </div>
         
        </div>

        <div className='dashboard_company_col'>
        {reportInfo ? ( 
          <>
          <div className='total_companies_row'>
              <p>Total Companies Analysed (Avg)</p>
              <div className='tc_number'>{reportInfo.total_companies_analyzed}</div>
          </div>

          <div className='dreport_review_row'>
              <div className='col_dreport_review'>
                <h5>{reportInfo.total_reports_published}</h5>
                <p>Reports <br />Published</p>
              </div>
              <div className='col_dreport_review'>
                <h5>{reportInfo.total_reports_review}</h5>
                <p>Reports <br />In Review</p>
              </div>
          </div>
          </>
          ) : (
            <></>
          )}
        </div>

        <div className='dashboard_report_col'>
          <div className='dashboard_report_col_row'>

          <div className='dreport__col'>
            <h5>Reports Analysed</h5>
            <div className='dashboard_report_row'>
                
            
            {analysedReport ? ( 
            <>
                <div className='dreport_analylsed'>
                              <p>This Week</p>
                              <h4>{analysedReport.total_reports_current_week} <span>Reports</span></h4>
                </div>

                <div className='dreport_analylsed'>
                              <p>Last Week</p>
                              <h4>{analysedReport.total_reports_last_week} <span>Reports</span></h4>
                </div>
            </>
            ) : (
              <></>
            )}


            </div>
          </div>

          <div className='daily_avrg_graph'>
            <div className='daily_avrg_graph_row'>
                <div className='graph_daily_areport'>
                  <p>Daily Average Reports</p>
                  <h5>{graphData.DailyAvgReport}</h5>
                </div>

                <div className='graph_analyed_week'>
                  <p>{graphData.AnalysedthisWeek} reports</p>
                  <h6>Analysed this week</h6>
                </div>
            </div>

            <div className="">
              <Chart type="bar" data={chartData} options={chartOptions} />
            </div>
          </div>

          </div>
        </div>
      </div>

      <div className='dashboard_row mt-3'>
        <div className='dcompany_table'>
          <div className='dcompany_table_title mb-3'>
            <h5>Companies Currently Analysing</h5>
            <p>Total Companies : {totalCompanies}</p>
          </div>

          <div className='company_table_list table_dashboard_company table-responsive' style={{maxHeight:320, overflow:'auto'}}>
            <table className='table'>
              <thead>
                <tr>
                  <th>Ticker and Company name</th>
                  <th>Country</th>
                  <th>Exch</th>
                  <th style={{textAlign:"center"}}>Compliance</th>
                </tr>
              </thead>
              <tbody>
              
              {companiesAnalyzing.length > 0 ? (
                  companiesAnalyzing.map((item, index) => (
                                <tr>
                                  <td>
                                    <div className='company_name_td'>
                                      <span className='company_lable'>{item.company_symbol}</span>
                                      {item.name}
                                    </div>
                                  </td>
                                  <td>{item.country}</td>
                                  <td>{item.exchange}</td>
                                  <td style={{ textAlign: "center" }}><span className={ item.status == 'NCMPT' ? 'not_halal_tag tag_td' : item.status == 'CMPT' ? 'halal_tag tag_td' : item.status == 'DBTF' ? 'doughtful_tag tag_td' : item.status == 'UNCV' ? 'uncover_tag tag_td' : 'tag_td' }>{item.status}</span></td>                  
                                </tr>
                ))
                ) : (
                  <tr>
                    <td colSpan="4" style={{ textAlign: "center" }}>
                      No data available
                    </td>
                  </tr>
                )}
                
              </tbody>
            </table>
          </div>
        </div>

        <div className='dsystem_statics'>
          <h5>System Statistics</h5>
          <p>This contains all the information related to the Nova system</p>
          <div className='row_dsystem_statics mt-3'>

          {systemStatistics ? ( 
<>
            <div className='dsystem_statics_col'>
              <h5>Total Tickers</h5>
              <p>Only Main Tickers</p>
              <h4>{systemStatistics.total_tickers}</h4>
            </div>

            <div className='dsystem_statics_col'>
              <h5>Total Companies</h5>
              <p>Main tickers + Subtickers</p>
              <h4>{systemStatistics.total_company}</h4>
            </div>

            <div className='dsystem_statics_col'>
              <h5>Total Exchanges</h5>
              <p><img src={IconUpYellow} />{systemStatistics.exchange_compare_to_last_month} compared to last month</p>
              <h4>{systemStatistics.total_exchange}</h4>
            </div>

            <div className='dsystem_statics_col'>
              <h5>Total Published</h5>
              <p><img src={IconUpGreen} />{systemStatistics.publish_compare_to_last_month}% more compared to last month</p>
              <h4>{systemStatistics.total_publish}</h4>
            </div>
            </>
             ) : (
              <></>
            )}

          </div>
        </div>
      </div>
    </div>
    </>
  );
}

export default Dashboard;
