import React, { useState,useEffect, useCallback } from 'react';
import Logo from "../images/logo.svg";
import Sidebar from '../component/Sidebar';
import { SelectPicker, Placeholder, Popover, Whisper, Button } from 'rsuite';
import 'rsuite/SelectPicker/styles/index.css';
import { Link  } from "react-router-dom";
import 'aos/dist/aos.css';
import AOS from 'aos';
import debounce from 'lodash.debounce';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import 'rsuite/dist/rsuite.min.css';

const StyledCell = styled('span')(({ theme }) => ({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  }));

function ETFS1() {

    const [tabName, setTabName] = useState('ETFs1');

    useEffect(() => {
      document.title = tabName;
    }, [tabName]);
    
const orderData = ['ASC', 'DESC'].map(
    item => ({ label: item, value: item })
);

useEffect(() => {
    AOS.init();
  }, []);

  const [isLoading, setLoading] = useState(true); // State to manage loading state
  const apiEndPoint = process.env.REACT_APP_ETF_API_ENDPOINT;
  const apiEndPoint1 = process.env.REACT_APP_API_ENDPOINT;
  const frontEndLink = process.env.REACT_APP_MUSAFFA_LINK;
  const roleName = localStorage.getItem("novaRolename");
  const merlinJuniorAnalyst = process.env.REACT_APP_MERLIN_JUNIOR_ANALYST;

  const [reportData, setReportData] = useState([]);
  const [reportCount, setReportCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [countryCode, setCountryCode] = useState("");
  const [complianceId, setComplianceId] = useState("");
  const [orderby, setOrderBy] = useState("");
  const [statusId, setStatusId] = useState("");
  const [searchInput, setSearchInput] = useState("");

  const [countryData, setCountryData] = useState([]);
  const [complianceData, setComplianceData] = useState([]);
  const [statusData, setStatusData] = useState([]);

  const [selectedQuarter, setSelectedQuarter] = useState("");

  const userid = localStorage.getItem("novauserid");
  const progressStatus = process.env.REACT_APP_TASK_MANAGER_PROGRESS_Status;


  const [errorMessage, setErrorMessage] = useState('');
  const [messageType, setMessageType] = useState(null); // success, error, info
  const [assetClassData, setAssetClassData] = useState([]);
  const [searchAsset, setSearchAsset] = useState("");

    const fetchGlobalReport = async () => {
      setLoading(true);
  
    try {
        const apiUrl = `${apiEndPoint}/etf_company_profile`;
        const requestData = {
          data: {
            user_id: userid,
            limit: "20",
            page: currentPage.toString(),
            q:searchInput,
            asset_class:searchAsset,
            order: orderby|| "DESC",
            country: countryCode || "",
            status: complianceId
          }
        };
        console.log(JSON.stringify(requestData));
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestData)
        });
  
        const jsonData = await response.json();
        const { status, message, count, data } = jsonData.data;
        if (status == "success") {
          setLoading(false);
          setReportCount(count);
          console.log('RES :'+ searchInput + ' : '+ JSON.stringify(data));
          setReportData(data);
        } else {
          console.error("Error: ", message);
        }
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };
  
    const debouncedFetchGlobalReport = useCallback(
      debounce(() => {
        fetchGlobalReport();
      }, 500),
      [searchInput] // Only debounce on search input changes
    );
  
    useEffect(() => {
      debouncedFetchGlobalReport();
      return () => {
        debouncedFetchGlobalReport.cancel();
      };
    }, [searchInput]);
  
    useEffect(() => {
      fetchGlobalReport();
    }, [currentPage, countryCode, complianceId, statusId,searchAsset,orderby]); // Regular effect for other dependencies

    
    const handlePageChange = (page) => {
      setCurrentPage(page);
      if (page >= startPage + 5) {
        setStartPage(page - 4);
      } else if (page < startPage) {
        setStartPage(page);
      }
    };
  
    const totalPages = Math.ceil(reportCount / 20);
    const endPage = Math.min(startPage + 4, totalPages);

    const handleSearch = (event) => {
      console.log(event.target.value);
    
      setSearchInput(event.target.value);
      setCurrentPage(1); // Reset to the first page for new search
    };


    const renderPagination = () => {
      const pages = [];
      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <li key={i} className={`page-item ${i === currentPage ? 'active' : ''}`}>
            <a className="page-link" href="#" onClick={() => handlePageChange(i)}>{i}</a>
          </li>
        );
      }
    
      return (
        <nav aria-label="..." id='pagination_row'>
          <ul className="pagination">
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <a className="page-link" href="#" onClick={() => handlePageChange(1)}>
                <i className='fa fa-angle-double-left'></i>
              </a>
            </li>
            <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
              <a className="page-link" href="#" onClick={() => handlePageChange(currentPage - 1)}>
                <i className='icon-prev'></i>
              </a>
            </li>
            {pages}
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
              <a className="page-link" href="#" onClick={() => handlePageChange(currentPage + 1)}>
                <i className='icon-next'></i>
              </a>
            </li>
            <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
              <a className="page-link" href="#" onClick={() => handlePageChange(totalPages)}>
                <i className='fa fa-angle-double-right'></i>
              </a>
            </li>
          </ul>
        </nav>
      );
    };

    const handleClearFilters = () => {
      setCurrentPage(1);
      setSearchInput("");
      setSearchAsset("");
      setCountryCode("");
      setStatusId("");
      setComplianceId("");
      setOrderBy("");
    };

    
    const handleAssetClass = (value) => {
        setSearchAsset(value);
        setCurrentPage(1); // Reset to the first page for new search
      };


    useEffect(() => {
        // Fetch the data from API
        const fetchAssetClasses = async () => {
          try {
            const response = await fetch(`${apiEndPoint}/getAssetClassDropdown`);
            const result = await response.json();
    
            if (result.data.status === 'success') {
              // Transform the data to fit SelectPicker's format
              const transformedData = result.data.data.map((item) => ({
                label: item.AssetClass,
                value: item.AssetClass,
              }));
              
              setAssetClassData(transformedData);
            }
          } catch (error) {
            console.error('Error fetching asset classes:', error);
          }
        };
    
        fetchAssetClasses();
      }, []);


      const handleCountryChange = (value) => {
        setCurrentPage(1);
        setCountryCode(value);
      };


      useEffect(() => {
        const fetchCountryList = async () => {
          const url = `${apiEndPoint}/get_country_list_etf`;
          const payload = {
            data: {
              user_id: userid // Replace with the actual user_id if dynamic
            }
          };
      
          try {
            const response = await fetch(url, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(payload),
            });
      
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
      
            const responseData = await response.json();
            const data = responseData.data; // Flatten the response
    
            const formattedCountryData = data.data.map((country) => ({
              label: country.country_name,
              value: country.country_code,
            }));
    
            setCountryData(formattedCountryData);
      
          } catch (error) {
            setErrorMessage(error.message);
            setMessageType('error');
            return false;
          }
        };
      
        fetchCountryList();
      }, []);


      const handleStatusChange = (value) => {
        setStatusId(value);
      };

      //Status List
  useEffect(() => {
    const fetchStatusList = async () => {
      const url = `${apiEndPoint1}/get_status`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response

        const formattedStatusData = data.data.map((status) => ({
          label: status.name,
          value: status.id,
        }));

        setStatusData(formattedStatusData);
  
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchStatusList();
  }, []);


  const handleComplianceChange = (value) => {
    setComplianceId(value);
  };

  const handleorderChange = (value) => {
    setOrderBy(value);
  };

  // Compliance List
  useEffect(() => {
    const fetchComplianceStatusList = async () => {
      const url = `${apiEndPoint1}/get_compliance_status`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response

        const formattedComplianceStatusData = data.data.map((complaince) => ({
          label: complaince.name,
          value: complaince.id,
        }));

        setComplianceData(formattedComplianceStatusData);
  
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchComplianceStatusList();
  }, []);


//   NEW DATA TABLE
const [anchorEl, setAnchorEl] = useState(null); // Element to anchor the popover
const [deleteRowId, setDeleteRowId] = useState(null); // Row ID to be deleted
const [showThankYou, setShowThankYou] = useState(false); // State to control Thank You message visibility

const handleEdit = (row) => {
  console.log('Edit clicked for row:', row);
};

const handleDelete = (row) => {
  console.log('Delete clicked for row:', row);
};

const handleDeleteClick = (event, row) => {
  setAnchorEl(event.currentTarget); // Set the anchor element for the popover
  setDeleteRowId(row.id); // Set the row ID to be deleted
  setShowThankYou(false); // Hide the Thank You message when opening popover
};

const handleClosePopover = () => {
  setAnchorEl(null);
  setDeleteRowId(null);
  setShowThankYou(false); // Hide the Thank You message when closing popover
};

const handleConfirmDelete = () => {
  const row = rows.find((r) => r.id === deleteRowId);
  if (row) {
    handleDelete(row);
  }
  setShowThankYou(true); // Show the Thank You message after confirming deletion
  setTimeout(() => {
    handleClosePopover(); // Close the popover after a delay
  }, 4000); // Adjust the delay time as needed
};

const columns = [
  
 
  {
    field: 'Published',
    headerName: 'Published',
    width: 500,
    flex: 1,
    editable: false,
    autoWidth: true,
    renderCell: (params) => (
    <Tooltip title={params.value || ''} placement="top" arrow>
      <StyledCell>{params.value}</StyledCell>
    </Tooltip>
  ),
  },
  {
    field: 'Symbol',
    headerName: 'Symbol',
    width: 140,
    flex: 1,
    editable: true,
    autoWidth: true,
    renderCell: (params) => (
    <Tooltip title={params.value || ''} placement="top" arrow>
      <StyledCell>{params.value}</StyledCell>
    </Tooltip>
  ),
  },
  {
    field: 'ISIN',
    headerName: 'ISIN',
    flex: 1,
    width: 90,
    editable: true,
    autoWidth: true,
    renderCell: (params) => (
    <Tooltip title={params.value || ''} placement="top" arrow>
      <StyledCell>{params.value}</StyledCell>
    </Tooltip>
  ),
  },
  {
    field: 'Name',
    headerName: 'ETF Name',
    width: 90,
    flex: 1,
    editable: true,
    autoWidth: true,
    renderCell: (params) => (
    <Tooltip title={params.value || ''} placement="top" arrow>
      <StyledCell>{params.value}</StyledCell>
    </Tooltip>
  ),
  },
  {
    field: 'Market',
    headerName: 'Market',
    width: 110,
    flex: 1,
    editable: true,
    autoWidth: true,
    renderCell: (params) => (
    <Tooltip title={params.value || ''} placement="top" arrow>
      <StyledCell>{params.value}</StyledCell>
    </Tooltip>
  ),
  },
  {
    field: 'Holdings',
    headerName: 'Holdings',
    flex: 1,
    width: 110,
    editable: true,
    autoWidth: true,
    renderCell: (params) => (
    <Tooltip title={params.value || ''} placement="top" arrow>
      <StyledCell>{params.value}</StyledCell>
    </Tooltip>
  ),
  },
  {
    field: 'Uncovered',
    headerName: 'Uncovered',
    flex: 1,
    width: 110,
    editable: true,
    autoWidth: true,
    renderCell: (params) => (
    <Tooltip title={params.value || ''} placement="top" arrow>
      <StyledCell>{params.value}</StyledCell>
    </Tooltip>
  ),
  },
  {
    field: 'Compliance',
    headerName: 'Compliance',
    flex: 1,
    width: 110,
    editable: true,
    autoWidth: true,
    renderCell: (params) => (
    <Tooltip title={params.value || ''} placement="top" arrow>
      <StyledCell>{params.value}</StyledCell>
    </Tooltip>
  ),
  },
  {
    field: 'AssetClass',
    headerName: 'Asset Class',
    flex: 1,
    width: 110,
    editable: true,
    autoWidth: true,
    renderCell: (params) => (
    <Tooltip title={params.value || ''} placement="top" arrow>
      <StyledCell>{params.value}</StyledCell>
    </Tooltip>
  ),
  },
  {
    field: 'NCMPTRatio',
    headerName: 'NCMPT Ratio',
    flex: 1,
    width: 110,
    editable: true,
    autoWidth: true,
    renderCell: (params) => (
    <Tooltip title={params.value || ''} placement="top" arrow>
      <StyledCell>{params.value}</StyledCell>
    </Tooltip>
  ),
  },
  {
    field: 'CMPTRatio',
    headerName: 'CMPT ratio',
    flex: 1,
    width: 110,
    editable: true,
    autoWidth: true,
    renderCell: (params) => (
    <Tooltip title={params.value || ''} placement="top" arrow>
      <StyledCell>{params.value}</StyledCell>
    </Tooltip>
  ),
  },
  {
    field: 'DBTFRatio',
    headerName: 'DBTF ratio',
    flex: 1,
    width: 110,
    editable: true,
    autoWidth: true,
    renderCell: (params) => (
    <Tooltip title={params.value || ''} placement="top" arrow>
      <StyledCell>{params.value}</StyledCell>
    </Tooltip>
  ),
  },
  {
    field: 'InterestRatio',
    headerName: 'Interest ratio',
    flex: 1,
    width: 110,
    editable: true,
    autoWidth: true,
    renderCell: (params) => (
    <Tooltip title={params.value || ''} placement="top" arrow>
      <StyledCell>{params.value}</StyledCell>
    </Tooltip>
  ),
  },
  {
    field: 'DebtRatio',
    headerName: 'Interest ratio',
    flex: 1,
    width: 110,
    editable: true,
    autoWidth: true,
    renderCell: (params) => (
    <Tooltip title={params.value || ''} placement="top" arrow>
      <StyledCell>{params.value}</StyledCell>
    </Tooltip>
  ),
  },
  {
    field: 'Action',
    headerName: 'Action',
    flex: 1,
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 160,
    autoWidth: true,
    renderCell: (params) => (
      <>
        <Tooltip title={'Edit'} placement="top" arrow>
          <IconButton
            onClick={() => handleEdit(params.row)}
            aria-label="edit"
            className='Icon_action_edit'
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
         
           
          <Whisper
            placement="auto"
            trigger="click"
            // open={Boolean(anchorEl)}
            // anchorEl={anchorEl}
            // onClose={handleClosePopover}

            open={deleteRowId === params.row.id}
            anchorEl={anchorEl}
            onClose={handleClosePopover}

            // open={Boolean(anchorEl)}
            // onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            
              speaker={
                <Popover className="deletePopoverWrap">
                  <div className="deletePopover text-center">
                    {showThankYou ? (
                      <div class="thankyou_content">         
                        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                          <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                          <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                        </svg>
                        <h4>Thank You!</h4>
                        <p>The row has been deleted.</p>
                      </div>
                    ) : (
                      <>
                        <h5>Confirm Deletion</h5>
                        <p>Are you sure you want to delete this row?</p>

                        <div className='btn__group'>
                          <a href="#" className="btn__assign" onClick={handleConfirmDelete}>Confirm</a>
                          <a href="#" className="btn__cancel" onClick={handleClosePopover}>
                            Cancel
                          </a>
                        </div>
                      </>
                    )}
                  </div>
                </Popover>
              }
            >
              <Tooltip title={'Delete'} placement="top" arrow>
                <StyledCell>
                <Button
                onClick={(event) => handleDeleteClick(event, params.row)}
                aria-label="delete"
                className='Icon_action_delete'
              >
                <DeleteIcon />
              </Button>
                </StyledCell>
              </Tooltip>
              
            </Whisper>                                
      </>
    ),
  },
];

const rows = [
    {id: 1, Published: 'Yes', Symbol: 'ZT1', ISIN: 'CA98979N1006', Name: 'Zonetail Inc', Market: 'CA - NEOE', Holdings: '50%', Uncovered: 'No', Compliance: 'NCMPT', AssetClass: 'Equity', NCMPTRatio: '3.5%', CMPTRatio: '2.1%', DBTFRatio: '1.0%', InterestRatio: '2.0%', DebtRatio: '4.5%', Action: 'NEW'},
    {id: 2, Published: 'No', Symbol: 'SNBL', ISIN: 'JP3379950003', Name: 'Shin Nippon Biomedical', Market: 'JP - XDUS', Holdings: '70%', Uncovered: 'Yes', Compliance: 'CMPT', AssetClass: 'Fixed Income', NCMPTRatio: '4.0%', CMPTRatio: '3.2%', DBTFRatio: '0.8%', InterestRatio: '1.5%', DebtRatio: '3.8%', Action: 'NEW'},
    {id: 3, Published: 'Yes', Symbol: 'ZT2', ISIN: 'CA98979N1006', Name: 'Zonetail Inc', Market: 'CA - NEOE', Holdings: '60%', Uncovered: 'No', Compliance: 'NCMPT', AssetClass: 'Equity', NCMPTRatio: '3.7%', CMPTRatio: '2.5%', DBTFRatio: '1.2%', InterestRatio: '2.3%', DebtRatio: '4.8%', Action: 'NEW'},
    {id: 4, Published: 'No', Symbol: 'SNB2', ISIN: 'JP3379950003', Name: 'Shin Nippon Biomedical', Market: 'JP - XDUS', Holdings: '65%', Uncovered: 'Yes', Compliance: 'CMPT', AssetClass: 'Fixed Income', NCMPTRatio: '3.9%', CMPTRatio: '3.0%', DBTFRatio: '0.9%', InterestRatio: '1.6%', DebtRatio: '3.7%', Action: 'NEW'},
    {id: 5, Published: 'Yes', Symbol: 'ZT3', ISIN: 'CA98979N1006', Name: 'Zonetail Inc', Market: 'CA - NEOE', Holdings: '55%', Uncovered: 'No', Compliance: 'NCMPT', AssetClass: 'Equity', NCMPTRatio: '3.6%', CMPTRatio: '2.3%', DBTFRatio: '1.1%', InterestRatio: '2.1%', DebtRatio: '4.6%', Action: 'NEW'},
    {id: 6, Published: 'No', Symbol: 'SNB3', ISIN: 'JP3379950003', Name: 'Shin Nippon Biomedical', Market: 'JP - XDUS', Holdings: '68%', Uncovered: 'Yes', Compliance: 'CMPT', AssetClass: 'Fixed Income', NCMPTRatio: '3.8%', CMPTRatio: '2.9%', DBTFRatio: '0.7%', InterestRatio: '1.4%', DebtRatio: '3.6%', Action: 'NEW'},
    {id: 7, Published: 'Yes', Symbol: 'ZT4', ISIN: 'CA98979N1006', Name: 'Zonetail Inc', Market: 'CA - NEOE', Holdings: '58%', Uncovered: 'No', Compliance: 'NCMPT', AssetClass: 'Equity', NCMPTRatio: '3.4%', CMPTRatio: '2.0%', DBTFRatio: '1.3%', InterestRatio: '2.2%', DebtRatio: '4.7%', Action: 'NEW'},
    {id: 8, Published: 'No', Symbol: 'SNB4', ISIN: 'JP3379950003', Name: 'Shin Nippon Biomedical', Market: 'JP - XDUS', Holdings: '72%', Uncovered: 'Yes', Compliance: 'CMPT', AssetClass: 'Fixed Income', NCMPTRatio: '3.7%', CMPTRatio: '2.8%', DBTFRatio: '0.6%', InterestRatio: '1.3%', DebtRatio: '3.5%', Action: 'NEW'},
    {id: 9, Published: 'Yes', Symbol: 'ZT5', ISIN: 'CA98979N1006', Name: 'Zonetail Inc', Market: 'CA - NEOE', Holdings: '52%', Uncovered: 'No', Compliance: 'NCMPT', AssetClass: 'Equity', NCMPTRatio: '3.3%', CMPTRatio: '2.2%', DBTFRatio: '1.4%', InterestRatio: '2.4%', DebtRatio: '4.9%', Action: 'NEW'},
    {id: 10, Published: 'No', Symbol: 'SNB5', ISIN: 'JP3379950003', Name: 'Shin Nippon Biomedical', Market: 'JP - XDUS', Holdings: '66%', Uncovered: 'Yes', Compliance: 'CMPT', AssetClass: 'Fixed Income', NCMPTRatio: '3.9%', CMPTRatio: '3.1%', DBTFRatio: '0.5%', InterestRatio: '1.2%', DebtRatio: '3.4%', Action: 'NEW'},
    {id: 11, Published: 'Yes', Symbol: 'ZT6', ISIN: 'CA98979N1006', Name: 'Zonetail Inc', Market: 'CA - NEOE', Holdings: '53%', Uncovered: 'No', Compliance: 'NCMPT', AssetClass: 'Equity', NCMPTRatio: '3.5%', CMPTRatio: '2.4%', DBTFRatio: '1.5%', InterestRatio: '2.5%', DebtRatio: '5.0%', Action: 'NEW'},
    {id: 12, Published: 'No', Symbol: 'SNB6', ISIN: 'JP3379950003', Name: 'Shin Nippon Biomedical', Market: 'JP - XDUS', Holdings: '67%', Uncovered: 'Yes', Compliance: 'CMPT', AssetClass: 'Fixed Income', NCMPTRatio: '3.8%', CMPTRatio: '2.7%', DBTFRatio: '0.4%', InterestRatio: '1.1%', DebtRatio: '3.3%', Action: 'NEW'}
  ];
  return (
    <>
    <Sidebar/>
    <div id='header'>
        <div className='logo'>
            <div className='logo_icon'>
                <img src={Logo} />
            </div>
            <div className='brand_name'>ETFS</div>
        </div>

        <div className='header_table_filter'>
            <div className='searchfield'>
                <button className='btn_search'><i className='icon-search2'></i></button>
                <input type='text' className='filter_searchfield' placeholder='Search' onChange={handleSearch}   value={searchInput} />
            </div>

            <div className='dropdown_style filter_by_country'>
            <SelectPicker
                    data={assetClassData}
                    searchable={false}
                    style={{ width:180 }}
                    placeholder="All Asset classes"
                    onChange={handleAssetClass} 
                    value={searchAsset} // Bind searchAsset to the value
                    />
            </div>

            <div className="dropdown_style filter_by_country">
            <SelectPicker
              data={countryData}
              searchable={false}
              style={{width:170}}
              placeholder="Filter by Country"
              onChange={handleCountryChange}
              value={countryCode}
            />
          </div>

          <div className='dropdown_col_filter dropdown__select'>
            <SelectPicker
              data={statusData}
              searchable={false}
              style={{ width: 180 }}
              placeholder="Both Status"
              onChange={handleStatusChange}
              value={statusId}
            />
          </div>

          <div className='dropdown_col_filter dropdown__select'>
            <SelectPicker
              data={complianceData}
              searchable={false}
              style={{ width: 180 }}
              placeholder="All Compliance"
              onChange={handleComplianceChange}
              value={complianceId}
            />
          </div>

          <div className='dropdown_style'>
                <SelectPicker
                    data={orderData}
                    searchable={false}
                    placeholder="Order By"
                    style={{ width: 80 }}
                    onChange={handleorderChange}
                    value={orderby}
                    />
            </div>

            {/* <div className='dropdown_style'>
                <SelectPicker
                    data={data}
                    searchable={false}
                    style={{ }}
                    placeholder="All Compliance statuses"
                    />
            </div>
            <div className='dropdown_style'>
                <SelectPicker
                    data={data}
                    searchable={false}
                    style={{ }}
                    placeholder="Both Publish Statuses"
                    />
            </div> */}

            <a href='javascript:void(0)' className="btn_clear_filter"  style={{textDecoration:"none"}} onClick={handleClearFilters}>Clear Filters</a>
        </div>
    </div>
    <div className="container-fluid content pb-5">
      <div className='company_table_list' data-aos="fade-up" data-aos-duration="800">

      {isLoading && ( <Placeholder.Grid rows={20} columns={9} active style={{paddingLeft:20, paddingRight:20, paddingTop:12}} /> )}

{/* Table */}
{!isLoading && (
    <>
        <Box sx={{}}>
          <DataGrid
            rows={rows}
            columns={columns.map(column => ({ ...column, editable: false }))}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            rowsPerPageOptions={[10, 20, 30]}
            checkboxSelection
            disableRowSelectionOnClick
          />
        </Box>
        </>
)}      
      </div>
    </div>
    </>
  );
}

export default ETFS1;
