import React, { useState, useEffect } from 'react';
import Logo from "../images/logo.svg";
import { SelectPicker } from 'rsuite';
import 'rsuite/SelectPicker/styles/index.css';
import Sidebar from '../component/Sidebar';
import 'aos/dist/aos.css';
import AOS from 'aos';

function MarketsSettings() {

    const [tabName, setTabName] = useState('Markets Settings');

    useEffect(() => {
      document.title = tabName;
    }, [tabName]);
    
    const [copied, setCopied] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
   
    const options = ['Fixed Income', 'United State', 'Australia', 'Canada', 'Israel', 'Germany'].map(
        item => ({ label: item, value: item })
    );

    const [selectedOption, setSelectedOption] = useState(null);
    // handle onChange event of the dropdown
    const handleChange = e => {
        setSelectedOption(e);
    }
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        AOS.init();
      }, []);

    return (
        <>
            <Sidebar />

            <div id='header'>
                <div className='logo'>
                    <div className='logo_icon'>
                        <img src={Logo} />
                    </div>
                    <div className='brand_name'>Markets Settings</div>
                </div>

                <div className='setting_table_filter_row'>                   
                    <div className='dropdown_col_filter dropdown_select'>
                            <SelectPicker
                                data={options}
                                searchable={false}
                                style={{ }}
                                placeholder="All Countries"
                                />
                    </div>
                </div>
            </div>
            <div className="container-fluid content pb-5">
                <div className='company_table_list' data-aos="fade-up" data-aos-duration="800">                    
                    <div className='table-responsive' style={{paddingBottom:0,}}>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Market</th>
                                    <th style={{textAlign:'center'}}>Available Stocks</th>
                                    <th style={{textAlign:'center'}}>Available ETFs</th>
                                    <th style={{textAlign:'center'}}>Enable/Disable</th>
                                    <th style={{textAlign:'center'}}>ETF</th>
                                    <th style={{textAlign:'center'}}>Stock</th>
                                    <th style={{textAlign:'center'}}>ETFs Covered</th>
                                    <th style={{textAlign:'center'}}>Stock Covered</th>
                                    <th style={{textAlign:'center'}}>Profile Available</th>
                                    <th style={{textAlign:'center'}}>Sort Order</th>
                                    <th style={{textAlign:'center'}}>Save</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        United States
                                    </td>
                                    <td style={{textAlign:'center'}}>
                                        10121
                                    </td>
                                    <td style={{textAlign:'center'}}>3806</td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{textAlign:'center'}}><input type='number' className='table_number_field' placeholder='0'>
                                    </input></td>
                                    <td style={{textAlign:'center'}}>

                                        <button class="savebtn" style={{margin:'auto'}}>Save</button>
                                    </td>
                                </tr>

                                <tr>
                                    <td>China</td>
                                    <td style={{textAlign:'center'}}>445</td>
                                    <td style={{textAlign:'center'}}>0</td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{textAlign:'center'}}><input type='number' className='table_number_field' placeholder='0'>
                                    </input></td>
                                    <td>

                                        <button class="savebtn" style={{margin:'auto'}}>Save</button>
                                    </td>
                                </tr>

                                <tr>
                                    <td>India</td>
                                    <td style={{textAlign:'center'}}>489</td>
                                    <td style={{textAlign:'center'}}>0</td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{textAlign:'center'}}><input type='number' className='table_number_field' placeholder='0'>
                                    </input></td>
                                    <td>

                                        <button class="savebtn" style={{margin:'auto'}}>Save</button>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        Germany
                                    </td>
                                    <td style={{textAlign:'center'}}>2256</td>
                                    <td style={{textAlign:'center'}}>1</td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{textAlign:'center'}}><input type='number' className='table_number_field' placeholder='0'>
                                    </input></td>
                                    <td>

                                        <button class="savebtn" style={{margin:'auto'}}>Save</button>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        United States
                                    </td>

                                    <td style={{textAlign:'center'}}>956</td>
                                    <td style={{textAlign:'center'}}>2</td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{textAlign:'center'}}><input type='number' className='table_number_field' placeholder='0'>
                                    </input></td>
                                    <td>

                                        <button class="savebtn" style={{margin:'auto'}}>Save</button>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        Republic of Korea
                                    </td>

                                    <td style={{textAlign:'center'}}>3368</td>
                                    <td style={{textAlign:'center'}}>0</td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{textAlign:'center'}}><input type='number' className='table_number_field' placeholder='0'>
                                    </input></td>
                                    <td>

                                        <button class="savebtn" style={{margin:'auto'}}>Save</button>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        Japan
                                    </td>

                                    <td style={{textAlign:'center'}}>289</td>
                                    <td style={{textAlign:'center'}}>4</td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{textAlign:'center'}}><input type='number' className='table_number_field' placeholder='0'>
                                    </input></td>
                                    <td>

                                        <button class="savebtn" style={{margin:'auto'}}>Save</button>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        Canada
                                    </td>

                                    <td style={{textAlign:'center'}}>1155</td>
                                    <td style={{textAlign:'center'}}>0</td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{textAlign:'center'}}><input type='number' className='table_number_field' placeholder='0'>
                                    </input></td>
                                    <td>

                                        <button class="savebtn" style={{margin:'auto'}}>Save</button>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        Taiwan, Province of...
                                    </td>

                                    <td style={{textAlign:'center'}}>8976</td>
                                    <td style={{textAlign:'center'}}>3</td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{textAlign:'center'}}><input type='number' className='table_number_field' placeholder='0'>
                                    </input></td>
                                    <td>

                                        <button class="savebtn" style={{margin:'auto'}}>Save</button>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        Australia
                                    </td>
                                    <td style={{textAlign:'center'}}>37893</td>
                                    <td style={{textAlign:'center'}}>109</td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{textAlign:'center'}}><input type='number' className='table_number_field' placeholder='0'>
                                    </input></td>
                                    <td>

                                        <button class="savebtn" style={{margin:'auto'}}>Save</button>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        United Kingdom
                                    </td>

                                    <td style={{textAlign:'center'}}>637</td>
                                    <td style={{textAlign:'center'}}>0</td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{textAlign:'center'}}><input type='number' className='table_number_field' placeholder='0'>
                                    </input></td>
                                    <td>

                                        <button class="savebtn" style={{margin:'auto'}}>Save</button>
                                    </td>
                                </tr>

                                <tr>
                                    <td>
                                        Viet Nam
                                    </td>

                                    <td style={{textAlign:'center'}}>9897</td>
                                    <td style={{textAlign:'center'}}>0</td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{textAlign:'center'}}><input type='number' className='table_number_field' placeholder='0'>
                                    </input></td>
                                    <td>

                                        <button class="savebtn" style={{margin:'auto'}}>Save</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Hong Kong
                                    </td>

                                    <td style={{textAlign:'center'}}>12382</td>
                                    <td style={{textAlign:'center'}}>1</td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{textAlign:'center'}}><input type='number' className='table_number_field' placeholder='0'>
                                    </input></td>
                                    <td>

                                        <button class="savebtn" style={{margin:'auto'}}>Save</button>
                                    </td>
                                </tr><tr>
                                    <td>
                                        France
                                    </td>

                                    <td style={{textAlign:'center'}}>584</td>
                                    <td style={{textAlign:'center'}}>9</td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{textAlign:'center'}}><input type='number' className='table_number_field' placeholder='0'>
                                    </input></td>
                                    <td>

                                        <button class="savebtn" style={{margin:'auto'}}>Save</button>
                                    </td>
                                </tr><tr>
                                    <td>
                                        Italy
                                    </td>

                                    <td style={{textAlign:'center'}}>3022</td>
                                    <td style={{textAlign:'center'}}>1</td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{textAlign:'center'}}><input type='number' className='table_number_field' placeholder='0'>
                                    </input></td>
                                    <td>

                                        <button class="savebtn" style={{margin:'auto'}}>Save</button>
                                    </td>
                                </tr><tr>
                                    <td>
                                        Turkey
                                    </td>

                                    <td style={{textAlign:'center'}}>890</td>
                                    <td style={{textAlign:'center'}}>0</td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{textAlign:'center'}}><input type='number' className='table_number_field' placeholder='0'>
                                    </input></td>
                                    <td>

                                        <button class="savebtn" style={{margin:'auto'}}>Save</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Israel
                                    </td>

                                    <td style={{textAlign:'center'}}>1890</td>
                                    <td style={{textAlign:'center'}}>0</td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{textAlign:'center'}}><input type='number' className='table_number_field' placeholder='0'>
                                    </input></td>
                                    <td>

                                        <button class="savebtn" style={{margin:'auto'}}>Save</button>
                                    </td>
                                </tr><tr>
                                    <td>
                                        Singapore
                                    </td>

                                    <td style={{textAlign:'center'}}>3789</td>
                                    <td style={{textAlign:'center'}}>12</td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{textAlign:'center'}}><input type='number' className='table_number_field' placeholder='0'>
                                    </input></td>
                                    <td>

                                        <button class="savebtn" style={{margin:'auto'}}>Save</button>
                                    </td>
                                </tr><tr>
                                    <td>
                                        Brazil
                                    </td>

                                    <td style={{textAlign:'center'}}>2328</td>
                                    <td style={{textAlign:'center'}}>1</td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{textAlign:'center'}}><input type='number' className='table_number_field' placeholder='0'>
                                    </input></td>
                                    <td>

                                        <button class="savebtn" style={{margin:'auto'}}>Save</button>
                                    </td>
                                </tr><tr>
                                    <td>
                                        Saudi Arabia
                                    </td>

                                    <td style={{textAlign:'center'}}>85998</td>
                                    <td style={{textAlign:'center'}}>234</td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{textAlign:'center'}}><input type='number' className='table_number_field' placeholder='0'>
                                    </input></td>
                                    <td>

                                        <button class="savebtn" style={{margin:'auto'}}>Save</button>
                                    </td>
                                </tr><tr>
                                    <td>
                                        Spain
                                    </td>

                                    <td style={{textAlign:'center'}}>8376</td>
                                    <td style={{textAlign:'center'}}>0</td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{textAlign:'center'}}><input type='number' className='table_number_field' placeholder='0'>
                                    </input></td>
                                    <td>

                                        <button class="savebtn" style={{margin:'auto'}}>Save</button>
                                    </td>
                                </tr><tr>
                                    <td>
                                        Norway
                                    </td>

                                    <td style={{textAlign:'center'}}>722</td>
                                    <td style={{textAlign:'center'}}>0</td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{ textAlign: 'center' }}><label class="switchSmall ">
                                        <input type="checkbox" />
                                        <small></small>
                                    </label></td>
                                    <td style={{textAlign:'center'}}><input type='number' className='table_number_field' placeholder='0'>
                                    </input></td>
                                    <td>

                                        <button class="savebtn" style={{margin:'auto'}}>Save</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {/* <nav aria-label="..." id='pagination_row'>
                        <ul class="pagination">
                            <li class="page-item disabled">
                                <a class="page-link"><i className='icon-prev'></i></a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">1</a></li>
                            <li class="page-item active" aria-current="page">
                                <a class="page-link" href="#">2</a>
                            </li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item"><a class="page-link" href="#">4</a></li>
                            <li class="page-item"><a class="page-link" href="#">5</a></li>
                            <li class="page-item">
                                <a class="page-link" href="#"><i className='icon-next'></i></a>
                            </li>
                        </ul>
                    </nav> */}
                </div>
            </div>
        </>
    );
}

export default MarketsSettings;
