import React, {useState} from 'react';
import Logo from "../images/logo.svg";
import ShowHide from "../images/icon-eye.svg";
import { useNavigate } from 'react-router-dom';


const Login1 = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(true);
    setTimeout(() => {
      setIsActive(false);
      // Redirect to another page after 5 seconds (5000 milliseconds)
      //window.location.href = '/nova/report';
      navigate('/report'); // Redirect to a new path

    }, 5000);
  };

  return (
    <> 
    <div className={`loader_ ${isActive ? 'loader--active_' : ''}`}>
      <div className="loader__icon">
      <svg width="80" height="80" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1_591)">
          <rect y="0.282349" width="66.4354" height="66.4354" rx="15.0491" fill="#141B3C"/>
          <rect x="17.0926" y="34.8087" width="0" height="7.12692" rx="3.56346" transform="rotate(-38.5482 17.0926 34.8087)" fill="#FDCC72">
            <animate attributeName="width" dur="2s" from="0" to="71.9071" repeatCount="indefinite"/>
          </rect>
          <rect x="13.556" y="50.7231" width="0" height="6.18755" rx="3.09378" transform="rotate(-38.5482 13.556 50.7231)" fill="#FDCC72">
            <animate attributeName="width" dur="2s" from="0" to="71.9071" repeatCount="indefinite"/>
          </rect>
          <path id="line1" d="M-2.48236 42.6675L20.5136 23.3716" stroke="white" stroke-width="5.89403" stroke-linecap="round" stroke-dasharray="150" stroke-dashoffset="150">
            <animate attributeName="stroke-dashoffset" dur="2s" repeatCount="indefinite" from="150" to="0"/>
          </path>
          <path id="line2" d="M20.6283 23.5651L40.9626 46.5518" stroke="white" stroke-width="5.89403" stroke-linecap="round" stroke-dasharray="150" stroke-dashoffset="150">
            <animate attributeName="stroke-dashoffset" dur="2s" repeatCount="indefinite" from="150" to="0"/>
          </path>
          <path id="line3" d="M41.2585 46.6971L77.2121 17.9658" stroke="white" stroke-width="5.89403" stroke-linecap="round" stroke-dasharray="150" stroke-dashoffset="150">
            <animate attributeName="stroke-dashoffset" dur="2s" repeatCount="indefinite" from="150" to="0"/>
          </path>
        </g>
        <defs>
          <clipPath id="clip0_1_591">
            <rect y="0.282349" width="66.4354" height="66.4354" rx="15.0491" fill="white"/>
          </clipPath>
        </defs>
        </svg>
      </div>
      <div className="loader__tile_"></div>
      <div className="loader__tile_"></div>
      <div className="loader__tile_"></div>
      <div className="loader__tile_"></div>
      <div className="loader__tile_"></div>
    </div>     
    <div className="login_wrapper">
        <div className='login_content'>
          <div className='login_header'>
            <div className='login_logo'>
              <img src={Logo} />
            </div>
            <div className='login_header_text'>
              <h3>Welcome Back !</h3>
              <p>Shariah Compliant status</p>
              <h6>ANALYSIS PLATFORM</h6>
            </div>
          </div>
          <div className='login_form'>
            <form>
              <div className='field_row'>
                <label className='custom_label'>Email address</label>
                <div className='text_field'>
                  <input type='text' className='form-control' />
                </div>
              </div>

              <div className='field_row'>
                <label className='custom_label'>Password</label>
                <div className='text_field'>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className='form-control'
                  />
                  <div className='icon_field' onClick={togglePasswordVisibility}>
                    <img src={ShowHide} alt='Show/Hide Password' />
                  </div>
                </div>
              </div>
              <div className='mt-5'>
                 <button type='button' className='btn_login' onClick={handleClick}>Login</button>
              </div>
            </form>
          </div>
        </div>
    </div>
    </>
  );
}

export default Login1;
