import React, { useState, useEffect, createContext, useContext } from 'react';
import { ReactComponent as UpArrowIcon } from '../images/Emoji.svg';
import { ReactComponent as IconGreen } from '../images/IconGreen.svg';
import { ReactComponent as IconRed } from '../images/IconRed.svg';
import { ReactComponent as IconOrange } from '../images/IconOrange.svg';
import Logo from "../images/logo.svg";
import IconSlide from "../images/btn-slide.svg";
import IconPlay from "../images/icon-play.svg";
import IconPause from "../images/icon-pause.svg";
import { SelectPicker, DatePicker, Tabs } from 'rsuite';
import 'rsuite/SelectPicker/styles/index.css';

// Create TimerContext
const TimerContext = createContext();

export const TimerProvider = ({ children }) => {
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [timerRunning, setTimerRunning] = useState(false);

  useEffect(() => {
    let timerInterval;
    if (timerRunning && startTime !== null) {
      timerInterval = setInterval(() => {
        const currentTime = Date.now();
        setElapsedTime(currentTime - startTime);
      }, 1000);
    }
    return () => clearInterval(timerInterval);
  }, [timerRunning, startTime]);

  return (
    <TimerContext.Provider value={{ startTime, setStartTime, elapsedTime, setElapsedTime, timerRunning, setTimerRunning }}>
      {children}
    </TimerContext.Provider>
  );
};

const useTimer = () => {
  return useContext(TimerContext);
};

// Timer Component
const Timer = ({ initialHours = 0, initialMinutes = 0, initialSeconds = 0 }) => {
  const { startTime, setStartTime, elapsedTime, setElapsedTime, timerRunning, setTimerRunning } = useTimer();

  useEffect(() => {
    if (!startTime && !elapsedTime && !timerRunning) {
      const initialElapsed = (initialHours * 60 * 60 + initialMinutes * 60 + initialSeconds) * 1000;
      setElapsedTime(initialElapsed);
      setTimerRunning(true);
      setStartTime(Date.now() - initialElapsed);
    }
  }, [initialHours, initialMinutes, initialSeconds, startTime, elapsedTime, timerRunning]);

  const formatTime = () => {
    const totalSeconds = Math.floor(elapsedTime / 1000);
    const seconds = totalSeconds % 60;
    const minutes = Math.floor((totalSeconds / 60) % 60);
    const hours = Math.floor(totalSeconds / 3600);

    return `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
    
  };

  const toggleTimer = () => {
    if (timerRunning) {
      setTimerRunning(false);
    } else {
      const currentTime = Date.now();
      if (!startTime) {
        setStartTime(currentTime - elapsedTime);
      }
      setTimerRunning(true);
    }
  };

  return (
    <div className='timer'>
      <span>{formatTime()}</span>
      <button onClick={toggleTimer} className='btn_timer_control'>
        {timerRunning ? <img src={IconPause} alt="Pause" /> : <img src={IconPlay} alt="Play" />}
      </button>
    </div>
  );
};

const optionsPeriod = [
    { value: "1", label: "Three Months" },
    { value: "2", label: "Six Months" },
    { value: "3", label: "Nine Months" },
    { value: "4", label: "Twelve Months" },
    
  ];
  

// TimerWithProvider Component
const TimerWithProvider = () => {
  return (
   
      <div id='header_screening'>
        <div className='logo'>
          <div className='logo_icon'>
            <img src={Logo} alt="Logo" />
          </div>            
        </div>

        <div className='header_right_section'>
          <div className='header_section_top'>
            <div className='brand_name'>Apple Inc. <span>AAPL</span></div>
            <div className='header_monthly_value'>
              <div className='month_price_row three_year_price'>
                <div className='month_col'>
                  36<span>-mo</span>
                </div>
                <div className='price_white_col'>
                  9,000,000,000,000 <span>USD</span>
                  <span className='divider'>|</span>
                  <span className='value_sortname'>9 Trillion <strong>USD</strong></span>
                </div>
              </div>

              <div className='month_price_row three_year_price'>
                <div className='month_col'>
                  24<span>-mo</span>
                </div>
                <div className='price_white_col'>
                  9,000,000,000,000 <span>USD</span>
                  <span className='divider'>|</span>
                  <span className='value_sortname'>9 Trillion <strong>USD</strong></span>
                </div>
              </div>
            </div>
          </div>

          <div className='header_section_bottom'>
            <div className='header_control_links'>
              <TimerProvider>
                <Timer initialHours={0} initialMinutes={1} initialSeconds={20} /> 
              </TimerProvider>            
              <div className='toggle_switch'>
                <label className="switch"><input type="checkbox" /><div></div></label>
              </div>
            </div>
            <div className='header_filter_col'>
              <div className='filter_col'>
                <div className='currency_dropdown_col_filter calendar_col'>
                  <SelectPicker
                    data={optionsPeriod}
                    searchable={false}
                    placeholder="Select Period"
                    style={{ width: 140 }}
                  />
                </div>
                <div className='date_col_filter'>
                  <span>Date</span>
                </div>
                <div className='date_col_filter'>
                  <span>FY</span>
                  <div className='date_col_text calendar_col'>
                    
                    <SelectPicker
                      data={optionsPeriod}
                      searchable={false}
                      style={{ width: 70 }}
                      placeholder="Q1"
                    />
                  </div>
                </div>
                <div className='currency_dropdown_col_filter calendar_col'>
                <SelectPicker
                      data={optionsPeriod}
                      searchable={false}
                      style={{ width: 70 }}
                      placeholder="Q1"
                    />
                </div>
                <div className='currency_col_filter'>
                  <button className='btn_currency_refresh'><i className='icon-refresh'></i></button>
                  <SelectPicker
                      data={optionsPeriod}
                      searchable={false}
                      style={{ width: 70 }}
                      placeholder="Q1"
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
  );
};

export default TimerWithProvider;
