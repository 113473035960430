import React, { useState } from 'react';
import { Popover, Whisper, Button } from 'rsuite';

const TooltipTest = () => {
  const [openReportPopover, setOpenReportPopover] = useState(false);

  const handleButtonClick = () => {
    setOpenReportPopover(true);
  };

  const handleCancelClick = () => {
    setOpenReportPopover(false);
  };

  const handleDeleteClick = () => {
    setOpenReportPopover(false); 
  };

  return (
    <>
    <br></br>
    <br></br>
    <br></br>
    <div style={{margin:30}}>
      <Whisper
        placement="right"
        trigger="click"
        open={openReportPopover}
        onClose={() => setOpenReportPopover(false)}
        speaker={
          <Popover className="tooltip_form Rsuite_popover">
            <div className="tooltip_form_inner text-center">
              <div style={{ fontSize: 20, color: "#141B3C", fontWeight: 600, lineHeight: 1.3 }}>
                Are you sure you want to <br />
                delete this record?
              </div>
              <div className='btn__group mt-5'>
                <a href="#" className='btn__cancel' onClick={handleCancelClick}>Cancel</a>
                <a href="#" className='btn__assign' onClick={handleDeleteClick}>Delete</a>
              </div>
            </div>
          </Popover>
        }
      >
        <Button  onClick={handleButtonClick} style={{ width:40, height:40,borderRadius:"50%", backgroundColor:'#F2F5FC', border:'1px solid #BCCDF2', color:"#DA293E", display:'flex', alignItems:'center', justifyContent:'center', fontSize:20, }}>
        <i className='icon-delete'></i>
      </Button>
      
      </Whisper>
      </div>

      <div style={{margin:30}}>
      <button className="" data-bs-toggle="modal" data-bs-target="#DeleteModal" style={{
        width:40, height:40,borderRadius:"50%", backgroundColor:'#F2F5FC', border:'1px solid #BCCDF2', color:"#DA293E",
        display:'flex', alignItems:'center', justifyContent:'center', fontSize:20,
      }}>
        <i className='icon-delete'></i>
      </button>
      </div>

      <div class="modal fade report_delete_modals" id="DeleteModal" tabindex="-1" aria-labelledby="DeleteModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered" style={{maxWidth: 440}}>
              <div class="modal-content">
                <div class="modal-body">
                  <h5>Are you sure you want to <br />Delete this report?</h5>
                  <p>Once Delete, it goes to Review section</p>
                  <div class="btn__group mt-5" style={{}}>
                      <button type="button" class="btn_cancel" data-bs-dismiss="modal">Cancel</button>
                      <Button className="btn_delete">Delete</Button>                      
                  </div>                                               
                </div>
              
              </div>
          </div>
      </div>
    </>
  );
};

export default TooltipTest;
