import React, { useState, useEffect, useCallback, useRef} from 'react';
import Logo from "../images/logo.svg";
import Select from 'react-select';
import Sidebar from '../component/Sidebar';
import { SelectPicker, Placeholder, Popover, Whisper, Button, Message } from 'rsuite';
import IconInfo from "../images/IconErrorInfo.svg";
import IconError from "../images/IconError.svg";
import IconCheckError from "../images/IconCheckError.svg";
import debounce from 'lodash.debounce';
import 'aos/dist/aos.css';
import AOS from 'aos';
import { FaLessThanEqual } from 'react-icons/fa';


function StockRequests() {
  const [tabName, setTabName] = useState('Update Requests');
  const [isLoader, setIsLoader] = useState(false);
  const userid = localStorage.getItem("novauserid");
  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const clientApiEndpoint = process.env.REACT_APP_CLIENT_API_ENDPOINT;
  const userServiceKey = process.env.REACT_APP_USER_SERVICE_KEY;

  const [errorMessage, setErrorMessage] = useState('');
  const [messageType, setMessageType] = useState(null); // success, error, info

  const [searchInput, setSearchInput] = useState("");
  
  useEffect(() => {
    document.title = tabName;
  }, [tabName]);

  useEffect(() => {
    AOS.init();
  }, []);
  const abortControllerRef = useRef(null);
  const [reportData, setReportData] = useState([]);
  const [reportCount, setReportCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setLoading] = useState(true); // State to manage loading state
const [pageRange, setPageRange] = useState("20");

  const data_Status = [
    { label: "Pending", value: "pending" },
    { label: "Completed", value: "completed" },
  ];

  const [status, setStatus] = useState("");

  // Fetch stock requests from API
  const fetchStockRequests = async () => {
    setLoading(true);
    
// Cancel the previous request if any
if (abortControllerRef.current) {
  abortControllerRef.current.abort();
}

 // Create a new AbortController for the current request
 const controller = new AbortController();
 abortControllerRef.current = controller;

    try {
      const response = await fetch(`${clientApiEndpoint}/api/analyst/updation-requests?sort_by=latest_request_at&sort_order=desc&per_page=${pageRange}&page=${currentPage.toString()}${status !== null ? `&status=${status}&q=${searchInput}` : ''}`, {
        headers: {
          'X-USER-SERVICE-KEY': `${userServiceKey}`
        },
        signal: controller.signal
      });
      const data = await response.json();
      if (data.status) {
        setReportData(data.data.data);
        setReportCount(data.data.total);
      } else {
        // setError('Failed to fetch data');
      }
    } catch (err) {
      if (err.name === "AbortError") {
        console.log("Request cancelled");
      }
else{
  console.log('Failed to fetch data');
}
      return false;
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchGlobalReport = useCallback(
    debounce(() => {
      fetchStockRequests();
    }, 500),
    [searchInput] // Only debounce on search input changes
  );

  useEffect(() => {
    debouncedFetchGlobalReport();
    return () => {
      debouncedFetchGlobalReport.cancel();
    };
  }, [searchInput]);

  useEffect(() => {
    

    fetchStockRequests();
  }, [currentPage, status, pageRange]);




  const handleStatusChange = (selectedOption) => {
    setStatus(selectedOption); // Update selectedPeriod state with the selected option
  };



  const [customPaginationValue, setCustomPaginationValue] = useState("");

  // Debounced function to update the page number
  const debouncedSetPage = useCallback(
    debounce((page) => {
      setCurrentPage(page);
    }, 500), // Delay of 500ms (half a second)
    []
  );

  const handlePaginationChange = (e) => {
    const value = (e.target.value == 0 ? 1 : e.target.value).toString() ;
    setCustomPaginationValue(value); // Update input value immediately for fast typing
    debouncedSetPage(Number(value)); // Trigger the debounced function
  };

  const handlePagelimit = (value) => {
    setCurrentPage(1);
    setCustomPaginationValue(1);
    setPageRange(value); 
  };

  
  const renderPagination = () => {
    // Calculate total number of pages
    const totalPages = Math.ceil(reportCount / pageRange);
  
    // Show a default of 3 pages if the total pages are more than 3
    const pageNumbers = [];
    const visiblePageCount = 4; // Number of pages to show by default
  
    let startPage = currentPage - 1;
    let endPage = currentPage + 2;
  
    // Adjust pagination if total pages are less than the default
    if (totalPages <= visiblePageCount) {
      startPage = 1;
      endPage = totalPages;
      
    } else {
      
      // Ensure the pagination does not go out of bounds
      startPage = Math.max(1, startPage);
      endPage = Math.min(totalPages, endPage);
    }
  
    // Create page numbers to display based on current page
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
  
    return (
      <nav aria-label="..." id='pagination_row' style={{alignItems:'center', justifyContent:'space-between', background:'#FFF', paddingTop:0, paddingBottom:0}}>
        <div className='totalRecords'>
          Total Records :  {Number(reportCount).toLocaleString()}
        </div>
        <ul className="pagination" style={{border: 0, background: 'none'}}>

        <li class="page-item" style={{marginLeft:10}}>
                      <SelectPicker onChange={handlePagelimit} cleanable={false}
                      value={pageRange} 
                      placement="top" placeholder='Pages' data={PagingData} searchable={false} style={{ width: 85, height:35, display:'flex', alignItems:'center', borderRadius: 5, border: '1px solid #A1B9ED'}} />
                    </li>

          {/* Previous Page (Double Arrow) */}
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`} style={{marginLeft: 10}}>
            <a href="#" className="page-link" onClick={() => setCurrentPage(1)}>
              <i className="fa fa-angle-double-left"></i>
            </a>
          </li>
  
          {/* Previous Page (Single Arrow) */}
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a href="#" className="page-link" onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}>
              <i className="fa fa-angle-left"></i>
            </a>
          </li>
  
          {/* Page numbers */}
          {pageNumbers.map(page => (
            <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
              <a 
                className="page-link" href="#" 
                onClick={() => setCurrentPage(page)} // Set the clicked page as the current page
              >
                {page}
              </a>
            </li>
          ))}
          
          {/* Next Page (Single Arrow) */}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a href="#" className="page-link" onClick={() => setCurrentPage(Math.min(totalPages, currentPage + 1))}>
              <i className="fa fa-angle-right"></i>
            </a>
          </li>
  
          {/* Next Page (Double Arrow) */}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`} style={{marginRight: 10}}>
            <a href="#" className="page-link" onClick={() => setCurrentPage(totalPages)}>
              <i className="fa fa-angle-double-right"></i>
            </a>
          </li>
          
          {/* Go to Page */}
          <li className="page-item" style={{marginLeft: 10, display: 'flex', alignItems: 'center', gap: 5}}>
            <input
              type="text"
              placeholder="Go#"
              className="form-control"
              value={customPaginationValue}
              onChange={handlePaginationChange}
              onInput={(e) => {
                // Only allow numbers
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
              }}
            
              style={{
                padding: '0px 10px', 
                color: '#878787', 
                fontSize: 16, 
                borderRadius: 5, 
                border: '1px solid #A1B9ED', 
                width: 60, 
                height: 35, 
                textAlign: 'center'
              }}
            />
          </li>
        </ul>
      </nav>
    );
  };
  
  
  


// PAGINATION
const PagingData = ['10', '20', '50', '100'].map(
  item => ({ label: item, value: item })
);


  const options = [
    { value: 'India', label: 'India' },
    { value: 'United States', label: 'United States' },
    { value: 'Australia', label: 'Australia' }
  ];

  const colourStyles = {
    option: (styles, { isFocused }) => ({
      ...styles,
      backgroundColor: isFocused ? "#F8F8F8" : "",
      color: "#333333",
      borderBottom: 1,
      borderStyle: "solid",
      borderColor: "#F2F2F2",
      fontSize: 14,
      fontWeight: 500,
    })
  };

  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = e => {
    setSelectedOption(e);
  };

  const [copied, setCopied] = useState(false);

  const copyText = text => {
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = text;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleClearFilters = () => {
    setCurrentPage(1);
    setStatus("");
    setSearchInput("");

  };

const data = [
  { label: "Updated", value: "updated" },
  { label: "Already Update", value: "already_uptodate" },
  { label: "Not Applicable", value: "not_applicable" },
];


// State to track the visibility of the popover
const [openReportPopover, setOpenReportPopover] = useState(false);
// State to track the selected value from SelectPicker
const [selectedStatus, setSelectedStatus] = useState(null);
// State to track the selected radio option
const [selectedRadio, setSelectedRadio] = useState(null);

// Handle SelectPicker change
const handleSelect = (value) => {

  // Reset radio selection when the country changes
  //setSelectedRadio(null);
  setSelectedStatus(value);
  if(value == 'not_applicable')
  {
    setSelectedRadio(true);
  }
  
};

// Handle Radio button change
const handleRadioChange = (value) => {
  setSelectedRadio(value);
};

const handlePopoverOpen = (id) => {
  setOpenReportPopover(openReportPopover == id ? null : id);
  setSelectedStatus(null);
};

  const [email, setEmail] = useState('');
  const [id, setId] = useState('');


const handleRequest = async (request) => {

  if(!selectedStatus)
    {
      return false;  
    }
    
  setIsLoader(true);
  let result = null;
  if (selectedStatus === 'updated') {
    try {
      const result = await getCheckReport(request.ticker);
      if (result) {
        setEmail(result.email);
      } else {
        // If result is false, exit early
        setIsLoader(false);
        return;
      }
    } catch (error) {
      setErrorMessage(`Error fetching report: ${error.message}`);
      setMessageType('error');
      setIsLoader(false);
      return;
    }
  }

      try {
        const response = await fetch(`${clientApiEndpoint}/api/analyst/updation-requests/update-status`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-USER-SERVICE-KEY': `${userServiceKey}`
          },
          body: JSON.stringify({
            request_id: request.id,
            analyst_email: selectedStatus === 'updated' ? (result ? result.email : null) : null, // Check if result is not null
            status: selectedStatus,
            status_reason: selectedRadio === null ? '' : selectedRadio
          })
        });

        console.log(JSON.stringify({
          request_id: request.id,
          analyst_email: selectedStatus === 'updated' ? (result ? result.email : null) : null, // Check if result is not null
          status: selectedStatus,
          status_reason: selectedRadio === null ? '' : selectedRadio
        }));

        if (!response.ok) {
          //throw new Error(`HTTP error! status: ${response.status}`);
          setErrorMessage(`HTTP error! status: ${response.status}`);
          setMessageType('error');
          setIsLoader(false);
        }
    
        const data = await response.json();
        //console.log('Success:', data);
        setIsLoader(false);
        if(data.status == false)
        {
          console.log('FAIL');
          setOpenReportPopover(null)
          setErrorMessage(data.message || 'An error occurred');
          setMessageType('error');
        }
        else{
          setOpenReportPopover(null);
          setErrorMessage('Request updated successfully!');
          setMessageType('success');
          fetchStockRequests();
        }
      } catch (error) {
        setErrorMessage(`CATCH Error: `+error);
        setMessageType('error');
        console.error('CATCH Error:', error);
        setIsLoader(false);
      }

    
};

// Function to call the get_status_report API
// const getStatusReport = async (ticker) => {
//   const payload = {
//     data: {
//       user_id: "1",
//       limit: "20",
//       page: "1",
//       sort_order: "desc",
//       q: ticker,  // Pass the ticker as a query parameter
//       country_code: "",
//       filter_Date: "",
//       status: 3
//     }
//   };

//   try {
//     const response = await fetch(`${apiEndPoint}/get_status_report`, {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json'
//       },
//       body: JSON.stringify(payload)
//     });
//     const data = await response.json();

//     if (data.data && data.data.data.length > 0) {
//       const result = data.data.data[0];
//       return {
//         email: result.email,
//         id: result.id
//       };
//     } else {
//       setIsLoader(false);
//       console.error("Report not published yet, so you can't update the status");
//       setErrorMessage('Report not published yet, so you cant update the status');
//       setMessageType('error');
//       return null;
//     }
//   } catch (error) {
//     setIsLoader(false);
//     console.error('Error fetching data:', error);
//     return null;
//   }
// };

const getCheckReport = async (ticker) => {
  const payload = {
    data: {
      user_id: userid,
      stock_name: ticker  // Pass the ticker as the stock_name
    }
  };

  try {
    const response = await fetch(`${apiEndPoint}/check_published_report`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    });
    const data = await response.json();

    if (data.data && data.data.status === true) {
      const result = data.data;
      return {
        email: result.email,
        id: result.id
      };
    } else {
      setIsLoader(false);
      console.error("Report not published yet, so you cant update the status");
      setErrorMessage('Report not published yet, so you cant update the status');
      setMessageType('error');
      return false;
    }
  } catch (error) {
    setIsLoader(false);
    console.error('Error fetching data:', error);
    return false;
  }
};


  // if (assignedId) {
  //   console.log("Selected Junior Id:", assignedId);

  //   //0 : indicated dont send the data on screening page
  //   handleProcessReport(assignedId,company_symbol,date,quarter,year,0);
  //   setSelectedUser(null);
  //   setOpenReportPopover(null);
  // } else {
  //   console.log("No user selected"+ assignedId);
  // }

  const hideMessage = () => {
    setMessageType(null);
  };

  // Error Message

  const renderMessage = () => {
    if (messageType) {
      setTimeout(hideMessage, 5000);
    };

    switch (messageType) {
      case 'success':
        return (
          <Message type="success" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10, zIndex:99}}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <div className='icon_error_msg Icon_success'>
                <div className='icon_error_msg_inner Icon_success_inner'>
                  <img src={IconCheckError} alt="Success Icon" />
                  <div className="progress-circle progress_circle_success"></div>
                </div>
              </div>
              <div className='error_msg_text error_msg_success_text'>
                <h5>Success</h5>
                <p>{errorMessage}</p>
              </div>
            </div>
          </Message>
        );
      case 'error':
        return (
          <Message type="error" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10, zIndex:99}}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <div className='icon_error_msg Icon_error'>
                <div className='icon_error_msg_inner Icon_error_inner'>
                  <img src={IconError} alt="Error Icon" />
                  <div className="progress-circle progress_circle_error"></div>
                </div>
              </div>
              <div className='error_msg_text error_msg_error_text'>
                <h5>Error</h5>
                <p>{errorMessage}</p>
              </div>
            </div>
          </Message>
        );
        case 'info':
        return (
          <Message type="info" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10, zIndex:99}}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <div className='icon_error_msg Icon_info'>
                <div className='icon_error_msg_inner Icon_info_inner'>
                  <img src={IconInfo} alt="Info Icon" />
                  <div className="progress-circle progress_circle_info"></div>
                </div>
              </div>
              <div className='error_msg_text error_msg_info_text'>
                <h5>Info</h5>
                <p>{errorMessage}</p>
              </div>
            </div>
          </Message>
        );
        default:
          return null;
    }
  };

  const handleSearch = (event) => {
    console.log(event.target.value);
  
    setSearchInput(event.target.value);
    setCurrentPage(1); // Reset to the first page for new search
  };


  return (
    <>
      <Sidebar />
      <div id='header'>
      {renderMessage()}
        <div className='logo'>
          <div className='logo_icon'>
            <img src={Logo} alt="Logo" />
          </div>
          <div className='brand_name'>Update Requests</div>
        </div>

        <div className='header_table_filter'>
        <div className='searchfield'>
            <button className='btn_search'><i className='icon-search2'></i></button>
            <input type='text' className='filter_searchfield' placeholder='Search' autocomplete="off"  onChange={handleSearch}   value={searchInput}/>
          </div>


          <div className=' dropdown_select'>
            <SelectPicker
              data={data_Status}
              searchable={false}
              placeholder="Status"
              style={{ width: 130 }}
              onChange={handleStatusChange} // Attach onChange event handler
              value={status} // Set the value prop to control the selected value
            />
          </div>

          <a href="javascript:void(0)" className="btn_clear_filter" style={{ textDecoration: "none" }} onClick={handleClearFilters}>
            Clear Filters
          </a>
        </div>
      </div>
      <div className="container-fluid content pb-5">
        <div className='company_table_list' data-aos="fade-up" data-aos-duration="600">
          {isLoading && (<Placeholder.Grid rows={20} columns={9} active style={{ paddingLeft: 20, paddingRight: 20, paddingTop: 12 }} />)}

          {/* Table */}
          {!isLoading && (
            <>
              <div className='table-responsive clsPagingWrap'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th>Ticker</th>
                      <th>Request Count</th>
                      <th>Request Date</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {reportData.length > 0 ? (
                      reportData.map((request, index) => (
                        <tr key={index}>
                          <td><span className='company_lable2'>{request.ticker}</span></td>
                          <td>{request.request_count}</td>
                          <td>{new Date(request.latest_request_at).toLocaleDateString()}</td>
                          <td><span className={`tag_td2 ${request.status === "completed" ? 'covered_tag' : request.status === "pending" ? 'not_covered_tag' : ''}`}


                          >{request.status.toUpperCase()}</span></td>

                          <td>
                          <Whisper
                            placement="auto"
                            trigger="click"
                            open={openReportPopover == request.ticker}
                            onClose={() => setOpenReportPopover(null)}
                            speaker={
                              <Popover 
                                className=""
                                container={() => document.body}
                                style={{width:420, marginTop: -4, marginLeft:-12, }} // Optional: Ensure consistent width
                              >
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                  <div className="tooltip_form_inner">
                                  
                                    <label>Status For {request.ticker}</label>
                                    <div>
                                      <SelectPicker
                                        data={data}
                                        searchable={false}
                                        placeholder="Select Status"
                                        style={{ width: '100%' }}
                                        onChange={handleSelect}
                                      />
                                    </div>
                                    {selectedStatus === 'not_applicable' && (
                                      <ul className='radio_check_list mt-3'>
                                      <li>
                                          <label for="opt1" class="radio">
                                            <input type="radio" name="rdo" id="opt1" class="hidden" onChange={() => handleRadioChange('acquired')}/>
                                            <span class="label"></span>acquired
                                          </label>
                                        </li>
                                        <li>
                                          <label for="opt2" class="radio">
                                            <input type="radio" name="rdo" id="opt2" class="hidden" onChange={() => handleRadioChange('merged')}/>
                                            <span class="label"></span>merged
                                          </label>
                                        </li>

                                        <li>
                                          <label for="opt3" class="radio">
                                            <input type="radio" name="rdo" id="opt3" class="hidden" onChange={() => handleRadioChange('ceased')}/>
                                            <span class="label"></span>ceased
                                          </label>
                                        </li>
                                        
                                        <li>
                                          <label for="opt4" class="radio">
                                            <input type="radio" name="rdo" id="opt4" class="hidden" onChange={() => handleRadioChange('bankrupt')}/>
                                            <span class="label"></span>bankrupt 
                                          </label>
                                        </li>

                                        <li>
                                          <label for="opt5" class="radio">
                                            <input type="radio" name="rdo" id="opt5" class="hidden" onChange={() => handleRadioChange('delisted')}/>
                                            <span class="label"></span>delisted
                                          </label>
                                        </li>

                                        <li>
                                          <label for="opt6" class="radio">
                                            <input type="radio" name="rdo" id="opt6" class="hidden" onChange={() => handleRadioChange('suspended')}/>
                                            <span class="label"></span>suspended
                                          </label>
                                        </li>

                                        <li>
                                          <label for="opt7" class="radio">
                                            <input type="radio" name="rdo" id="opt7" class="hidden" onChange={() => handleRadioChange('private_company')}/>
                                            <span class="label"></span>private_company
                                          </label>
                                        </li>

                                        <li>
                                          <label for="opt8" class="radio">
                                            <input type="radio" name="rdo" id="opt8" class="hidden" onChange={() => handleRadioChange('closed_end_trust')}/>
                                            <span class="label"></span>closed_end_trust
                                          </label>
                                        </li>

                                      </ul>
                                    )}

                                    <div className='btn__group mt-5'>
                                      <a href="#" className="btn__cancel" onClick={(e) => { e.preventDefault(); setOpenReportPopover(null) }}>Cancel</a>
                                      <Button className="btn__assign" onClick={() => handleRequest(request) } loading={isLoader}> Submit </Button>
                                    </div>

                                  </div>
                                </div>
                              </Popover>
                            }
                          >
                              <button className="btn_completed" onClick={() => handlePopoverOpen(request.ticker)}>
                                Change Status
                              </button>
                          </Whisper>
                            
                          </td>
                        </tr>

                      ))
                    ) : (
                      <tr>
                        <td colSpan="9" style={{ textAlign: "center" }}>
                          No data available
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              {reportCount > 1 && renderPagination()}


            </>
          )}
        </div>
      </div>
    </>
  );
}

export default StockRequests;
