import React,  { useState, useEffect }  from 'react';
import Sidebar from "../component/Sidebar";
import { useParams, useNavigate, Navigate } from "react-router-dom";

function ComplianceView() {
    const { encryptJSON } = useParams();
    const [responseDecodeData, setResponseDecodeData] = useState({});
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;

    const navigate = useNavigate();
    
      //Decode API
  useEffect(() => {
    const fetchData = async () => {
      const url = `${apiEndPoint}/decode`;
      const requestData = {
        data: {
          encoded: encryptJSON
        }
      };
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData)
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
       
      const responseData = await response.json();
      const data = responseData.data; // Flatten the response

      if(data.status === 'success')
      {
          // Handle the response data here
          console.log(data.data);
          setResponseDecodeData(data.data);

          //company_symbol
          //exchange_symbol

      }
  
      } catch (error) {
        // Handle errors here
        console.error("Decode API Error:", error);
      }
    };
  
    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once on component mount


  useEffect(() => {
    const fetchData = async () => {
      try {
        const { company_symbol, exchange_symbol } = responseDecodeData;

          const url = `${apiEndPoint}/get_compliance_data?main_ticker=&stock_name=${exchange_symbol}`;
          const response = await fetch(url, {
            method: 'GET'
          });

        if (!response.ok) {
          throw new Error(`Error: ${response.status}`);
        }

        const result = await response.json();
        setData(result);
      } catch (error) {
        setError(error.message);
      } finally {
        
      }
    };
    if(responseDecodeData.company_symbol && responseDecodeData.exchange_symbol)
    {
        fetchData();
    }
  }, [responseDecodeData.company_symbol,responseDecodeData.exchange_symbol]);
  

  const handleBack = () => {
    navigate('/TaskManager');
  };
  

  return (
   <>
    <Sidebar />

    <button className="btn_cancel_form" onClick={handleBack}> Back </button>

    <div>
      <h5>Compliance Data</h5>
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>

   </>
  );
}

export default ComplianceView;
