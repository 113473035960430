import React, { useState, useEffect, useRef  } from "react";
import Logo from "../images/logo.svg";
import Sidebar from "../component/Sidebar";
import { SelectPicker, DatePicker, Dropdown, Tabs } from "rsuite";
import "rsuite/DatePicker/styles/index.css";
import "rsuite/SelectPicker/styles/index.css";
import "rsuite/Dropdown/styles/index.css";
import IconNoData from "../images/IconNoData.svg";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip, TooltipProvider } from "react-tooltip";

const Test1 = (props) => {
  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const [reportLRUrl, setReportLRUrl] = useState("");
  const [reportARUrl, setReportARUrl] = useState("");
  const [activeKey, setActiveKey] = useState("1"); // State to track active tab
  const [isActive, setIsActive] = useState(false);
  const [loadingLR, setLoadingLR] = useState(true); // Loading state for LR report
  const [loadingAR, setLoadingAR] = useState(true); // Loading state for AR report

  const [tempReportLR, setTempReportLR] = useState("");
  const [tempReportAR, setTempReportAR] = useState("");
  const [responseDecodeData, setResponseDecodeData] = useState({});
  const popupRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  

  useEffect(() => {
    const fetchData = async () => {
      const url = `${apiEndPoint}/decode`;
      const requestData = {
        data: {
          encoded: 'enNZVWxWa3JmSUhMMTN5ZjFXS0hEZz09'
        }
      };

      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData)
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const responseData = await response.json();
        const data = responseData.data;

        if (data.status === 'success') {
          setResponseDecodeData(data.data);
          setTempReportLR(data.data.iframe_url10k || data.data.LR_report || "");
          setTempReportAR(data.data.iframe_url10q);
          if(data.data.country == 'US')
          {
            setActiveKey("");
          }
          else{
            setActiveKey("1");
          }
          
        }
      } catch (error) {
        console.error("Decode API Error:", error);
      } finally {
        setLoadingLR(false); // Set loading state to false when done loading LR report
        setLoadingAR(false); // Set loading state to false when done loading AR report
      }
    };

    fetchData();
  }, [apiEndPoint]);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };

  const handleReportLRInputChange = (e) => {
    //LR Link Paste
    setReportLRUrl(e.target.value);
    setIsActive(false);
  };

  const handleReportARInputChange = (e) => {
    //AR Link Paste
    setReportARUrl(e.target.value);
    setIsActive(false);
  };

  const handleTabChange = (key) => {
    setActiveKey(key);

    // if(key == 1)
    // {
    //   setReportLRUrl(tempReportLR);
    // }
    // else if(key == 2)
    // {
    //   setReportARUrl(tempReportAR);
    // }
    
  };

  const handle10kReport = () => {
    // Your logic here
    console.log('10k report clicked');
    setReportLRUrl(tempReportLR);
    handleTabChange("1");
  };

  const handle10QReport = () => {
    // Your logic here
    console.log('10Q report clicked');
    setReportLRUrl(tempReportAR);
    handleTabChange("1");
  };


  return (
    <>
      <Sidebar />
      <div id="header_screening">
        <div className="logo">
          <div className="logo_icon">
            <img src={Logo} alt="Logo" />
          </div>
        </div>
        <div className="header_right_section">
          <div className="header_section_bottom">
            <div className="header_control_links">
              <div className="lisk_dropdown_row">
                <a href="#" className="link_trigger" onClick={toggleMenu}>
                  <i className="icon-link"></i>
                </a>

                <div ref={popupRef} className={`paste_link_box ${isActive ? "active" : ""}`}>
                  <div className="paste_link_header">Paste Links</div>
                  <div className="paste_link_body">
                    <div className="link_field_row">
                      <label>
                        <i className="icon-link"></i>Link
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="https://"
                        onChange={handleReportLRInputChange}
                        value={reportLRUrl}
                      />
                    </div>

                    <div className="link_field_row">
                      <label>
                        <i className="icon-link"></i>AR
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="https://"
                        onChange={handleReportARInputChange}
                        value={reportARUrl}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="toggle_switch">
                <label className="switch">
                  <input type="checkbox" />
                  <div></div>
                </label>
              </div>

              <Tabs
                activeKey={activeKey}
                onSelect={handleTabChange}
                appearance="subtle"
                className="clsTab"
              >
                <Tabs.Tab eventKey="1" title="LR"></Tabs.Tab>
                <Tabs.Tab eventKey="2" title="AR"></Tabs.Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </div>

      <div className="report_table_data_row">
        <div id="Reportbox1" className="screening_report_pdf">
          <div className="tab-content">
            {activeKey === "" ? (
              <div className="zero_data_content">
              <div className="zero_data_inner">
                <div className="report_load_desc">Choose the Type to load the latest Report <br/>
                of MSFT from Edgar</div>
              <div style={{display:"flex", alignItems:"center", justifyContent:"center", gap:20, marginTop:40}}>
                   <button className="btn_report_load" onClick={handle10kReport}>10-K</button>
                   <button className="btn_report_load" onClick={handle10QReport}>10-Q</button>
              </div>
              </div>
            </div>
            ) : activeKey === "1" ? (
              loadingLR ? (
                <div>Loading LR Report...</div>
              ) : reportLRUrl ? (
                <iframe
                  title="LR Report PDF"
                  width="100%"
                  height="550"
                  src={reportLRUrl}
                  className="iframe_pdf"
                ></iframe>
              ) : (
                <div className="zero_data_content">
                  <div className="zero_data_inner">
                    <img src={IconNoData} alt="No Data" />
                    <p>No LR Report Available</p>
                  </div>
                </div>
              )
            ) : activeKey === "2" ? (
              loadingAR ? (
                <div>Loading AR Report...</div>
              ) : reportARUrl ? (
                <iframe
                  title="AR Report PDF"
                  width="100%"
                  height="550"
                  src={reportARUrl}
                  className="iframe_pdf"
                ></iframe>
              ) : (
                <div className="zero_data_content">
                  <div className="zero_data_inner">
                    <img src={IconNoData} alt="No Data" />
                    <p>No AR Report Available</p>
                  </div>
                </div>
              )
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Test1;
