import React, { useState, useEffect, useCallback, useRef } from "react";
import Logo from "../images/logo.svg";
import { SelectPicker, Placeholder } from "rsuite";
import Sidebar from '../component/Sidebar';
import "rsuite/SelectPicker/styles/index.css";
import { ReactComponent as IconStarOutline } from "../images/star-outline.svg";
import { ReactComponent as IconStar } from "../images/IconStar.svg";
import IconNoData from "../images/IconNoData.svg";
import debounce from 'lodash.debounce';
import 'aos/dist/aos.css';
import AOS from 'aos';

const Ranking = () => {
  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const [tabName, setTabName] = useState('Scoring & Ranking');
  const [isLoading, setLoading] = useState(true); // State to manage loading state
  useEffect(() => {
    document.title = tabName;
  }, [tabName]);

  useEffect(() => {
    AOS.init();
  }, []);

  const userid = localStorage.getItem("novauserid");

  const abortControllerRef = useRef(null);
  const [countryData, setCountryData] = useState([]);
  const [standardData, setStandardData] = useState([]);
  const [countryCode, setCountryCode] = useState("");
  const [ranking, setRanking] = useState("");
  const [standard, setStandard] = useState("");
  const [reportData, setReportData] = useState([]);
  const [reportCount, setReportCount] = useState(0);
  const [startPage, setStartPage] = useState(1);
  const [pageRange, setPageRange] = useState("20");

  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState(""); // New state for search query
  //const [startPageProgress, setStartPageProgress] = useState(1);
  
  


    const fetchCountryList = async () => {
      const url = `${apiEndPoint}/get_country_list`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        console.log(JSON.stringify(payload));
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response
      console.log(responseData.data);
        const formattedCountryData = data.data.map((country) => ({
          label: country.country_name,
          value: country.country_code,
        }));


        setCountryData(formattedCountryData);
  
      } catch (error) {
        console.log('get_country_list API: '+ error.message);
      }
    };
  

  const fetchStandardData = () => {
    fetch(`${apiEndPoint}/get_standard_master`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: {
          user_id: userid,
        },
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        const formattedStandardData = data.data.data.map((standard) => ({
          label: standard.name,
          value: standard.id,
        }));
        setStandardData(formattedStandardData);
      })
      .catch((error) => {
        console.log('get_standard_master API :' + error.message);
      });
  };
  
  const fetchTableData = () => {

    setLoading(true);

    // Cancel the previous request if any
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

     // Create a new AbortController for the current request
     const controller = new AbortController();
     abortControllerRef.current = controller;

    const payload = {
      data: {
        user_id: userid,
        limit: pageRange,
        page: currentPage.toString(),
        q: query,
        country: countryCode,
        standard: standard,
      },
    };

    console.log(JSON.stringify(payload));

    fetch(`${apiEndPoint}/get_ranking_report`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
      signal: controller.signal,
    })
      .then((response) => {
        if (!response.ok) {
          setLoading(false);
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.data.status === "success") {
          setLoading(false);
          setReportCount(data.data.count); // Accessing the count and converting it to an integer
          setReportData(data.data.data); // Accessing the nested data array
        } else {
          setLoading(false);
          throw new Error('Data fetch was not successful');
        }
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("Request cancelled");
        }
        else{
  
        setLoading(false);
        console.log(error);
      }
      return false;
      });
  };
  

  useEffect(() => {
    fetchCountryList();
    fetchStandardData();
  }, []);

  const debouncedFetchGlobalReport = useCallback(
    debounce(() => {
      fetchTableData();
    }, 500),
    [query] // Only debounce on search input changes
  );

  useEffect(() => {
    debouncedFetchGlobalReport();
    return () => {
      debouncedFetchGlobalReport.cancel();
    };
  }, [query]);

  useEffect(() => {
    fetchTableData();
  }, [currentPage, countryCode, standard,pageRange]);


  


  const handleCountryChange = (value) => {
    setCountryCode(value);
    setCurrentPage(1); // Reset to first page on filter change
  };

  const handleStandardChange = (value) => {
    setStandard(value);
    setCurrentPage(1); // Reset to first page on filter change
  };

  const handleClearFilters = () => {
    setCurrentPage(1); 
    setCountryCode("");
    setStandard("");
    setQuery(""); 
    //handlePageChange(1);
  };



  const handleSearchChange = (event) => {
    setQuery(event.target.value); // Update the search query state
    setCurrentPage(1); // Reset to first page on search change
  };


  const renderStars = (ranking) => {
    const fullStars = Math.floor(ranking);
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < fullStars) {
        stars.push(<li key={i}><IconStar /></li>);
      } else {
        stars.push(<li key={i}><IconStarOutline /></li>);
      }
    }
    return stars;
  };



  



  const [customPaginationValue, setCustomPaginationValue] = useState("");

  // Debounced function to update the page number
  const debouncedSetPage = useCallback(
    debounce((page) => {
      setCurrentPage(page);
    }, 500), // Delay of 500ms (half a second)
    []
  );

  const handlePaginationChange = (e) => {
    const value = (e.target.value == 0 ? 1 : e.target.value).toString() ;
    setCustomPaginationValue(value); // Update input value immediately for fast typing
    debouncedSetPage(Number(value)); // Trigger the debounced function
  };

  const handlePagelimit = (value) => {
    setCurrentPage(1);
    setCustomPaginationValue(1);
    setPageRange(value); 
  };

  
  const renderPagination = () => {
    // Calculate total number of pages
    const totalPages = Math.ceil(reportCount / pageRange);
  
    // Show a default of 3 pages if the total pages are more than 3
    const pageNumbers = [];
    const visiblePageCount = 4; // Number of pages to show by default
  
    let startPage = currentPage - 1;
    let endPage = currentPage + 2;
  
    // Adjust pagination if total pages are less than the default
    if (totalPages <= visiblePageCount) {
      startPage = 1;
      endPage = totalPages;
      
    } else {
      
      // Ensure the pagination does not go out of bounds
      startPage = Math.max(1, startPage);
      endPage = Math.min(totalPages, endPage);
    }
  
    // Create page numbers to display based on current page
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
  
    return (
      <nav aria-label="..." id='pagination_row' style={{alignItems:'center', justifyContent:'space-between', background:'#FFF', paddingTop:0, paddingBottom:0}}>
        <div className='totalRecords'>
          Total Records : {Number(reportCount).toLocaleString()}
        </div>
        <ul className="pagination" style={{border: 0, background: 'none'}}>

        <li class="page-item" style={{marginLeft:10}}>
                      <SelectPicker onChange={handlePagelimit} cleanable={false}
                      value={pageRange} 
                      placement="top" placeholder='Pages' data={PagingData} searchable={false} style={{ width: 85, height:35, display:'flex', alignItems:'center', borderRadius: 5, border: '1px solid #A1B9ED'}} />
                    </li>

          {/* Previous Page (Double Arrow) */}
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`} style={{marginLeft: 10}}>
            <a href="#" className="page-link" onClick={() => setCurrentPage(1)}>
              <i className="fa fa-angle-double-left"></i>
            </a>
          </li>
  
          {/* Previous Page (Single Arrow) */}
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a href="#" className="page-link" onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}>
              <i className="fa fa-angle-left"></i>
            </a>
          </li>
  
          {/* Page numbers */}
          {pageNumbers.map(page => (
            <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
              <a 
                className="page-link" href="#" 
                onClick={() => setCurrentPage(page)} // Set the clicked page as the current page
              >
                {page}
              </a>
            </li>
          ))}
          
          {/* Next Page (Single Arrow) */}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a href="#" className="page-link" onClick={() => setCurrentPage(Math.min(totalPages, currentPage + 1))}>
              <i className="fa fa-angle-right"></i>
            </a>
          </li>
  
          {/* Next Page (Double Arrow) */}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`} style={{marginRight: 10}}>
            <a href="#" className="page-link" onClick={() => setCurrentPage(totalPages)}>
              <i className="fa fa-angle-double-right"></i>
            </a>
          </li>
          
          {/* Go to Page */}
          <li className="page-item" style={{marginLeft: 10, display: 'flex', alignItems: 'center', gap: 5}}>
            <input
              type="text"
              placeholder="Go#"
              className="form-control"
              value={customPaginationValue}
              onChange={handlePaginationChange}
              onInput={(e) => {
                // Only allow numbers
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
              }}
            
              style={{
                padding: '0px 10px', 
                color: '#878787', 
                fontSize: 16, 
                borderRadius: 5, 
                border: '1px solid #A1B9ED', 
                width: 60, 
                height: 35, 
                textAlign: 'center'
              }}
            />
          </li>
        </ul>
      </nav>
    );
  };
  
  
  


// PAGINATION
const PagingData = ['10', '20', '50', '100'].map(
  item => ({ label: item, value: item })
);




  return (
    <>
      <Sidebar />
      <div id="header">
        <div className="logo">
          <div className="logo_icon">
            <img src={Logo} alt="Logo" />
          </div>
          <div className="brand_name">Ranking </div>
        </div>

        <div className="header_table_filter">
         <div className="searchfield">
            <button className="btn_search"><i className="icon-search2"></i></button>
            <input 
              type="text" 
              className="filter_searchfield" 
              placeholder="Search" 
              value={query} // Bind the input value to the search query state
              onChange={handleSearchChange} // Handle change event
            />
          </div>

          <div className="dropdown_col_filter dropdown_select" style={{ width: 'auto', padding: 0 }}>
            <SelectPicker
              data={countryData}
              searchable={false}
              placeholder="Filter by Country"
              style={{ width: 180 }}
              onChange={handleCountryChange}
              value={countryCode}
            />
          </div>

          <div className="dropdown_col_filter dropdown_select" style={{ width: 'auto', padding: 0 }}>
            <SelectPicker
              data={standardData}
              searchable={false}
              placeholder="Filter by Standards"
              style={{ width: 180 }}
              onChange={handleStandardChange}
              value={standard}
            />
          </div>

          
          <a href="javascript:void(0)" className="btn_clear_filter" style={{textDecoration:"none"}} onClick={handleClearFilters}>
            Clear Filters
          </a>
        </div>
      </div>
      <div className="container-fluid content pb-5">
  
        <div className="company_table_list table_ranking" data-aos="fade-up" data-aos-duration="600">
        {isLoading && ( <Placeholder.Grid rows={20} columns={9} active style={{paddingLeft:20, paddingRight:20, paddingTop:12}} /> )}

{/* Table */}
{!isLoading && (
<>
          <div className="table-responsive clsPagingWrap">

          {reportData && reportData.length > 0 ? (
            <table className="table">
           

              <thead>
                <tr>
                  <th>No</th>
                  <th>Ticker</th>
                  <th>Company Name</th>
                  <th>Country</th>
                  <th style={{ textAlign: 'center' }}>Compliance</th>
                  <th style={{ textAlign: 'center' }}>Score</th>
                  <th style={{ textAlign: 'center' }}>Ranking</th>
                </tr>
              </thead>
              <tbody>
                {reportData.map((company, index) => (
                  <tr key={index}>
                    <td style={{ fontWeight: 600 }}>{index + 1 + (currentPage - 1) * 20}</td>
                    <td>
                      <div className="	">
                        <span className="stock_lable" style={{ fontWeight: 600, textTransform: 'uppercase', width: 120 }}>{company.ticker}</span>
                      </div>
                    </td>
                    <td style={{ fontWeight: 600, textTransform: 'uppercase' }}>{company.name}</td>
                    <td style={{ color: '#000' }}>{company.country_name}</td>
                    <td style={{ textTransform: 'uppercase', color: '#000', textAlign: 'center' }}>{company.compliance}</td>
                    <td style={{ fontWeight: 600, textAlign: 'center' }}>{company.scoring}</td>
                    <td>
                      <ul className="rating_list">
                        {renderStars(company.star_rating)}
                      </ul>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            ) : (
              <div className="table_zero_data">
                <img src={IconNoData} alt="No Data" />
                <p>No Reports Available</p>
              </div>
            )}
          </div>

          {/* {tableData && tableData.length > 0 && (
              <nav aria-label="..." className="clsPagination">
                <ul className="pagination">
                  <li
                    className={`page-item ${
                      currentPageProgress === 1 ? "disabled" : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      onClick={() =>
                        handleProgressPageChange(currentPageProgress - 1)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <i className="icon-prev"></i>
                    </a>
                  </li>
                  {[
                    ...Array(totalPagesProgress - startPageProgress + 1).keys(),
                  ].map((page) => (
                    <li
                      key={startPageProgress + page}
                      className={`page-item ${
                        currentPageProgress === startPageProgress + page
                          ? "active"
                          : ""
                      }`}
                    >
                      <a
                        className="page-link"
                        onClick={() =>
                          handleProgressPageChange(startPageProgress + page)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {startPageProgress + page}
                      </a>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPageProgress === totalPagesProgress
                        ? "disabled"
                        : ""
                    }`}
                  >
                    <a
                      className="page-link"
                      onClick={() =>
                        handleProgressPageChange(currentPageProgress + 1)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <i className="icon-next"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            )} */}

{reportCount > 0 && renderPagination()}

</>    
)}
        </div>

      </div>
    </>
  );
};

export default Ranking;
