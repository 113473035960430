import React from 'react';

const Loader = ({ isLoader }) => {
  return (
    <div className="loader-container" >
      <div className={`loader ${isLoader ? 'loader--active' : ''}`}>
        <div className="loader__icon">
          <svg width="80" height="80" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1_591)">
              <rect y="0.282349" width="66.4354" height="66.4354" rx="15.0491" fill="#141B3C" />
              <rect x="17.0926" y="34.8087" width="0" height="7.12692" rx="3.56346" transform="rotate(-38.5482 17.0926 34.8087)" fill="#FDCC72">
                <animate attributeName="width" dur="2s" from="0" to="71.9071" repeatCount="indefinite" />
              </rect>
              <rect x="13.556" y="50.7231" width="0" height="6.18755" rx="3.09378" transform="rotate(-38.5482 13.556 50.7231)" fill="#FDCC72">
                <animate attributeName="width" dur="2s" from="0" to="71.9071" repeatCount="indefinite" />
              </rect>
              <path id="line1" d="M-2.48236 42.6675L20.5136 23.3716" stroke="white" strokeWidth="5.89403" strokeLinecap="round" strokeDasharray="150" strokeDashoffset="150">
                <animate attributeName="stroke-dashoffset" dur="2s" repeatCount="indefinite" from="150" to="0" />
              </path>
              <path id="line2" d="M20.6283 23.5651L40.9626 46.5518" stroke="white" strokeWidth="5.89403" strokeLinecap="round" strokeDasharray="150" strokeDashoffset="150">
                <animate attributeName="stroke-dashoffset" dur="2s" repeatCount="indefinite" from="150" to="0" />
              </path>
              <path id="line3" d="M41.2585 46.6971L77.2121 17.9658" stroke="white" strokeWidth="5.89403" strokeLinecap="round" strokeDasharray="150" strokeDashoffset="150">
                <animate attributeName="stroke-dashoffset" dur="2s" repeatCount="indefinite" from="150" to="0" />
              </path>
            </g>
            <defs>
              <clipPath id="clip0_1_591">
                <rect y="0.282349" width="66.4354" height="66.4354" rx="15.0491" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>

        

        <div className="loader__tile" style={{ backgroundColor: 'rgba(255, 255, 255, 0.9)', position:'fixed',height:"100%", width:"100%"}}></div>

      </div>
    </div>
  );
};

export default Loader;
