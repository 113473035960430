import React, { useState, useEffect, useRef } from "react";
// import Select from 'react-select';
import ReportPdf from "../images/report.pdf";
import Select from "react-select";
import { ReactComponent as Happyicon } from "../images/Happy.svg";
import { ReactComponent as Sadicon } from "../images/Sad.svg";
import { ReactComponent as Doubticon } from "../images/Doubtful.svg";
import Logo from "../images/logo.svg";
import Sidebar from "../component/Sidebar";
import IconSlide from "../images/btn-slide.svg";
import { useStopwatch } from 'react-timer-hook';
import IconPlay from "../images/icon-play.svg";
import IconPause from "../images/icon-pause.svg";

import DatePicker from 'rsuite/DatePicker';
import 'rsuite/DatePicker/styles/index.css';

import CurrencySelect from 'react-select-currency';
import { auto } from "@popperjs/core";

// const Report = () => {
function Report() {

  
  const [isPanelVisible, setIsPanelVisible] = useState(true);
  const standardsThRef = useRef(null);
  const swapSwitchThRef = useRef(null);

  const [month36Val, setMonth36Val] = useState(200000000);
  const [month24Val, setMonth24Val] = useState(190000000);

  const [calculate36Val, setCalculate36Val] = useState(0);
  const [calculate24Val, setCalculate24Val] = useState(0);

  // CURRENCY SELECT
const [selectedCurrency, setSelectedCurrency] = useState('');

// DATEPICKER
const [date, setDate] = useState(new Date());

// handle onChange event of the dropdown
const handleChange = (e, value, currency) => {
  setSelectedOption(e);
  setDate(value);
  setSelectedCurrency(currency);
};


const toggleTimer = () => {
  if (isRunning) {
    pause();
  } else {
    start();
  }
};
// END TIMER


// LINK MODAL
const [isActive, setIsActive] = useState(false);

const toggleMenu = () => {
  setIsActive(!isActive);
};


  const handleKeyPress = (event) => {
    // Check if Ctrl key and S key are pressed
    if (event.ctrlKey && event.key === "q") {
      // Trigger a click event on the standardsThRef
      standardsThRef.current.click();
    }
  };

  const handleSwitchPress = (event) => {
    // Check if Ctrl key and S key are pressed
    if (event.ctrlKey && event.key === "m") {
      // Trigger a click event on the standardsThRef
      swapSwitchThRef.current.click();
    }
  };


  const calculateARCashSum = (callback) => {
    const classes = [".clsCashItems", ".clsNonAssetCash", ".clsAcreceivables"];
    let sum = 0; // Declare sum using let

    classes.forEach(cls => {
      // Select elements with current class
      const elements = document.querySelectorAll(cls);

      // Loop through selected elements and calculate sum
      elements.forEach(element => {
             sum += parseFloat(element.value) || 0;
      });
      
  });

  
    setArAndCash(sum); // Assuming arAndCash is a state variable
     
  // console.log('calculateARCashSum() update : '+ sum);
  //      // After summing up, execute the callback
  //      if (callback && typeof callback === "function") {
  //       callback();
  //       }
  };






  const handleKeyPressReport = (event) => {
    // Check if Ctrl key and S key are pressed
    if (event.ctrlKey && event.key === "y") {
      // Apply styles to the report element
      const reportElement = document.getElementById("Reportbox1");
      if (reportElement) {
        reportElement.style.position = "fixed";
        reportElement.style.top = 0;
        reportElement.style.left = 0;
        reportElement.style.width = "100%";
        reportElement.style.height = "100%";
        reportElement.style.zIndex = 999;
        reportElement.style.marginTop = 0;
      }
      const reportViewer = document.getElementById("reportViewer");
      if (reportViewer) {
        reportViewer.style.height = "100vh";
      }
    }
  };

  const handleKeyPressScreen = (event) => {
    // Check if Ctrl key and S key are pressed
    if (event.ctrlKey && event.key === "i") {
      // Apply styles to the report element
      const reportElement = document.getElementById("Reportbox2");
      if (reportElement) {
        reportElement.style.position = "fixed";
        reportElement.style.top = 0;
        reportElement.style.left = 0;
        reportElement.style.width = "100%";
        reportElement.style.height = "100%";
        reportElement.style.zIndex = 999;
        reportElement.style.marginTop = 0;
      }
      const ReportScreening = document.getElementById("ReportScreening");
      if (ReportScreening) {
        ReportScreening.style.maxHeight = "100vh";
      }
      

    }
  };


  
  
  


  const handleKeyPressEsc = (event) => {
    // Check if Ctrl key and Esc key are pressed
    if (event.ctrlKey && event.key === "z") {
      // Remove styles from the report element
      const reportElement = document.getElementById("Reportbox1");
      if (reportElement) {
        reportElement.style.position = "";
        reportElement.style.top = "";
        reportElement.style.left = "";
        reportElement.style.width = "";
        reportElement.style.height = "";
        reportElement.style.zIndex = "";
        reportElement.style.marginTop = "";
      }
      const reportViewer = document.getElementById("reportViewer");
      if (reportViewer) {
        reportViewer.style.height = "";
      }

    }
  };

  const handleKeyPressEscScreen = (event) => {
    // Check if Ctrl key and Esc key are pressed
    if (event.ctrlKey && event.key === "x") {
      // Remove styles from the report element
      const reportElement = document.getElementById("Reportbox2");
      if (reportElement) {
        reportElement.style.position = "";
        reportElement.style.top = "";
        reportElement.style.left = "";
        reportElement.style.width = "";
        reportElement.style.height = "";
        reportElement.style.zIndex = "";
        reportElement.style.marginTop = "";
      }

      const ReportScreening = document.getElementById("ReportScreening");
      if (ReportScreening) {
        ReportScreening.style.maxHeight = "";
      }

    }
  };


  useEffect(() => {
    // Add event listener when component mounts
    window.addEventListener("keydown", handleKeyPress);
    window.addEventListener("keydown", handleSwitchPress);
    window.addEventListener("keydown", handleKeyPressReport);
    window.addEventListener("keydown", handleKeyPressEsc);

    window.addEventListener("keydown", handleKeyPressScreen);
    window.addEventListener("keydown", handleKeyPressEscScreen);
    
    // setMonth36Val("200000000");
    // setMonth24Val("190000000");

    //handleSelectChange(options.find(option => option.value === selectedValue));// call default select dropdown change event
    
    const defaultValue = options.find(option => option.value === "1000");
    if (defaultValue) {
      handleSelectChange(defaultValue);
    }

    // Remove event listener when component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      window.removeEventListener("keydown", handleSwitchPress);
      window.removeEventListener("keydown", handleKeyPressReport);
      window.removeEventListener("keydown", handleKeyPressEsc);
      window.removeEventListener("keydown", handleKeyPressScreen);
      window.removeEventListener("keydown", handleKeyPressEscScreen);
      
    };
  }, [isPanelVisible]); // Dependency array includes isPanelVisible to ensure effect runs when its value changes


  const [globalSum, setGlobalSum] = useState(0);
  const [selectedValue, setSelectedValue] = useState("1000"); //Dropdown value

  //Current assets : CASH ITEMs
  const [cashEquivalents, setCashEquivalents] = useState("0.00");
  const [restrictedCash, setRestrictedCash] = useState("0.00");
  const [etc, setEtc] = useState("0.00");
  const [totalSum, setTotalSum] = useState(0);

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptCashItem1, setSelectedOptCashItem1] = useState(null);
  const [selectedOptCashItem2, setSelectedOptCashItem2] = useState(null);
  const [selectedOptCashItem3, setSelectedOptCashItem3] = useState(null);

  //Current assets : Other interest-bearing assets
  const [otherAssetsLeases, setOtherAssetsLeases] = useState("0.00");
  const [otherAssetsNotes, setOtherAssetsNotes] = useState("0.00");
  const [otherAssetsLoans, setOtherAssetsLoans] = useState("0.00");
  const [otherAssetsEtc, setOtherAssetsEtc] = useState("0.00");
  const [totalOtherAssetsSum, setTotalOtherAssetsSum] = useState(0);
  const [accountsreceivables, setAccountsreceivables] = useState(0);
  
  const [selectedOptCashItem4, setSelectedOptCashItem4] = useState(null);
  const [selectedOptCashItem5, setSelectedOptCashItem5] = useState(null);
  const [selectedOptCashItem6, setSelectedOptCashItem6] = useState(null);
  const [selectedOptCashItem7, setSelectedOptCashItem7] = useState(null);

  //Non-current assets : CASH Items
  const [nonCashEquivalents, setNonCashEquivalents] = useState("0.00");
  const [nonRestrictedCash, setNonRestrictedCash] = useState("0.00");
  const [nonEtc, setNonEtc] = useState("0.00");
  const [nonCashItemSum, setNonCashItemSum] = useState(0);

  const [selectedOptCashItem8, setSelectedOptCashItem8] = useState(null);
  const [selectedOptCashItem9, setSelectedOptCashItem9] = useState(null);
  const [selectedOptCashItem10, setSelectedOptCashItem10] = useState(null);

  //Non-current assets : Other interest-bearing assets
  const [nonAssetsLeases, setNonAssetsLeases] = useState("0.00");
  const [nonAssetsNotes, setNonAssetsNotes] = useState("0.00");
  const [nonAssetsLoans, setNonAssetsLoans] = useState("0.00");
  const [nonAssetsEtc, setNonAssetsEtc] = useState("0.00");
  const [nonaccountsreceivables, setNonaccountsreceivables] = useState(0);
  const [arAndCash, setArAndCash] = useState(0);
  const [totalAssets, setTotalAssets] = useState(0);

  const [totalnonAssetsSum, setTotalnonAssetsSum] = useState(0);
  

  const [selectedOptCashItem11, setSelectedOptCashItem11] = useState(null);
  const [selectedOptCashItem12, setSelectedOptCashItem12] = useState(null);
  const [selectedOptCashItem13, setSelectedOptCashItem13] = useState(null);
  const [selectedOptCashItem14, setSelectedOptCashItem14] = useState(null);


    //Current liabilities : Interest bearing liabilities

  const [currentLiabilitiesTotalSum, setCurrentLiabilitiesTotalSum] = useState(0);
  const [currentLiabilitiesBorrowings, setCurrentLiabilitiesBorrowings] = useState("0.00");
  const [currentLiabilitiesFinancial, setCurrentLiabilitiesFinancial] = useState("0.00");
  const [currentLiabilitiesNotes, setCurrentLiabilitiesNotes] = useState("0.00");
  const [currentLiabilitiesEtc, setCurrentLiabilitiesEtc] = useState("0.00");
    

   //Non-current liabilities : Interest bearing liabilities
  const [nonCurrentLiabilitiesTotalSum, setNonCurrentLiabilitiesTotalSum] = useState(0);
  const [nonCurrentLiabilitiesLoans, setNonCurrentLiabilitiesLoans] = useState("0.00");
  const [nonCurrentLiabilitiesLongTermDebt, setNonCurrentLiabilitiesLongTermDebtl] = useState("0.00");
  const [nonCurrentLiabilitiesEtc, setNonCurrentLiabilitiesEtc] = useState("0.00");

 //INCOME STATEMENT : Advertising
 const [incomeStatementTotalRevenueSum, setIncomeStatementTotalRevenueSum] = useState(0);
 const [incomeStatementAdvertisingSum, setIncomeStatementAdvertisingSum] = useState(0);
 const [incomeStatementNonAdvertising, setIncomeStatementNonAdvertising] = useState("0.00");
 const [incomeStatementCompliant, setIncomeStatementCompliant] = useState("0.00");

 const [selectedOptCashItem15, setSelectedOptCashItem15] = useState(null);
 const [selectedOptCashItem16, setSelectedOptCashItem16] = useState(null);


//Games 
const [incomeStatementGamesSum, setIncomeStatementGamesSum] = useState(0);
const [incomeStatementAdultGames, setIncomeStatementAdultGames] = useState("0.00");
const [incomeStatementEducationalGames, setIncomeStatementEducationalGames] = useState("0.00");

const [selectedOptCashItem17, setSelectedOptCashItem17] = useState(null);
const [selectedOptCashItem18, setSelectedOptCashItem18] = useState(null);


//Beverages	
const [incomeStatementBeverageSum, setIncomeStatementBeverageSum] = useState(0);
const [incomeStatementNonAlcoholic, setIncomeStatementNonAlcoholic] = useState("0.00");
const [incomeStatementAlcoholic, setIncomeStatementAlcoholic] = useState("0.00");

const [selectedOptCashItem19, setSelectedOptCashItem19] = useState(null);
const [selectedOptCashItem20, setSelectedOptCashItem20] = useState(null);

//Meat	
const [incomeStatementMeatSum, setIncomeStatementMeatSum] = useState(0);
const [incomeStatementHalalBeef, setIncomeStatementHalalBeef] = useState("0.00");
const [incomeStatementHalalMutton, setIncomeStatementHalalMutton] = useState("0.00");
const [incomeStatementPork, setIncomeStatementPork] = useState("0.00");

const [selectedOptCashItem21, setSelectedOptCashItem21] = useState(null);
const [selectedOptCashItem22, setSelectedOptCashItem22] = useState(null);
const [selectedOptCashItem23, setSelectedOptCashItem23] = useState(null);

const [interestIncomeSum, SetInterestIncomeSum] = useState(0);
const [selectedOptCashItem24, setSelectedOptCashItem24] = useState(null);


  //Standards
  const [ratios, setRatios] = useState({
    IBAssetsTo36MC: 0,
    IBDebtTo36MC: 0,
    IBAssetsTo24MC: 0,
    IBAssetsToTA: 0,
    ARAndCashToTA: 0,
    ARAndCashTo36MC: 0,
    IBDebtToTA: 0
});

useEffect(() => {
  console.log('arAndCash updated:', arAndCash);
  calculateGlobalSum();
}, [arAndCash, totalOtherAssetsSum,currentLiabilitiesTotalSum,nonCurrentLiabilitiesTotalSum,incomeStatementAdvertisingSum,incomeStatementGamesSum,incomeStatementBeverageSum,incomeStatementMeatSum,interestIncomeSum,calculate36Val,calculate24Val]);

//Standards 1
const [ratios1, setRatios1] = useState({
  NoncompliantRevenue: 0,
  ImpermissibleRevenue: 0,
  DoubtfulRevenue: 0
  
});

const [statusCompliance, setStatusCompliance] = useState({
  ComplianceStatusAAOIFI: '-',
  ComplianceStatusDJIM: '-',
  ComplianceStatusSP: '-',
  ComplianceStatusFTSE: '-',
  ComplianceStatusMSCI: '-',
  ComplianceStatusMSCIMseries: '-',
  ComplianceStatusSACSC: '-',
  ComplianceStatusISSI: '-'
});




  //FUNCTIONS 

  // Function to calculate sum
  const calculateCurrentAssetSum = () => {
    // Select elements with class 'clsCashItems'
    const elements = document.querySelectorAll(".clsCashItems");

    let sum = 0;
    // Loop through selected elements and calculate sum
    elements.forEach((element) => {
          // Parse value as float and add to sum
          sum += parseFloat(element.value) || 0; 
    });

      // Update state with total sum
     setTotalSum(sum);
     calculateARCashSum();// Calculate AR & Cash

  //    calculateARCashSum(() => {
  //     console.log('ex');
  //     calculateGlobalSum();
      
  // });


    //  calculateARCashSum();// Calculate AR & Cash
    //  calculateGlobalSum();

  };

  const calculateOtherAssetSum = () => {
    // Select elements with class 'clsCashItems'
    const elements = document.querySelectorAll(".clsOtherassets");

    let sum = 0;
    // Loop through selected elements and calculate sum
    elements.forEach((element) => {
          // Parse value as float and add to sum
          sum += parseFloat(element.value) || 0;
    });

    // Update state with total sum
    setTotalOtherAssetsSum(sum);
   
    //calculateGlobalSum();
  };

  const calculateNonCurrentAssetSum = () => {
    // Select elements with class 'clsCashItems'
    const elements = document.querySelectorAll(".clsNonAssetCash");

    let sum = 0;
    // Loop through selected elements and calculate sum
    elements.forEach((element) => {
          // Parse value as float and add to sum
          sum += parseFloat(element.value) || 0;
    });

    // Update state with total sum
    setNonCashItemSum(sum);
    calculateARCashSum();// Calculate AR & Cash
    calculateGlobalSum();
  };

  const calculateNonCurrentOtherAssetSum = () => {
    // Select elements with class 'clsCashItems'
    const elements = document.querySelectorAll(".clsNonOtherAssets");

    let sum = 0;
    // Loop through selected elements and calculate sum
    elements.forEach((element) => {
          // Parse value as float and add to sum
          sum += parseFloat(element.value) || 0;
    });

    // Update state with total sum
    setTotalnonAssetsSum(sum);
    calculateGlobalSum();
  };

  const calculateCurrentLiabilitiesSum = () => {
    // Select elements with class 'clsCashItems'
    const elements = document.querySelectorAll(".clsCurrentLiabilities");

    let sum = 0;
    // Loop through selected elements and calculate sum
    elements.forEach((element) => {
          // Parse value as float and add to sum
          sum += parseFloat(element.value) || 0;
    });

    // Update state with total sum
    setCurrentLiabilitiesTotalSum(sum);
    calculateGlobalSum();
  };

  const calculateNonCurrentLiabilitiesSum = () => {
    // Select elements with class 'clsCashItems'
    const elements = document.querySelectorAll(".clsNonCurrentLiabilities");

    let sum = 0;
    // Loop through selected elements and calculate sum
    elements.forEach((element) => {
          // Parse value as float and add to sum
          sum += parseFloat(element.value) || 0;
    });

    // Update state with total sum
    setNonCurrentLiabilitiesTotalSum(sum);
    calculateGlobalSum();
  };

  const calculateIncomeStatementSum = () => {
    // Select elements with class 'clsCashItems'
    const elements = document.querySelectorAll(".clsAdvertising");

    let sum = 0;
    // Loop through selected elements and calculate sum
    elements.forEach((element) => {
          // Parse value as float and add to sum
          sum += parseFloat(element.value) || 0;
    });
    

    // Update state with total sum
    setIncomeStatementAdvertisingSum(sum);
    calculateGlobalSum();
  };

  //Games
  const calculateISGamesSum = () => {
    // Select elements with class 'clsCashItems'
    const elements = document.querySelectorAll(".clsGames");

    let sum = 0;
    // Loop through selected elements and calculate sum
    elements.forEach((element) => {
          // Parse value as float and add to sum
          sum += parseFloat(element.value) || 0;
    });

    // Update state with total sum
    setIncomeStatementGamesSum(sum);
    calculateGlobalSum();
  };

  //Beverages
  const calculateISBeveragesSum = () => {
    // Select elements with class 'clsCashItems'
    const elements = document.querySelectorAll(".clsBeverages");

    let sum = 0;
    // Loop through selected elements and calculate sum
    elements.forEach((element) => {
          // Parse value as float and add to sum
          sum += parseFloat(element.value) || 0;
    });

    // Update state with total sum
    setIncomeStatementBeverageSum(sum);
    calculateGlobalSum();
  };

  //Meat
  const calculateISMeatSum = () => {
    // Select elements with class 'clsCashItems'
    const elements = document.querySelectorAll(".clsMeat");

    let sum = 0;
    // Loop through selected elements and calculate sum
    elements.forEach((element) => {
          // Parse value as float and add to sum
          sum += parseFloat(element.value) || 0;
    });

    // Update state with total sum
    setIncomeStatementMeatSum(sum);
    calculateGlobalSum();
  };

  const calculateISSum = () => {
    // Select elements with class 'clsCashItems'
    const elements = document.querySelectorAll(".clsII");

    let sum = 0;
    // Loop through selected elements and calculate sum
    elements.forEach((element) => {
          // Parse value as float and add to sum
          sum += parseFloat(element.value) || 0;
    });

    // Update state with total sum
    SetInterestIncomeSum(sum);
    calculateGlobalSum();
  };


  // Function to handle keyup event
  const handleCurrentAssetsCashKeyUp = () => {
    calculateCurrentAssetSum();
  };

  const handleOtherAssetsCashKeyUp = () => {
    calculateOtherAssetSum();
  };

  const handleAccountsreceivables = () => {
   // calculateOtherAssetSum();
   calculateARCashSum();// Calculate AR & Cash

  };

  


  const handleNonCurrentAssetsCashKeyUp = () => {
    calculateNonCurrentAssetSum();
  };

  const handleNonCurrentOtherAssetsCashKeyUp = () => {
    calculateNonCurrentOtherAssetSum();
  };


  

  //Dropdown
  const options = [
    { value: "1", label: "Ones" },
    { value: "1000", label: "Thousands" },
    { value: "100000", label: "Lakhs" },
    { value: "1000000", label: "Millions" },
    { value: "10000000", label: "Crores" },
    { value: "1000000000", label: "Billions" },
  ];

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      //      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isFocused ? "#F8F8F8" : "",
        color: "#333333",
        borderBottom: 1,
        borderStyle: "solid",
        borderColor: "#F2F2F2",
        fontSize: 14,
        fontWeight: 500,
      };
    },
  };

  const data = [
    {
      value: 1,
      text: "",
      icon: <Happyicon />,
    },
    {
      value: 2,
      text: "",
      icon: <Sadicon />,
    },
    {
      value: 3,
      text: "",
      icon: <Doubticon />,
    }    
  ];
  


  const handleSelectChange = (selectedOption) => {
    
    setSelectedValue(selectedOption);
    //console.log(selectedOption.value); // Selected value
    
    const val36 = month36Val / selectedOption.value;
    const val24 = month24Val / selectedOption.value;
  
    setCalculate36Val(val36);
    setCalculate24Val(val24);

   // calculateGlobalSum();
    
  };


  // Function to calculate the global sum
  const calculateGlobalSum = () => {

    
    console.log('calculateGlobalSum fetch update value' + arAndCash); 
    
  
    let sum = 0;
    let sum1 = 0;
    let sumImpermissibleRevenue = 0;
   
    // Define classes to loop through
    const classes = [".clsCashItems", ".clsOtherassets", ".clsNonAssetCash", ".clsNonOtherAssets"];

    classes.forEach(cls => {
      // Select elements with current class
      const elements = document.querySelectorAll(cls);

      // Loop through selected elements and calculate sum
      elements.forEach(element => {
          if (element.getAttribute('data-id') === '2') {
              // Parse value as float and add to sum
              sum += parseFloat(element.value) || 0;
          }
          
      });

        

  });

  const classes1 = [".clsAdvertising", ".clsGames", ".clsBeverages", ".clsMeat",".clsII"];

  classes1.forEach(cls => {
    // Select elements with current class
    const elements = document.querySelectorAll(cls);

    // Loop through selected elements and calculate sum
    elements.forEach(element => {
        if (element.getAttribute('data-id') === '2') {
            // Parse value as float and add to sum
            sum1 += parseFloat(element.value) || 0;
        }
        else if(element.getAttribute('data-id') === '3')
          {
            sumImpermissibleRevenue += parseFloat(element.value) || 0;
          }
    });
});

    //  // Select elements with class 'clsCashItems'
    //  const elements = document.querySelectorAll(".clsCashItems");

    //  // Loop through selected elements and calculate sum
    //  elements.forEach((element) => {
     
    //      if (element.getAttribute('data-id') === '2') 
    //      {
    //        // Parse value as float and add to sum
    //        sum += parseFloat(element.value) || 0;
    //      }
    //  });


  
     // Calculate percentage
    //IB assets to 36MC
    let IBAssetspercentage = (sum / calculate36Val) * 100;
    IBAssetspercentage = Math.round(IBAssetspercentage * 100) / 100;

    //IB debt to 36MC
    let IBDebtTo36MCpercentag = (currentLiabilitiesTotalSum + nonCurrentLiabilitiesTotalSum)/calculate36Val;
    IBDebtTo36MCpercentag = IBDebtTo36MCpercentag * 100;

    //IB assets to 24MC
    let IBAssetsTo24MCpercentage = (sum / calculate24Val) * 100;
    IBAssetsTo24MCpercentage = Math.round(IBAssetsTo24MCpercentage * 100) / 100;

    //IB assets to TA
    let IBAssetsToTApercentage = parseFloat(((sum) / totalAssets * 100).toFixed(2));

    //AR & Cash to TA
    let ARAndCashToTApercentage = (arAndCash / totalAssets) * 100;
    //ARAndCashToTApercentage = Math.round(ARAndCashToTApercentage * 100) / 100;

    //AR & Cash to 36MC
    let ARAndCashTo36MCpercentage = (arAndCash / calculate36Val) * 100;
    //ARAndCashTo36MCpercentage = Math.round(ARAndCashTo36MCpercentage * 100) / 100;

    //IB debt to TA
    let IBDebtToTApercentage =  (currentLiabilitiesTotalSum + nonCurrentLiabilitiesTotalSum)/totalAssets;
    IBDebtToTApercentage = IBDebtToTApercentage *100;

    let IBAssetsTo36MC = isNaN(IBAssetspercentage) || !isFinite(IBAssetspercentage) ? 0 : IBAssetspercentage.toFixed(2);
IBAssetsTo36MC = IBAssetsTo36MC === "0.00" ? 0 : IBAssetsTo36MC;

let IBDebtTo36MC = isNaN(IBDebtTo36MCpercentag) || !isFinite(IBDebtTo36MCpercentag) ? 0 : IBDebtTo36MCpercentag.toFixed(2);
IBDebtTo36MC = IBDebtTo36MC === "0.00" ? 0 : IBDebtTo36MC;

let IBAssetsTo24MC = isNaN(IBAssetsTo24MCpercentage) || !isFinite(IBAssetsTo24MCpercentage) ? 0 : IBAssetsTo24MCpercentage.toFixed(2);
IBAssetsTo24MC = IBAssetsTo24MC === "0.00" ? 0 : IBAssetsTo24MC;

let IBAssetsToTA = isNaN(IBAssetsToTApercentage) || !isFinite(IBAssetsToTApercentage) ? 0 : IBAssetsToTApercentage.toFixed(2);
IBAssetsToTA = IBAssetsToTA === "0.00" ? 0 : IBAssetsToTA;

let ARAndCashToTA = isNaN(ARAndCashToTApercentage) || !isFinite(ARAndCashToTApercentage) ? 0 : ARAndCashToTApercentage.toFixed(2);
ARAndCashToTA = ARAndCashToTA === "0.00" ? 0 : ARAndCashToTA;

let ARAndCashTo36MC = isNaN(ARAndCashTo36MCpercentage) || !isFinite(ARAndCashTo36MCpercentage) ? 0 : ARAndCashTo36MCpercentage.toFixed(2);
ARAndCashTo36MC = ARAndCashTo36MC === "0.00" ? 0 : ARAndCashTo36MC;

let IBDebtToTA = isNaN(IBDebtToTApercentage) || !isFinite(IBDebtToTApercentage) ? 0 : IBDebtToTApercentage.toFixed(2);
IBDebtToTA = IBDebtToTA === "0.00" ? 0 : IBDebtToTA;

    

        // Update ratios state
        setRatios({
          IBAssetsTo36MC,
           IBDebtTo36MC,
           IBAssetsTo24MC,
           IBAssetsToTA,
           ARAndCashToTA,
           ARAndCashTo36MC,
           IBDebtToTA
      });

      //Non-compliant revenue
      let total = incomeStatementAdvertisingSum + incomeStatementGamesSum + incomeStatementBeverageSum + incomeStatementMeatSum + interestIncomeSum;
      let NoncompliantRevenuePercentage = (sum1/total) * 100;

      //Doubtful revenue 
      let DoubtfulRevenuePercentage = (sumImpermissibleRevenue/total) * 100;

      //Impermissible revenue
      let ImpermissibleRevenuePercentage = NoncompliantRevenuePercentage + DoubtfulRevenuePercentage;
      

         let NoncompliantRevenue =isNaN(NoncompliantRevenuePercentage) || !isFinite(NoncompliantRevenuePercentage) ? 0 : NoncompliantRevenuePercentage.toFixed(2);
         let DoubtfulRevenue = isNaN(DoubtfulRevenuePercentage) || !isFinite(DoubtfulRevenuePercentage) ? 0 : DoubtfulRevenuePercentage.toFixed(2); 
         let ImpermissibleRevenue =isNaN(ImpermissibleRevenuePercentage) || !isFinite(ImpermissibleRevenuePercentage) ? 0 : ImpermissibleRevenuePercentage.toFixed(2); 
         
         

      // Update ratios state
      setRatios1({
        NoncompliantRevenue,
        DoubtfulRevenue,
        ImpermissibleRevenue
        
        
        
    });


    // Compliance Status
   //let ComplianceStatusAAOIFI = (Math.max(A, B) >= 30) ? 'Non-compliant' : '-';
   let ComplianceStatusAAOIFI =  (Math.max(IBAssetsTo36MC, IBDebtTo36MC) === 0 || NoncompliantRevenue === 0 || ImpermissibleRevenue === 0) ? "-" :(Math.max(IBAssetsTo36MC, IBDebtTo36MC) >= 30) ? "Non-compliant" : (NoncompliantRevenue >= 5) ? "Non-compliant" : (ImpermissibleRevenue >= 5) ? "Doubtful" : "Compliant";
   let ComplianceStatusDJIM = (Math.max(IBAssetsTo24MC) === 0 || NoncompliantRevenue === 0 || ImpermissibleRevenue === 0) ? "-" : (Math.max(IBAssetsTo24MC) >= 33) ? "Non-compliant" : (NoncompliantRevenue >= 5) ? "Non-compliant" : (ImpermissibleRevenue >= 5) ? "Doubtful" : "Compliant";	
   let ComplianceStatusSP = (Math.max(IBAssetsTo36MC) === 0 || ImpermissibleRevenue === 0) ? "-" : (Math.max(IBAssetsTo36MC) >= 33) ? "Non-compliant" : (ImpermissibleRevenue >= 5) ? "Doubtful" : "Compliant";
   let ComplianceStatusFTSE = (Math.max(IBAssetsToTA, ARAndCashToTA, IBDebtToTA) === 0 || ImpermissibleRevenue === 0) ? "-" : (Math.max(IBAssetsToTA,ARAndCashToTA,IBDebtToTA) >= 33.333) ? "Non-compliant" : (ImpermissibleRevenue >= 5) ? "Doubtful" : "Compliant";	
   let ComplianceStatusMSCI = (Math.max(IBAssetsToTA, ARAndCashToTA, IBDebtToTA) === 0 || ImpermissibleRevenue === 0) ? "-" : (Math.max(IBAssetsToTA,ARAndCashToTA,IBDebtToTA) >= 33.33) ? "Non-compliant" : (ImpermissibleRevenue >= 5) ? "Doubtful" : "Compliant";	
   let ComplianceStatusMSCIMseries = (Math.max(IBAssetsTo36MC, IBDebtTo36MC, ARAndCashTo36MC) === 0 || ImpermissibleRevenue === 0) ? "-" :  (Math.max(IBAssetsTo36MC,IBDebtTo36MC,ARAndCashTo36MC) >= 33.33) ? "Non-compliant" : (ImpermissibleRevenue >= 5) ? "Doubtful" : "Compliant";		
   let ComplianceStatusSACSC = (Math.max(IBAssetsToTA, IBDebtToTA) === 0 || ImpermissibleRevenue === 0) ? "-" :(Math.max(IBAssetsToTA,IBDebtToTA) >= 33) ? "Non-compliant" : (ImpermissibleRevenue >= 5) ? "Doubtful" : "Compliant";		
   let ComplianceStatusISSI  = (Math.max(IBDebtToTA[0]) === 0 || ImpermissibleRevenue === 0) ? "-" : (Math.max(IBDebtToTA) >= 45) ? "Non-compliant" : (ImpermissibleRevenue >= 10) ? "Doubtful" : "Compliant";		    

    setStatusCompliance({
      ComplianceStatusAAOIFI,
      ComplianceStatusDJIM,		
      ComplianceStatusSP,	
      ComplianceStatusFTSE,			
      ComplianceStatusMSCI,	
      ComplianceStatusMSCIMseries,	
      ComplianceStatusSACSC,	
      ComplianceStatusISSI      
  });


  };

  const [sum, setSum] = useState(0);
  // handle onChange event of the dropdown
  // const handleChange = (selectedOption, setStateFunc, textboxValue) => {
  //   calculateCurrentAssetSum();
  // };

  //Dropdown change event
  //Current Assets : CASH ITEMS
  const handleChangeCashItem1 = (selectedOption) => {
    setSelectedOptCashItem1(selectedOption);

    const textBox = document.querySelector('#txt1'); // Assuming you have only one element with class 'clsCashItems'
    textBox.setAttribute('data-id', selectedOption.value);

    //if (selectedOption && selectedOption.value === 2) {
      calculateGlobalSum();
  // }

  };

  const handleChangeCashItem2 = (selectedOption) => {
     setSelectedOptCashItem2(selectedOption);
     const textBox = document.querySelector('#txt2'); // Assuming you have only one element with class 'clsCashItems'
    textBox.setAttribute('data-id', selectedOption.value);

    //if (selectedOption && selectedOption.value === 2) {
       calculateGlobalSum();
    //}
  };

  const handleChangeCashItem3 = (selectedOption) => {
     setSelectedOptCashItem3(selectedOption);
     const textBox = document.querySelector('#txt3'); // Assuming you have only one element with class 'clsCashItems'
     textBox.setAttribute('data-id', selectedOption.value);
    //if (selectedOption && selectedOption.value === 2) {
       calculateGlobalSum();
   // }
  };

 //Current Assets : Other interest bearing assets
 const handleChangeCashItem4 = (selectedOption) => {
  setSelectedOptCashItem4(selectedOption);
  const textBox = document.querySelector('#txt4'); // Assuming you have only one element with class 'clsCashItems'
  textBox.setAttribute('data-id', selectedOption.value);
    calculateGlobalSum();
};

const handleChangeCashItem5 = (selectedOption) => {
  setSelectedOptCashItem5(selectedOption);
  const textBox = document.querySelector('#txt5'); // Assuming you have only one element with class 'clsCashItems'
  textBox.setAttribute('data-id', selectedOption.value);
    calculateGlobalSum();
};

const handleChangeCashItem6 = (selectedOption) => {
  setSelectedOptCashItem6(selectedOption);
  const textBox = document.querySelector('#txt6'); // Assuming you have only one element with class 'clsCashItems'
  textBox.setAttribute('data-id', selectedOption.value);
    calculateGlobalSum();
};

const handleChangeCashItem7 = (selectedOption) => {
  setSelectedOptCashItem7(selectedOption);
  const textBox = document.querySelector('#txt7'); // Assuming you have only one element with class 'clsCashItems'
  textBox.setAttribute('data-id', selectedOption.value);
    calculateGlobalSum();
};

  //Non-Current Assets : CASH ITEMS
  const handleChangeCashItem8 = (selectedOption) => {
    setSelectedOptCashItem8(selectedOption);
    const textBox = document.querySelector('#txt9'); // Assuming you have only one element with class 'clsCashItems'
    textBox.setAttribute('data-id', selectedOption.value);
      calculateGlobalSum();
  };
  const handleChangeCashItem9 = (selectedOption) => {
    setSelectedOptCashItem9(selectedOption);
    const textBox = document.querySelector('#txt10'); // Assuming you have only one element with class 'clsCashItems'
    textBox.setAttribute('data-id', selectedOption.value);
      calculateGlobalSum();
  };

  const handleChangeCashItem10 = (selectedOption) => {
    setSelectedOptCashItem10(selectedOption);
    const textBox = document.querySelector('#txt11'); // Assuming you have only one element with class 'clsCashItems'
    textBox.setAttribute('data-id', selectedOption.value);
      calculateGlobalSum();
  };

   //Non-Current Assets : CASH ITEMS
   const handleChangeCashItem11 = (selectedOption) => {
    setSelectedOptCashItem11(selectedOption);
    const textBox = document.querySelector('#txt12'); // Assuming you have only one element with class 'clsCashItems'
    textBox.setAttribute('data-id', selectedOption.value);
      calculateGlobalSum();
  };
  const handleChangeCashItem12 = (selectedOption) => {
    setSelectedOptCashItem12(selectedOption);
    const textBox = document.querySelector('#txt13'); // Assuming you have only one element with class 'clsCashItems'
    textBox.setAttribute('data-id', selectedOption.value);
      calculateGlobalSum();
  };

  const handleChangeCashItem13 = (selectedOption) => {
    setSelectedOptCashItem13(selectedOption);
    const textBox = document.querySelector('#txt14'); // Assuming you have only one element with class 'clsCashItems'
    textBox.setAttribute('data-id', selectedOption.value);
      calculateGlobalSum();
  };

  const handleChangeCashItem14 = (selectedOption) => {
    setSelectedOptCashItem14(selectedOption);
    const textBox = document.querySelector('#txt15'); // Assuming you have only one element with class 'clsCashItems'
    textBox.setAttribute('data-id', selectedOption.value);
      calculateGlobalSum();
  };

 // Income Statement
  const handleChangeCashItem15 = (selectedOption) => {
    setSelectedOptCashItem15(selectedOption);
    const textBox = document.querySelector('#txt26'); // Assuming you have only one element with class 'clsCashItems'
    textBox.setAttribute('data-id', selectedOption.value);
      calculateGlobalSum();
  };

  const handleChangeCashItem16 = (selectedOption) => {
    setSelectedOptCashItem16(selectedOption);
    const textBox = document.querySelector('#txt27'); // Assuming you have only one element with class 'clsCashItems'
    textBox.setAttribute('data-id', selectedOption.value);
      calculateGlobalSum();
  };

// Games
const handleChangeCashItem17 = (selectedOption) => {
  setSelectedOptCashItem17(selectedOption);
  const textBox = document.querySelector('#txt28'); // Assuming you have only one element with class 'clsCashItems'
  textBox.setAttribute('data-id', selectedOption.value);
    calculateGlobalSum();
};

const handleChangeCashItem18 = (selectedOption) => {
  setSelectedOptCashItem18(selectedOption);
  const textBox = document.querySelector('#txt29'); // Assuming you have only one element with class 'clsCashItems'
  textBox.setAttribute('data-id', selectedOption.value);
    calculateGlobalSum();
};

const handleChangeCashItem19 = (selectedOption) => {
  setSelectedOptCashItem19(selectedOption);
  const textBox = document.querySelector('#txt30'); // Assuming you have only one element with class 'clsCashItems'
  textBox.setAttribute('data-id', selectedOption.value);
    calculateGlobalSum();
};

const handleChangeCashItem20 = (selectedOption) => {
  setSelectedOptCashItem20(selectedOption);
  const textBox = document.querySelector('#txt31'); // Assuming you have only one element with class 'clsCashItems'
  textBox.setAttribute('data-id', selectedOption.value);
    calculateGlobalSum();
};

const handleChangeCashItem21 = (selectedOption) => {
  setSelectedOptCashItem21(selectedOption);
  const textBox = document.querySelector('#txt32'); // Assuming you have only one element with class 'clsCashItems'
  textBox.setAttribute('data-id', selectedOption.value);
    calculateGlobalSum();
};

const handleChangeCashItem22 = (selectedOption) => {
  setSelectedOptCashItem22(selectedOption);
  const textBox = document.querySelector('#txt33'); // Assuming you have only one element with class 'clsCashItems'
  textBox.setAttribute('data-id', selectedOption.value);
    calculateGlobalSum();
};

const handleChangeCashItem23 = (selectedOption) => {
  setSelectedOptCashItem23(selectedOption);
  const textBox = document.querySelector('#txt34'); // Assuming you have only one element with class 'clsCashItems'
  textBox.setAttribute('data-id', selectedOption.value);
    calculateGlobalSum();
};

const handleChangeCashItem24 = (selectedOption) => {
  setSelectedOptCashItem24(selectedOption);
  const textBox = document.querySelector('#txt35'); // Assuming you have only one element with class 'clsCashItems'
  textBox.setAttribute('data-id', selectedOption.value);
    calculateGlobalSum();
};


  //const defaultValue = options[0];
  const defaultValue = "1000"; // value ke base par set karein
  const [isBox1Visible, setIsBox1Visible] = useState(true);

  const toggleBoxes = () => {
    setIsBox1Visible(!isBox1Visible);
  };
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true });

  return (
    <>
      <div className='Screening_sidebar'><Sidebar/></div>
      <div id='header_screening'>
          <div className='logo'>
            <div className='logo_icon'>
              <img src={Logo} />
            </div>            
          </div>

          <div className='header_right_section'>
            <div className='header_section_top'>
              <div className='brand_name'>Apple Inc. <span>AAPL</span></div>
              <div className='header_monthly_value'>
                <div className='month_price_row three_year_price'>
                  <div className='month_col'>
                  36<span>-mo</span>
                  </div>
                  <div className='price_white_col'>
                  {month36Val} <span>USD</span>
                  <span className='divider'>|</span>
                  <span className='value_sortname'>20 M <strong>USD</strong></span>
                  </div>
                </div>

                <div className='month_price_row three_year_price'>
                  <div className='month_col'>
                  24<span>-mo</span>
                  </div>
                  <div className='price_white_col'>
                  {month24Val} <span>USD</span>
                    <span className='divider'>|</span>
                  <span className='value_sortname'>19 M <strong>USD</strong></span>
                  </div>
                </div>
              </div>
            </div>

            <div className='header_section_bottom'>
                <div className='header_control_links'>
                <div className='timer'>
                  <div>
                    <span>{hours}</span>:<span>{minutes}</span>:<span>{seconds}</span>
                  </div>
                  <button onClick={toggleTimer} className='btn_timer_control'>
                    {isRunning ? <img src={IconPause} alt="Pause" /> : <img src={IconPlay} alt="Play" />}
                  </button>
                  </div>

                  <div className='lisk_dropdown_row'>
                      <a href='#' className='link_trigger' onClick={toggleMenu}><i className='icon-link'></i></a>

                      <div className={`paste_link_box ${isActive ? 'active' : ''}`}>
                        <div className='paste_link_header'>
                          Paste Links
                        </div>
                        <div className='paste_link_body'>
                            <div className='link_field_row'>
                                <label><i className='icon-link'></i>Link</label>
                                <input type='text' className='form-control' placeholder='https://' />
                            </div>

                            <div className='link_field_row'>
                                <label><i className='icon-link'></i>AR</label>
                                <input type='text' className='form-control' placeholder='https://' />
                            </div>
                        </div>
                      </div>
                  </div>

                  <div className='toggle_switch'>
                    {/* <input type="checkbox" id="switch" /><label for="switch">Toggle</label> */}

                    <label class="switch"><input type="checkbox" /><div></div></label>
                  </div>
                </div>

                <div className='header_filter_col'>
                <div className='filter_col'>
                <div className='date_col_filter'>
                    <span>Date</span>
                    <div className='date_col_text calendar_col'>
                      <DatePicker oneTap style={{ width: 140 }} placeholder="Select Date" />
                    </div>
                  </div>

                  <div className='date_col_filter'>
                    <span>FY</span>
                    <div className='date_col_text calendar_col'>
                      {/* Annual, 2023 */}                    
                      <input type="text" className="form-control" placeholder="Annual Date" style={{backgroundColor:"#F5F5F5", border:0, maxWidth:100}}/>
                    </div>
                  </div>

                  <div className='currency_dropdown_col_filter'>
                  <Select options={options}
                  styles={colourStyles}
                  onChange={handleSelectChange}
                  //value={selectedValue}
                  value={options.find(option => option.value === selectedValue)}
                  />
                  
                  </div>

                  <div className='currency_col_filter'>
                    {/* <button className='btn_currency_refresh'><i className='icon-refresh'></i></button> */}
                    <CurrencySelect
                          value={selectedCurrency}
                          onChange={handleChange}
                          placeholder="USD"
                      />
                  </div>
              </div>
                </div>
            </div>
          </div>                    
      </div>
      
      
      <div className="container-fluid content pb-5">
        <div className="report_table">
          <div className="table-responsive">
            <table className="table table_main_report">
              <thead>
                <tr>
                  <th
                    className="standard_th"
                    data-bs-toggle="collapse"
                    data-bs-target="#StandardsAcco"
                    ref={standardsThRef}
                  >Standards
                    {/* <label htmlFor="Standards">Standards</label> */}
                  </th>
                  <th>
                    <span className={statusCompliance.ComplianceStatusAAOIFI === 'Non-compliant' ? 'table_icon_cross' : statusCompliance.ComplianceStatusAAOIFI === 'Doubtful' ? 'table_icon_exlamation' : statusCompliance.ComplianceStatusAAOIFI === 'Compliant' ? 'table_icon_check' : ''}>
                      <i className={statusCompliance.ComplianceStatusAAOIFI === 'Non-compliant' ? 'icon-cross' : statusCompliance.ComplianceStatusAAOIFI === 'Doubtful' ? 'icon-exclamation' : statusCompliance.ComplianceStatusAAOIFI === 'Compliant' ? 'icon-check' : ''}></i>AAOIFI
                    </span>
                  </th>
                  <th>
                    <span className={statusCompliance.ComplianceStatusDJIM === 'Non-compliant' ? 'table_icon_cross' : statusCompliance.ComplianceStatusDJIM === 'Doubtful' ? 'table_icon_exlamation' : statusCompliance.ComplianceStatusDJIM === 'Compliant' ? 'table_icon_check' : ''}>
                      <i className={statusCompliance.ComplianceStatusDJIM === 'Non-compliant' ? 'icon-cross' : statusCompliance.ComplianceStatusDJIM === 'Doubtful' ? 'icon-exclamation' : statusCompliance.ComplianceStatusDJIM === 'Compliant' ? 'icon-check' : ''}></i>DJIM</span>
                  </th>
                  <th>
                    <span className={statusCompliance.ComplianceStatusSP === 'Non-compliant' ? 'table_icon_cross' : statusCompliance.ComplianceStatusSP === 'Doubtful' ? 'table_icon_exlamation' : statusCompliance.ComplianceStatusSP === 'Compliant' ? 'table_icon_check' : ''}>
                      <i className={statusCompliance.ComplianceStatusSP === 'Non-compliant' ? 'icon-cross' : statusCompliance.ComplianceStatusSP === 'Doubtful' ? 'icon-exclamation' : statusCompliance.ComplianceStatusSP === 'Compliant' ? 'icon-check' : ''}></i>S&P</span>
                  </th>
                  <th>
                    <span className={statusCompliance.ComplianceStatusFTSE === 'Non-compliant' ? 'table_icon_cross' : statusCompliance.ComplianceStatusFTSE === 'Doubtful' ? 'table_icon_exlamation' : statusCompliance.ComplianceStatusFTSE === 'Compliant' ? 'table_icon_check' : ''}>
                      <i className={statusCompliance.ComplianceStatusFTSE === 'Non-compliant' ? 'icon-cross' : statusCompliance.ComplianceStatusFTSE === 'Doubtful' ? 'icon-exclamation' : statusCompliance.ComplianceStatusFTSE === 'Compliant' ? 'icon-check' : ''}></i>FTSE</span>
                  </th>
                  <th>
                    <span className={statusCompliance.ComplianceStatusMSCI === 'Non-compliant' ? 'table_icon_cross' : statusCompliance.ComplianceStatusMSCI === 'Doubtful' ? 'table_icon_exlamation' : statusCompliance.ComplianceStatusMSCI === 'Compliant' ? 'table_icon_check' : ''}>
                      <i className={statusCompliance.ComplianceStatusMSCI === 'Non-compliant' ? 'icon-cross' : statusCompliance.ComplianceStatusMSCI === 'Doubtful' ? 'icon-exclamation' : statusCompliance.ComplianceStatusMSCI === 'Compliant' ? 'icon-check' : ''}></i>MSCI</span>
                  </th>
                  <th>
                    <span className={statusCompliance.ComplianceStatusMSCIMseries === 'Non-compliant' ? 'table_icon_cross' : statusCompliance.ComplianceStatusMSCIMseries === 'Doubtful' ? 'table_icon_exlamation' : statusCompliance.ComplianceStatusMSCIMseries === 'Compliant' ? 'table_icon_check' : ''}>
                      <i className={statusCompliance.ComplianceStatusMSCIMseries === 'Non-compliant' ? 'icon-cross' : statusCompliance.ComplianceStatusMSCIMseries === 'Doubtful' ? 'icon-exclamation' : statusCompliance.ComplianceStatusMSCIMseries === 'Compliant' ? 'icon-check' : ''}></i>MSCI M-series</span>
                  </th>
                  <th>
                    <span className={statusCompliance.ComplianceStatusSACSC === 'Non-compliant' ? 'table_icon_cross' : statusCompliance.ComplianceStatusSACSC === 'Doubtful' ? 'table_icon_exlamation' : statusCompliance.ComplianceStatusSACSC === 'Compliant' ? 'table_icon_check' : ''}>
                      <i className={statusCompliance.ComplianceStatusSACSC === 'Non-compliant' ? 'icon-cross' : statusCompliance.ComplianceStatusSACSC === 'Doubtful' ? 'icon-exclamation' : statusCompliance.ComplianceStatusSACSC === 'Compliant' ? 'icon-check' : ''}></i>SAC-SC</span>
                  </th>
                  <th>
                    <span className={statusCompliance.ComplianceStatusISSI === 'Non-compliant' ? 'table_icon_cross' : statusCompliance.ComplianceStatusISSI === 'Doubtful' ? 'table_icon_exlamation' : statusCompliance.ComplianceStatusISSI === 'Compliant' ? 'table_icon_check' : ''}>
                      <i className={statusCompliance.ComplianceStatusISSI === 'Non-compliant' ? 'icon-cross' : statusCompliance.ComplianceStatusISSI === 'Doubtful' ? 'icon-exclamation' : statusCompliance.ComplianceStatusISSI === 'Compliant' ? 'icon-check' : ''}></i>ISSI</span>
                  </th>
                </tr>
              </thead>
              <tbody className="collapse accordion-collapse" id="StandardsAcco" data-bs-parent=".table_main_report">
                
                        <tr className="threshold_row">
                          <td className="left_align_td">Threshold</td>
                          <td>30%</td>
                          <td>33%</td>
                          <td>33%</td>
                          <td>33.333%</td>
                          <td>33.33%</td>
                          <td>33.33%</td>
                          <td>33%</td>
                          <td>45%</td>
                        </tr>
                        <tr>
                          <td className="left_align_td">IB assets to 36MC</td>
                          <td className={ratios.IBAssetsTo36MC === 0 ? "" : (ratios.IBAssetsTo36MC > 30 ? "tble_red_color" : "tble_green_color")}>{ratios.IBAssetsTo36MC}%</td>
                          <td>-</td>
                          <td className={ratios.IBAssetsTo36MC === 0 ? "" : (ratios.IBAssetsTo36MC > 33 ? "tble_red_color" : "tble_green_color")}>{ratios.IBAssetsTo36MC}%</td>
                          <td>-</td>
                          <td>-</td>
                          <td className={ratios.IBAssetsTo36MC === 0 ? "" : (ratios.IBAssetsTo36MC > 33.33 ? "tble_red_color" : "tble_green_color")}>{ratios.IBAssetsTo36MC}%</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td className="left_align_td">IB debt to 36MC</td>
                          <td className={ratios.IBDebtTo36MC === 0 ? "" : (ratios.IBDebtTo36MC > 30 ? "tble_red_color" : "tble_green_color")}>{ratios.IBDebtTo36MC}%</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td className={ratios.IBDebtTo36MC === 0 ? "" : (ratios.IBDebtTo36MC > 33.33 ? "tble_red_color" : "tble_green_color")}>{ratios.IBDebtTo36MC}%</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td className="left_align_td">IB assets to 24MC</td>
                          <td>-</td>
                          <td className={ratios.IBAssetsTo24MC === 0 ? "" : (ratios.IBAssetsTo24MC > 33 ? "tble_red_color" : "tble_green_color")}>{ratios.IBAssetsTo24MC}%</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td className="left_align_td">IB assets to TA</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td className={ratios.IBAssetsToTA === 0 ? "" : (ratios.IBAssetsToTA > 33.333 ? "tble_red_color" : "tble_green_color")}>{ratios.IBAssetsToTA}%</td>
                          <td className={ratios.IBAssetsToTA === 0 ? "" : (ratios.IBAssetsToTA > 33.33 ? "tble_red_color" : "tble_green_color")}>{ratios.IBAssetsToTA}%</td>
                          <td>-</td>
                          <td className={ratios.IBAssetsToTA === 0 ? "" : (ratios.IBAssetsToTA > 33 ? "tble_red_color" : "tble_green_color")}>{ratios.IBAssetsToTA}% </td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td className="left_align_td">AR & Cash to TA</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td className={ratios.ARAndCashToTA === 0 ? "" : (ratios.ARAndCashToTA > 33.333 ? "tble_red_color" : "tble_green_color")}>{ratios.ARAndCashToTA}%</td>
                          <td className={ratios.ARAndCashToTA === 0 ? "" : (ratios.ARAndCashToTA > 33.33 ? "tble_red_color" : "tble_green_color")}>{ratios.ARAndCashToTA}%</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td className="left_align_td">AR & Cash to 36MC</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td className={ratios.ARAndCashTo36MC === 0 ? "" : (ratios.ARAndCashTo36MC > 33.33 ? "tble_red_color" : "tble_green_color")}>{ratios.ARAndCashTo36MC}%</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                        <tr>
                          <td className="left_align_td">IB debt to TA</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td className={ratios.IBDebtToTA === 0 ? "" : (ratios.IBDebtToTA > 33.333 ? "tble_red_color" : "tble_green_color")}>{ratios.IBDebtToTA}%</td>
                          <td className={ratios.IBDebtToTA === 0 ? "" : (ratios.IBDebtToTA > 33.33 ? "tble_red_color" : "tble_green_color")}>{ratios.IBDebtToTA}%</td>
                          <td>-</td>
                          <td className={ratios.IBDebtToTA === 0 ? "" : (ratios.IBDebtToTA > 33 ? "tble_red_color" : "tble_green_color")}>{ratios.IBDebtToTA}%</td>
                          <td className={ratios.IBDebtToTA === 0 ? "" : (ratios.IBDebtToTA > 45 ? "tble_red_color" : "tble_green_color")}>{ratios.IBDebtToTA}%</td>
                        </tr>
                        <tr className="threshold_row">
                          <td className="left_align_td">Threshold</td>
                          <td>5%</td>
                          <td>5%</td>
                          <td>5%</td>
                          <td>5%</td>
                          <td>5%</td>
                          <td>5%</td>
                          <td>5%</td>
                          <td>10%</td>
                        </tr>
                        <tr>
                          <td className="left_align_td">
                            Non-compliant revenue
                          </td>
                          <td className={ratios1.NoncompliantRevenue === 0 ? "" : (ratios1.NoncompliantRevenue >= 5 ? "tble_red_color" : "tble_green_color")}>{ratios1.NoncompliantRevenue}%</td>
                          <td className={ratios1.NoncompliantRevenue === 0 ? "" : (ratios1.NoncompliantRevenue >= 5 ? "tble_red_color" : "tble_green_color")}>{ratios1.NoncompliantRevenue}%</td>
                          <td className={ratios1.NoncompliantRevenue === 0 ? "" : (ratios1.NoncompliantRevenue >= 5 ? "tble_red_color" : "tble_green_color")}>{ratios1.NoncompliantRevenue}%</td>
                          <td className={ratios1.NoncompliantRevenue === 0 ? "" : (ratios1.NoncompliantRevenue >= 5 ? "tble_red_color" : "tble_green_color")}>{ratios1.NoncompliantRevenue}%</td>
                          <td className={ratios1.NoncompliantRevenue === 0 ? "" : (ratios1.NoncompliantRevenue >= 5 ? "tble_red_color" : "tble_green_color")}>{ratios1.NoncompliantRevenue}%</td>
                          <td className={ratios1.NoncompliantRevenue === 0 ? "" : (ratios1.NoncompliantRevenue >= 5 ? "tble_red_color" : "tble_green_color")}>{ratios1.NoncompliantRevenue}%</td>
                          <td className={ratios1.NoncompliantRevenue === 0 ? "" : (ratios1.NoncompliantRevenue >= 5 ? "tble_red_color" : "tble_green_color")}>{ratios1.NoncompliantRevenue}%</td>
                          <td className={ratios1.NoncompliantRevenue === 0 ? "" : (ratios1.NoncompliantRevenue >= 10 ? "tble_red_color" : "tble_green_color")}>{ratios1.NoncompliantRevenue}%</td>
                        </tr>
                        <tr>
                          <td className="left_align_td">Doubtful revenue</td>
                          <td className={ratios1.DoubtfulRevenue === 0 ? "" : (ratios1.DoubtfulRevenue >= 5 ? "tble_lyellow_color" : "tble_green_color")}>{ratios1.DoubtfulRevenue}%</td>
                          <td className={ratios1.DoubtfulRevenue === 0 ? "" : (ratios1.DoubtfulRevenue >= 5 ? "tble_lyellow_color" : "tble_green_color")}>{ratios1.DoubtfulRevenue}%</td>
                          <td className={ratios1.DoubtfulRevenue === 0 ? "" : (ratios1.DoubtfulRevenue >= 5 ? "tble_lyellow_color" : "tble_green_color")}>{ratios1.DoubtfulRevenue}%</td>
                          <td className={ratios1.DoubtfulRevenue === 0 ? "" : (ratios1.DoubtfulRevenue >= 5 ? "tble_lyellow_color" : "tble_green_color")}>{ratios1.DoubtfulRevenue}%</td>
                          <td className={ratios1.DoubtfulRevenue === 0 ? "" : (ratios1.DoubtfulRevenue >= 5 ? "tble_lyellow_color" : "tble_green_color")}>{ratios1.DoubtfulRevenue}%</td>
                          <td className={ratios1.DoubtfulRevenue === 0 ? "" : (ratios1.DoubtfulRevenue >= 5 ? "tble_lyellow_color" : "tble_green_color")}>{ratios1.DoubtfulRevenue}%</td>
                          <td className={ratios1.DoubtfulRevenue === 0 ? "" : (ratios1.DoubtfulRevenue >= 5 ? "tble_lyellow_color" : "tble_green_color")}>{ratios1.DoubtfulRevenue}%</td>
                          <td className={ratios1.DoubtfulRevenue === 0 ? "" : (ratios1.DoubtfulRevenue >= 10 ? "tble_lyellow_color" : "tble_green_color")}>{ratios1.DoubtfulRevenue}%</td>
                        </tr>

                        <tr>
                          <td className="left_align_td">
                            Impermissible revenue
                          </td>
                          <td className={ratios1.ImpermissibleRevenue === 0 ? "" : (ratios1.NoncompliantRevenue >= 5 ? "tble_red_color" : (ratios1.NoncompliantRevenue < 5 && ratios1.ImpermissibleRevenue < 5 ? "tble_green_color" : (ratios1.NoncompliantRevenue < 5 && ratios1.ImpermissibleRevenue >= 5 ? "tble_lyellow_color" : "")))}> {ratios1.ImpermissibleRevenue}%</td>
                          <td className={ratios1.ImpermissibleRevenue === 0 ? "" : (ratios1.NoncompliantRevenue >= 5 ? "tble_red_color" : (ratios1.NoncompliantRevenue < 5 && ratios1.ImpermissibleRevenue < 5 ? "tble_green_color" : (ratios1.NoncompliantRevenue < 5 && ratios1.ImpermissibleRevenue >= 5 ? "tble_lyellow_color" : "")))}> {ratios1.ImpermissibleRevenue}%</td>
                          <td className={ratios1.ImpermissibleRevenue === 0 ? "" : (ratios1.NoncompliantRevenue >= 5 ? "tble_red_color" : (ratios1.NoncompliantRevenue < 5 && ratios1.ImpermissibleRevenue < 5 ? "tble_green_color" : (ratios1.NoncompliantRevenue < 5 && ratios1.ImpermissibleRevenue >= 5 ? "tble_lyellow_color" : "")))}> {ratios1.ImpermissibleRevenue}%</td>
                          <td className={ratios1.ImpermissibleRevenue === 0 ? "" : (ratios1.NoncompliantRevenue >= 5 ? "tble_red_color" : (ratios1.NoncompliantRevenue < 5 && ratios1.ImpermissibleRevenue < 5 ? "tble_green_color" : (ratios1.NoncompliantRevenue < 5 && ratios1.ImpermissibleRevenue >= 5 ? "tble_lyellow_color" : "")))}> {ratios1.ImpermissibleRevenue}%</td>
                          <td className={ratios1.ImpermissibleRevenue === 0 ? "" : (ratios1.NoncompliantRevenue >= 5 ? "tble_red_color" : (ratios1.NoncompliantRevenue < 5 && ratios1.ImpermissibleRevenue < 5 ? "tble_green_color" : (ratios1.NoncompliantRevenue < 5 && ratios1.ImpermissibleRevenue >= 5 ? "tble_lyellow_color" : "")))}> {ratios1.ImpermissibleRevenue}%</td>
                          <td className={ratios1.ImpermissibleRevenue === 0 ? "" : (ratios1.NoncompliantRevenue >= 5 ? "tble_red_color" : (ratios1.NoncompliantRevenue < 5 && ratios1.ImpermissibleRevenue < 5 ? "tble_green_color" : (ratios1.NoncompliantRevenue < 5 && ratios1.ImpermissibleRevenue >= 5 ? "tble_lyellow_color" : "")))}> {ratios1.ImpermissibleRevenue}%</td>
                          <td className={ratios1.ImpermissibleRevenue === 0 ? "" : (ratios1.NoncompliantRevenue >= 5 ? "tble_red_color" : (ratios1.NoncompliantRevenue < 5 && ratios1.ImpermissibleRevenue < 5 ? "tble_green_color" : (ratios1.NoncompliantRevenue < 5 && ratios1.ImpermissibleRevenue >= 5 ? "tble_lyellow_color" : "")))}> {ratios1.ImpermissibleRevenue}%</td>
                          <td className={ratios1.ImpermissibleRevenue === 0 ? "" : (ratios1.NoncompliantRevenue >= 10 ? "tble_red_color" : (ratios1.NoncompliantRevenue < 10 && ratios1.ImpermissibleRevenue < 10 ? "tble_green_color" : (ratios1.NoncompliantRevenue < 10 && ratios1.ImpermissibleRevenue >= 10 ? "tble_lyellow_color" : "")))}> {ratios1.ImpermissibleRevenue}%</td>
                          
                        </tr>
                       
                        <tr>
                        {/* tble_red_color
                        tble_yellow_color
                        tble_green_color */}
                        
                          <td className="left_align_td">Compliance</td>
                          <td className={statusCompliance.ComplianceStatusAAOIFI === 'Non-compliant' ? 'tble_red_color' : statusCompliance.ComplianceStatusAAOIFI === 'Doubtful' ? 'tble_yellow_color' : statusCompliance.ComplianceStatusAAOIFI === 'Compliant' ? 'tble_green_color' :  ''}>{statusCompliance.ComplianceStatusAAOIFI}</td>
                          <td className={statusCompliance.ComplianceStatusDJIM === 'Non-compliant' ? 'tble_red_color' : statusCompliance.ComplianceStatusDJIM === 'Doubtful' ? 'tble_yellow_color' : statusCompliance.ComplianceStatusDJIM === 'Compliant' ? 'tble_green_color' :  ''}>{statusCompliance.ComplianceStatusDJIM}</td>
                          <td className={statusCompliance.ComplianceStatusSP === 'Non-compliant' ? 'tble_red_color' : statusCompliance.ComplianceStatusSP === 'Doubtful' ? 'tble_yellow_color' : statusCompliance.ComplianceStatusSP === 'Compliant' ? 'tble_green_color' :  ''}>{statusCompliance.ComplianceStatusSP}</td>
                          <td className={statusCompliance.ComplianceStatusFTSE === 'Non-compliant' ? 'tble_red_color' : statusCompliance.ComplianceStatusFTSE === 'Doubtful' ? 'tble_yellow_color' : statusCompliance.ComplianceStatusFTSE === 'Compliant' ? 'tble_green_color' :  ''}>{statusCompliance.ComplianceStatusFTSE}</td>
                          <td className={statusCompliance.ComplianceStatusMSCI === 'Non-compliant' ? 'tble_red_color' : statusCompliance.ComplianceStatusMSCI === 'Doubtful' ? 'tble_yellow_color' : statusCompliance.ComplianceStatusMSCI === 'Compliant' ? 'tble_green_color' :  ''}>{statusCompliance.ComplianceStatusMSCI}</td>
                          <td className={statusCompliance.ComplianceStatusMSCIMseries === 'Non-compliant' ? 'tble_red_color' : statusCompliance.ComplianceStatusMSCIMseries === 'Doubtful' ? 'tble_yellow_color' : statusCompliance.ComplianceStatusMSCIMseries === 'Compliant' ? 'tble_green_color' :  ''}>{statusCompliance.ComplianceStatusMSCIMseries}</td>
                          <td className={statusCompliance.ComplianceStatusSACSC === 'Non-compliant' ? 'tble_red_color' : statusCompliance.ComplianceStatusSACSC === 'Doubtful' ? 'tble_yellow_color' : statusCompliance.ComplianceStatusSACSC === 'Compliant' ? 'tble_green_color' :  ''}>{statusCompliance.ComplianceStatusSACSC}</td>
                          <td className={statusCompliance.ComplianceStatusISSI === 'Non-compliant' ? 'tble_red_color' : statusCompliance.ComplianceStatusISSI === 'Doubtful' ? 'tble_yellow_color' : statusCompliance.ComplianceStatusISSI === 'Compliant' ? 'tble_green_color' :  ''}>{statusCompliance.ComplianceStatusISSI}</td>
                        </tr>
                      
              </tbody>
            </table>
          </div>
        </div>

        <div className='report_table_data_row'>
        <div id="Reportbox1" className={isBox1Visible ? 'screening_report_pdf' : 'slide-in'} >
          <iframe id="reportViewer" title="Report PDF" width="100%" src={ReportPdf} className='iframe_pdf'></iframe>
        </div>

        <button className='btn_slide_trigger' onClick={toggleBoxes} id="toggleBtn"  ref={swapSwitchThRef}><img src={IconSlide} /></button>
        
        <div id="Reportbox2" className={!isBox1Visible ? 'slide-out' : 'financial_table_col'}>
        <div className="financial_table" >
              <div className="table-responsive" id="ReportScreening">
                <table className="table accordion table_accordion">
                  <thead>
                    <tr>
                      <th colSpan={3}>FINANCIAL SCREENING</th>
                    </tr>
                    <tr>
                      <td colSpan={3}>Current assets</td>
                    </tr>
                  </thead>
                  <tbody>

                  <tr className="cash_items_row">
                      <td className="">Cash Items</td>
                      <td col className="text_right">
                      {totalSum}
                      </td>
                      <td>&nbsp;</td>
                    </tr>

                    {/* <tr className="cash_items_row">
                      
                      <td><div style={{display:"flex",alignItems:"center"}}><button className="add_more_btn"><i className="icon-plus"></i></button> <div>Cash Items</div></div></td>
                      <td className="text_right">{totalSum}</td>
                      <td>&nbsp;</td>
                    </tr> */}

                    <tr className="">
                      {/* <td>
                        <div style={{display:"flex",alignItems:"center",gap:6}}> 
                        <ul className="remove_Comment list_HoverControl">
                          <li><a><i className="icon-delete"></i></a></li>
                          <li><a><i className="icon-comment"></i></a></li>
                          {/* <li><a><i className="icon-plus"></i></a></li> 
                      </ul>
                       <div>Cash and cash equivalents</div></div></td> */}

                  <td>Cash and cash equivalents</td>
                      <td className="text_right">
                        <input
                          id="txt1"
                          type="text"
                          className="borderNone clsCashItems"
                          value={cashEquivalents}
                          onChange={(e) => {
                            setCashEquivalents(e.target.value);
                            handleCurrentAssetsCashKeyUp(e); // Call handleCurrentAssetsCashKeyUp here
                          }}

                          // onChange={(e) => setCashEquivalents(e.target.value)}
                          // onKeyDown={handleCurrentAssetsCashKeyUp}
                          data-id=""
                        />
                      </td>

                      <td className="">
                        <div class="select_box select_box_red_caret">
                          <Select
                            placeholder=""
                            className="clsCalculation"
                            value={selectedOptCashItem1}
                            options={data}
                            onChange={handleChangeCashItem1}
                           //onChange={(selectedOption) => handleChange(selectedOption, setSelectedOptCashItem1, cashEquivalents)}
                            styles={colourStyles}
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {e.icon}
                              </div>
                            )}
                            menuIsOpen={true}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Restricted cash</td>
                      {/* <td>
                        <div style={{display:"flex",alignItems:"center",gap:6}}> 
                        <ul className="remove_Comment list_HoverControl">
                          <li><a><i className="icon-delete"></i></a></li>
                          <li><a><i className="icon-comment"></i></a></li>
                          {/* <li><a><i className="icon-plus"></i></a></li> 
                      </ul>
                       <div>Restricted cash</div></div></td> */}
                      <td className="text_right">
                        <input
                          id="txt2"
                          type="text"
                          className="borderNone clsCashItems"
                          value={restrictedCash}
                          onChange={(e) => {
                            setRestrictedCash(e.target.value);
                            handleCurrentAssetsCashKeyUp(e); // Call handleCurrentAssetsCashKeyUp here
                          }}
                          // onChange={(e) => setRestrictedCash(e.target.value)}
                          // onKeyUp={handleCurrentAssetsCashKeyUp}
                          data-id=""
                        />
                      </td>
                      <td>
                        <div class="select_box select_box_red_caret">
                          <Select
                            placeholder=""
                             className="clsCalculation"
                            value={selectedOptCashItem2}
                            options={data}
                            onChange={handleChangeCashItem2}
                            styles={colourStyles}
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {e.icon}
                              </div>
                            )}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td className="">Etc.</td>
                      {/* <td>
                        <div style={{display:"flex",alignItems:"center",gap:6}}> 
                        <ul className="remove_Comment list_HoverControl">
                          <li><a><i className="icon-delete"></i></a></li>
                          <li><a><i className="icon-comment"></i></a></li>
                          {/* <li><a><i className="icon-plus"></i></a></li> 
                      </ul>
                       <div>Etc.</div></div></td> */}
                      <td className="text_right">
                        <input
                          id="txt3"
                          type="text"
                          className="borderNone clsCashItems"
                          value={etc}
                          onChange={(e) => {
                            setEtc(e.target.value);
                            handleCurrentAssetsCashKeyUp(e); // Call handleCurrentAssetsCashKeyUp here
                          }}

                          // onChange={(e) => setEtc(e.target.value)}
                          // onKeyUp={handleCurrentAssetsCashKeyUp}
                          data-id=""
                        />
                      </td>
                      <td>
                        <div class="select_box select_box_red_caret">
                          <Select
                            placeholder=""
                            value={selectedOptCashItem3}
                            options={data}
                            onChange={handleChangeCashItem3}
                            styles={colourStyles}
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {e.icon}
                              </div>
                            )}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="cash_items_row">
                      <td className="">Other interest-bearing assets</td>
                      <td col className="text_right">
                      {totalOtherAssetsSum}
                      </td>
                      <td>&nbsp;</td>
                    </tr>

                    <tr>
                    <td className="">Leases</td>
                      <td className="text_right">
                        <input
                          id="txt4"
                          type="text"
                          className="borderNone clsOtherassets"
                          value={otherAssetsLeases}
                          onChange={(e) => setOtherAssetsLeases(e.target.value)}
                          onKeyUp={handleOtherAssetsCashKeyUp}
                          data-id=""
                        />
                      </td>
                      <td>
                        <div class="select_box select_box_red_caret">
                          <Select
                            placeholder=""
                            value={selectedOptCashItem4}
                            options={data}
                            onChange={handleChangeCashItem4}
                            styles={colourStyles}
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {e.icon}
                              </div>
                            )}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                    <td className="">Notes</td>
                      <td className="text_right">
                        <input
                          id="txt5"
                          type="text"
                          className="borderNone clsOtherassets"
                          value={otherAssetsNotes}
                          onChange={(e) => setOtherAssetsNotes(e.target.value)}
                          onKeyUp={handleOtherAssetsCashKeyUp}
                          data-id=""
                        />
                      </td>
                      <td>
                        <div class="select_box select_box_red_caret">
                          <Select
                            placeholder=""
                            value={selectedOptCashItem5}
                            options={data}
                            onChange={handleChangeCashItem5}
                            styles={colourStyles}
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {e.icon}
                              </div>
                            )}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                    <td className="">Loans</td>
                      <td className="text_right">
                        <input
                          id="txt6"
                          type="text"
                          className="borderNone clsOtherassets"
                          value={otherAssetsLoans}
                          onChange={(e) => setOtherAssetsLoans(e.target.value)}
                          onKeyUp={handleOtherAssetsCashKeyUp}
                          data-id=""
                        />
                      </td>
                      <td>
                        <div class="select_box select_box_red_caret">
                          <Select
                            placeholder=""
                            value={selectedOptCashItem6}
                            options={data}
                            onChange={handleChangeCashItem6}
                            styles={colourStyles}
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {e.icon}
                              </div>
                            )}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                    <td className="">Etc.</td>
                      <td className="text_right">
                        <input
                          id="txt7"
                          type="text"
                          className="borderNone clsOtherassets"
                          value={otherAssetsEtc}
                          onChange={(e) => setOtherAssetsEtc(e.target.value)}
                          onKeyUp={handleOtherAssetsCashKeyUp}
                          data-id=""
                        />
                      </td>
                      <td>
                        <div class="select_box select_box_red_caret">
                          <Select
                            placeholder=""
                            value={selectedOptCashItem7}
                            options={data}
                            onChange={handleChangeCashItem7}
                            styles={colourStyles}
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {e.icon}
                              </div>
                            )}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="tbl_manual_row">
                      <td>Accounts receivables</td>
                      <td className="text_right">
                        <input
                          id="txt8"
                          type="text"
                          className="borderNone clsAcreceivables"
                          value={accountsreceivables}
                          onChange={(e) => {
                            setAccountsreceivables(e.target.value);
                            handleAccountsreceivables(e); // Call handleCurrentAssetsCashKeyUp here
                          }}

                          //onChange={(e) => setAccountsreceivables(e.target.value)}
                          //onKeyUp={handleOtherAssetsCashKeyUp}
                          data-id=""
                        />
                      </td>
                      <td>&nbsp;</td>
                    </tr>


                    <tr className="tbl_current_row">
                      <td colSpan={3}>Non-current assets</td>
                    </tr>

                    <tr className="cash_items_row">
                      <td>Cash Items</td>
                      <td className="text_right">{nonCashItemSum}</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Cash and cash equivalents</td>
                      <td className="text_right">
                      <input
                          id="txt9"
                          type="text"
                          className="borderNone clsNonAssetCash"
                          value={nonCashEquivalents}
                          onChange={(e) => {
                            setNonCashEquivalents(e.target.value);
                            handleNonCurrentAssetsCashKeyUp(e); // Call handleCurrentAssetsCashKeyUp here
                          }}

                          // onChange={(e) => setNonCashEquivalents(e.target.value)}
                          // onKeyUp={handleNonCurrentAssetsCashKeyUp}
                          data-id=""
                        />
                      </td>
                      <td>
                        <div class="select_box select_box_red_caret">
                          <Select
                            placeholder=""
                            value={selectedOptCashItem8}
                            options={data}
                            onChange={handleChangeCashItem8}
                            styles={colourStyles}
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {e.icon}
                              </div>
                            )}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Restricted cash</td>
                      <td className="text_right">
                      <input
                          id="txt10"
                          type="text"
                          className="borderNone clsNonAssetCash"
                          value={nonRestrictedCash}
                          onChange={(e) => {
                            setNonRestrictedCash(e.target.value);
                            handleNonCurrentAssetsCashKeyUp(e); // Call handleCurrentAssetsCashKeyUp here
                          }}

                          // onChange={(e) => setNonRestrictedCash(e.target.value)}
                          // onKeyUp={handleNonCurrentAssetsCashKeyUp}
                          data-id=""
                        />

                      </td>
                      <td>
                        <div class="select_box select_box_red_caret">
                          <Select
                            placeholder=""
                            value={selectedOptCashItem9}
                            options={data}
                            onChange={handleChangeCashItem9}
                            styles={colourStyles}
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {e.icon}
                              </div>
                            )}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Etc.</td>
                      <td className="text_right">
                      <input
                          id="txt11"
                          type="text"
                          className="borderNone clsNonAssetCash"
                          value={nonEtc}
                          onChange={(e) => {
                            setNonEtc(e.target.value);
                            handleNonCurrentAssetsCashKeyUp(e); // Call handleCurrentAssetsCashKeyUp here
                          }}

                          // onChange={(e) => setNonEtc(e.target.value)}
                          // onKeyUp={handleNonCurrentAssetsCashKeyUp}

                          data-id=""
                        />
                      </td>
                      <td>
                        <div class="select_box select_box_red_caret">
                          <Select
                            placeholder=""
                            value={selectedOptCashItem10}
                            options={data}
                            onChange={handleChangeCashItem10}
                            styles={colourStyles}
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {e.icon}
                              </div>
                            )}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="cash_items_row">
                      <td>Other interest-bearing assets</td>
                      <td className="text_right">{totalnonAssetsSum}</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Leases</td>
                      <td className="text_right">
                        <input
                          id="txt12"
                          type="text"
                          className="borderNone clsNonOtherAssets"
                          value={nonAssetsLeases}
                          onChange={(e) => setNonAssetsLeases(e.target.value)}
                          onKeyUp={handleNonCurrentOtherAssetsCashKeyUp}
                          data-id=""
                        />
                      </td>
                      <td>
                        <div class="select_box select_box_red_caret">
                          <Select
                            placeholder=""
                            value={selectedOptCashItem11}
                            options={data}
                            onChange={handleChangeCashItem11}
                            styles={colourStyles}
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {e.icon}
                              </div>
                            )}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Notes</td>
                      <td className="text_right">
                        <input
                          id="txt13"
                          type="text"
                          className="borderNone clsNonOtherAssets"
                          value={nonAssetsNotes}
                          onChange={(e) => setNonAssetsNotes(e.target.value)}
                          onKeyUp={handleNonCurrentOtherAssetsCashKeyUp}
                          data-id=""
                        />
                      </td>
                      <td>
                        <div class="select_box select_box_red_caret">
                          <Select
                            placeholder=""
                            value={selectedOptCashItem12}
                            options={data}
                            onChange={handleChangeCashItem12}
                            styles={colourStyles}
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {e.icon}
                              </div>
                            )}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Loans</td>
                      <td className="text_right">
                        <input
                          id="txt14"
                          type="text"
                          className="borderNone clsNonOtherAssets"
                          value={nonAssetsLoans}
                          onChange={(e) => setNonAssetsLoans(e.target.value)}
                          onKeyUp={handleNonCurrentOtherAssetsCashKeyUp}
                          data-id=""
                        />
                      </td>
                      <td>
                        <div class="select_box select_box_red_caret">
                          <Select
                            placeholder=""
                            value={selectedOptCashItem13}
                            options={data}
                            onChange={handleChangeCashItem13}
                            styles={colourStyles}
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {e.icon}
                              </div>
                            )}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Etc.</td>
                      <td className="text_right">
                        <input
                          id="txt15"
                          type="text"
                          className="borderNone clsNonOtherAssets"
                          value={nonAssetsEtc}
                          onChange={(e) => setNonAssetsEtc(e.target.value)}
                          onKeyUp={handleNonCurrentOtherAssetsCashKeyUp}
                          data-id=""
                        />
                      </td>
                      <td>
                        <div class="select_box select_box_red_caret">
                          <Select
                            placeholder=""
                            value={selectedOptCashItem14}
                            options={data}
                            onChange={handleChangeCashItem14}
                            styles={colourStyles}
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {e.icon}
                              </div>
                            )}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr className="tbl_manual_row">
                      <td>Accounts receivables</td>
                      <td className="text_right">
                      <input
                          id="txt16"
                          type="text"
                          className="borderNone clsAcreceivables"
                          value={nonaccountsreceivables}
                          onChange={(e) => {
                            setNonaccountsreceivables(e.target.value);
                            handleAccountsreceivables(e); // Call handleCurrentAssetsCashKeyUp here
                          }}

                          //onChange={(e) => setNonaccountsreceivables(e.target.value)}
                          //onKeyUp={handleNonCurrentOtherAssetsCashKeyUp}
                          data-id=""
                        />
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr className="tbl_manual_row">
                      <td>AR and Cash</td>
                      <td className="text_right">
                      <input
                          readOnly
                          id="txt17"
                          type="text"
                          className="borderNone"
                          value={arAndCash}
                          //value={Number(totalSum) + Number(accountsreceivables) + Number(nonCashItemSum) + Number(nonaccountsreceivables)}
                          onChange={(e) => setArAndCash(e.target.value)}
                          //onKeyUp={calculateNonCurrentOtherAssetSum}
                          onKeyUp={calculateARCashSum}
                          data-id=""
                        />
                      </td>
                      <td>&nbsp;</td>
                    </tr>

                    <tr className="tbl_total_assets_row">
                      <td>TOTAL ASSETS</td>
                      <td className="text_right">
                      <input
                          id="txt18"
                          type="text"
                          className="borderNone"
                          value={totalAssets}
                          onChange={(e) => setTotalAssets(e.target.value)}
                          onKeyUp={calculateNonCurrentOtherAssetSum}
                          data-id=""
                        />

                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr className="tbl_current_row">
                      <td colSpan={3}>Current liabilities</td>
                    </tr>
                    <tr className="cash_items_row">
                      <td>Interest-bearing liabilites</td>
                      <td className="text_right">{currentLiabilitiesTotalSum}</td>
                      <td>&nbsp;</td>
                    </tr>

                    <tr>
                      <td>Borrowings</td>
                      <td className="text_right">
                      <input
                          id="txt19"
                          type="text"
                          className="borderNone clsCurrentLiabilities"
                          value={currentLiabilitiesBorrowings}
                          // onChange={(e) => setCurrentLiabilitiesBorrowings(e.target.value)}
                          // onKeyUp={calculateCurrentLiabilitiesSum}
                          onChange={(e) => {
                            setCurrentLiabilitiesBorrowings(e.target.value);
                            calculateCurrentLiabilitiesSum(e); // Call handleCurrentAssetsCashKeyUp here
                          }}
                          data-id=""
                        />
                      </td>
                      <td>&nbsp;</td>
                    </tr>

                    <tr>
                      <td>Financial lease liabilities</td>
                      <td className="text_right">
                      <input
                          id="txt20"
                          type="text"
                          className="borderNone clsCurrentLiabilities"
                          value={currentLiabilitiesFinancial}
                          onChange={(e) => setCurrentLiabilitiesFinancial(e.target.value)}
                          onKeyUp={calculateCurrentLiabilitiesSum}
                          data-id=""
                        />
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Notes payable</td>
                      <td className="text_right">
                      <input
                          id="txt21"
                          type="text"
                          className="borderNone clsCurrentLiabilities"
                          value={currentLiabilitiesNotes}
                          onChange={(e) => setCurrentLiabilitiesNotes(e.target.value)}
                          onKeyUp={calculateCurrentLiabilitiesSum}
                          data-id=""
                        />
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Etc.</td>
                      <td className="text_right">
                      <input
                          id="txt22"
                          type="text"
                          className="borderNone clsCurrentLiabilities"
                          value={currentLiabilitiesEtc}
                          onChange={(e) => setCurrentLiabilitiesEtc(e.target.value)}
                          onKeyUp={calculateCurrentLiabilitiesSum}
                          data-id=""
                        />
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr className="tbl_current_row">
                      <td colSpan={3}>Non-current liabilities</td>
                    </tr>
                    <tr className="cash_items_row">
                      <td>Interest-bearing liabilites</td>
                      <td className="text_right">{nonCurrentLiabilitiesTotalSum}</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Loans</td>
                      <td className="text_right">
                      <input
                          id="txt23"
                          type="text"
                          className="borderNone clsNonCurrentLiabilities"
                          value={nonCurrentLiabilitiesLoans}
                          onChange={(e) => setNonCurrentLiabilitiesLoans(e.target.value)}
                          onKeyUp={calculateNonCurrentLiabilitiesSum}
                          data-id=""
                        />
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Long term debt, net of current portion</td>
                      <td className="text_right">
                      <input
                          id="txt24"
                          type="text"
                          className="borderNone clsNonCurrentLiabilities"
                          value={nonCurrentLiabilitiesLongTermDebt}
                          onChange={(e) => setNonCurrentLiabilitiesLongTermDebtl(e.target.value)}
                          onKeyUp={calculateNonCurrentLiabilitiesSum}
                          data-id=""
                        />
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Etc.</td>
                      <td className="text_right">
                      <input
                          id="txt25"
                          type="text"
                          className="borderNone clsNonCurrentLiabilities"
                          value={nonCurrentLiabilitiesEtc}
                          onChange={(e) => setNonCurrentLiabilitiesEtc(e.target.value)}
                          onKeyUp={calculateNonCurrentLiabilitiesSum}
                          data-id=""
                        />
                      </td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr className="tbl_current_row">
                      <td colSpan={3}>INCOME STATEMENT</td>
                    </tr>
                    <tr className="cash_items_row">
                      <td>Total revenue</td>
                      <td className="text_right">
                      <input
                          readOnly
                          id="txt"
                          type="text"
                          className="borderNone"
                          //value={incomeStatementTotalRevenueSum}
                          value={incomeStatementAdvertisingSum + incomeStatementGamesSum + incomeStatementBeverageSum + incomeStatementMeatSum + interestIncomeSum}
                          //{incomeStatementAdvertisingSum + incomeStatementGamesSum + incomeStatementBeverageSum + incomeStatementMeatSum + interestIncomeSum}</td>
                          onChange={(e) => setIncomeStatementTotalRevenueSum(e.target.value)}
                          //onKeyUp={calculateNonCurrentOtherAssetSum}
                          //onKeyUp={calculateIntrestIncome}
                          data-id=""
                        />
                       </td> 
                      <td>&nbsp;</td>
                    </tr>
                    <tr className="cash_items_row">
                      <td>Advertising</td>
                      <td className="text_right">{incomeStatementAdvertisingSum}</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Non-compliant advertising</td>
                      <td className="text_right">
                      <input
                          id="txt26"
                          type="text"
                          className="borderNone clsAdvertising clsTotalRevenue"
                          value={incomeStatementNonAdvertising}
                          onChange={(e) => setIncomeStatementNonAdvertising(e.target.value)}
                          onKeyUp={calculateIncomeStatementSum}
                          data-id=""
                        />
                      </td>
                      <td>
                        <div class="select_box select_box_red_caret">
                          <Select
                            placeholder=""
                            value={selectedOptCashItem15}
                            options={data}
                            onChange={handleChangeCashItem15}
                            styles={colourStyles}
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {e.icon}
                              </div>
                            )}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Compliant advertising</td>
                      <td className="text_right">
                      <input
                          id="txt27"
                          type="text"
                          className="borderNone clsAdvertising clsTotalRevenue"
                          value={incomeStatementCompliant}
                          onChange={(e) => setIncomeStatementCompliant(e.target.value)}
                          onKeyUp={calculateIncomeStatementSum}
                          data-id=""
                        />
                      </td>
                      <td>
                        <div class="select_box select_box_red_caret">
                          <Select
                            placeholder=""
                            value={selectedOptCashItem16}
                            options={data}
                            onChange={handleChangeCashItem16}
                            styles={colourStyles}
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {e.icon}
                              </div>
                            )}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="cash_items_row">
                      <td>Games</td>
                      <td className="text_right">{incomeStatementGamesSum}</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Adult games</td>
                      <td className="text_right">
                      <input
                          id="txt28"
                          type="text"
                          className="borderNone clsGames"
                          value={incomeStatementAdultGames}
                          onChange={(e) => setIncomeStatementAdultGames(e.target.value)}
                          onKeyUp={calculateISGamesSum}
                          data-id=""
                        />
                      </td>
                      <td>
                        <div class="select_box select_box_red_caret">
                          <Select
                            placeholder=""
                            value={selectedOptCashItem17}
                            options={data}
                            onChange={handleChangeCashItem17}
                            styles={colourStyles}
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {e.icon}
                              </div>
                            )}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Educational games</td>
                      <td className="text_right">
                      <input
                          id="txt29"
                          type="text"
                          className="borderNone clsGames"
                          value={incomeStatementEducationalGames}
                          onChange={(e) => setIncomeStatementEducationalGames(e.target.value)}
                          onKeyUp={calculateISGamesSum}
                          data-id=""
                        />
                      </td>
                      <td>
                        <div class="select_box select_box_red_caret">
                          <Select
                            placeholder=""
                            value={selectedOptCashItem18}
                            options={data}
                            onChange={handleChangeCashItem18}
                            styles={colourStyles}
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {e.icon}
                              </div>
                            )}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="cash_items_row">
                      <td>Beverages</td>
                      <td className="text_right">{incomeStatementBeverageSum}</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Non-alcoholic beverages</td>
                      <td className="text_right">
                      <input
                          id="txt30"
                          type="text"
                          className="borderNone clsBeverages"
                          value={incomeStatementNonAlcoholic}
                          onChange={(e) => setIncomeStatementNonAlcoholic(e.target.value)}
                          onKeyUp={calculateISBeveragesSum}
                          data-id=""
                        />
                      </td>
                      <td>
                        <div class="select_box select_box_red_caret">
                          <Select
                            placeholder=""
                            value={selectedOptCashItem19}
                            options={data}
                            onChange={handleChangeCashItem19}
                            styles={colourStyles}
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {e.icon}
                              </div>
                            )}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Alcoholic beverages</td>
                      <td className="text_right">
                      <input
                          id="txt31"
                          type="text"
                          className="borderNone clsBeverages"
                          value={incomeStatementAlcoholic}
                          onChange={(e) => setIncomeStatementAlcoholic(e.target.value)}
                          onKeyUp={calculateISBeveragesSum}
                          data-id=""
                        />
                      </td>
                      <td>
                        <div class="select_box select_box_red_caret">
                          <Select
                            placeholder=""
                            value={selectedOptCashItem20}
                            options={data}
                            onChange={handleChangeCashItem20}
                            styles={colourStyles}
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {e.icon}
                              </div>
                            )}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="cash_items_row">
                      <td>Meat</td>
                      <td className="text_right">{incomeStatementMeatSum}</td>
                      <td>&nbsp;</td>
                    </tr>
                    <tr>
                      <td>Halal certifed beef</td>
                      <td className="text_right">
                      <input
                          id="txt32"
                          type="text"
                          className="borderNone clsMeat"
                          value={incomeStatementHalalBeef}
                          onChange={(e) => setIncomeStatementHalalBeef(e.target.value)}
                          onKeyUp={calculateISMeatSum}
                          data-id=""
                        />
                      </td>
                      <td>
                        <div class="select_box select_box_red_caret">
                          <Select
                            placeholder=""
                            value={selectedOptCashItem21}
                            options={data}
                            onChange={handleChangeCashItem21}
                            styles={colourStyles}
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {e.icon}
                              </div>
                            )}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>Halal certified  mutton</td>
                      <td className="text_right">
                      <input
                          id="txt33"
                          type="text"
                          className="borderNone clsMeat"
                          value={incomeStatementHalalMutton}
                          onChange={(e) => setIncomeStatementHalalMutton(e.target.value)}
                          onKeyUp={calculateISMeatSum}
                          data-id=""
                        />
                      </td>
                      <td>
                        <div class="select_box select_box_red_caret">
                          <Select
                            placeholder=""
                            value={selectedOptCashItem22}
                            options={data}
                            onChange={handleChangeCashItem22}
                            styles={colourStyles}
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {e.icon}
                              </div>
                            )}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>Pork</td>
                      <td className="text_right">
                      <input
                          id="txt34"
                          type="text"
                          className="borderNone clsMeat"
                          value={incomeStatementPork}
                          onChange={(e) => setIncomeStatementPork(e.target.value)}
                          onKeyUp={calculateISMeatSum}
                          data-id=""
                        />
                      </td>
                      <td>
                        <div class="select_box select_box_red_caret">
                          <Select
                            placeholder=""
                            value={selectedOptCashItem23}
                            options={data}
                            onChange={handleChangeCashItem23}
                            styles={colourStyles}
                            menuIsOpen={true}
                            isSearchable={false}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {e.icon}
                              </div>
                            )}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="tbl_manual_row">
                      <td>Interest Income</td>
                      <td className="text_right">
                      <input
                          id="txt35"
                          type="text"
                          className="borderNone clsII"
                          value={interestIncomeSum}
                          //value={Number(totalSum) + Number(accountsreceivables) + Number(nonCashItemSum) + Number(nonaccountsreceivables)}
                          onChange={(e) => SetInterestIncomeSum(e.target.value)}
                          //onKeyUp={calculateNonCurrentOtherAssetSum}
                          onKeyUp={calculateISSum}
                          data-id=""
                        />
                      </td>
                      <td>
                      <div class="select_box select_box_red_caret">
                          <Select
                            placeholder=""
                            value={selectedOptCashItem24}
                            options={data}
                            onChange={handleChangeCashItem24}
                            styles={colourStyles}
                            isSearchable={false}
                            // menuIsOpen={true}
                            menuPlacement={auto}
                            getOptionLabel={(e) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {e.icon}
                              </div>
                            )}
                          />
                        </div>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
              <div className='add_new_btn_group clsScreeningButton'>
        <button className='btn_cancel_form'>Publish</button>
        <button className='btn_save_form'>Save</button>
    </div>
            </div>
        </div>
        </div>
       
      </div>
    </>
  );
}

export default Report;


