import React, { useState, useEffect, useCallback,useRef } from 'react';
import Logo from "../images/logo.svg";
import Select from 'react-select';
import Sidebar from '../component/Sidebar';
import { SelectPicker, Placeholder, Whisper, Tooltip } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { Link } from "react-router-dom";
import debounce from 'lodash.debounce';
import 'aos/dist/aos.css';
import AOS from 'aos';


function StockReport() {
  const [tabName, setTabName] = useState('Reports');
  const [isLoading, setLoading] = useState(true); // State to manage loading state
  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
  useEffect(() => {
    document.title = tabName;
  }, [tabName]);

  useEffect(() => {
    AOS.init();
  }, []);
  

  const roleName = localStorage.getItem("novaRolename");
  const abortControllerRef = useRef(null);
  const [reportData, setReportData] = useState([]);
  const [reportCount, setReportCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [countryCode, setCountryCode] = useState("");
  const [complianceId, setComplianceId] = useState("");
  const [statusId, setStatusId] = useState("");
  const [pageRange, setPageRange] = useState("20");
  const [copiedText, setCopiedText] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  const [countryData, setCountryData] = useState([]);
  const [complianceData, setComplianceData] = useState([]);
  const [statusData, setStatusData] = useState([]);
  const userid = localStorage.getItem("novauserid");
  //Country List
  useEffect(() => {
    const fetchCountryList = async () => {
      const url = `${apiEndPoint}/get_country_list`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response

        const formattedCountryData = data.data.map((country) => ({
          label: country.country_name,
          value: country.country_code,
        }));

        setCountryData(formattedCountryData);
  
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchCountryList();
  }, []);

  // Compliance List
  useEffect(() => {
    const fetchComplianceStatusList = async () => {
      const url = `${apiEndPoint}/get_compliance_status`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response

        const formattedComplianceStatusData = data.data.map((complaince) => ({
          label: complaince.name,
          value: complaince.id,
        }));

        setComplianceData(formattedComplianceStatusData);
  
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchComplianceStatusList();
  }, []);

  //Status List
  useEffect(() => {
    const fetchStatusList = async () => {
      const url = `${apiEndPoint}/get_status`;
      const payload = {
        data: {
          user_id: userid // Replace with the actual user_id if dynamic
        }
      };
  
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const responseData = await response.json();
        const data = responseData.data; // Flatten the response

        const formattedStatusData = data.data.map((status) => ({
          label: status.name,
          value: status.id,
        }));

        setStatusData(formattedStatusData);
  
      } catch (error) {
        console.log(error);
      }
    };
  
    fetchStatusList();
  }, []);

  const fetchGlobalReport = async () => {
    setLoading(true);
// Cancel the previous request if any
if (abortControllerRef.current) {
  abortControllerRef.current.abort();
}

 // Create a new AbortController for the current request
 const controller = new AbortController();
 abortControllerRef.current = controller;



    try {
      const apiUrl = `${apiEndPoint}/get_company_report`;
      const requestData = {
        data: {
          user_id: userid,
          limit: pageRange,
          page: currentPage.toString(),
          q:searchInput || "",
          country_code: countryCode || "",
          AAOIFI_status:complianceId,
          status: statusId,
        }
      };
      console.log(JSON.stringify(requestData));
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData),
        signal: controller.signal
      });

      const jsonData = await response.json();
      const { status, message, count, data } = jsonData.data;
      if (status === "success") {
        setLoading(false);
        setReportCount(count);
        setReportData(data);
      } else {
        console.error("Error: ", message);
      }
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("Request cancelled");
      }
      else{

      console.error("Error fetching data:", error);
      }
      return false;
      
    }
  };

  const debouncedFetchGlobalReport = useCallback(
    debounce(() => {
      fetchGlobalReport();
    }, 500),
    [searchInput] // Only debounce on search input changes
  );

  useEffect(() => {
    debouncedFetchGlobalReport();
    return () => {
      debouncedFetchGlobalReport.cancel();
    };
  }, [searchInput]);


  useEffect(() => {
    fetchGlobalReport();
  }, [currentPage, countryCode, complianceId, statusId, pageRange]);

  

  
  const data = ['India', 'United States', 'Australia'].map(
    item => ({ label: item, value: item })
  );

  const data2 = ['Quarter', 'Second Quarter', 'Third Quarter'].map(
    item => ({ label: item, value: item })
  );

  const [copied, setCopied] = useState(false);

  const copyText = (text) => {
  if (text !== copiedText) {
    const tempTextArea = document.createElement('textarea');
    tempTextArea.value = text;
    document.body.appendChild(tempTextArea);
    tempTextArea.select();
    document.execCommand('copy');
    document.body.removeChild(tempTextArea);
    setCopiedText(text);
    setTimeout(() => {
      setCopiedText(null);
    }, 2000);
  }
};


 //Table Loader 
 
//  useEffect(() => {
//      // Simulate an API call or data loading delay
//      setTimeout(() => setLoading(false), 2000); // Simulate 2 seconds loading time
//    }, []);



const handleSearch = (event) => {
  setSearchInput(event.target.value);
  const inputValue = event.target.value;
  //if (inputValue.length >= 3) {
  setCurrentPage(1); // Reset to the first page for new search
  //}
};


const handleCountryChange = (value) => {
  setCountryCode(value);
};

const handleComplianceChange = (value) => {
  setComplianceId(value);
};

const handleStatusChange = (value) => {
  setStatusId(value);
};

const handleClearFilters = () => {
  setCurrentPage(1);
  setCountryCode("");
  setSearchInput("");
  setComplianceId("");
  setStatusId("");
};


const [customPaginationValue, setCustomPaginationValue] = useState("");

  // Debounced function to update the page number
  const debouncedSetPage = useCallback(
    debounce((page) => {
      setCurrentPage(page);
    }, 500), // Delay of 500ms (half a second)
    []
  );

  const handlePaginationChange = (e) => {
    const value = (e.target.value == 0 ? 1 : e.target.value).toString() ;
    setCustomPaginationValue(value); // Update input value immediately for fast typing
    debouncedSetPage(Number(value)); // Trigger the debounced function
  };

  const handlePagelimit = (value) => {
    setCurrentPage(1);
    setCustomPaginationValue(1);
    setPageRange(value); 
  };

  
  const renderPagination = () => {
    // Calculate total number of pages
    const totalPages = Math.ceil(reportCount / pageRange);
  
    // Show a default of 3 pages if the total pages are more than 3
    const pageNumbers = [];
    const visiblePageCount = 4; // Number of pages to show by default
  
    let startPage = currentPage - 1;
    let endPage = currentPage + 2;
  
    // Adjust pagination if total pages are less than the default
    if (totalPages <= visiblePageCount) {
      startPage = 1;
      endPage = totalPages;
      
    } else {
      
      // Ensure the pagination does not go out of bounds
      startPage = Math.max(1, startPage);
      endPage = Math.min(totalPages, endPage);
    }
  
    // Create page numbers to display based on current page
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
  
    return (
      <nav aria-label="..." id='pagination_row' style={{alignItems:'center', justifyContent:'space-between', background:'#FFF', paddingTop:0, paddingBottom:0}}>
        <div className='totalRecords'>
          Total Records :  {Number(reportCount).toLocaleString()}
        </div>
        <ul className="pagination" style={{border: 0, background: 'none'}}>

        <li class="page-item" style={{marginLeft:10}}>
                      <SelectPicker onChange={handlePagelimit} cleanable={false}
                      value={pageRange} 
                      placement="top" placeholder='Pages' data={PagingData} searchable={false} style={{ width: 85, height:35, display:'flex', alignItems:'center', borderRadius: 5, border: '1px solid #A1B9ED'}} />
                    </li>

          {/* Previous Page (Double Arrow) */}
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`} style={{marginLeft: 10}}>
            <a href="#" className="page-link" onClick={() => setCurrentPage(1)}>
              <i className="fa fa-angle-double-left"></i>
            </a>
          </li>
  
          {/* Previous Page (Single Arrow) */}
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a href="#" className="page-link" onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}>
              <i className="fa fa-angle-left"></i>
            </a>
          </li>
  
          {/* Page numbers */}
          {pageNumbers.map(page => (
            <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
              <a 
                className="page-link" href="#" 
                onClick={() => setCurrentPage(page)} // Set the clicked page as the current page
              >
                {page}
              </a>
            </li>
          ))}
          
          {/* Next Page (Single Arrow) */}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a href="#" className="page-link" onClick={() => setCurrentPage(Math.min(totalPages, currentPage + 1))}>
              <i className="fa fa-angle-right"></i>
            </a>
          </li>
  
          {/* Next Page (Double Arrow) */}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`} style={{marginRight: 10}}>
            <a href="#" className="page-link" onClick={() => setCurrentPage(totalPages)}>
              <i className="fa fa-angle-double-right"></i>
            </a>
          </li>
          
          {/* Go to Page */}
          <li className="page-item" style={{marginLeft: 10, display: 'flex', alignItems: 'center', gap: 5}}>
            <input
              type="text"
              placeholder="Go#"
              className="form-control"
              value={customPaginationValue}
              onChange={handlePaginationChange}
              onInput={(e) => {
                // Only allow numbers
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
              }}
            
              style={{
                padding: '0px 10px', 
                color: '#878787', 
                fontSize: 16, 
                borderRadius: 5, 
                border: '1px solid #A1B9ED', 
                width: 60, 
                height: 35, 
                textAlign: 'center'
              }}
            />
          </li>
        </ul>
      </nav>
    );
  };
  
  
  


// PAGINATION
const PagingData = ['10', '20', '50', '100'].map(
  item => ({ label: item, value: item })
);


  return (
    <>
      <Sidebar />
      <div id='header'>
        <div className='logo'>
          <div className='logo_icon'>
            <img src={Logo} alt="Logo" />
          </div>
          <div className='brand_name'>Reports</div>
        </div>

        <div className='header_table_filter'>
          <div className='searchfield'>
            <button className='btn_search'><i className='icon-search2'></i></button>
            <input type='text' className='filter_searchfield' placeholder='Search'  onChange={handleSearch}   value={searchInput}/>
          </div>

          <div className='dropdown_col_filter dropdown__select'>
            <SelectPicker
              data={statusData}
              searchable={false}
              style={{ width: 180 }}
              placeholder="Both Status"
              onChange={handleStatusChange}
              value={statusId}
            />
          </div>
          <div className='dropdown_col_filter dropdown__select'>
          <SelectPicker
              data={countryData}
              searchable={false}
              style={{width:170}}
              placeholder="Filter by Country"
              onChange={handleCountryChange}
              value={countryCode}
            />
          </div>
          <div className='dropdown_col_filter dropdown__select'>
            <SelectPicker
              data={complianceData}
              searchable={false}
              style={{ width: 180 }}
              placeholder="All Compliance"
              onChange={handleComplianceChange}
              value={complianceId}
            />
          </div>

          <a href="javascript:void(0)" className="btn_clear_filter" style={{textDecoration:"none"}} onClick={handleClearFilters}>
            Clear Filters
          </a>
          
        </div>
      </div>
      <div className="container-fluid content pb-5">
        <div className='company_table_list' data-aos="fade-up" data-aos-duration="600">
        {isLoading && ( <Placeholder.Grid rows={20} columns={9} active style={{paddingLeft:20, paddingRight:20, paddingTop:12}} /> )}

{/* Table */}
{!isLoading && (
<>
          <div className='table-responsive clsPagingWrap'>
            <table className='table'>
              <thead>
              <tr>
                  <th>Stock and Company name</th>
                  <th>Username</th>
                  <th style={{textAlign:'center'}}>ISIN</th>
                  <Whisper placement="top" controlId="control-id-hover" trigger="hover"  speaker={<Tooltip>Combined Country and Exchange information</Tooltip>}> 
                  <th style={{textAlign:'center', cursor:'pointer'}}>Country</th>
                  </Whisper>

                  
                  <th style={{textAlign:'center'}}>Quarter</th>
                  {/* <th style={{textAlign:'center'}}>Year</th> */}
                  <th>Report Stat</th>
                  <th style={{textAlign:'center'}}>Compliance</th>
                  <th style={{textAlign:'center'}}>Publish</th>
                  <th style={{textAlign:'center'}}>Actions</th>
                </tr>
              </thead>
              <tbody>
              {reportData.length > 0 ? (
  reportData.map(item => (
    <tr key={item.id}>
    <td>
      <div className='company_name_td'>
        
          <span className='stock_lable'>{item.company_symbol}</span>
          <div className="clogo">
                    <img src={item.logo} />
                  </div>
        {item.name}
      </div>
    </td>
    <td>{item.user_name}</td>
    <td style={{textAlign:'center'}}>{item.isin}</td>
    <td style={{textAlign:'center'}}>{item.country} - {item.exchange_symbol}</td>
    <td style={{textAlign:'center'}}>{item.year} - Q{item.quarter}</td>
    {/* <td style={{textAlign:'center'}}>{item.year}</td> */}
    <td>{item.status_name}</td>
    <td style={{textAlign:'center'}}>
    <span style={{margin:'auto', width:75}} className={`tag_td ${ item.AAOIFI_status === "0" || item.AAOIFI_status === null ? '' : item.AAOIFI_status === "1" ? 'halal_tag' : item.AAOIFI_status === "2" ? 'not_halal_tag' : item.AAOIFI_status === "3" ? 'doughtful_tag' : item.AAOIFI_status === "4" ? 'uncover_tag' : 'doubtful_tag' }`} >
    {item.AAOIFI_status_name == 'Non-Compliant' ? 'NCMPT' : item.AAOIFI_status_name == 'Compliant' ? 'CMPT' : item.AAOIFI_status_name == 'Doubtful' ? 'DBTF' : item.AAOIFI_status_name}
      </span>
    </td>                  
    <td style={{textAlign:'center'}}>
      <span className={item.status === '3' ? 'status_active' : 'status_unpublish'}><i className={item.status === '3' ? 'icon-check' : 'icon-cross'}></i>
  </span>
      </td>
      <td style={{ textAlign: 'center' }}>
  {roleName === "MERLIN_SENIOR_ANALYST" || roleName === "MERLIN_ADMIN" ? (
    <Link to={`/report/${item.encryptJSON}`} target="_blank" className='btn_icon_action'>
      <i className='icon-edit' style={{ cursor: "pointer" }}></i>
    </Link>
  ) : roleName === "MERLIN_JUNIOR_ANALYST" && item.status == 1 && item.user_id == userid ? (
    <Link to={`/report/${item.encryptJSON}`} target="_blank" className='btn_icon_action'>
      <i className='icon-edit' style={{ cursor: "pointer" }}></i>
    </Link>
  ) : (
    <Link to={`/report/${item.encryptJSON}`} target="_blank" className='btn_icon_action action_disable'>
      <i className='icon-edit' style={{ cursor: "pointer" }}></i>
    </Link>
  )}
</td>
  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              )}
              </tbody>
            </table>
          </div>

          {reportCount > 0 && renderPagination()}

          {/* <nav aria-label="..." id='pagination_row' style={{alignItems:'center', justifyContent:'space-between', background:'#FFF', paddingTop:0, paddingBottom:0, }}>
            <div className='totalRecords'>
                Total Records : 10,254
            </div>
                <ul class="pagination" style={{border:0,background:'none'}}>
                    <li class="page-item" style={{marginLeft:10}}>
                      <SelectPicker placement="top" placeholder='Pages' data={PagingData} searchable={false} style={{ width: 85, height:35, display:'flex', alignItems:'center', borderRadius: 5, border: '1px solid #A1B9ED'}} />
                    </li>
                    <li class="page-item disabled">
                      <a class="page-link"><i className='fa fa-angle-double-left'></i></a>
                    </li>
                    <li class="page-item">
                      <a class="page-link"><i className='icon-prev'></i></a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                    <li class="page-item active" aria-current="page">
                    <a class="page-link" href="#">2</a>
                    </li>
                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                    <li class="page-item"><a class="page-link" href="#">4</a></li>
                    <li class="page-item">
                    <a class="page-link" href="#"><i className='icon-next'></i></a>
                    </li>
                    <li class="page-item">
                      <a class="page-link"><i className='fa fa-angle-double-right'></i></a>
                    </li>

                    <li class="page-item" style={{marginLeft:10, display:'flex',alignItems:'center', gap:5,}}>
                      <input type='text' placeholder='Go#' className='form-control' style={{padding: '0px 10px', color:'#878787',fontSize: 16, borderRadius: 5, border: '1px solid #A1B9ED', width:60, height:35, textAlign:'center'}} />
                    </li>
                </ul>
          </nav> */}
          
        </>    
)}
        </div>
      </div>

      {/* Modal */}
      <div className="modal fade duplicate_form_modal" id="CreateReportModal" tabIndex="-1" aria-labelledby="DuplicateFormModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5">Igene Biotechnology Inc</h1>
            </div>
            <div className="modal-body">
              <div className='duplicate_form_content'>
                <div className='duplicate_form_row'>
                  <div className='duplicate_form_col'>
                    <label>Report Source</label>
                    <input type='text' className='form-control' placeholder='YYYY' />
                  </div>

                  <div className='duplicate_form_col'>
                    <label>Filling Type</label>
                    <div className='select_drop_picker'>
                      <SelectPicker
                        data={data2}
                        searchable={false}
                        style={{ width: '100%' }}
                        placeholder="Quarter"
                      />
                    </div>
                  </div>
                </div>

                <div className="btn__group">
                  <button type="button" className="btn_cancel" data-bs-dismiss="modal">Cancel</button>
                  <button type="button" className="btn_duplicate btn_add_new_report">Add New Report</button>
                </div>

              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default StockReport;
