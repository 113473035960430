import React, { createContext, useContext, useState, useEffect } from 'react';
import IconPlay from '../images/icon-play.svg';
import IconPause from '../images/icon-pause.svg';

// Create TimerContext
const TimerContext = createContext();

export const TimerProvider = ({ children }) => {
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [timerRunning, setTimerRunning] = useState(false);

  useEffect(() => {
    let timerInterval;
    if (timerRunning && startTime !== null) {
      timerInterval = setInterval(() => {
        const currentTime = Date.now();
        setElapsedTime(currentTime - startTime);
      }, 1000);
    }
    return () => clearInterval(timerInterval);
  }, [timerRunning, startTime]);

  return (
    <TimerContext.Provider value={{ startTime, setStartTime, elapsedTime, setElapsedTime, timerRunning, setTimerRunning }}>
      {children}
    </TimerContext.Provider>
  );
};

const useTimer = () => {
  return useContext(TimerContext);
};

// Timer Component
const Timer = ({ initialHours = 0, initialMinutes = 0, initialSeconds = 0 }) => {
  const { startTime, setStartTime, elapsedTime, setElapsedTime, timerRunning, setTimerRunning } = useTimer();

  useEffect(() => {
    if (!startTime && !elapsedTime && !timerRunning) {
      const initialElapsed = (initialHours * 60 * 60 + initialMinutes * 60 + initialSeconds) * 1000;
      setElapsedTime(initialElapsed);
      setTimerRunning(true);
      setStartTime(Date.now() - initialElapsed);
    }
  }, [initialHours, initialMinutes, initialSeconds, startTime, elapsedTime, timerRunning]);

  const formatTime = () => {
    const totalSeconds = Math.floor(elapsedTime / 1000);
    const seconds = totalSeconds % 60;
    const minutes = Math.floor((totalSeconds / 60) % 60);
    const hours = Math.floor(totalSeconds / 3600);

    return `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
  };

  const toggleTimer = () => {
    if (timerRunning) {
      setTimerRunning(false);
    } else {
      const currentTime = Date.now();
      if (!startTime) {
        setStartTime(currentTime - elapsedTime);
      }
      setTimerRunning(true);
    }
  };

  return (
    <div className='timer'>
      <span>{formatTime()}</span>
      <button onClick={toggleTimer} className='btn_timer_control'>
        {timerRunning ? <img src={IconPause} alt="Pause" /> : <img src={IconPlay} alt="Play" />}
      </button>
    </div>
  );
};

// TimerProvider with Timer and App Component
const TimerWithProvider = () => {
  return (
    <TimerProvider>
        <Timer initialHours={0} initialMinutes={1} initialSeconds={20} />
    </TimerProvider>
  );
};

export default TimerWithProvider;
