import React, { useState, useEffect } from 'react';
import Logo from "../images/logo.svg";
import { Navigate, useNavigate } from 'react-router-dom';
import { Message, useToaster } from 'rsuite';
import 'rsuite/dist/rsuite.min.css'; 
import { ReactComponent as PasswordShow } from "../images/icon-eye.svg";
import { ReactComponent as PasswordHide } from "../images/icon-eye-hide.svg";
import IconInfo from "../images/IconErrorInfo.svg";
import IconError from "../images/IconError.svg";
import IconCheckError from "../images/IconCheckError.svg";
import 'aos/dist/aos.css';
import AOS from 'aos';
import { CgOverflow } from 'react-icons/cg';
import { encryptData, decryptData } from "../pages/cryptoUtil";

const Login = () => {
  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const [tabName, setTabName] = useState('Login');

  useEffect(() => {
    document.title = tabName;
  }, [tabName]);

  useEffect(() => {
    AOS.init();
  }, []);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [messageType, setMessageType] = useState(null); // success, error, info


  const toaster = useToaster();
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleClick = async () => {
    setMessageType(null); // Reset messageType before making the API call
    //const encrypted = encryptData(JSON.stringify({ data: { username: email, password: password, }, }));
    //console.log(encrypted);
    try {
      const response = await fetch(`${apiEndPoint}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: { username: email, password: password, } }),
      });


      
      
      // //console.log(JSON.stringify({ data: { username: email, password: password, }, }));
      // console.log(encrypted);


      // const decrypted = decryptData(encrypted);
      // console.log(decrypted); // { username: "user", password: "pass" }


      //const decryptedData = decryptData(encryptedData, iv);
      
      //return false;
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

     // console.log(JSON.stringify({data: {username: email, password: password}}));

      const responseData = await response.json();
      const data = responseData.data;

      //const data = decryptData(responseData);

      if (data && data.status === 'success') {

        if(data.pwd_flag === '0')
        {
          localStorage.setItem('isLoggedIn', 'true');
          localStorage.setItem('novauserid', data.user_id);
          navigate("/ChangePassword");
        }
        else{
          setIsActive(true);
          localStorage.setItem('isLoggedIn', 'true');
          localStorage.setItem('novauserid', data.user_id);
          localStorage.setItem('novaFirstname', data.user_name);
          localStorage.setItem('novaRolename', data.role_name);
          localStorage.setItem('Identifier', data.identifier);
          
          setMessageType('success');
          setTimeout(() => {
            setIsActive(false);
            navigate('/TaskManager');
          }, 2000);
        } 
      } else {
        setErrorMessage(data.message || 'Login failed');
        setMessageType('error');
      }
    } catch (error) {
      setErrorMessage(error.message);
      setMessageType('error');
      return false;
      
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
        handleClick();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleClick]);

  const hideMessage = () => {
    setMessageType(null);
  };

  const renderMessage = () => {
    if (messageType) {
      setTimeout(hideMessage, 5000);
    };

    switch (messageType) {
      case 'success':
        return (
          <Message type="success" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10}}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <div className='icon_error_msg Icon_success'>
                <div className='icon_error_msg_inner Icon_success_inner'>
                  <img src={IconCheckError} alt="Success Icon" />
                  <div className="progress-circle progress_circle_success"></div>
                </div>
              </div>
              <div className='error_msg_text error_msg_success_text'>
                <h5>Success</h5>
                <p>You have logged in successfully!</p>
              </div>
            </div>
          </Message>
        );
      case 'error':
        return (
          <Message type="error" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10}}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <div className='icon_error_msg Icon_error'>
                <div className='icon_error_msg_inner Icon_error_inner'>
                  <img src={IconError} alt="Error Icon" />
                  <div className="progress-circle progress_circle_error"></div>
                </div>
              </div>
              <div className='error_msg_text error_msg_error_text'>
                <h5>Error</h5>
                <p>{errorMessage}</p>
              </div>
            </div>
          </Message>
        );
        case 'info':
        return (
          <Message type="info" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10}}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
              <div className='icon_error_msg Icon_info'>
                <div className='icon_error_msg_inner Icon_info_inner'>
                  <img src={IconInfo} alt="Info Icon" />
                  <div className="progress-circle progress_circle_info"></div>
                </div>
              </div>
              <div className='error_msg_text error_msg_info_text'>
                <h5>Info</h5>
                <p>No reports found for the selected priority</p>
              </div>
            </div>
          </Message>
        );
        default:
          return null;
    }
  };

  return (
    <>
      {renderMessage()}
      <div className="loader-container">
        <div className={`loader ${isActive ? 'loader--active' : ''}`}>
          <div className="loader__icon">
            <svg width="80" height="80" viewBox="0 0 67 67" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_1_591)">
                <rect y="0.282349" width="66.4354" height="66.4354" rx="15.0491" fill="#141B3C" />
                <rect x="17.0926" y="34.8087" width="0" height="7.12692" rx="3.56346" transform="rotate(-38.5482 17.0926 34.8087)" fill="#FDCC72">
                  <animate attributeName="width" dur="2s" from="0" to="71.9071" repeatCount="indefinite" />
                </rect>
                <rect x="13.556" y="50.7231" width="0" height="6.18755" rx="3.09378" transform="rotate(-38.5482 13.556 50.7231)" fill="#FDCC72">
                  <animate attributeName="width" dur="2s" from="0" to="71.9071" repeatCount="indefinite" />
                </rect>
                <path id="line1" d="M-2.48236 42.6675L20.5136 23.3716" stroke="white" strokeWidth="5.89403" strokeLinecap="round" strokeDasharray="150" strokeDashoffset="150">
                  <animate attributeName="stroke-dashoffset" dur="2s" repeatCount="indefinite" from="150" to="0" />
                </path>
                <path id="line2" d="M20.6283 23.5651L40.9626 46.5518" stroke="white" strokeWidth="5.89403" strokeLinecap="round" strokeDasharray="150" strokeDashoffset="150">
                  <animate attributeName="stroke-dashoffset" dur="2s" repeatCount="indefinite" from="150" to="0" />
                </path>
                <path id="line3" d="M41.2585 46.6971L77.2121 17.9658" stroke="white" strokeWidth="5.89403" strokeLinecap="round" strokeDasharray="150" strokeDashoffset="150">
                  <animate attributeName="stroke-dashoffset" dur="2s" repeatCount="indefinite" from="150" to="0" />
                </path>
              </g>
              <defs>
                <clipPath id="clip0_1_591">
                  <rect y="0.282349" width="66.4354" height="66.4354" rx="15.0491" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="loader__tile"></div>
        </div>
      </div>
      
      <div className="login_wrapper" style={{overflow:"hidden"}}>
        
        <div className='login_content' data-aos="fade-up" data-aos-duration="600">
          <div className='login_header'>
            <div className='login_logo'>
              <img src={Logo} alt="Logo" />
            </div>
            <div className='login_header_text'>
              <h3>Welcome Back!</h3>
              <p>Shariah Compliant status</p>
              <h6>ANALYSIS PLATFORM</h6>
            </div>
          </div>
          <div className='login_form'>
            <form>
              <div className='field_row'>
                <label className='custom_label'>Email Address</label>
                <div className='text_field'>
                  <input type='text' className='form-control' value={email} onChange={(e) => setEmail(e.target.value)}  />
                </div>
              </div>

              <div className='field_row'>
                <label className='custom_label'>Password</label>
                <div className='text_field'>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className='form-control'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} 
                  />
                  <div className='icon_field' onClick={togglePasswordVisibility}>
                    {showPassword ? <PasswordShow /> : <PasswordHide />}
                  </div>
                </div>
              </div>
              <div className='mt-5'>
                <button type='button' className='btn_login' onClick={handleClick}>Login</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      

    </>
  );
}

export default Login;
