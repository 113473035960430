import React, { useState, useEffect } from 'react';
import { Modal, Button, SelectPicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import 'rsuite/SelectPicker/styles/index.css';
import iconGreenCls from "../images/IconCalculatorGreen.svg";
import iconBlueCls from "../images/IconCalculatorBlue.svg";

const PageDemo = () => {
    useEffect(() => {
        if (QuarterlyReportOpen) {
            const backdrop = document.querySelector('.rs-modal-open');
            if (backdrop) {
                backdrop.classList.add('custom_backdrop');
            }
        }

        return () => {
            const backdrop = document.querySelector('.rs-modal-backdrop');
            if (backdrop) {
                backdrop.classList.remove('custom_backdrop');
            }
        };
    });

    const data = ['Quarter 1', 'Quarter 2', 'Quarter 3', 'Quarter 4'].map(
        item => ({ label: item, value: item })
    );
    // const [AnnualReportOpen, setIsOpen] = useState(false);
    // const handleAnualReportOpen = () => setIsOpen(true);
    // const handleAnnualReportClose = () => setIsOpen(false);

    // 
    const [AnnualReportOpen, setAnnualReportOpen] = useState(false);
    const [QuarterlyReportOpen, setQuarterlyReportOpen] = useState(false);

    const handleAnnualReportOpen = () => setAnnualReportOpen(true);
    const handleAnnualReportClose = () => setAnnualReportOpen(false);

    const handleQuarterlyReportOpen = () => setQuarterlyReportOpen(true);
    const handleQuarterlyReportClose = () => setQuarterlyReportOpen(false);

    return (
        <>
        <img src={iconBlueCls} onClick={handleAnnualReportOpen}/>
        <img src={iconGreenCls} onClick={handleQuarterlyReportOpen}/>

            {/* <Button onClick={handleAnnualReportOpen} style={{ backgroundColor: "#000", color: "#FFF", marginLeft: 12, marginTop: 20 }}>Annual Modal</Button>
            <Button onClick={handleQuarterlyReportOpen} style={{ backgroundColor: "#000", color: "#FFF", marginLeft: 12, marginTop: 20 }}>Quarterly Modal</Button> */}

            <Modal open={AnnualReportOpen} onClose={handleAnnualReportClose} className="annual_report_modal">
                <Modal.Header className="annual_report_modal_header">
                    <Modal.Title className="annual_report_modal_title">Annual Report</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <div className="annual_report_modal_content">
                        <h5 className='title_steps'>STEP - 1</h5>

                        <div className='form_field_row'>
                            <div className='form_field_col'>
                                <label className='label_form'>Beginning Cash</label>
                                <input type='text' className='form-control custom_field' placeholder='0.00' id="" />
                            </div>

                            <div className='form_field_col'>
                                <label className='label_form'>Ending Cash</label>
                                <input type='text' className='form-control custom_field' placeholder='0.00' id="" />
                            </div>
                        </div>

                        <div className='form_pink_total_row'>
                            <h4>Average Cash</h4>
                            <div className='form_pink_total_col'>00.00</div>
                        </div>

                        <h5 className='title_steps mt-4'>STEP - 2</h5>
                        <div className='form_field_row'>
                            <div className='form_field_col_full'>
                                <label className='label_form'>Interest Income</label>
                                <input type='text' className='form-control custom_field' placeholder='0.00' id="" />
                            </div>
                        </div>

                        <div className='form_pink_total_row'>
                            <h4>Interest Rate %</h4>
                            <div className='form_pink_total_col'>00.00</div>
                        </div>

                        <h5 className='title_steps mt-4'>STEP - 3</h5>
                        <div className='form_field_row'>
                            <div className='form_field_col'>
                                <label className='label_form'>Beginning Cash</label>
                                <input type='text' className='form-control custom_field' placeholder='0.00' id="" />
                            </div>

                            <div className='form_field_col'>
                                <label className='label_form'>Ending Cash</label>
                                <input type='text' className='form-control custom_field' placeholder='0.00' id="" />
                            </div>
                        </div>

                        <div className='form_field_row'>
                            <div className='form_field_col_full'>
                                <label className='label_form'>Cumulative Quarter</label>
                                <div className='annual_report_select_drop'>
                                    <SelectPicker
                                        data={data}
                                        searchable={false}
                                        style={{ width: '100%' }}
                                        placeholder="Choose Quarter"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='form_pink_total_row'>
                            <h4>Interest Income</h4>
                            <div className='form_pink_total_col'>00.00</div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer className="modal_footer_annual_report">
                    <div className="btn__group">
                        <button type="button" className="btn_cancel" onClick={handleAnnualReportClose}>Cancel</button>
                        <button className="btn_save_form">Save</button>
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal open={QuarterlyReportOpen} onClose={handleQuarterlyReportClose} className="annual_report_modal">
                <Modal.Header className="quarterly_report_modal_header">
                    <Modal.Title className="quarterly_report_modal_title">Quarterly Report</Modal.Title>
                </Modal.Header>

                <Modal.Body>

                    <div className="annual_report_modal_content">
                        <div className='form_field_row' style={{ marginTop: 0 }}>
                            <div className='form_field_col'>
                                <label className='label_form'>Beginning Cash</label>
                                <input type='text' className='form-control custom_field' placeholder='0.00' id="" />
                            </div>

                            <div className='form_field_col'>
                                <label className='label_form'>Ending Cash</label>
                                <input type='text' className='form-control custom_field' placeholder='0.00' id="" />
                            </div>
                        </div>

                        <div className='form_pink_total_row'>
                            <h4>Average Cash</h4>
                            <div className='form_pink_total_col'>00.00</div>
                        </div>

                        <div className='form_field_row' style={{ marginTop: 0 }}>
                            <div className='form_field_col'>
                                <label className='label_form'>Deducted Rate of Cntry</label>
                                <input type='text' className='form-control custom_field' placeholder='0.00' id="" />
                            </div>

                            <div className='form_field_col'>
                                <label className='label_form'>Cumulative Quarter</label>
                                <div class="annual_report_select_drop">
                                    <SelectPicker
                                        data={data}
                                        searchable={false}
                                        style={{ width: '100%' }}
                                        placeholder="Choose Quarter"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='form_pink_total_row'>
                            <h4>Interest Income</h4>
                            <div className='form_pink_total_col'>00.00</div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer className="modal_footer_annual_report">
                    <div className="btn__group">
                        <button type="button" className="btn_cancel" onClick={handleQuarterlyReportClose}>Cancel</button>
                        <button className="btn_save_form">Save</button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PageDemo;
