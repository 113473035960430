import React, { useState } from 'react';
import MenuToggle from "../images/MenuToggle.svg";
const SettingsNav = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
      };
    
      // SUBMENU
      const [openMenuIndex, setOpenMenuIndex] = useState(null);
    
      const toggleSubMenu = (index) => {
        setOpenMenuIndex(prevIndex => (prevIndex === index ? null : index));
      };

      
    return (
        <>
            <input type="checkbox" id="menu-toggle" class="toggle__input" />
            <label htmlFor="menu-toggle" class="toggle__label">
                <img src={MenuToggle} />
            </label>

            <nav className="setting_nav">
                <ul className="nav__list">
                <li className="nav__item parent" onClick={() => toggleSubMenu(0)}>
                    <a href="#" className='parent_link'><i className='icon-users'></i>Users</a>
                    <ul className={`submenu ${openMenuIndex === 0 ? 'open' : ''}`}>
                    <li><a href="#">User Profiles</a></li>
                    <li><a href="#">Analysts</a></li>
                    </ul>
                </li>

                <li className="nav__item parent" onClick={() => toggleSubMenu(1)}>
                    <a href="#" className='parent_link'><i className='icon-data-search'></i>Finnhub-Musaffa Data</a>
                    <ul className={`submenu ${openMenuIndex === 1 ? 'open' : ''}`}>
                    <li><a href="/nova/Empty-ISINS" className='active'>Empty ISINs</a></li>
                    <li><a href="/nova/EmptyMainTickers">Empty Main Tickers</a></li>
                    <li><a href="/nova/MissingMainTickers">Missing Main Tickers</a></li>
                    <li><a href="#">No Price Tickers</a></li>
                    <li><a href="#">No Profile Tickers</a></li>
                    <li><a href="#">No dividend Tickers</a></li>
                    </ul>
                </li>
                <li className="nav__item parent" onClick={() => toggleSubMenu(2)}>
                    <a href="#" className='parent_link'><i className='icon-report'></i>Finnhub Data Quality</a>
                    <ul className={`submenu ${openMenuIndex === 2 ? 'open' : ''}`}>
                    <li><a href="#">Finnhub ISIN changes</a></li>
                    <li><a href="#">Purification</a></li>
                    </ul>
                </li>
                </ul>
            </nav>
        </>
    );
};

export default SettingsNav;

 
 
