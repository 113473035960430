import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Popover, Whisper, Button, Toggle } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { ReactComponent as IconStarOutline } from "../images/star-outline.svg";
import CheckIcon from '@rsuite/icons/Check';
import CloseIcon from '@rsuite/icons/Close';

const StyledCell = styled('span')(({ theme }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));



const Test = () => {
  const [anchorEl, setAnchorEl] = useState(null); // Element to anchor the popover
  const [deleteRowId, setDeleteRowId] = useState(null); // Row ID to be deleted
  const [showThankYou, setShowThankYou] = useState(false); // State to control Thank You message visibility


  // TOGGLE

  const handleEdit = (row) => {
    console.log('Edit clicked for row:', row);
  };

  const handleDelete = (row) => {
    console.log('Delete clicked for row:', row);
  };

  const handleDeleteClick = (event, row) => {
    setAnchorEl(event.currentTarget); // Set the anchor element for the popover
    setDeleteRowId(row.id); // Set the row ID to be deleted
    setShowThankYou(false); // Hide the Thank You message when opening popover
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
    setDeleteRowId(null);
    setShowThankYou(false); // Hide the Thank You message when closing popover
  };

  const handleConfirmDelete = () => {
    const row = rows.find((r) => r.id === deleteRowId);
    if (row) {
      handleDelete(row);
    }
    setShowThankYou(true); // Show the Thank You message after confirming deletion
    setTimeout(() => {
      handleClosePopover(); // Close the popover after a delay
    }, 4000); // Adjust the delay time as needed
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'Toggle',
      headerName: 'Toggle',
      flex: 1,
      sortable: false,
      width: 80,
      autoWidth: true,
      renderCell: (params) => (
        <>
        <label class="switchSmall ">
            <input type="checkbox" />
            <small></small>
        </label>
          {/*<Toggle
            size="sm"
            checkedChildren={<CheckIcon />}
            unCheckedChildren={<CloseIcon />}
            defaultChecked
          />*/}
        </>
      ),
    },
    {
      field: 'Company',
      headerName: 'Company Name',
      width: 300,
      flex: 1,
      editable: true,
      autoWidth: true,
      renderCell: (params) => (
        <Tooltip title={params.value || ''} placement="top" arrow>
          <div><span class="company_name_td"><span class="company_lable" style={{width:'auto',paddingLeft:10,paddingRight:10}}>AGCCF</span> <StyledCell>{params.value}</StyledCell></span></div>
        </Tooltip>
      ),
    },
    {
      field: 'ISIN',
      headerName: 'ISIN',
      width: 140,
      flex: 1,
      editable: true,
      autoWidth: true,
      renderCell: (params) => (
        <Tooltip title={params.value || ''} placement="top" arrow>
          <StyledCell>{params.value}</StyledCell>
        </Tooltip>
      ),
    },
    {
      field: 'Country',
      headerName: 'Country',
      flex: 1,
      width: 90,
      editable: true,
      autoWidth: true,
      renderCell: (params) => (
        <Tooltip title={params.value || ''} placement="top" arrow>
          <StyledCell>{params.value}</StyledCell>
        </Tooltip>
      ),
    },
    {
      field: 'Compliance',
      headerName: 'Compliance',
      width: 90,
      flex: 1,
      editable: true,
      autoWidth: true,
      renderCell: (params) => {
      // Determine the class based on the Compliance value
      const complianceClass = params.value === 'NCMPT' ? 'not_halal_tag' : params.value === 'DBTF' ? 'doughtful_tag' : '';

      return (
        <Tooltip title={params.value || ''} placement="top" arrow>
          <StyledCell>
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <div className={`tag_td ${complianceClass}`} style={{ width: 80 }}>
                {params.value}
              </div>
            </div>
          </StyledCell>
        </Tooltip>
      );
    },
    },
    {
      field: 'Username',
      headerName: 'Username',
      width: 90,
      flex: 1,
      editable: true,
      autoWidth: true,
      renderCell: (params) => (
        <Tooltip title={params.value || ''} placement="top" arrow>
          <StyledCell>{params.value}</StyledCell>
        </Tooltip>
      ),
    },
    {
      field: 'Updated',
      headerName: 'Updated',
      flex: 1,
      width: 100,
      editable: false,
      autoWidth: true,
      renderCell: (params) => (
        <Tooltip title={params.value || ''} placement="top" arrow>
        <div>
          <ul class="rating_list">
            <li><IconStarOutline /></li>
            <li><IconStarOutline /></li>
            <li><IconStarOutline /></li>
            <li><IconStarOutline /></li>
            <li><IconStarOutline /></li>
          </ul>
          </div>
        </Tooltip>
      ),
    },
    {
      field: 'Quarter',
      headerName: 'Quarter',
      flex: 1,
      width: 90,
      editable: true,
      autoWidth: true,
      renderCell: (params) => (
        <Tooltip title={params.value || ''} placement="top" arrow>
          <StyledCell>{params.value}</StyledCell>
        </Tooltip>
      ),
    },
    {
      field: 'Action',
      headerName: 'Action',
      flex: 0,
      description: '',
      sortable: false,
      width: 230,
      autoWidth: false,
      renderCell: (params) => (
        <>
          <div className="action_btn_row">
          <Tooltip title={'Edit'} placement="top" arrow>          
            <div><a class="btn_create_report" href="#" style={{textDecoration:'none'}}>Create Report</a></div>
          </Tooltip>

          <Tooltip title={'Edit'} placement="top" arrow>          
            <IconButton
              onClick={() => handleEdit(params.row)}
              aria-label="edit"
              className='Icon_action_edit'
            >
              <i className="icon-edit"></i>
            </IconButton>
          </Tooltip>

          <Whisper
            placement="auto"
            trigger="click"
            // open={Boolean(anchorEl)}
            // anchorEl={anchorEl}
            // onClose={handleClosePopover}

            open={deleteRowId === params.row.id}
            anchorEl={anchorEl}
            onClose={handleClosePopover}

            // open={Boolean(anchorEl)}
            // onClose={handleClosePopover}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}

            speaker={
              <Popover className="deletePopoverWrap">
                <div className="deletePopover text-center">
                  {showThankYou ? (
                    <div class="thankyou_content">
                      <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                      </svg>
                      <h4>Thank You!</h4>
                      <p>The row has been deleted.</p>
                    </div>
                  ) : (
                    <>
                      <h5>Confirm Deletion</h5>
                      <p>Are you sure you want to delete this row?</p>

                      <div className='btn__group'>
                        <a href="#" className="btn__assign" onClick={handleConfirmDelete}>Confirm</a>
                        <a href="#" className="btn__cancel" onClick={handleClosePopover}>
                          Cancel
                        </a>
                      </div>
                    </>
                  )}
                </div>
              </Popover>
            }
          >
            <Tooltip title={'Delete'} placement="top" arrow>
              <StyledCell>
                <Button
                  onClick={(event) => handleDeleteClick(event, params.row)}
                  aria-label="delete"
                  className='Icon_action_delete'
                >
                  <DeleteIcon />
                </Button>
              </StyledCell>
            </Tooltip>

          </Whisper>
          </div>
        </>
      ),
    },
  ];

  const rows = [
    { id: 1, Toggle: 'On/Off', Company: 'Zonetail Inc', ISIN: 'CA98979N1006', Country: 'CA - NEOE', Compliance: 'NCMPT', Username: 'b', Updated: '16 Aug 2024', Quarter: '2024 - Q3', Action: 'NEW' },
    { id: 2, Toggle: 'On/Off', Company: 'Shin Nippon Biomedical Laboratories Ltd', ISIN: 'JP3379950003', Country: 'JP - XDUS', Compliance: 'NCMPT', Username: 'c', Updated: '16 Aug 2024', Quarter: '2024 - Q3', Action: 'NEW' },
    { id: 3, Toggle: 'On/Off', Company: 'Zonetail Inc', ISIN: 'CA98979N1006', Country: 'CA - NEOE', Compliance: 'DBTF', Username: 'b', Updated: '16 Aug 2024', Quarter: '2024 - Q3', Action: 'NEW' },
    { id: 4, Toggle: 'On/Off', Company: 'Shin Nippon Biomedical Laboratories Ltd', ISIN: 'JP3379950003', Country: 'JP - XDUS', Compliance: 'DBTF', Username: 'c', Updated: '16 Aug 2024', Quarter: '2024 - Q3', Action: 'NEW' },
    { id: 5, Toggle: 'On/Off', Company: 'Zonetail Inc', ISIN: 'CA98979N1006', Country: 'CA - NEOE', Compliance: 'NCMPT', Username: 'b', Updated: '16 Aug 2024', Quarter: '2024 - Q3', Action: 'NEW' },
    { id: 6, Toggle: 'On/Off', Company: 'Shin Nippon Biomedical Laboratories Ltd', ISIN: 'JP3379950003', Country: 'JP - XDUS', Compliance: 'NCMPT', Username: 'c', Updated: '16 Aug 2024', Quarter: '2024 - Q3', Action: 'NEW' },
    { id: 7, Toggle: 'On/Off', Company: 'Zonetail Inc', ISIN: 'CA98979N1006', Country: 'CA - NEOE', Compliance: 'NCMPT', Username: 'b', Updated: '16 Aug 2024', Quarter: '2024 - Q3', Action: 'NEW' },
    { id: 8, Toggle: 'On/Off', Company: 'Shin Nippon Biomedical Laboratories Ltd', ISIN: 'JP3379950003', Country: 'JP - XDUS', Compliance: 'DBTF', Username: 'c', Updated: '16 Aug 2024', Quarter: '2024 - Q3', Action: 'NEW' },
    { id: 9, Toggle: 'On/Off', Company: 'Zonetail Inc', ISIN: 'CA98979N1006', Country: 'CA - NEOE', Compliance: 'NCMPT', Username: 'b', Updated: '16 Aug 2024', Quarter: '2024 - Q3', Action: 'NEW' },
    { id: 10, Toggle: 'On/Off', Company: 'Shin Nippon Biomedical Laboratories Ltd', ISIN: 'JP3379950003', Country: 'JP - XDUS', Compliance: 'DBTF', Username: 'c', Updated: '16 Aug 2024', Quarter: '2024 - Q3', Action: 'NEW' },
    { id: 11, Toggle: 'On/Off', Company: 'Zonetail Inc', ISIN: 'CA98979N1006', Country: 'CA - NEOE', Compliance: 'DBTF', Username: 'b', Updated: '16 Aug 2024', Quarter: '2024 - Q3', Action: 'NEW' },
    { id: 12, Toggle: 'On/Off', Company: 'Shin Nippon Biomedical Laboratories Ltd', ISIN: 'JP3379950003', Country: 'JP - XDUS', Compliance: 'NCMPT', Username: 'c', Updated: '16 Aug 2024', Quarter: '2024 - Q3', Action: 'NEW' },
  ];

  return (
    <>
      <div className="container-fluid content pt-3">
        <div className='company_table_list' style={{ }}>
          <Box sx={{ width: '100%', overflowX: 'auto'}}>
            <DataGrid
              rows={rows}
              columns={columns}
              pagination={false}
              hideFooterPagination
              checkboxSelection
              disableRowSelectionOnClick
              autoPageSize={false}
            />
          </Box>
        </div>
      </div>

    </>
  );
};

export default Test;
