import React, { useState, useEffect, useCallback, useRef } from 'react';
import Logo from "../images/logo.svg";
import Select from 'react-select';
import Sidebar from '../component/Sidebar';
import { SelectPicker, Placeholder, Popover, Whisper, Button, Message } from 'rsuite';
import IconInfo from "../images/IconErrorInfo.svg";
import IconError from "../images/IconError.svg";
import IconCheckError from "../images/IconCheckError.svg";
import debounce from 'lodash.debounce';
import 'aos/dist/aos.css';
import AOS from 'aos';

function MissingCompanies() {
  const [tabName, setTabName] = useState('Missing Companies');
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    document.title = tabName;
  }, [tabName]);

  useEffect(() => {
    AOS.init();
  }, []);
  
  const abortControllerRef = useRef(null);
  const userid = localStorage.getItem("novauserid");
  const apiEndPoint = process.env.REACT_APP_API_ENDPOINT;
  const [loadingRowId, setLoadingRowId] = useState(null);

  const [pageRange, setPageRange] = useState("20");
  const [reportData, setReportData] = useState([]);
  const [reportCount, setReportCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [startPage, setStartPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setLoading] = useState(true); // State to manage loading state

  const [errorMessage, setErrorMessage] = useState('');
  const [messageType, setMessageType] = useState(null); // success, error, info
  const [searchInput, setSearchInput] = useState("");
  
  
   
   const fetchMissingCompanies = async () => {
    setLoading(true);
   // Cancel the previous request if any
   if (abortControllerRef.current) {
    abortControllerRef.current.abort();
  }

   // Create a new AbortController for the current request
   const controller = new AbortController();
   abortControllerRef.current = controller;



    try {
        const apiUrl = `${apiEndPoint}/get_non_finnhub_companies`;
        const requestData = {
          data: {
            user_id: userid,
            limit: pageRange,
            page: currentPage.toString(),
            q:searchInput,
          }
        };
        console.log(JSON.stringify(requestData));
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(requestData),
          signal: controller.signal
        });
  
        const jsonData = await response.json();
        const { status, message, count, data } = jsonData.data;
        if (status == "success") {
          setLoading(false);
          setReportCount(count);
          console.log('RES :'+ searchInput + ' : '+ JSON.stringify(data));
          setReportData(data);
        } else if(status == "error"){
          setLoading(false);
          setReportCount(count);
          setReportData(data);
          console.error("Error: ", message);
        }
      } catch (error) {
        if (error.name === "AbortError") {
          console.log("Request cancelled");
        }
        else{
        console.error("Error fetching data:", error.message);
        }
        return false;
      } finally {
      setLoading(false);
    }
  };

  const debouncedFetchGlobalReport = useCallback(
    debounce(() => {
        fetchMissingCompanies();
    }, 500),
    [searchInput] // Only debounce on search input changes
  );

  useEffect(() => {
    debouncedFetchGlobalReport();
    return () => {
      debouncedFetchGlobalReport.cancel();
    };
  }, [searchInput]);

  useEffect(() => {
    fetchMissingCompanies();
  }, [currentPage, pageRange]);


  
  const [customPaginationValue, setCustomPaginationValue] = useState("");

  // Debounced function to update the page number
  const debouncedSetPage = useCallback(
    debounce((page) => {
      setCurrentPage(page);
    }, 500), // Delay of 500ms (half a second)
    []
  );

  const handlePaginationChange = (e) => {
    const value = (e.target.value == 0 ? 1 : e.target.value).toString() ;
    setCustomPaginationValue(value); // Update input value immediately for fast typing
    debouncedSetPage(Number(value)); // Trigger the debounced function
  };

  const handlePagelimit = (value) => {
    setCurrentPage(1);
    setCustomPaginationValue(1);
    setPageRange(value); 
  };

  
  const renderPagination = () => {
    // Calculate total number of pages
    const totalPages = Math.ceil(reportCount / pageRange);
  
    // Show a default of 3 pages if the total pages are more than 3
    const pageNumbers = [];
    const visiblePageCount = 4; // Number of pages to show by default
  
    let startPage = currentPage - 1;
    let endPage = currentPage + 2;
  
    // Adjust pagination if total pages are less than the default
    if (totalPages <= visiblePageCount) {
      startPage = 1;
      endPage = totalPages;
      
    } else {
      
      // Ensure the pagination does not go out of bounds
      startPage = Math.max(1, startPage);
      endPage = Math.min(totalPages, endPage);
    }
  
    // Create page numbers to display based on current page
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
  
    return (
      <nav aria-label="..." id='pagination_row' style={{alignItems:'center', justifyContent:'space-between', background:'#FFF', paddingTop:0, paddingBottom:0}}>
        <div className='totalRecords'>
          Total Records :  {Number(reportCount).toLocaleString()}
        </div>
        <ul className="pagination" style={{border: 0, background: 'none'}}>

        <li class="page-item" style={{marginLeft:10}}>
                      <SelectPicker onChange={handlePagelimit} cleanable={false}
                      value={pageRange} 
                      placement="top" placeholder='Pages' data={PagingData} searchable={false} style={{ width: 85, height:35, display:'flex', alignItems:'center', borderRadius: 5, border: '1px solid #A1B9ED'}} />
                    </li>

          {/* Previous Page (Double Arrow) */}
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`} style={{marginLeft: 10}}>
            <a href="#" className="page-link" onClick={() => setCurrentPage(1)}>
              <i className="fa fa-angle-double-left"></i>
            </a>
          </li>
  
          {/* Previous Page (Single Arrow) */}
          <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
            <a href="#" className="page-link" onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}>
              <i className="fa fa-angle-left"></i>
            </a>
          </li>
  
          {/* Page numbers */}
          {pageNumbers.map(page => (
            <li key={page} className={`page-item ${currentPage === page ? 'active' : ''}`}>
              <a 
                className="page-link" href="#" 
                onClick={() => setCurrentPage(page)} // Set the clicked page as the current page
              >
                {page}
              </a>
            </li>
          ))}
          
          {/* Next Page (Single Arrow) */}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
            <a href="#" className="page-link" onClick={() => setCurrentPage(Math.min(totalPages, currentPage + 1))}>
              <i className="fa fa-angle-right"></i>
            </a>
          </li>
  
          {/* Next Page (Double Arrow) */}
          <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`} style={{marginRight: 10}}>
            <a href="#" className="page-link" onClick={() => setCurrentPage(totalPages)}>
              <i className="fa fa-angle-double-right"></i>
            </a>
          </li>
          
          {/* Go to Page */}
          <li className="page-item" style={{marginLeft: 10, display: 'flex', alignItems: 'center', gap: 5}}>
            <input
              type="text"
              placeholder="Go#"
              className="form-control"
              value={customPaginationValue}
              onChange={handlePaginationChange}
              onInput={(e) => {
                // Only allow numbers
                e.target.value = e.target.value.replace(/[^0-9]/g, '');
              }}
            
              style={{
                padding: '0px 10px', 
                color: '#878787', 
                fontSize: 16, 
                borderRadius: 5, 
                border: '1px solid #A1B9ED', 
                width: 60, 
                height: 35, 
                textAlign: 'center'
              }}
            />
          </li>
        </ul>
      </nav>
    );
  };
  
  
  


// PAGINATION
const PagingData = ['10', '20', '50', '100'].map(
  item => ({ label: item, value: item })
);

  
  

 


  
  const handleClearFilters = () => {
    setCurrentPage(1);
    setSearchInput("");

  };


const hideMessage = () => {
  setMessageType(null);
};

const renderMessage = () => {
  if (messageType) {
    setTimeout(hideMessage, 5000);
  };

  switch (messageType) {
    case 'success':
      return (
        <Message type="success" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10,zIndex:99}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_success'>
              <div className='icon_error_msg_inner Icon_success_inner'>
                <img src={IconCheckError} alt="Success Icon" />
                <div className="progress-circle progress_circle_success"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_success_text'>
              <h5>Success</h5>
              <p>{errorMessage}</p>
            </div>
          </div>
        </Message>
      );
    case 'error':
      return (
        <Message type="error" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10,zIndex:99}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_error'>
              <div className='icon_error_msg_inner Icon_error_inner'>
                <img src={IconError} alt="Error Icon" />
                <div className="progress-circle progress_circle_error"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_error_text'>
              <h5>Error</h5>
              <p>{errorMessage}</p>
            </div>
          </div>
        </Message>
      );
      case 'info':
      return (
        <Message type="info" closable style={{ display: 'flex', alignItems: 'center', width: 'auto', position:'absolute', right:0, top:10,zIndex:99}}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
            <div className='icon_error_msg Icon_info'>
              <div className='icon_error_msg_inner Icon_info_inner'>
                <img src={IconInfo} alt="Info Icon" />
                <div className="progress-circle progress_circle_info"></div>
              </div>
            </div>
            <div className='error_msg_text error_msg_info_text'>
              <h5>Info</h5>
              <p>{errorMessage}</p>
            </div>
          </div>
        </Message>
      );
      default:
        return null;
  }
};

const handleSearch = (event) => {
  setSearchInput(event.target.value);
  setCurrentPage(1); // Reset to the first page for new search
};



  return (
    <>
      <Sidebar />
      <div id='header'>
      {renderMessage()}
        <div className='logo'>
          <div className='logo_icon'>
            <img src={Logo} alt="Logo" />
          </div>
          <div className='brand_name'>Finhubb Missing Companies</div>
        </div>

        <div className='header_table_filter'>
        <div className='searchfield'>
            <button className='btn_search'><i className='icon-search2'></i></button>
            <input type='text' className='filter_searchfield' placeholder='Search' autocomplete="off"  onChange={handleSearch}   value={searchInput}/>
          </div>

            <a href="javascript:void(0)" className="btn_clear_filter" style={{textDecoration:"none"}} onClick={handleClearFilters}>
            Clear Filters
          </a>
        </div>
      </div>
      <div className="container-fluid content pb-5">
      <div className='company_table_list' data-aos="fade-up" data-aos-duration="600">
        {isLoading && ( <Placeholder.Grid rows={20} columns={9} active style={{paddingLeft:20, paddingRight:20, paddingTop:12}} /> )}

{/* Table */}
{!isLoading && (
<>
          <div className='table-responsive clsPagingWrap'>
            <table className='table'>
              <thead>
                <tr>
                  <th style={{textAlign:'center'}}>Main Ticker</th>
                  <th style={{textAlign:'center'}}>Sub Ticker</th>
                  <th>Company Name</th>
                </tr>
              </thead>
              <tbody>
              {reportData.length > 0 ? (
  reportData.map((request, index) => (
    <tr key={index}>
    <td style={{textAlign:'center'}}><span style={{margin:'auto'}} className='company_lable2'>{request.mainticker}</span></td>
    <td style={{textAlign:'center'}}><span style={{margin:'auto'}} className='company_lable2'>{request.subticker}</span></td>
    <td>
                      <div className='company_name_td'>
                        {request.company_name}
                      </div>
                    </td>
  </tr>

                ))
              ) : (
                <tr>
                  <td colSpan="1" style={{ textAlign: "center" }}>
                    No data available
                  </td>
                </tr>
              )}
              </tbody>
            </table>
          </div>

          {reportCount > 1 && renderPagination()}


        </>    
)}
        </div>
      </div>
    </>
  );
}
export default MissingCompanies;
