import React, { useState, useEffect } from 'react';
import Logo from "../images/logo.svg";
import Select from 'react-select';
import Sidebar from '../component/Sidebar';
import SettingsNav from '../component/SettingsNav';


function EmptyMainTickers() {
  const [tabName, setTabName] = useState('Empty Main Tickers');

    useEffect(() => {
      document.title = tabName;
    }, [tabName]);
  const [copied, setCopied] = useState(false);

  const options = [
    { value: 'India', label: 'India' },
    { value: 'United States', label: 'United States' },
    { value: 'Australia', label: 'Australia' }
  ]
  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isFocused ? "#F8F8F8" : "",
        color: "#333333",
        borderBottom: 1,
        borderStyle: "solid",
        borderColor: "#F2F2F2",
        fontSize: 14,
        fontWeight: 500,
      };
    }
  };

  const [selectedOption, setSelectedOption] = useState(null);
  // handle onChange event of the dropdown
  const handleChange = e => {
    setSelectedOption(e);
  }
  
  return (
    <>
      <Sidebar />

      <div id='header'>
        <div className='logo'>
          <div className='logo_icon'>
            <img src={Logo} />
          </div>
          <div className='brand_name'>Settings</div>
        </div>
      </div>
      <div className="container-fluid content pb-5">
        <div className='company_table_list settign_table_list'>
         
        <SettingsNav />

        <div className='setting_table_content'>
          <div className='setting_page_table_header'>
            <h5 className='page_subtitle'>Empty Main Tickers</h5>

            <div className='setting_table_filter_row'>
                <div className='searchfield'>
                  <button className='btn_search'><i className='icon-search'></i></button>
                  <input type='text' className='filter_searchfield' placeholder='Search by ISIN' />
                </div>

                <div className='dropdown_col_filter dropdown_select'>
                  <Select options={options}
                  styles={colourStyles}
                  placeholder="All Countries" />
                </div>
            </div>
          </div>
          
        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th>Ticker</th>
                {/* <th>Ticker</th> */}
                <th>Company Name</th>
                <th>Country</th>
                <th style={{ textAlign: 'center' }}>Curr</th>
                <th style={{ textAlign: 'center' }}>Main Cu</th>
                <th style={{ textAlign: 'center' }}>Exch</th>
                <th style={{ textAlign: 'center' }}>ISIN</th>
                <th>Created</th>
                <th>Last Updated</th>
                <th>Publish Status</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div className='company_name_td'>
                    <span className='stock_lable'>543375.BO</span>
                  </div>
                </td>
                {/* <td><span className='stock_lable'>543375.BO</span></td> */}
                <td>
                  Central Puerto SA
                </td>
                <td>United States</td>
                <td style={{ textAlign: 'center' }}>EUR</td>
                <td style={{ textAlign: 'center' }}>EUR</td>
                <td style={{ textAlign: 'center' }}>NYSE</td>
                <td>ARP2354W1188</td>
                <td>Jan 20, 1970</td>
                <td>Jan 20, 1970</td>
                <td>Unpublished</td>
                <td>
                  <div className='action_row'>
                    <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <div className='company_name_td'>
                    <span className='stock_lable'>543375.BO</span>
                  </div>
                </td>

                <td>
                  Central Puerto SA
                </td>
                <td>United States</td>
                <td style={{ textAlign: 'center' }}>EUR</td>
                <td style={{ textAlign: 'center' }}>EUR</td>
                <td style={{ textAlign: 'center' }}>NYSE</td>
                <td>ARP2354W1188</td>
                <td>Jan 20, 1970</td>
                <td>Jan 20, 1970</td>
                <td>Unpublished</td>
                <td>
                  <div className='action_row'>
                    <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <div className='company_name_td'>
                    <span className='stock_lable'>543375.BO</span>
                  </div>
                </td>

                <td>
                  Central Puerto SA
                </td>
                <td>United States</td>
                <td style={{ textAlign: 'center' }}>EUR</td>
                <td style={{ textAlign: 'center' }}>EUR</td>
                <td style={{ textAlign: 'center' }}>NYSE</td>
                <td>ARP2354W1188</td>
                <td>Jan 20, 1970</td>
                <td>Jan 20, 1970</td>
                <td>Unpublished</td>
                <td>
                  <div className='action_row'>
                    <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <div className='company_name_td'>
                    <span className='stock_lable'>543375.BO</span>
                  </div>
                </td>

                <td>
                  Central Puerto SA
                </td>
                <td>United States</td>
                <td style={{ textAlign: 'center' }}>EUR</td>
                <td style={{ textAlign: 'center' }}>EUR</td>
                <td style={{ textAlign: 'center' }}>NYSE</td>
                <td>ARP2354W1188</td>
                <td>Jan 20, 1970</td>
                <td>Jan 20, 1970</td>
                <td>Unpublished</td>
                <td>
                  <div className='action_row'>
                    <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <div className='company_name_td'>
                    <span className='stock_lable'>543375.BO</span>
                  </div>
                </td>

                <td>
                  Central Puerto SA
                </td>
                <td>United States</td>
                <td style={{ textAlign: 'center' }}>EUR</td>
                <td style={{ textAlign: 'center' }}>EUR</td>
                <td style={{ textAlign: 'center' }}>NYSE</td>
                <td>ARP2354W1188</td>
                <td>Jan 20, 1970</td>
                <td>Jan 20, 1970</td>
                <td>Unpublished</td>
                <td>
                  <div className='action_row'>
                    <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <div className='company_name_td'>
                    <span className='stock_lable'>543375.BO</span>
                  </div>
                </td>

                <td>
                  Central Puerto SA
                </td>
                <td>United States</td>
                <td style={{ textAlign: 'center' }}>EUR</td>
                <td style={{ textAlign: 'center' }}>EUR</td>
                <td style={{ textAlign: 'center' }}>NYSE</td>
                <td>ARP2354W1188</td>
                <td>Jan 20, 1970</td>
                <td>Jan 20, 1970</td>
                <td>Unpublished</td>
                <td>
                  <div className='action_row'>
                    <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <div className='company_name_td'>
                    <span className='stock_lable'>543375.BO</span>
                  </div>
                </td>

                <td>
                  Central Puerto SA
                </td>
                <td>United States</td>
                <td style={{ textAlign: 'center' }}>EUR</td>
                <td style={{ textAlign: 'center' }}>EUR</td>
                <td style={{ textAlign: 'center' }}>NYSE</td>
                <td>ARP2354W1188</td>
                <td>Jan 20, 1970</td>
                <td>Jan 20, 1970</td>
                <td>Unpublished</td>
                <td>
                  <div className='action_row'>
                    <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <div className='company_name_td'>
                    <span className='stock_lable'>543375.BO</span>
                  </div>
                </td>

                <td>
                  Central Puerto SA
                </td>
                <td>United States</td>
                <td style={{ textAlign: 'center' }}>EUR</td>
                <td style={{ textAlign: 'center' }}>EUR</td>
                <td style={{ textAlign: 'center' }}>NYSE</td>
                <td>ARP2354W1188</td>
                <td>Jan 20, 1970</td>
                <td>Jan 20, 1970</td>
                <td>Unpublished</td>
                <td>
                  <div className='action_row'>
                    <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <div className='company_name_td'>
                    <span className='stock_lable'>543375.BO</span>
                  </div>
                </td>

                <td>
                  Central Puerto SA
                </td>
                <td>United States</td>
                <td style={{ textAlign: 'center' }}>EUR</td>
                <td style={{ textAlign: 'center' }}>EUR</td>
                <td style={{ textAlign: 'center' }}>NYSE</td>
                <td>ARP2354W1188</td>
                <td>Jan 20, 1970</td>
                <td>Jan 20, 1970</td>
                <td>Unpublished</td>
                <td>
                  <div className='action_row'>
                    <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <div className='company_name_td'>
                    <span className='stock_lable'>543375.BO</span>
                  </div>
                </td>

                <td>
                  Central Puerto SA
                </td>
                <td>United States</td>
                <td style={{ textAlign: 'center' }}>EUR</td>
                <td style={{ textAlign: 'center' }}>EUR</td>
                <td style={{ textAlign: 'center' }}>NYSE</td>
                <td>ARP2354W1188</td>
                <td>Jan 20, 1970</td>
                <td>Jan 20, 1970</td>
                <td>Unpublished</td>
                <td>
                  <div className='action_row'>
                    <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <div className='company_name_td'>
                    <span className='stock_lable'>543375.BO</span>
                  </div>
                </td>

                <td>
                  Central Puerto SA
                </td>
                <td>United States</td>
                <td style={{ textAlign: 'center' }}>EUR</td>
                <td style={{ textAlign: 'center' }}>EUR</td>
                <td style={{ textAlign: 'center' }}>NYSE</td>
                <td>ARP2354W1188</td>
                <td>Jan 20, 1970</td>
                <td>Jan 20, 1970</td>
                <td>Unpublished</td>
                <td>
                  <div className='action_row'>
                    <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <div className='company_name_td'>
                    <span className='stock_lable'>543375.BO</span>
                  </div>
                </td>

                <td>
                  Central Puerto SA
                </td>
                <td>United States</td>
                <td style={{ textAlign: 'center' }}>EUR</td>
                <td style={{ textAlign: 'center' }}>EUR</td>
                <td style={{ textAlign: 'center' }}>NYSE</td>
                <td>ARP2354W1188</td>
                <td>Jan 20, 1970</td>
                <td>Jan 20, 1970</td>
                <td>Unpublished</td>
                <td>
                  <div className='action_row'>
                    <a href='#' className='btn_icon_action'><i className='icon-edit'></i></a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

          <nav aria-label="..." id='pagination_row'>
              <ul class="pagination">
                  <li class="page-item disabled">
                  <a class="page-link"><i className='icon-prev'></i></a>
                  </li>
                  <li class="page-item"><a class="page-link" href="#">1</a></li>
                  <li class="page-item active" aria-current="page">
                  <a class="page-link" href="#">2</a>
                  </li>
                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                  <li class="page-item"><a class="page-link" href="#">4</a></li>
                  <li class="page-item"><a class="page-link" href="#">5</a></li>
                  <li class="page-item">
                  <a class="page-link" href="#"><i className='icon-next'></i></a>
                  </li>
              </ul>
          </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmptyMainTickers;
